import { FC } from "react";
import AceEditor from "react-ace";
import { Labeled } from "react-admin";
import { useForm, useFormState } from "react-final-form";
import { isString } from "../utils/helpers";

import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-twilight";

type AceEditorInputProps = {
    source: string;
    label?: string;
    [key: string]: any;
};

const AceEditorInput: FC<AceEditorInputProps> = ({
    source,
    label = source,
    ...rest
}) => {
    const form = useForm();
    const { values } = useFormState();

    const handleChange = (newValue) => {
        form.change(source, newValue);
    };

    return (
        <Labeled label={label} fullWidth>
            <AceEditor
                width="1000px"
                theme="twilight"
                fontSize={14}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 4,
                }}
                value={isString(values[source]) ? values[source] : ""}
                onChange={handleChange}
                wrapEnabled
                highlightActiveLine
                showPrintMargin
                showGutter
                {...rest}
            />
        </Labeled>
    );
};

export default AceEditorInput;
