import { Box } from "@material-ui/core";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import { FC, useState } from "react";
import {
    ArrayField,
    BooleanField,
    Button,
    Datagrid,
    FunctionField,
    Labeled,
    Link,
    NumberField,
    Pagination,
    Record,
    ReferenceField,
    ReferenceManyField,
    Show,
    ShowProps,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    useShowController,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import AroggaImageField from "../../../components/AroggaImageField";
import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";
import {
    capitalizeFirstLetterOfEachWord,
    getQuantityLabel,
    getReadableSKU,
} from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import IssueApprovalDialog from "./IssueApprovalDialog";

const IssueShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Issue Show");
    const classes = useAroggaStyles();
    const { record } = useShowController(props);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [status, setStatus] = useState(null);

    const issueType = record?.i_type;
    const renderQtyLabel = (source: string, record: Record) => (
        <span className={classes.whitespaceNowrap}>
            {getQuantityLabel({
                qty: record?.[source],
                salesUnit:
                    issueType === "replacement" && source === "ic_in_count"
                        ? record?.replacement_unit_label
                        : record?.unit_label,
                baseUnit:
                    issueType === "replacement" && source === "ic_in_count"
                        ? record?.product_replacement_base_unit
                        : record?.product_base_unit,
                salesUnitMultiplier:
                    issueType === "replacement" && source === "ic_in_count"
                        ? record?.replacement_unit_multiplier
                        : record?.unit_multiplier,
            })}
        </span>
    );

    const renderApprovalButtons = (issueStatus) => {
        if (issueStatus !== "processing") return;

        return (
            <Box display="flex" gridGap={5} mt={1}>
                <Button
                    label="Approve"
                    variant="contained"
                    onClick={() => handleApproval("approved")}
                />
                <Button
                    label="Reject"
                    variant="contained"
                    style={{ backgroundColor: "#dc3545" }}
                    onClick={() => handleApproval("rejected")}
                />
            </Box>
        );
    };

    const handleApproval = (status) => {
        setStatus(status);
        setIsDialogOpen(true);
    };
    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="Issues">
                    <ColumnShowLayout md={4} simpleShowLayout={false}>
                        <TextField source="i_id" label="ID" />
                        <FunctionField
                            label="Shipment ID"
                            onClick={(e) => e.stopPropagation()}
                            sortBy="i_shipment_id"
                            render={({
                                i_shipment_id,
                                i_order_id,
                                s_sequence,
                            }: Record) => (
                                <Link to={`/v1/shipment/${i_shipment_id}/show`}>
                                    {i_order_id}
                                    {s_sequence}
                                </Link>
                            )}
                        />
                        <FunctionField
                            label="Order ID"
                            onClick={(e) => e.stopPropagation()}
                            sortBy="i_order_id"
                            render={({ i_order_id }: Record) => (
                                <Link
                                    to={`/v1/productOrder/${i_order_id}/show`}
                                >
                                    {i_order_id}
                                </Link>
                            )}
                        />
                        <TextField
                            source="i_type"
                            label="Type"
                            className={classes.capitalize}
                        />
                        <FunctionField
                            label="Status"
                            onClick={(e) => e.stopPropagation()}
                            sortBy="i_status"
                            className={classes.whitespaceNowrap}
                            render={({ i_status }: Record) =>
                                capitalizeFirstLetterOfEachWord(i_status)
                            }
                        />
                        <ReferenceField
                            source="i_shipment_id"
                            label="Cold"
                            reference="v1/shipment"
                            link={false}
                        >
                            <FunctionField
                                render={(record: Record) => {
                                    if (!record?.s_m_cold) return;
                                    return <AcUnitIcon />;
                                }}
                            />
                        </ReferenceField>
                        <NumberField
                            source="i_total_in_amount"
                            label="Total In Amount"
                        />
                        <NumberField
                            source="i_total_out_amount"
                            label="Total Out Amount"
                        />
                        <TextField
                            source="i_money_or_product"
                            label="Return Money / Product"
                            className={classes.capitalize}
                        />
                        <BooleanField
                            source="i_is_calculate_additions"
                            label="Calculate Additions?"
                            FalseIcon={() => null}
                            looseValue
                        />
                        <TextField source="i_details" label="Details" />
                        <AroggaImageField
                            source="attachedFiles_i_images"
                            label="Images"
                            className="small__img"
                        />
                        <AroggaDateField
                            source="i_created_at"
                            label="Created At"
                        />
                        <ReferenceField
                            source="i_created_by"
                            label="Created By"
                            reference="v1/users"
                            link="show"
                        >
                            <TextField source="u_name" />
                        </ReferenceField>
                    </ColumnShowLayout>
                    <Labeled label="Details">
                        <>
                            <ArrayField source="ic">
                                <Datagrid>
                                    <TextField
                                        source="ic_id"
                                        label="Details ID"
                                    />
                                    <ReferenceField
                                        source="ic_product_id"
                                        label="Given Product"
                                        reference="v1/product"
                                        link="show"
                                    >
                                        <TextField source="p_name" />
                                    </ReferenceField>
                                    <ReferenceField
                                        source="ic_variant_id"
                                        label="Given Variant"
                                        reference="v1/productVariant"
                                        link={false}
                                    >
                                        <FunctionField
                                            render={(record: Record) =>
                                                getReadableSKU(
                                                    record.pv_attribute
                                                )
                                            }
                                        />
                                    </ReferenceField>
                                    {/* TODO: */}
                                    {record?.i_type === "damage_refund" ? (
                                        <FunctionField
                                            label="Given Qty"
                                            render={(record: Record) => (
                                                <p>
                                                    {record?.ic_out_count}
                                                    <span>
                                                        {" "}
                                                        {
                                                            record?.product_base_unit
                                                        }
                                                    </span>
                                                </p>
                                            )}
                                        />
                                    ) : (
                                        <FunctionField
                                            source="ic_out_count"
                                            label="Given Qty"
                                            render={(record: Record) =>
                                                renderQtyLabel(
                                                    "ic_out_count",
                                                    record
                                                )
                                            }
                                        />
                                    )}
                                    <ReferenceField
                                        source="ic_in_product_id"
                                        label="Taken Product"
                                        reference="v1/product"
                                        link="show"
                                    >
                                        <TextField source="p_name" />
                                    </ReferenceField>
                                    <ReferenceField
                                        source="ic_in_variant_id"
                                        label="Taken Variant"
                                        reference="v1/productVariant"
                                        link={false}
                                    >
                                        <FunctionField
                                            render={(record: Record) =>
                                                getReadableSKU(
                                                    record.pv_attribute
                                                )
                                            }
                                        />
                                    </ReferenceField>
                                    <FunctionField
                                        source="ic_in_count"
                                        label="Taken Qty"
                                        render={(record: Record) =>
                                            renderQtyLabel(
                                                "ic_in_count",
                                                record
                                            )
                                        }
                                    />
                                </Datagrid>
                            </ArrayField>
                            <FunctionField
                                addLabel={false}
                                render={({ i_status }: Record) =>
                                    renderApprovalButtons(i_status)
                                }
                            />
                            <SimpleForm toolbar={null}>
                                <IssueApprovalDialog
                                    isDialogOpen={isDialogOpen}
                                    setIsDialogOpen={setIsDialogOpen}
                                    status={status}
                                    {...props}
                                />
                            </SimpleForm>
                        </>
                    </Labeled>
                </Tab>
                <Tab label="History">
                    <ReferenceManyField
                        reference="v1/orderActivityLog"
                        target="_issue_id"
                        pagination={<Pagination />}
                        addLabel={false}
                        sort={{ field: "oal_created_at", order: "DESC" }}
                    >
                        <Datagrid>
                            <ReferenceField
                                source="oal_created_by"
                                label="Name"
                                reference="v1/users"
                                link="show"
                            >
                                <TextField source="u_name" />
                            </ReferenceField>
                            <AroggaDateField
                                source="oal_created_at"
                                label="Date"
                            />
                            <TextField source="oal_title" label="Title" />
                            <TextField
                                source="oal_description"
                                label="Description"
                            />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default IssueShow;
