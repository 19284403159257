import { Grid } from "@material-ui/core";
import {
    TextInput,
    required,
    ReferenceInput,
    AutocompleteInput,
} from "react-admin";
import { useFormState } from "react-final-form";

const validateRequired = [required()];

const QuotationItemMappingForm = () => {
    const { values } = useFormState();

    return (
        <Grid container spacing={1}>
            {!!values.ac_id && (
                <Grid item sm={6} md={4}>
                    <TextInput
                        source="ac_id"
                        label="ID"
                        variant="outlined"
                        helperText={false}
                        fullWidth
                        disabled
                    />
                </Grid>
            )}
            <Grid item sm={6} md={4}>
                <TextInput
                    source="qim_name"
                    label="Name"
                    variant="outlined"
                    helperText={false}
                    validate={validateRequired}
                />
                <ReferenceInput
                    source="qim_head_id"
                    label="Account From"
                    reference="v1/accountingHead"
                    variant="outlined"
                    helperText={false}
                >
                    <AutocompleteInput
                        optionValue="ah_id"
                        optionText="ah_name"
                        resettable
                    />
                </ReferenceInput>
            </Grid>
        </Grid>
    );
};

export default QuotationItemMappingForm;
