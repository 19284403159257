import { makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

const LabTestCalculate = ({ calculate }) => {
    const classes = useStyles();
    return (
        <div>
            <TableContainer>
                <Table size="small" className={classes.table} align="center">
                    <TableBody>
                        <TableRow>
                            <TableCell>Subtotal</TableCell>
                            <TableCell align="right">
                                {calculate.subtotalAmount}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Discount Applied</TableCell>
                            <TableCell align="right">
                                {calculate.discountAmount}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Arogga Cash</TableCell>
                            <TableCell align="right">
                                {calculate.cash}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Rounding Off</TableCell>
                            <TableCell align="right">
                                {calculate.roundingOff}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Sample Collection Conveyance</TableCell>
                            <TableCell align="right">
                                {calculate.collectionConveyance}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Delivery Conveyance</TableCell>
                            <TableCell align="right">
                                {calculate.hardCopyConveyance}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}>
                                Total Amount
                            </TableCell>
                            <TableCell
                                align="right"
                                style={{ fontWeight: "bold" }}
                            >
                                {calculate.totalAmount}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
const useStyles = makeStyles(() => ({
    table: {
        width: 362,
        marginTop: 20,
        marginBottom: 18,
    },
}));

export default LabTestCalculate;
