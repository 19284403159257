import { FC } from "react";
import { Create, CreateProps, SimpleForm } from "react-admin";

import VariantForm from "../../../components/manageDatabase/variants/VariantForm";
import { useDocumentTitle } from "../../../hooks";

const VariantCreate: FC<CreateProps> = ({ hasCreate, ...rest }) => {
    useDocumentTitle("Arogga | Variant Create");

    return (
        <Create {...rest}>
            <SimpleForm
                redirect="list"
                initialValues={{
                    vt_config: [
                        {
                            name: "",
                            weight: "",
                            status: 0,
                        },
                    ],
                }}
            >
                <VariantForm />
            </SimpleForm>
        </Create>
    );
};

export default VariantCreate;
