import { FC } from "react";
import { Create, CreateProps, SimpleForm } from "react-admin";

import { useDocumentTitle } from "../../../hooks";
import QuotationItemMappingForm from "../../../components/manageRequisition/quotationItemMapping/QuotationItemMappingForm";

const QuotationItemMappingCreate: FC<CreateProps> = ({
    hasCreate,
    ...rest
}) => {
    useDocumentTitle("Arogga | Quotation Item Mapping Create");

    return (
        <Create {...rest}>
            <SimpleForm redirect="list">
                <QuotationItemMappingForm />
            </SimpleForm>
        </Create>
    );
};

export default QuotationItemMappingCreate;
