import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { Link } from "react-admin";
import { useFormState } from "react-final-form";

type LocationInvalidDialogProps = {
    isInvalidLocationDialogOpen: boolean;
    onDialogClose: () => void;
};

const LocationInvalidDialog: FC<LocationInvalidDialogProps> = ({
    isInvalidLocationDialogOpen,
    onDialogClose,
}) => {
    const { values } = useFormState();
    return (
        <Dialog open={isInvalidLocationDialogOpen} onClose={onDialogClose}>
            <DialogTitle>
                Invalid user location! Do you want to update user location?
            </DialogTitle>
            <DialogActions>
                <Link
                    to={{
                        pathname: `/v1/userLocations/${values.po_user_location_id}`,
                    }}
                    target="_blank"
                >
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={onDialogClose}
                        style={{
                            fontWeight: 600,
                            fontSize: 16,
                        }}
                    >
                        Update Location
                    </Button>
                </Link>
            </DialogActions>
        </Dialog>
    );
};

export default LocationInvalidDialog;
