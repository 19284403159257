import { FC } from "react";
import {
    ReferenceField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import { useDocumentTitle } from "../../../hooks";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

const BulkUserShow: FC<ShowProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Bulk User Show");

    const classes = useAroggaStyles();

    return (
        <Show {...rest}>
            <SimpleShowLayout>
                <TextField source="bucr_id" label="ID" />
                <TextField source="bucr_title" label="Title" />
                <TextField
                    source="bucr_request_status"
                    label="Request Status"
                    className={classes.capitalize}
                />
                <TextField
                    source="bucr_previous_exists_user_count"
                    label="Previous Exists User Count"
                />
                <TextField
                    source="bucr_created_user_count"
                    label="Created User Count"
                />
                <AroggaDateField source="bucr_created_at" label="Created At" />
                <ReferenceField
                    source="bucr_created_by"
                    label="Created By"
                    reference="v1/users"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    );
};

export default BulkUserShow;
