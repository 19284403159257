import { FC } from "react";
import { SelectInput } from "react-admin";
import { useFormState } from "react-final-form";

type DistrictInputProps = {
    locations: any;
    setLocations: (locations) => void;
    [key: string]: any;
};

const DistrictLocationInputProps: FC<DistrictInputProps> = ({
    locations,
    setLocations,
    ...rest
}) => {
    const { values } = useFormState();

    const toChoices = (items = []) =>
        items?.map((item) => ({ id: item, name: item }));

    if (!locations) return null;

    return (
        <SelectInput
            choices={
                !!values.l_division
                    ? toChoices(Object.keys(locations[values.l_division]))
                    : []
            }
            {...rest}
        />
    );
};

export default DistrictLocationInputProps;
