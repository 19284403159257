import { Box, Grid } from "@material-ui/core";
import { FC, useState } from "react";
import {
    AutocompleteInput,
    Confirm,
    DateInput,
    FileField,
    ReferenceInput,
    TextInput,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import AroggaButton from "../../../../components/AroggaButton";
import CustomField from "../../../../components/CustomField";
import FormatedBooleanInput from "../../../../components/FormatedBooleanInput";
import TaxonomiesByVocabularyInput from "../../../../components/TaxonomiesByVocabularyInput";
import WarehouseInput from "../../../../components/WarehouseInput";
import CancelOrderDialog from "../../../../components/manageOrder/orders/CancelOrderDialog";
import InternalNote from "../../../../components/manageOrder/orders/InternalNote";
import { useRequest } from "../../../../hooks";
import { toFormattedDateTime } from "../../../../utils/helpers";
import { useAroggaStyles } from "../../../../utils/useAroggaStyles";

type SetupProps = {
    record?: any;
    permissions: string[];
    [key: string]: any;
};

const Setup: FC<SetupProps> = ({ permissions, ...rest }) => {
    const classes = useAroggaStyles();
    const form = useForm();
    const { values } = useFormState();

    const [
        isRejectCancelRequestDialogOpen,
        setIsRejectCancelRequestDialogOpen,
    ] = useState(false);
    const [isCancelOrderDialogOpen, setIsCancelOrderDialogOpen] =
        useState(false);

    const { record } = rest;

    const {
        isLoading: isLoadingRejectCancelRequest,
        refetch: handleRejectCancelRequest,
    } = useRequest(
        `/v1/productOrderAction/${record?.po_id}/falseCallAction`,
        {
            method: "POST",
        },
        {
            isRefresh: true,
            onSuccess: () => setIsRejectCancelRequestDialogOpen(false),
        }
    );

    return (
        <>
            <Grid container spacing={1}>
                <Grid item sm={2}>
                    <CustomField
                        label="Status"
                        value={values.po_status}
                        className={`${classes.capitalize} ${
                            ["processing", "cancelled"].includes(
                                values.po_status
                            ) && classes.textRed
                        }`}
                    />
                </Grid>
                {values.po_status !== "delivered" && (
                    <Grid
                        item
                        sm={6}
                        md={3}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <FormatedBooleanInput
                            source="po_is_urgent"
                            label="Urgent?"
                        />
                        {values.invoice_url && (
                            <Box mb={1}>
                                <FileField
                                    source="invoice_url"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    label="Invoice"
                                    title="Invoice"
                                />
                            </Box>
                        )}
                    </Grid>
                )}
                <Grid container spacing={1} className={classes.relative}>
                    <Grid item sm={6} md={2}>
                        <WarehouseInput
                            source="po_warehouse_id"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <TaxonomiesByVocabularyInput
                            fetchKey="shift_type"
                            source="po_delivery_option"
                            label="Delivery Option"
                            helperText={false}
                            excludeChoiceByMachineName={["general"]}
                            disabled={values.is_outside_dhaka}
                            fullWidth
                        />
                    </Grid>
                    {values.po_delivery_option === "express" && (
                        <Grid item sm={6} md={2}>
                            <DateInput
                                source="deliveryDate"
                                label="Delivery Date"
                                variant="outlined"
                                helperText={false}
                                inputProps={{
                                    min: toFormattedDateTime({
                                        isDate: true,
                                        dateString: new Date().toString(),
                                    }),
                                }}
                                onChange={() =>
                                    form.change(
                                        "po_delivery_shift_schedule_id",
                                        undefined
                                    )
                                }
                                fullWidth
                            />
                        </Grid>
                    )}
                    {values.po_delivery_option === "express" && (
                        <Grid item sm={6} md={2}>
                            <ReferenceInput
                                source="po_delivery_shift_schedule_id"
                                label="Delivery Shift Schedule"
                                variant="outlined"
                                helperText={false}
                                reference="v1/shiftSchedule"
                                filter={{
                                    _shift_type: values.po_delivery_option,
                                    _from_date: values.deliveryDate,
                                    _to_date: values.deliveryDate,
                                }}
                                fullWidth
                            >
                                <AutocompleteInput
                                    matchSuggestion={() => true}
                                    optionText={(item) =>
                                        `${item?.s_title} (${item?.ss_date})`
                                    }
                                    resettable
                                />
                            </ReferenceInput>
                        </Grid>
                    )}
                    <Grid item sm={6} md={3}>
                        <TextInput
                            source="po_user_notes"
                            label="Note (Visible to Customer)"
                            variant="outlined"
                            helperText={false}
                            minRows={2}
                            multiline
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={3}>
                        <InternalNote />
                    </Grid>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" gridGap={5}>
                {record?.po_status !== "cancelled" &&
                    !!record?.po_cancel_requested_by && (
                        <>
                            <AroggaButton
                                label="Reject Cancel Request"
                                type="danger"
                                onClick={() =>
                                    setIsRejectCancelRequestDialogOpen(true)
                                }
                            />
                            <Confirm
                                isOpen={isRejectCancelRequestDialogOpen}
                                loading={isLoadingRejectCancelRequest}
                                title={`Are you sure you want to reject cancel request
						 this order? #${record?.po_id}`}
                                content={false}
                                onConfirm={handleRejectCancelRequest}
                                onClose={() =>
                                    setIsRejectCancelRequestDialogOpen(false)
                                }
                            />
                        </>
                    )}
                {(permissions?.includes("orderStatusProcessingToCancelled") ||
                    permissions?.includes("orderStatusConfirmedToCancelled")) &&
                    // TODO: Ask for confirmation (Shamim bhai)
                    (record?.po_status === "processing" ||
                        record?.po_status === "confirmed") && (
                        <AroggaButton
                            label="Cancel this order"
                            type="danger"
                            onClick={() => setIsCancelOrderDialogOpen(true)}
                        />
                    )}
                <CancelOrderDialog
                    open={isCancelOrderDialogOpen}
                    handleDialogClose={() => setIsCancelOrderDialogOpen(false)}
                />
            </Box>
        </>
    );
};

export default Setup;
