import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import { FC } from "react";
import { TextInput } from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { useRequest } from "../hooks";
import AroggaDialogActions from "./AroggaDialogActions";

type TaxonomyCreateDialogProps = {
    fetchKey: string;
    inputType?:
        | "selectInput"
        | "selectArrayInput"
        | "checkboxGroupInput"
        | "autoCompleteInput"
        | "autoCompleteInputMui"
        | "referenceInput";
    source: string;
    label?: string;
    title?: boolean;
    isDialogOpen: boolean;
    setIsDialogOpen: (isDialogOpen: boolean) => void;
};

const TaxonomyCreateDialog: FC<TaxonomyCreateDialogProps> = ({
    fetchKey,
    inputType,
    source,
    label,
    title,
    isDialogOpen,
    setIsDialogOpen,
}) => {
    const form = useForm();
    const { values } = useFormState();

    const { isLoading, refetch } = useRequest(
        `/v1/taxonomiesByVocabulary/multi/${fetchKey}`,
        {
            method: "POST",
            body: { t: [{ t_title: values.t_title }] },
        },
        {
            onSuccess: ({ data }) => {
                if (inputType === "referenceInput") {
                    form.change(source, data?.[0]?.t_id);
                    form.change(
                        `${source}${title ? "Title" : "MachineName"}`,
                        title ? data?.[0]?.t_title : data?.[0]?.t_machine_name
                    );
                } else {
                    const createdTaxonomy = title
                        ? data?.[0]?.t_title.toLowerCase()
                        : data?.[0]?.t_machine_name;
                    form.change(source, createdTaxonomy);
                }
                onDialogClose();
            },
        }
    );

    const onDialogClose = () => {
        setIsDialogOpen(false);
        form.change("t_title", undefined);
    };

    return (
        <Dialog open={isDialogOpen} onClose={onDialogClose}>
            <DialogTitle>
                <Typography>Create New {label || source}</Typography>
            </DialogTitle>
            <DialogContent>
                <TextInput
                    source="t_title"
                    label="Title"
                    variant="outlined"
                    helperText={false}
                    autoFocus
                    multiline
                    fullWidth
                />
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={onDialogClose}
                onConfirm={refetch}
                disabled={!values.t_title}
            />
        </Dialog>
    );
};

export default TaxonomyCreateDialog;
