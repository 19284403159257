import { FC } from "react";
import {
    Edit,
    EditProps,
    FormTab,
    SaveButton,
    SelectInput,
    TabbedForm,
    TextInput,
    Toolbar,
    TranslatableInputs,
} from "react-admin";

import { useDocumentTitle } from "../../../hooks";
import BranchDetails from "./tabs/BranchDetails";
import CoverageDetails from "./tabs/CoverageDetails";
import TestDetails from "./tabs/TestDetails";

const CustomToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const LabVendorEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga |Lab Test |Vendor Edit");
    return (
        <Edit {...rest} mutationMode="pessimistic">
            <TabbedForm toolbar={<CustomToolbar />}>
                <FormTab label="Update Vendor">
                    <div style={{ width: "25%", marginBottom: 20 }}>
                        <TranslatableInputs locales={["en", "bn"]}>
                            <TextInput source="name" variant="outlined" />
                        </TranslatableInputs>
                    </div>

                    <SelectInput
                        variant="outlined"
                        source="status"
                        choices={[
                            { id: "active", name: "Active" },
                            { id: "inactive", name: "Inactive" },
                        ]}
                    />
                </FormTab>
                <FormTab label="Branch Details">
                    <BranchDetails permissions={permissions} />
                </FormTab>
                <FormTab label="Test Details">
                    <TestDetails permissions={permissions} />
                </FormTab>
                <FormTab label="Coverage Details">
                    <CoverageDetails permissions={permissions} />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default LabVendorEdit;
