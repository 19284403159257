import { useEffect, useState } from "react";
import { Filter, SelectInput, TextInput } from "react-admin";

import { Status } from "../../../utils/enums";
import { isJSONParsable, logger } from "../../../utils/helpers";
import { httpClient } from "../../../utils/http";

const LabLocationFilter = ({ ...props }) => {
    const [locations, setLocations] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const locationsFromStroage = sessionStorage.getItem("locations");
        if (locationsFromStroage) {
            setLocations(
                isJSONParsable(locationsFromStroage)
                    ? JSON.parse(locationsFromStroage)
                    : {}
            );
            setLoading(false);
        } else {
            httpClient("/v1/allLocations/", { isBaseURL: true })
                .then(({ json }: any) => {
                    if (json.status === Status.SUCCESS) {
                        setLocations(json.data);
                        sessionStorage.setItem(
                            "locations",
                            JSON.stringify(json.data)
                        );
                    }
                })
                .catch((err) => logger(err));
        }
    }, []);
    const toChoices = (items = []) =>
        items?.map((item) => ({ id: item, name: item }));

    return (
        <Filter {...props}>
            <TextInput
                source="_search"
                label="Search"
                variant="outlined"
                resettable
                alwaysOn
            />
            <SelectInput
                source="_division"
                label="Division"
                variant="outlined"
                choices={!!locations ? toChoices(Object.keys(locations)) : []}
                allowEmpty
                resettable
                alwaysOn
            />
            <SelectInput
                source="_district"
                label="District"
                variant="outlined"
                choices={
                    !!props?.filterValues?._division
                        ? toChoices(
                              locations
                                  ? Object.keys(
                                        locations[
                                            props?.filterValues?._division
                                        ]
                                    )
                                  : []
                          )
                        : []
                }
                allowEmpty
                resettable
                alwaysOn
            />
            <SelectInput
                source="_area"
                label="Area"
                variant="outlined"
                choices={
                    !!props?.filterValues?._division &&
                    !!props?.filterValues?._district &&
                    locations &&
                    locations[props?.filterValues?._division] &&
                    locations[props?.filterValues?._division][
                        props?.filterValues?._district
                    ] &&
                    !loading
                        ? toChoices(
                              Object.keys(
                                  locations[props?.filterValues?._division][
                                      props?.filterValues?._district
                                  ]
                              )
                          )
                        : []
                }
                resettable
                alwaysOn
            />
            <TextInput
                source="_zone"
                label="Zone"
                variant="outlined"
                resettable
                alwaysOn
            />
        </Filter>
    );
};

export default LabLocationFilter;
