import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TextInput,
} from "react-admin";

import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import { userEmployeeInputTextRenderer } from "../../../utils/helpers";

const RevisionFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            resettable
            alwaysOn
        />
        <NumberInput
            source="_entity_id"
            label="Entity ID"
            variant="outlined"
            alwaysOn
        />
        <SelectInput
            source="_status"
            label="Status"
            variant="outlined"
            choices={[
                { id: "PENDING", name: "Pending" },
                { id: "APPROVED", name: "Approved" },
                { id: "REJECTED", name: "Rejected" },
            ]}
            alwaysOn
        />
        <ReferenceInput
            source="_created_by"
            label="Created By"
            variant="outlined"
            reference="v1/users"
        >
            <AutocompleteInput
                matchSuggestion={() => true}
                optionValue="u_id"
                helperText={false}
                optionText={<UserEmployeeOptionTextRenderer />}
                inputText={userEmployeeInputTextRenderer}
                resettable
            />
        </ReferenceInput>
        <ReferenceInput
            source="_approved_by"
            label="Approved By"
            variant="outlined"
            reference="v1/users"
        >
            <AutocompleteInput
                matchSuggestion={() => true}
                optionValue="u_id"
                helperText={false}
                optionText={<UserEmployeeOptionTextRenderer />}
                inputText={userEmployeeInputTextRenderer}
                resettable
            />
        </ReferenceInput>
    </Filter>
);

export default RevisionFilter;
