import { FC } from "react";
import {
    AutocompleteInput,
    DateInput,
    Filter,
    FilterProps,
    ReferenceInput,
    SelectInput,
    TextInput,
    usePermissions,
} from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import { userEmployeeInputTextRenderer } from "../../../utils/helpers";

const OrderFilter: FC<FilterProps> = (props) => {
    const { permissions } = usePermissions();

    const isRoleOperator = permissions?.includes("role:operator");

    return (
        <Filter {...props}>
            <TextInput
                label="Search"
                source="_search"
                variant="outlined"
                resettable
                alwaysOn
            />
            <SelectInput
                source="_po_delivery_option"
                label="Delivery Option"
                variant="outlined"
                choices={[
                    { id: "regular", name: "Regular" },
                    { id: "express", name: "Express" },
                ]}
                alwaysOn
            />
            <SelectInput
                source="_po_status"
                label="Status"
                variant="outlined"
                choices={[
                    { id: "processing", name: "Processing" },
                    { id: "confirmed", name: "Confirmed" },
                    { id: "delivered", name: "Delivered" },
                    { id: "cancelled", name: "Cancelled" },
                ]}
                alwaysOn
            />
            <FormatedBooleanInput source="_is_issued" label="Issue" alwaysOn />
            <FormatedBooleanInput
                source="_po_out_of_stock"
                label="Out of Stock"
                alwaysOn
            />
            <FormatedBooleanInput
                source="_is_cancel_requested"
                label="Cancel Requested"
                alwaysOn
            />
            <FormatedBooleanInput
                source="_is_reschedule_requested"
                label="Reschedule Requested"
                alwaysOn
            />
            <ReferenceInput
                source="_po_user_id"
                label="User"
                variant="outlined"
                reference="v1/users"
            >
                <AutocompleteInput
                    matchSuggestion={() => true}
                    optionText={<UserEmployeeOptionTextRenderer />}
                    inputText={userEmployeeInputTextRenderer}
                    resettable
                />
            </ReferenceInput>
            <ReferenceInput
                source="_po_partner_id"
                label="Partner"
                variant="outlined"
                reference="v1/users"
                filter={{ _role: "partner" }}
            >
                <AutocompleteInput
                    matchSuggestion={() => true}
                    optionValue="u_id"
                    optionText={<UserEmployeeOptionTextRenderer />}
                    inputText={userEmployeeInputTextRenderer}
                    resettable
                />
            </ReferenceInput>
            <FormatedBooleanInput
                source="_po_item_unavailable"
                label="Item Unavailable?"
            />
            <FormatedBooleanInput
                source="_po_prescriptions"
                label="Prescription?"
                alwaysOn={isRoleOperator}
            />
            <FormatedBooleanInput
                source="_po_is_b2b_order"
                label="B2B Order?"
            />
            <FormatedBooleanInput source="_po_is_urgent" label="Urgent?" />
            <SelectInput
                source="_po_issue_status"
                label="Issue Status"
                variant="outlined"
                choices={[
                    { id: "processing", name: "Processing" },
                    { id: "approved", name: "Approved" },
                    { id: "rejected", name: "Rejected" },
                    { id: "settled", name: "Settled" },
                ]}
            />
            <SelectInput
                source="_po_payment_method"
                label="Payment Method"
                variant="outlined"
                choices={[
                    { id: "cod", name: "Cash on delivery" },
                    { id: "cash", name: "Cash" },
                    { id: "fosterPayment", name: "Foster Payment" },
                    { id: "nagad", name: "Nagad" },
                    { id: "bKash", name: "bKash" },
                    { id: "sslCommerz", name: "SSL Commerz" },
                    { id: "online", name: "Online" },
                    { id: "Redx", name: "Redx" },
                ]}
            />
            <DateInput
                source="_po_created_at"
                label="Order Date"
                variant="outlined"
            />
            <DateInput
                source="_po_created_at_end"
                label="Order Date End"
                variant="outlined"
            />
            <DateInput
                source="_po_delivered_at"
                label="Delivery Date"
                variant="outlined"
            />
            <DateInput
                source="_po_delivered_at_end"
                label="Delivery Date End"
                variant="outlined"
            />
        </Filter>
    );
};

export default OrderFilter;
