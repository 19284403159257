import { Grid } from "@material-ui/core";
import { useState } from "react";
import { AutocompleteInput, NumberInput, ReferenceInput } from "react-admin";
import { useForm, useFormState } from "react-final-form";

import AroggaButton from "../../../components/AroggaButton";
import ProductVariantUnitInput from "../../../components/ProductVariantUnitInput";
import {
    useEffectOnDependencyChange,
    useInputRef,
    useRequest,
} from "../../../hooks";
import {
    getProductTextRenderer,
    getQuantityLabel,
} from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

const PurchaseOrderItemAdd = ({ purchaseOrderId }) => {
    const classes = useAroggaStyles();
    const form = useForm();
    const { values } = useFormState();

    const [selectedProduct, setSelectedProduct] = useState<any>({});

    const { refetch } = useRequest(
        "/v1/purchaseOrderItem",
        {
            method: "POST",
            body: {
                poi_po_id: purchaseOrderId,
                poi_product_id: values.poi_product_id,
                poi_product_variant_id: values.poi_product_variant_id,
                poi_unit: values.poi_unit,
                poi_quantity: values.poi_quantity,
            },
        },
        {
            isRefresh: true,
            onSuccess: () => resetFormFields(),
        }
    );

    useEffectOnDependencyChange(() => {
        if (!values.poi_product_id) {
            setSelectedProduct({});
        }
    }, [values.poi_product_id]);

    const inputRefs = {
        product: useInputRef(),
        variant: useInputRef(),
        unit: useInputRef(),
        qty: useInputRef(),
    };

    const selectedProductVariant = selectedProduct?.pv?.find(
        (item) => item.pv_id === values.poi_product_variant_id
    );
    const selectedProductUnit = selectedProduct?.pu?.find(
        (item) => item.pu_id === values.poi_unit
    );

    const resetFormFields = () => {
        [
            "poi_product_id",
            "poi_product_variant_id",
            "poi_unit",
            "poi_quantity",
        ].forEach((field) => form.change(field, undefined));
    };

    return (
        <Grid container spacing={1}>
            <Grid item md={4}>
                <ReferenceInput
                    source="poi_product_id"
                    label="Product"
                    variant="outlined"
                    helperText={false}
                    reference="v1/product"
                    enableGetChoices={({ q }) => !!q && q.trim().length > 0}
                    filter={{
                        _details: 1,
                        // _allow_sales: 1,
                    }}
                    sort={{ field: "p_name", order: "ASC" }}
                    fullWidth
                >
                    <AutocompleteInput
                        optionValue="p_id"
                        optionText={getProductTextRenderer}
                        options={{
                            InputProps: {
                                inputRef: inputRefs.product,
                                multiline: true,
                            },
                        }}
                        matchSuggestion={() => true}
                        onSelect={(product) => {
                            setSelectedProduct(product);
                            setTimeout(() => {
                                product?.pv?.length === 1
                                    ? inputRefs.qty.current?.focus()
                                    : inputRefs.variant.current?.focus();
                            }, 1);
                        }}
                        resettable
                    />
                </ReferenceInput>
            </Grid>
            <Grid item md={3}>
                <ProductVariantUnitInput
                    source="poi_product_variant_id"
                    label="Variant"
                    data={selectedProduct?.pv}
                    inputRef={inputRefs.variant}
                    onSelect={() => inputRefs.qty.current?.focus()}
                />
            </Grid>
            <Grid item md={2}>
                <ProductVariantUnitInput
                    source="poi_unit"
                    label="Unit"
                    data={selectedProduct?.pu}
                    inputRef={inputRefs.unit}
                    onSelect={() =>
                        !values.poi_quantity && inputRefs.qty.current?.focus()
                    }
                    disabled
                />
            </Grid>
            <Grid item md={1}>
                <NumberInput
                    source="poi_quantity"
                    label="Qty"
                    variant="outlined"
                    helperText={false}
                    inputRef={inputRefs.qty}
                    onKeyDown={(e) => e.key === "Enter" && refetch()}
                    min={1}
                    fullWidth
                />
                <span className={classes.whitespaceNowrap}>
                    {getQuantityLabel({
                        qty: values.poi_quantity,
                        salesUnit: selectedProductUnit?.pu_label,
                        baseUnit: selectedProductVariant?.pu_base_unit_label,
                        salesUnitMultiplier: selectedProductUnit?.pu_multiplier,
                    })}
                </span>
            </Grid>
            {!!values.poi_product_id &&
                !!values.poi_product_variant_id &&
                !!values.poi_unit &&
                !!values.poi_quantity && (
                    <Grid item md={1}>
                        <AroggaButton
                            label="Save"
                            onClick={refetch}
                            type="success"
                            style={{
                                width: 93,
                                height: 38,
                                marginTop: 9,
                            }}
                        />
                    </Grid>
                )}
        </Grid>
    );
};

export default PurchaseOrderItemAdd;
