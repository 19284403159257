import { Box } from "@material-ui/core";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import { FC, useState } from "react";
import {
    Button,
    Confirm,
    FileField,
    FunctionField,
    NumberField,
    Record,
    ReferenceField,
    SimpleForm,
    TextField,
    usePermissions,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import { useRequest } from "../../../hooks";
import { CustomizableDatagrid } from "../../../lib";
import {
    downloadFileFromUrl,
    getColorByStatus,
    isInfinity,
} from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import OfficialVendorFilter from "../officialVendors/OfficialVendorFilter";
import PurchaseOrderExpand from "./PurchaseOrderExpand";

type PurchaseOrderDatagridProps = {
    from?: "purchaseOrderMenu" | "vendorHistoryTab";
    [key: string]: any;
};

const PurchaseOrderDatagrid: FC<PurchaseOrderDatagridProps> = ({
    from,
    ...rest
}) => {
    const classes = useAroggaStyles();
    const { permissions } = usePermissions();

    const [isDialogReceiveAll, setIsDialogReceiveAll] = useState(false);
    const [purchaseOrderId, setPurchaseOrderId] = useState("");

    const { isLoading, refetch: handleReceiveAll } = useRequest(
        `/v1/purchaseOrderStatusUpdate/${purchaseOrderId}`,
        {
            method: "POST",
            body: {
                _status: "received",
            },
        },
        {
            isRefresh: true,
            onSuccess: () => setIsDialogReceiveAll(false),
        }
    );

    return (
        <>
            <CustomizableDatagrid
                {...rest}
                expand={
                    permissions?.includes("purchaseOrderItemView") ? (
                        <SimpleForm toolbar={null}>
                            <PurchaseOrderExpand from={from} />
                        </SimpleForm>
                    ) : null
                }
                isRowExpandable={(row) => !!row?.po_item_count}
                // @ts-ignore
                filters={<OfficialVendorFilter />}
                classes={{ expandedPanel: classes.expandedPanel }}
                hideableColumns={["po_created_at", "po_created_by"]}
            >
                <FunctionField
                    source="po_id"
                    label="PO ID"
                    render={({ po_id, po_item_count }) => (
                        <span className={classes.whitespaceNowrap}>
                            {po_item_count
                                ? `${po_id} (${po_item_count})`
                                : po_id}
                        </span>
                    )}
                />
                <ReferenceField
                    source="po_vendor_id"
                    label="Vendor"
                    reference="v1/vendor"
                    link="show"
                >
                    <TextField source="v_name" />
                </ReferenceField>
                {from === "purchaseOrderMenu" && (
                    <TextField
                        source="po_vendor_type"
                        label="Vendor Type"
                        className={classes.capitalize}
                    />
                )}
                <TextField
                    source="po_payment_method"
                    label="Payment Mode"
                    className={classes.capitalize}
                />
                <TextField
                    source="po_payment_terms"
                    label="Payment terms (cash/bank)"
                />
                {from === "purchaseOrderMenu" && (
                    <TextField
                        source="po_payment_term_condition"
                        label="Payment Term Condition"
                    />
                )}
                {from === "purchaseOrderMenu" && (
                    <AroggaDateField
                        source="po_delivery_date"
                        label="Delivery Date"
                    />
                )}
                {from === "purchaseOrderMenu" && (
                    <TextField source="po_description" label="Description" />
                )}
                <NumberField source="po_assigned_product" label="Sent Qty" />
                <NumberField
                    source="po_accepted_product"
                    label="Received Qty"
                />
                <FunctionField
                    label={
                        from === "vendorHistoryTab"
                            ? "Fulfilment"
                            : "Difference"
                    }
                    render={({
                        po_assigned_product,
                        po_accepted_product,
                    }: Record) => {
                        let className;
                        let difference;
                        let fulfillment;

                        if (from === "vendorHistoryTab") {
                            fulfillment =
                                (po_accepted_product / po_assigned_product) *
                                100;
                            className =
                                fulfillment < 80
                                    ? classes.textRed
                                    : classes.textGreen;
                        } else {
                            difference =
                                po_assigned_product - po_accepted_product;
                            className = difference < 0 ? classes.textRed : "";
                        }

                        return (
                            <span className={className}>
                                {from !== "vendorHistoryTab" && difference}
                                {from === "vendorHistoryTab" &&
                                    !Number.isNaN(fulfillment) &&
                                    !isInfinity(fulfillment) &&
                                    !!fulfillment &&
                                    `${fulfillment}%`}
                            </span>
                        );
                    }}
                />
                <AroggaDateField source="po_created_at" label="PO Created At" />
                {from === "purchaseOrderMenu" && (
                    <ReferenceField
                        source="po_created_by"
                        label="PO Created By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                )}
                {from !== "vendorHistoryTab" && (
                    <FunctionField
                        source="po_created_at"
                        label="Waiting since PO creations"
                        render={({ po_created_at }: Record) => {
                            const diff = DateTime.local()
                                .diff(DateTime.fromSQL(po_created_at), [
                                    "days",
                                    "hours",
                                    "minutes",
                                ])
                                .toObject();

                            return (
                                <span
                                    className={`${classes.textRed} ${classes.whitespaceNowrap}`}
                                >
                                    {!!diff.days &&
                                        `${diff.days} ${pluralize(
                                            "day",
                                            diff.days
                                        )} `}
                                    {!!diff.hours &&
                                        `${diff.hours} ${pluralize(
                                            "hour",
                                            diff.hours
                                        )} `}
                                    {!!Math.floor(diff.minutes) &&
                                        `${Math.floor(diff.minutes)}min`}
                                </span>
                            );
                        }}
                    />
                )}
                {from === "vendorHistoryTab" && (
                    <FunctionField
                        label="Items received duration"
                        render={({
                            po_delivery_date,
                            po_created_at,
                        }: Record) => {
                            const diff = DateTime.fromSQL(po_delivery_date)
                                .diff(DateTime.fromSQL(po_created_at), [
                                    "days",
                                    "hours",
                                    "minutes",
                                ])
                                .toObject();

                            return (
                                <span
                                    className={classes.whitespaceNowrap}
                                    style={{ color: "#F79009" }}
                                >
                                    {!!diff.days && `${diff.days}d `}
                                    {!!diff.hours && `${diff.hours}h `}
                                    {!!Math.floor(diff.minutes) &&
                                        `${Math.floor(diff.minutes)}min`}
                                </span>
                            );
                        }}
                    />
                )}
                {from === "purchaseOrderMenu" && (
                    <FileField
                        source="attachedFiles_po_attachment"
                        label="Related Files"
                        src="src"
                        title="title"
                        target="_blank"
                        // @ts-ignore
                        onClick={(e) => e.stopPropagation()}
                        sortable={false}
                    />
                )}
                {from === "vendorHistoryTab" && (
                    <TextField
                        source="po_status"
                        label="Status"
                        className={classes.capitalize}
                    />
                )}
                <FunctionField
                    label="Invoice"
                    render={({
                        po_id,
                        invoice_csv_url,
                        invoice_pdf_url,
                    }: Record) => (
                        <Box display="flex" gridGap={4}>
                            {invoice_csv_url && (
                                <Button
                                    label="PO CSV"
                                    variant="contained"
                                    className={classes.whitespaceNowrap}
                                    onClick={() => {
                                        downloadFileFromUrl(
                                            invoice_csv_url,
                                            `Invoice CSV-${po_id}`
                                        );
                                    }}
                                />
                            )}
                            {invoice_pdf_url && (
                                <Button
                                    label="PO PDF"
                                    variant="contained"
                                    className={classes.whitespaceNowrap}
                                    onClick={() => {
                                        downloadFileFromUrl(
                                            invoice_pdf_url,
                                            `Invoice PDF-${po_id}`,
                                            "_blank"
                                        );
                                    }}
                                />
                            )}
                        </Box>
                    )}
                />
                {from !== "vendorHistoryTab" && (
                    <FunctionField
                        label="Action"
                        render={({ po_id, po_status }) => {
                            if (po_status === "pending")
                                return (
                                    <Button
                                        label="Receive All"
                                        variant="contained"
                                        className={classes.whitespaceNowrap}
                                        onClick={() => {
                                            setPurchaseOrderId(po_id);
                                            setIsDialogReceiveAll(true);
                                        }}
                                    />
                                );

                            return (
                                <span
                                    className={classes.capitalize}
                                    style={{
                                        color: getColorByStatus(po_status),
                                    }}
                                >
                                    {po_status}
                                </span>
                            );
                        }}
                    />
                )}
            </CustomizableDatagrid>
            <Confirm
                isOpen={isDialogReceiveAll}
                loading={isLoading}
                title={`Are you sure you want to receive all product #${purchaseOrderId}?`}
                content={false}
                onConfirm={handleReceiveAll}
                onClose={() => setIsDialogReceiveAll(false)}
            />
        </>
    );
};

export default PurchaseOrderDatagrid;
