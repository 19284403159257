import { FC } from "react";
import { List, ListProps } from "react-admin";

import {
    useClearResourcePaginationTrack,
    useDocumentTitle,
    useExport,
} from "../../../hooks";
import PurchaseOrderDatagrid from "./PurchaseOrderDatagrid";
import PurchaseOrderFilter from "./PurchaseOrderFilter";

const PurchaseOrderList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Purchase Order List");
    useClearResourcePaginationTrack();

    const exporter = useExport(rest);

    return (
        <List
            {...rest}
            title="List of Purchase Order"
            filters={
                <PurchaseOrderFilter children={""} from="purchaseOrderMenu" />
            }
            perPage={25}
            sort={{ field: "po_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={false}
        >
            <PurchaseOrderDatagrid from="purchaseOrderMenu" />
        </List>
    );
};

export default PurchaseOrderList;
