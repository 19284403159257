import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import {
    AutocompleteArrayInput,
    ReferenceArrayInput,
    SelectInput,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import AroggaDialogActions from "../../../components/AroggaDialogActions";
import { useRequest } from "../../../hooks";

type ChangeVendorDialogProps = {
    isDialogOpen: boolean;
    setIsDialogOpen: (value: boolean) => void;
    selectedProductIds: number[];
};

const ChangeVendorDialog: FC<ChangeVendorDialogProps> = ({
    isDialogOpen,
    setIsDialogOpen,
    selectedProductIds,
}) => {
    const form = useForm();
    const { values } = useFormState();

    const [vendorOptions, setVendorOptions] = useState([]);
    const [hasZeroUserId, setHasZeroUserId] = useState(false);

    const { isLoading, refetch } = useRequest(
        "/v3/productBulkUpdate",
        {
            method: "POST",
            body: {
                _vendor_type: values._vendor_type,
                _product_ids: selectedProductIds,
                _vendor_ids: values?._vendor_ids?.map((id) => ({ id })),
            },
        },
        {
            onSuccess: () => handleDialogClose(),
        }
    );

    useEffect(() => {
        if (values?._vendor_ids && vendorOptions.length > 0) {
            const filteredChoices = vendorOptions.filter((option) =>
                values._vendor_ids.includes(option.id)
            );
            const hasZeroUserId = filteredChoices.some(
                (choice) => choice.v_user_id === 0
            );
            setHasZeroUserId(hasZeroUserId);
        }
    }, [values._vendor_ids, vendorOptions]);

    useEffect(() => {
        setVendorOptions([]);
    }, [values._vendor_type]);

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        values._product_ids = [];
        values._vendor_type = "";
        values._vendor_ids = [];
    };

    return (
        <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="md">
            <DialogTitle>Change Vendor</DialogTitle>
            <DialogContent>
                <SelectInput
                    source="_vendor_type"
                    label="Type"
                    variant="outlined"
                    helperText={false}
                    choices={[
                        { id: "company", name: "Official" },
                        { id: "local", name: "Local" },
                        { id: "foreign", name: "Foreign" },
                    ]}
                    onChange={() => {
                        form.change("_vendor_ids", []);
                    }}
                    fullWidth
                />
                {values._vendor_type && (
                    <ReferenceArrayInput
                        source="_vendor_ids"
                        label="Vendors"
                        variant="outlined"
                        helperText={false}
                        reference="v1/vendor"
                        filter={{
                            _status: "active",
                            _type: values._vendor_type,
                        }}
                        filterToQuery={(searchText) => ({
                            _name: searchText,
                        })}
                        disabled={
                            values?._vendor_type === "company" &&
                            values?._vendor_ids?.length > 0
                        }
                        onSelect={(item: any) => {
                            setVendorOptions([...vendorOptions, item]);
                        }}
                    >
                        <AutocompleteArrayInput
                            optionText={(choice) =>
                                `${choice.v_name}${
                                    choice.v_user_id !== 0
                                        ? ` (${choice.v_user_id})`
                                        : ""
                                }`
                            }
                            matchSuggestion={() => true}
                        />
                    </ReferenceArrayInput>
                )}
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={handleDialogClose}
                onConfirm={refetch}
                disabled={
                    !values._vendor_type ||
                    !values._vendor_ids?.length ||
                    hasZeroUserId
                }
            />
        </Dialog>
    );
};

export default ChangeVendorDialog;
