import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { Labeled, useNotify } from "react-admin";
import { useForm, useFormState } from "react-final-form";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";

import AroggaDialogActions from "../../../components/AroggaDialogActions";
import { useGetTaxonomiesByVocabulary, useRequest } from "../../../hooks";
import { isArrayofTypeObject, logger } from "../../../utils/helpers";
import { httpClient } from "../../../utils/http";
import { toQueryString } from "../../../dataProvider/toQueryString";

const animatedComponents = makeAnimated();

type BulkTagAssignDialogProps = {
    isDialogOpen: boolean;
    setIsDialogOpen: (value: boolean) => void;
    selectedProductIds: number[];
};

const BulkTagAssignDialog: FC<BulkTagAssignDialogProps> = ({
    isDialogOpen,
    setIsDialogOpen,
    selectedProductIds,
}) => {
    const form = useForm();
    const { values } = useFormState();

    const notify = useNotify();
    const productTagFromTaxonomies = useGetTaxonomiesByVocabulary({
        fetchKey: "product_tag",
    });

    const getCreatableSelectOptions = (taxonomies) => {
        return taxonomies?.map(({ t_id, t_title }) => ({
            value: t_id,
            label: t_title,
        }));
    };

    const getExistingFilteredShapedTagsOrKeywords = (data) => {
        if (Array.isArray(data)) {
            return isArrayofTypeObject(data)
                ? data
                      .filter((item) => !item.__isNew__)
                      .map((item) => item.value)
                : data;
        } else {
            return data && !data.__isNew__ ? [data.value] : [];
        }
    };

    const getNewlyAddedTagsOrKeywords = (data) => {
        if (!data?.length) return [];

        return data
            .filter((item) => item.__isNew__)
            .map((item) => ({
                t_title: item.value,
            }));
    };

    const getAllCreatedMachineName = async (fetchKey, data) => {
        if (!getNewlyAddedTagsOrKeywords(data).length) return [];

        try {
            const res: any = await httpClient(
                `/v1/taxonomiesByVocabulary/multi/${fetchKey}`,
                {
                    method: "POST",
                    body: toQueryString({
                        t: getNewlyAddedTagsOrKeywords(data),
                    }),
                }
            );

            return res?.json?.data?.map(({ t_id, t_title }) => ({
                value: t_id,
                label: t_title,
            }));
        } catch (err: any) {
            logger(err);
            return notify(
                err?.message || "Something went wrong, Please try again!",
                {
                    type: "error",
                }
            );
        }
    };

    const handleChangeCreatableSelect = (name, value) => {
        const newValue = Array.isArray(value) ? value : [value];
        form.change(name, newValue);
    };

    const handleTagCreation = async () => {
        const existingTags = getExistingFilteredShapedTagsOrKeywords(
            values.p_product_tags
        );
        const newTags = await getAllCreatedMachineName(
            "product_tag",
            values.p_product_tags
        );
        const combinedValue = [...existingTags, ...newTags];
        form.change("p_product_tags", combinedValue);
    };

    const { isLoading, refetch } = useRequest(
        "/v1/bulkTagAssignAction",
        {
            method: "POST",
            body: {
                _product_ids: selectedProductIds,
                _taxonomy_id:
                    getExistingFilteredShapedTagsOrKeywords(
                        values.p_product_tags
                    ).length === 1
                        ? getExistingFilteredShapedTagsOrKeywords(
                              values.p_product_tags
                          )[0]
                        : getExistingFilteredShapedTagsOrKeywords(
                              values.p_product_tags
                          ),
            },
        },
        {
            onSuccess: () => {
                handleDialogClose();
                window.location.reload();
            },
        }
    );

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        values.p_product_tags = [];
    };

    return (
        <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth>
            <DialogTitle>Bulk Tag Assign</DialogTitle>
            <DialogContent>
                <Labeled label="Tags" fullWidth>
                    <CreatableSelect
                        options={getCreatableSelectOptions(
                            productTagFromTaxonomies
                        )}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        onChange={(value) =>
                            handleChangeCreatableSelect("p_product_tags", value)
                        }
                        styles={{
                            container: (baseStyles) => ({
                                ...baseStyles,
                                zIndex: 999,
                            }),
                            menuPortal: (baseStyles) => ({
                                ...baseStyles,
                                zIndex: 9999,
                            }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        isClearable
                    />
                </Labeled>
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={handleDialogClose}
                onConfirm={async () => {
                    await handleTagCreation();
                    refetch();
                }}
            />
        </Dialog>
    );
};

export default BulkTagAssignDialog;
