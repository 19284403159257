import { useState } from "react";
import {
    Datagrid,
    Pagination,
    ReferenceManyField,
    TextField,
} from "react-admin";

import LoaderOrButton from "../../../../components/LoaderOrButton";

const PreviousHistory = () => {
    const [isLoadPreviousHistory, setIsLoadPreviousHistory] = useState(false);

    return (
        <>
            {!isLoadPreviousHistory && (
                <LoaderOrButton
                    label="Load Previous History"
                    isLoading={false}
                    display="flex"
                    justifyContent="center"
                    mt={3}
                    mb={4}
                    onClick={() => setIsLoadPreviousHistory(true)}
                />
            )}
            {isLoadPreviousHistory && (
                <ReferenceManyField
                    reference="v1/histories"
                    target="_obj_id"
                    pagination={<Pagination />}
                    filter={{ _h_obj: "order" }}
                    sort={{ field: "h_id", order: "DESC" }}
                >
                    <Datagrid>
                        <TextField source="u_name" label="Name" sortBy="u_id" />
                        <TextField source="h_created" label="Date" />
                        <TextField source="h_action" label="Action" />
                        <TextField source="h_from" label="From" />
                        <TextField source="h_to" label="To" />
                    </Datagrid>
                </ReferenceManyField>
            )}
        </>
    );
};

export default PreviousHistory;
