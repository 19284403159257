import { Box } from "@material-ui/core";
import { FC } from "react";
import { FixedSizeList } from "react-window";

import {
    getFormattedDateString,
    numberFormat,
    toFixedNumber,
} from "../../../utils/helpers";
import Body from "./Body";
import styles from "./LedgerTable.module.css";

type LedgerTableProps = {
    RENDER_REACT_WINDOW_LIMIT: number;
    renderLength: number;
    endDate: string;
    data: any;
    headIdsData: any;
};

const LedgerTable: FC<LedgerTableProps> = ({
    RENDER_REACT_WINDOW_LIMIT,
    renderLength,
    endDate,
    data,
    headIdsData,
}) => {
    const formattedBalance = (balance) => {
        if (balance === 0) {
            return 0;
        } else if (!balance) {
            return "";
        }

        return numberFormat(balance);
    };

    const total = formattedBalance(
        toFixedNumber(Math.max(data?.debit_sum || 0, data?.credit_sum || 0))
    );

    const difference = formattedBalance(
        Math.abs(data?.debit_sum - data?.credit_sum)
    );

    return (
        <>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <span>Debit</span>
                <span>Credit</span>
            </Box>
            <div className={styles.table}>
                <div
                    className={styles.thead}
                    style={
                        renderLength < RENDER_REACT_WINDOW_LIMIT
                            ? { width: "100%" }
                            : {}
                    }
                >
                    <div className={styles.td}>Date</div>
                    <div className={`${styles.td} ${styles["w-220"]}`}>
                        Particulars
                    </div>
                    <div className={`${styles.td} ${styles["align-right"]}`}>
                        Dr Balance
                    </div>
                    <div className={styles.td}>Date</div>
                    <div className={`${styles.td} ${styles["w-220"]}`}>
                        Particulars
                    </div>
                    <div className={`${styles.td} ${styles["align-right"]}`}>
                        Cr Balance
                    </div>
                </div>
                {renderLength > RENDER_REACT_WINDOW_LIMIT ? (
                    <FixedSizeList
                        itemCount={renderLength}
                        height={500}
                        width="100%"
                        itemSize={25}
                    >
                        {({ index, style }) => (
                            <Body
                                index={index}
                                data={data}
                                headIdsData={headIdsData}
                                formattedBalance={formattedBalance}
                                style={style}
                            />
                        )}
                    </FixedSizeList>
                ) : (
                    Array.from({ length: renderLength }).map((_, index) => (
                        <Body
                            key={index}
                            index={index}
                            data={data}
                            headIdsData={headIdsData}
                            formattedBalance={formattedBalance}
                            style={{ width: "100%" }}
                        />
                    ))
                )}
                <div
                    className={styles.tbody}
                    style={
                        renderLength < RENDER_REACT_WINDOW_LIMIT
                            ? { width: "100%" }
                            : {}
                    }
                >
                    <div className={styles.td}>
                        {data?.credit_sum < data?.debit_sum &&
                            getFormattedDateString(endDate)}
                    </div>
                    <div className={`${styles.td} ${styles["w-220"]}`}>
                        {data?.credit_sum < data?.debit_sum && "Balance C/D"}
                    </div>
                    <div className={`${styles.td} ${styles["align-right"]}`}>
                        {data?.credit_sum < data?.debit_sum && difference}
                    </div>
                    <div className={styles.td}>
                        {data?.credit_sum > data?.debit_sum &&
                            getFormattedDateString(endDate)}
                    </div>
                    <div className={`${styles.td} ${styles["w-220"]}`}>
                        {data?.credit_sum > data?.debit_sum && "Balance C/D"}
                    </div>
                    <div className={`${styles.td} ${styles["align-right"]}`}>
                        {data?.credit_sum > data?.debit_sum && difference}
                    </div>
                </div>
                <div
                    className={styles.tfoot}
                    style={
                        renderLength < RENDER_REACT_WINDOW_LIMIT
                            ? { width: "100%" }
                            : {}
                    }
                >
                    <div className={styles.td}></div>
                    <div className={`${styles.td} ${styles["w-220"]}`}>
                        Total
                    </div>
                    <div className={`${styles.td} ${styles["align-right"]}`}>
                        {total}
                    </div>
                    <div className={styles.td}></div>
                    <div className={`${styles.td} ${styles["w-220"]}`}>
                        Total
                    </div>
                    <div className={`${styles.td} ${styles["align-right"]}`}>
                        {total}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LedgerTable;
