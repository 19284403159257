import { FC } from "react";
import {
    ReferenceField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";

const QuotationItemMappingShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga |Quotation Item Mapping Show");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ColumnShowLayout md={6} simpleShowLayout={false}>
                    <TextField source="qim_id" label={"ID"} />
                    <TextField source="qim_name" label="Name" />
                    <ReferenceField
                        source="qim_head_id"
                        label="Accounting To"
                        reference="v1/accountingHead"
                        link="show"
                    >
                        <TextField source="ah_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="qim_created_at"
                        label="Created At"
                    />
                    <ReferenceField
                        source="qim_created_by"
                        label="Created By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="qim_modified_at"
                        label="Modified At"
                    />
                    <ReferenceField
                        source="qim_modified_by"
                        label="Modified By"
                        reference="v1/users"
                        sortBy="qim_modified_by"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                </ColumnShowLayout>
            </SimpleShowLayout>
        </Show>
    );
};

export default QuotationItemMappingShow;
