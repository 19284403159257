import { Checkbox } from "@material-ui/core";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import keyBy from "lodash/keyBy";
import { FC, memo, useMemo, useState } from "react";
import {
    Button,
    Datagrid,
    FunctionField,
    Record,
    ReferenceField,
    SimpleForm,
    TextField,
} from "react-admin";

import { useRequest } from "../../../hooks";
import {
    getQuantityLabel,
    getReadableSKU,
    groupBy,
} from "../../../utils/helpers";
import IssueCreateModal from "./IssueCreateModal";

const ShipmentExpand: FC = ({ record }: any) => {
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [isCreateIssueDialogOpen, setIsCreateIssueDialogOpen] =
        useState(false);
    const [orderShipmentItemVariantIds, setOrderShipmentItemVariantIds] =
        useState<number[]>([]);

    const { data } = useRequest(
        `/v1/shipment/${record?.s_id}`,
        {},
        {
            isPreFetching: true,
        }
    );

    const groupShipmentOrderItemsByVariantId = useMemo(
        () =>
            groupBy(
                record.s_order_items,
                (data) => data.poi_product_variant_id
            ),
        [record.s_order_items]
    );

    const handleOnToggleSelectAll = (e) => {
        setIsSelectedAll(e.target.checked);
        setOrderShipmentItemVariantIds(
            e.target.checked
                ? record.si?.map(({ si_variant_id }) => si_variant_id)
                : []
        );
    };

    const handleOnToggleCheck = (e, variantId) => {
        const ids =
            e.target.checked &&
            !orderShipmentItemVariantIds?.includes(variantId)
                ? [...orderShipmentItemVariantIds, variantId]
                : orderShipmentItemVariantIds.filter((id) => id !== variantId);
        setOrderShipmentItemVariantIds(ids);
    };

    return (
        <>
            <Datagrid
                data={keyBy(data?.si, "id")}
                ids={data?.si?.map(({ id }) => id)}
                optimized
            >
                {record?.s_status === "delivered" &&
                    record?.s_type !== "issue" && (
                        <FunctionField
                            label={
                                <Checkbox
                                    value={isSelectedAll}
                                    onChange={handleOnToggleSelectAll}
                                />
                            }
                            render={({ si_variant_id }: Record) => (
                                <Checkbox
                                    checked={orderShipmentItemVariantIds?.includes(
                                        si_variant_id
                                    )}
                                    onChange={(e) =>
                                        handleOnToggleCheck(e, si_variant_id)
                                    }
                                />
                            )}
                        />
                    )}
                <ReferenceField
                    source="si_product_id"
                    label="Product"
                    reference="v1/product"
                    link="show"
                >
                    <TextField source="p_name" />
                </ReferenceField>
                <ReferenceField
                    source="si_variant_id"
                    label="Variant"
                    reference="v1/productVariant"
                    link={false}
                >
                    <FunctionField
                        render={(record: Record) =>
                            getReadableSKU(record?.pv_attribute)
                        }
                    />
                </ReferenceField>
                <ReferenceField
                    source="si_product_id"
                    label="Form"
                    reference="v1/product"
                    link={false}
                >
                    <TextField source="p_form" />
                </ReferenceField>
                <ReferenceField
                    source="si_product_id"
                    label="Strength"
                    reference="v1/product"
                    link={false}
                >
                    <TextField source="p_strength" />
                </ReferenceField>
                <ReferenceField
                    source="si_product_id"
                    label="Cold"
                    reference="v1/product"
                    link={false}
                >
                    <FunctionField
                        render={(record: Record) => {
                            if (!record?.p_cold) return;
                            return <AcUnitIcon />;
                        }}
                    />
                </ReferenceField>
                <FunctionField
                    source="si_in_count"
                    label="In Count"
                    render={({
                        si_in_count: qty,
                        p_unit_label: salesUnit,
                        product_base_unit: baseUnit,
                        pu_multiplier: salesUnitMultiplier,
                    }: Record) =>
                        getQuantityLabel({
                            qty,
                            salesUnit,
                            baseUnit,
                            salesUnitMultiplier,
                        })
                    }
                />
                <FunctionField
                    source="si_out_count"
                    label="Out Count"
                    render={({
                        si_out_count: qty,
                        p_unit_label: salesUnit,
                        product_base_unit: baseUnit,
                        pu_multiplier: salesUnitMultiplier,
                    }: Record) =>
                        getQuantityLabel({
                            qty,
                            salesUnit,
                            baseUnit,
                            salesUnitMultiplier,
                        })
                    }
                />
            </Datagrid>
            {!!orderShipmentItemVariantIds?.length && (
                <Button
                    label="Create Issue"
                    variant="contained"
                    style={{ marginTop: 12, marginBottom: 5 }}
                    onClick={() => setIsCreateIssueDialogOpen(true)}
                />
            )}
            {isCreateIssueDialogOpen && (
                <SimpleForm toolbar={null}>
                    <IssueCreateModal
                        open={isCreateIssueDialogOpen}
                        onDialogClose={() => setIsCreateIssueDialogOpen(false)}
                        shipmentId={record.s_id}
                        orderShipmentItemVariantIds={
                            orderShipmentItemVariantIds
                        }
                        groupShipmentOrderItemsByVariantId={
                            groupShipmentOrderItemsByVariantId
                        }
                    />
                </SimpleForm>
            )}
        </>
    );
};

export default memo(ShipmentExpand);
