import { FC } from "react";
import { Filter, FilterProps, SelectInput, TextInput } from "react-admin";

const HeadFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            source="_abbr"
            label="Search by Abbreviation"
            variant="outlined"
            resettable
            alwaysOn
        />
        <TextInput
            source="_name"
            label="Search by Name"
            variant="outlined"
            resettable
            alwaysOn
        />
        <SelectInput
            source="_type"
            variant="outlined"
            choices={[
                { id: "income", name: "Income" },
                { id: "expense", name: "Expense" },
                { id: "asset", name: "Asset" },
                { id: "liability", name: "Liability" },
                { id: "equity", name: "Equity" },
            ]}
            resettable
            alwaysOn
            fullWidth
        />
    </Filter>
);

export default HeadFilter;
