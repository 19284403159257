import { FC, useState } from "react";
import { CreateProps, Edit, SimpleForm, TransformData } from "react-admin";

import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import TransactionForm from "../../../components/manageAccounting/transactions/TransactionForm";
import { useDocumentTitle } from "../../../hooks";
import { convertAttachmentsToBase64 } from "../../../utils/helpers";

const transform: TransformData = async (data) => ({
    ...data,
    atd: await convertAttachmentsToBase64("atd_attachments", data.atd),
});

const TransactionEdit: FC<CreateProps> = ({
    permissions,
    hasCreate,
    ...rest
}) => {
    useDocumentTitle("Arogga | Transaction Edit");

    const [isDifference, setIsDifference] = useState(false);

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            transform={transform}
            {...rest}
        >
            <SimpleForm
                redirect="list"
                toolbar={
                    permissions?.includes("accountingTransactionEdit") ? (
                        <SaveDeleteToolbar
                            isSave
                            isSaveDisabled={isDifference}
                        />
                    ) : null
                }
            >
                <TransactionForm setIsDifference={setIsDifference} />
            </SimpleForm>
        </Edit>
    );
};

export default TransactionEdit;
