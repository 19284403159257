import { Box, Button } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { FC, useEffect } from "react";
import {
    ArrayInput,
    AutocompleteArrayInput,
    AutocompleteInput,
    FileField,
    FileInput,
    FormDataConsumer,
    ReferenceArrayInput,
    ReferenceInput,
    SimpleFormIterator,
    TextInput,
    useNotify,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import {
    convertFileToString,
    downloadFileFromUrl,
    isEmpty,
    isJSONParsable,
} from "../../../utils/helpers";

type SecureFormProps = {
    source: string;
    prePopulatedFields?: string[];
    inputLabel?: string;
    inputType?: "textInput" | "referenceInput" | "referenceArrayInput";
    referenceField?: string;
    referenceSource?: string;
    record?: any;
};

const SecureForm: FC<SecureFormProps> = ({
    source,
    prePopulatedFields,
    inputType = "textInput",
    inputLabel,
    referenceField,
    referenceSource,
    record,
}) => {
    const notify = useNotify();
    const form = useForm();
    const { values } = useFormState();

    const fileSourceName = `attachedFiles_${source}`;

    useEffect(() => {
        if (isEmpty(values[fileSourceName])) return;

        (async () => {
            const result: any = await convertFileToString(
                values[fileSourceName]
            );
            form.change(
                source,
                isJSONParsable(result) ? JSON.parse(result) : []
            );
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values[fileSourceName]]);

    useEffect(() => {
        record &&
            isEmpty(record) &&
            prePopulatedFields &&
            form.change(
                source,
                prePopulatedFields?.map((key) => ({ key, value: "" }))
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record]);

    const handleExport = () => {
        const dataURI = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(values[source])
        )}`;
        downloadFileFromUrl(dataURI, `${source}-env.json`);
        notify("Successfully exported!", {
            type: "success",
        });
    };

    return (
        <div style={{ position: "relative" }}>
            <FileInput
                source={fileSourceName}
                label="Import Env Variables"
                accept="application/json"
            >
                <FileField source="src" title="title" />
            </FileInput>
            <ArrayInput
                source={source}
                label=""
                style={{
                    marginTop: -12,
                }}
                // initialValue={prePopulatedFields?.map((key) => ({ key }))}
            >
                <SimpleFormIterator
                    // @ts-ignore
                    TransitionProps={{ classNames: "fade-exit" }}
                    addButton={
                        <Box ml={3}>
                            <Button
                                variant="outlined"
                                style={{
                                    backgroundColor: "#027bff",
                                    color: "white",
                                    position: "relative",
                                }}
                            >
                                Add
                            </Button>
                        </Box>
                    }
                    removeButton={
                        <Box
                            mt={1}
                            ml={1}
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            <HighlightOffIcon />
                        </Box>
                    }
                    disableAdd={!!prePopulatedFields?.length}
                    disableReordering
                >
                    <FormDataConsumer>
                        {({ getSource, scopedFormData }) => (
                            <Box display="flex" gridGap={10}>
                                <TextInput
                                    source={getSource("key")}
                                    record={scopedFormData}
                                    label="Key"
                                    variant="outlined"
                                    style={{ width: 400 }}
                                    helperText={false}
                                    disabled={!!prePopulatedFields?.length}
                                />
                                {inputType === "textInput" && (
                                    <TextInput
                                        source={getSource("value")}
                                        record={scopedFormData}
                                        label="Value"
                                        variant="outlined"
                                        style={{ width: 400 }}
                                        helperText={false}
                                    />
                                )}
                                {inputType === "referenceInput" && (
                                    <ReferenceInput
                                        source={getSource("value")}
                                        record={scopedFormData}
                                        label={inputLabel}
                                        variant="outlined"
                                        reference={referenceSource}
                                    >
                                        <AutocompleteInput
                                            matchSuggestion={() => true}
                                            helperText={false}
                                            optionText={referenceField}
                                            resettable
                                        />
                                    </ReferenceInput>
                                )}
                                {inputType === "referenceArrayInput" && (
                                    <ReferenceArrayInput
                                        source={getSource("value")}
                                        record={scopedFormData}
                                        label={inputLabel}
                                        variant="outlined"
                                        reference={referenceSource}
                                    >
                                        <AutocompleteArrayInput
                                            matchSuggestion={() => true}
                                            helperText={false}
                                            optionText={referenceField}
                                            resettable
                                        />
                                    </ReferenceArrayInput>
                                )}
                            </Box>
                        )}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
            {!isEmpty(values[source]) && (
                <Button
                    variant="outlined"
                    style={{
                        backgroundColor: "#027bff",
                        color: "white",
                        ...(prePopulatedFields?.length
                            ? { left: 26 }
                            : { position: "absolute", bottom: 8, left: 93 }),
                    }}
                    onClick={handleExport}
                >
                    Export
                </Button>
            )}
        </div>
    );
};

export default SecureForm;
