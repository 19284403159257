import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { useFormState } from "react-final-form";

import AroggaDialogActions from "../../../components/AroggaDialogActions";
import { useRequest } from "../../../hooks";

type MoveToVendorDialogProps = {
    selectedIds: number[];
    isDialogOpen: boolean;
    setIsDialogOpen: (isDialogOpen: boolean) => void;
};

const MoveToVendorDialog: FC<MoveToVendorDialogProps> = ({
    selectedIds,
    isDialogOpen,
    setIsDialogOpen,
}) => {
    const { values } = useFormState();

    const { isLoading, refetch } = useRequest(
        `/v3/procurements/moveToVendor/${values.selectedVendorId}`,
        {
            method: "POST",
            body: {
                _ps_ids: selectedIds,
            },
        },
        {
            isRefresh: true,
            onSuccess: () => handleDialogClose(),
        }
    );

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        values.selectedVendorId = undefined;
    };

    return (
        <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="md">
            <DialogTitle>Move to vendor</DialogTitle>
            <DialogContent>
                <ReferenceInput
                    source="selectedVendorId"
                    label="Select Vendor"
                    variant="outlined"
                    reference="v1/vendor"
                    filter={{
                        _type: "local",
                    }}
                    filterToQuery={(searchText) => ({
                        _name: searchText,
                    })}
                    alwaysOn
                >
                    <AutocompleteInput
                        optionText="v_name"
                        options={{
                            InputProps: {
                                multiline: true,
                            },
                        }}
                        resettable
                    />
                </ReferenceInput>
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={handleDialogClose}
                onConfirm={refetch}
                disabled={!values.selectedVendorId}
            />
        </Dialog>
    );
};

export default MoveToVendorDialog;
