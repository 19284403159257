import { FC } from "react";
import {
    ReferenceField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";
import AroggaDateField from "../../../components/AroggaDateField";

const ExpenseHeadMappingShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga |Expense Head Mapping Show");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ColumnShowLayout md={6} simpleShowLayout={false}>
                    <TextField source="ehm_id" label="Id" />
                    <TextField source="ehm_expense_title" label="Title" />
                    <ReferenceField
                        source="ehm_debit_head_id"
                        label="Debit Head"
                        reference="v1/accountingHead"
                    >
                        <TextField source="ah_name" />
                    </ReferenceField>
                    <ReferenceField
                        source="ehm_credit_head_id"
                        label="Credit Head"
                        reference="v1/accountingHead"
                    >
                        <TextField source="ah_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="ehm_created_at"
                        label="Created At"
                    />
                    <ReferenceField
                        source="ehm_created_by"
                        label="Created By"
                        reference="v1/users"
                        sortBy="ehm_created_by"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="ehm_modified_at"
                        label="Modify At"
                    />
                    <ReferenceField
                        source="ehm_modified_by"
                        label="Modify By"
                        reference="v1/users"
                        sortBy="ehm_created_by"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                </ColumnShowLayout>
            </SimpleShowLayout>
        </Show>
    );
};

export default ExpenseHeadMappingShow;
