import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { FC, useEffect, useState } from "react";
import {
    ArrayInput,
    Edit,
    EditProps,
    FormDataConsumer,
    FormTab,
    ImageField,
    ImageInput,
    NumberInput,
    SaveButton,
    SelectArrayInput,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    Toolbar,
    TranslatableInputs,
} from "react-admin";
import { useParams } from "react-router-dom";

import { useDocumentTitle, useRequest } from "../../../hooks";
import { HomeBanner, LabTestHistoryTab } from "./tabs";
import Faq from "./tabs/Faq";
import LabTestDetailsList from "./tabs/LabTestDetailsList";
import Test from "./tabs/Test";

interface Params {
    id: string;
}
const CustomToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const LabTestPckgEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Lab Test| Edit");
    const { id } = useParams<Params>();
    const { data: Order } = useRequest(
        `/misc/api/v1/admin/lab-items/${id}`,
        {},
        {
            isPreFetching: true,
            isSuccessNotify: false,
        }
    );
    const { data } = useRequest(
        "/misc/api/v1/admin/lab-items/sample-requirements",
        {},
        { isBaseUrl: true, isPreFetching: true, isSuccessNotify: false }
    );
    const { data: categoryIds } = useRequest(
        "/misc/api/v1/admin/category/?page=1&limit=100",
        {},
        { isBaseUrl: true, isPreFetching: true, isSuccessNotify: false }
    );
    // const [translate, setTranslate] = useState("");
    // const [recordImagesPayload, setRecordImagesPayload] = useState([]);
    const [basePrice, setBasePrice] = useState<string | null>(
        Order?.basePrice || null
    );
    const [materialCost, setMaterialCost] = useState<string | null>(
        Order?.materialCost || null
    );
    const [margin, setMargin] = useState<string | null>(Order?.margin || null);
    const [total, setTotal] = useState<number | null>(null);

    useEffect(() => {
        setBasePrice(Order?.basePrice || null);
        setMaterialCost(Order?.materialCost || null);
        setMargin(Order?.margin || null);
    }, [Order]);

    useEffect(() => {
        const basePriceValue = parseFloat(basePrice || "0");
        const materialCostValue = parseFloat(materialCost || "0") || 0;
        const marginValue = parseFloat(margin || "0") || 0;
        const calculatedTotal =
            basePriceValue + materialCostValue + marginValue;
        setTotal(calculatedTotal);
    }, [basePrice, materialCost, margin]);

    // const handleChange = (event: any) => {
    //     setTranslate(event.target.value as string);
    // };

    // const { refetch: toGoogleTranslate } = useRequest(
    //     `/translator/api/v1/translate`,
    //     {
    //         method: "POST",
    //         body: {
    //             text: translate,
    //         },
    //     },
    //     {
    //         onSuccess: (response) => {
    //             setRecordImagesPayload(response.data);
    //         },
    //     }
    // );
    // @ts-ignore
    // const bangla = recordImagesPayload?.translatedText;
    // const onSave = async (data) => {
    //     let payload = {
    //         ...data,
    //     };
    //     // if (data.knownAs === "") {
    //     //     payload = {
    //     //         ...payload,
    //     //         knownAs: [],
    //     //     };
    //     // }

    //     try {
    //         //@ts-ignore
    //         await labTestUploadDataProvider.create(
    //             "misc/api/v1/admin/lab-items",
    //             {
    //                 ...payload,
    //             }
    //         );
    //         notify("Successfully Test Edit!", { type: "success" });
    //     } catch (err) {
    //         logger(err);
    //         notify("Something went wrong! Please try again!", {
    //             type: "warning",
    //         });
    //     }
    // };
    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <TabbedForm toolbar={<CustomToolbar />} sanitizeEmptyValues={false}>
                <FormTab label="Details">
                    <Grid container spacing={1} style={{ width: "100%" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TranslatableInputs locales={["en", "bn"]}>
                                    <TextInput
                                        source="name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </TranslatableInputs>
                            </Grid>
                            <Grid item xs={6}>
                                <TranslatableInputs locales={["en", "bn"]}>
                                    <TextInput
                                        source="subTitle"
                                        label="Sub Title"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </TranslatableInputs>
                            </Grid>
                            <Grid item xs={6}>
                                <TranslatableInputs locales={["en", "bn"]}>
                                    <TextInput
                                        source="description"
                                        variant="outlined"
                                        fullWidth
                                        minRows={3}
                                        multiline
                                    />
                                </TranslatableInputs>
                            </Grid>
                            <Grid item xs={3}>
                                <SelectInput
                                    variant="outlined"
                                    fullWidth
                                    source="targetGender"
                                    choices={[
                                        { id: "male", name: "Male" },
                                        { id: "female", name: "Female" },
                                        { id: "male_female", name: "Both" },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <SelectInput
                                    variant="outlined"
                                    fullWidth
                                    source="itemType"
                                    choices={[
                                        { id: "test", name: "Test" },
                                        { id: "package", name: "Package" },
                                    ]}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectInput
                                    variant="outlined"
                                    fullWidth
                                    source="status"
                                    choices={[
                                        { id: "active", name: "Active" },
                                        { id: "inactive", name: "Inactive" },
                                    ]}
                                />
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <NumberInput
                                        source="targetAge.start"
                                        variant="outlined"
                                        fullWidth
                                        label="Target Age Start"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput
                                        source="targetAge.end"
                                        variant="outlined"
                                        fullWidth
                                        label="Target Age End"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    source="basePrice"
                                    variant="outlined"
                                    label="Base price"
                                    disabled={Order?.itemType === "package"}
                                    fullWidth
                                    onChange={(e) =>
                                        setBasePrice(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    source="materialCost"
                                    variant="outlined"
                                    label="Material Cost"
                                    // disabled={Order?.itemType === "package"}
                                    fullWidth
                                    onChange={(e) =>
                                        setMaterialCost(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    source="margin"
                                    variant="outlined"
                                    label="Margin"
                                    // disabled={Order?.itemType === "package"}
                                    fullWidth
                                    onChange={(e) => setMargin(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="outlined-basic"
                                    label="MRP Price"
                                    variant="outlined"
                                    fullWidth
                                    value={total !== null ? total : "0.00"}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    source="externalMaterialCost"
                                    variant="outlined"
                                    label="External Material Cost"
                                    // disabled={Order?.itemType === "package"}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    source="discountPrice"
                                    variant="outlined"
                                    label="Discount Price"
                                    // disabled={Order?.itemType === "package"}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <NumberInput
                                    source="reportAvailableHour"
                                    variant="outlined"
                                    fullWidth
                                    label="Report Available Hour"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInput
                                    source="fastingRequired"
                                    variant="outlined"
                                    label="Fasting Required (Yes/ No/ (as like 10 -12 Hrs))"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <SelectArrayInput
                                    source="tags"
                                    label="Tags"
                                    variant="outlined"
                                    helperText={false}
                                    choices={categoryIds?.map(
                                        (category: {
                                            tag: any;
                                            name: { en: any };
                                        }) => ({
                                            id: category.tag,
                                            name: category.name.en,
                                        })
                                    )}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <ArrayInput source="knownAs">
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ getSource }) => (
                                                <>
                                                    <TextInput
                                                        source={getSource("en")}
                                                        variant="outlined"
                                                        label="knownAs (EN)"
                                                        fullWidth
                                                    />
                                                    <TextInput
                                                        source={getSource("bn")}
                                                        variant="outlined"
                                                        label="knownAs (BN)"
                                                        fullWidth
                                                    />
                                                </>
                                            )}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                            <Grid item xs={6}>
                                <ArrayInput
                                    source="sampleRequirements"
                                    disabled={Order?.itemType === "package"}
                                >
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({
                                                getSource,
                                                scopedFormData,
                                            }) => (
                                                <>
                                                    <SelectInput
                                                        variant="outlined"
                                                        label="sampleRequirements (EN)"
                                                        disabled={
                                                            Order?.itemType ===
                                                            "package"
                                                        }
                                                        fullWidth
                                                        record={scopedFormData}
                                                        source={getSource("en")}
                                                        choices={data?.map(
                                                            (requirement: {
                                                                en: any;
                                                            }) => ({
                                                                id: requirement.en,
                                                                name: requirement.en,
                                                            })
                                                        )}
                                                    />
                                                    <SelectInput
                                                        variant="outlined"
                                                        label="sampleRequirements (BN)"
                                                        fullWidth
                                                        disabled={
                                                            Order?.itemType ===
                                                            "package"
                                                        }
                                                        record={scopedFormData}
                                                        source={getSource("bn")}
                                                        choices={data?.map(
                                                            (requirement: {
                                                                bn: any;
                                                            }) => ({
                                                                id: requirement.bn,
                                                                name: requirement.bn,
                                                            })
                                                        )}
                                                    />
                                                </>
                                            )}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                            {/* <Grid item xs={6}>
                                <ArrayInput
                                    source="testRequirements"
                                    disabled={Order?.itemType === "package"}
                                >
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({
                                                getSource,
                                                scopedFormData,
                                            }) => (
                                                <>
                                                    <TextInput
                                                        source={getSource("en")}
                                                        variant="outlined"
                                                        disabled={
                                                            Order?.itemType ===
                                                            "package"
                                                        }
                                                        record={scopedFormData}
                                                        label="Test Requirements (EN)"
                                                        fullWidth
                                                    />
                                                    <TextInput
                                                        source={getSource("bn")}
                                                        variant="outlined"
                                                        disabled={
                                                            Order?.itemType ===
                                                            "package"
                                                        }
                                                        record={scopedFormData}
                                                        label="Test Requirements (BN)"
                                                        fullWidth
                                                    />
                                                </>
                                            )}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid> */}

                            <Grid item xs={6} style={{ display: "none" }}>
                                <NumberInput
                                    source="__v"
                                    variant="outlined"
                                    label="Version"
                                    fullWidth
                                />
                            </Grid>
                            {Order?.itemType === "package" && (
                                <Grid item xs={12}>
                                    <ImageInput
                                        source="attachedFiles-homeIcon"
                                        label="Home Icon (120*120 px)"
                                        accept="image/*"
                                        maxSize={10000000}
                                    >
                                        <ImageField
                                            source="src"
                                            title="title"
                                        />
                                    </ImageInput>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <ImageInput
                                    source="attachedFiles-bannerImage"
                                    label="Product Image( APP 1080*1080 px )"
                                    accept="image/*"
                                    maxSize={10000000}
                                >
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormTab>
                {Order?.itemType === "package" && (
                    <FormTab label="Test">
                        <Test />
                    </FormTab>
                )}
                <FormTab label="History">
                    <LabTestHistoryTab />
                </FormTab>
                <FormTab label="Details List">
                    <LabTestDetailsList />
                </FormTab>
                <FormTab label="Faq">
                    <Faq />
                </FormTab>
                <FormTab label="Home-Banner">
                    <HomeBanner />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default LabTestPckgEdit;
