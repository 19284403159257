const CollectorIcon = (props) => (
    <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect
            width="59"
            height="59"
            rx="29.5"
            fill="#1DA099"
            fillOpacity="0.1"
        />
        <path
            d="M39.7083 16.375H33.6125C33 14.6833 31.3958 13.4583 29.5 13.4583C27.6042 13.4583 26 14.6833 25.3875 16.375H19.2917C17.6875 16.375 16.375 17.6875 16.375 19.2917V39.7083C16.375 41.3125 17.6875 42.625 19.2917 42.625H39.7083C41.3125 42.625 42.625 41.3125 42.625 39.7083V19.2917C42.625 17.6875 41.3125 16.375 39.7083 16.375ZM29.5 16.375C30.3021 16.375 30.9583 17.0313 30.9583 17.8333C30.9583 18.6354 30.3021 19.2917 29.5 19.2917C28.6979 19.2917 28.0417 18.6354 28.0417 17.8333C28.0417 17.0313 28.6979 16.375 29.5 16.375ZM29.5 22.2083C31.9208 22.2083 33.875 24.1625 33.875 26.5833C33.875 29.0042 31.9208 30.9583 29.5 30.9583C27.0792 30.9583 25.125 29.0042 25.125 26.5833C25.125 24.1625 27.0792 22.2083 29.5 22.2083ZM38.25 39.7083H20.75V37.6667C20.75 34.75 26.5833 33.1458 29.5 33.1458C32.4167 33.1458 38.25 34.75 38.25 37.6667V39.7083Z"
            fill="#1DA099"
        />
    </svg>
);
export default CollectorIcon;
