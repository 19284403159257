import { FC } from "react";
import { FunctionField, Record, ReferenceField } from "react-admin";

import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import Tag from "../../Tag";

type UserFieldProps = {
    record?: Record;
    [key: string]: any;
};

const UserField: FC<UserFieldProps> = ({ record, ...rest }) => {
    const classes = useAroggaStyles();

    const isB2BOrder = record?.po_is_b2b_order === 1;

    return (
        <ReferenceField
            source="po_user_id"
            reference="v1/users"
            link="show"
            {...rest}
        >
            <FunctionField
                onClick={(e) => e.stopPropagation()}
                render={({ u_id, u_name, u_o_count }: Record) => {
                    const userOrderCount = u_o_count ? `(${u_o_count})` : "";

                    return (
                        <span className={classes.whitespaceNowrap}>
                            {`${u_name || u_id} ${userOrderCount}`}
                            <br />
                            {isB2BOrder && <Tag name="B2B" />}
                        </span>
                    );
                }}
            />
        </ReferenceField>
    );
};

export default UserField;

UserField.defaultProps = { label: "User" };
