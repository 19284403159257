import { FC } from "react";
import {
    FileField,
    FunctionField,
    Link,
    Record,
    ReferenceField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

const ExpenseEntryShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Expense Entry Show");

    const classes = useAroggaStyles();

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ColumnShowLayout md={6} simpleShowLayout={false}>
                    <TextField source="ee_id" label="Entry ID" />
                    <FunctionField
                        source="atd_transaction_id"
                        label="Transaction ID"
                        render={({ atd_transaction_id }: Record) => (
                            <Link
                                to={`/v1/accountingTransaction/${atd_transaction_id}`}
                            >
                                {atd_transaction_id}
                            </Link>
                        )}
                    />
                    <ReferenceField
                        source="ee_expense_head_mapping_id"
                        label="Expense Type"
                        reference="v1/expenseHeadMapping"
                    >
                        <TextField source="ehm_expense_title" />
                    </ReferenceField>
                    <TextField source="ee_expense_amount" label="Amount" />
                    <TextField
                        source="ee_expense_description"
                        label="Description"
                    />
                    <TextField
                        source="ee_approval_status"
                        label="Status"
                        className={classes.capitalize}
                    />
                    <AroggaDateField
                        source="ee_created_at"
                        label="Created At"
                    />
                    <ReferenceField
                        source="ee_created_by"
                        label="Created By"
                        reference="v1/users"
                        sortBy="ee_created_at"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="ee_modified_at"
                        label="Modified At"
                    />
                    <ReferenceField
                        source="ee_modified_by"
                        label="Modified By"
                        reference="v1/users"
                        sortBy="ee_modified_by"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <FileField
                        source="attachedFiles_ee_attachment"
                        src="src"
                        title="Attached Files"
                        target="_blank"
                        label="Attached Files"
                        // @ts-ignore
                        onClick={(e) => e.stopPropagation()}
                    />
                </ColumnShowLayout>
            </SimpleShowLayout>
        </Show>
    );
};

export default ExpenseEntryShow;
