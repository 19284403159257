import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { TextInput, useRedirect } from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import AroggaDialogActions from "../../AroggaDialogActions";
import TaxonomiesByVocabularyInput from "../../TaxonomiesByVocabularyInput";

type CancelOrderDialogProps = {
    open: boolean;
    handleDialogClose: () => void;
};

const CancelOrderDialog: FC<CancelOrderDialogProps> = ({
    open,
    handleDialogClose,
}) => {
    const form = useForm();
    const redirect = useRedirect();
    const { values } = useFormState();

    const { isLoading, refetch } = useRequest(
        `/v1/productOrderAction/${values.po_id}/orderCancelAction`,
        {
            method: "POST",
            body: { _reason: values.note },
        },
        {
            onSuccess: () => {
                handleDialogClose();
                redirect("list", "/v1/productOrder");
            },
        }
    );

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <DialogTitle>
                Are you sure you want to cancel this order?
            </DialogTitle>
            <DialogContent>
                <TaxonomiesByVocabularyInput
                    fetchKey="order_cancel_reasons"
                    source="cancelReason"
                    label="Cancel Reason *"
                    helperText={false}
                    onChange={(e) => {
                        if (e.target.value === "Other") {
                            form.change("note", "");
                        } else {
                            form.change("note", e.target.value);
                        }
                    }}
                    title
                    fullWidth
                />
                {values.cancelReason && (
                    <TextInput
                        source="note"
                        label="Note (Visible to Customer)"
                        variant="outlined"
                        helperText={false}
                        minRows={2}
                        multiline
                        fullWidth
                    />
                )}
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={handleDialogClose}
                onConfirm={refetch}
                disabled={!values.cancelReason}
            />
        </Dialog>
    );
};

export default CancelOrderDialog;
