import { FC } from "react";
import { Create, CreateProps, SimpleForm, TransformData } from "react-admin";

import RegionForm from "../../../components/manageSite/regions/RegionForm";
import { useDocumentTitle } from "../../../hooks";

const RegionCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga | Region Create");

    const transform: TransformData = ({ regionType, ...rest }) => ({
        ...rest,
        rt: regionType?.map((rt_name) => ({ rt_name })),
    });

    return (
        <Create {...props} transform={transform}>
            <SimpleForm redirect="list">
                <RegionForm />
            </SimpleForm>
        </Create>
    );
};

export default RegionCreate;
