import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import {
    Confirm,
    Link,
    ReferenceInput,
    SelectInput,
    TextInput,
    required,
    useNotify,
    useRedirect,
    useRefresh,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import AroggaButton from "../../../../components/AroggaButton";
import SendSmsDialog from "../../../../components/SendSmsDialog";
import Tracking from "../../../../components/Tracking";
import ProfilePhotoPlaceholderIcon from "../../../../components/icons/ProfilePhotoPlaceholder";
import AddnewSubArea from "../../../../components/manageOrder/orders/AddnewSubArea";
import AreaInput from "../../../../components/manageOrder/orders/AreaInput";
import DistrictInput from "../../../../components/manageOrder/orders/DistrictInput";
import LocationCreateModal from "../../../../components/manageOrder/orders/LocationCreateModal";
import LocationInvalidDialog from "../../../../components/manageOrder/orders/LocationInvalidDialog";
import LocationUpdateDialog from "../../../../components/manageOrder/orders/LocationUpdateDialog";
import SubAreaInput from "../../../../components/manageOrder/orders/SubAreaInput";
import UserLocationAutocompleteInput from "../../../../components/manageOrder/orders/UserLocationAutocompleteInput";
import { useClipboard, useRequest } from "../../../../hooks";
import { Status } from "../../../../utils/enums";
import { isEmpty, isJSONParsable, logger } from "../../../../utils/helpers";
import { httpClient } from "../../../../utils/http";
type UserProps = {
    permissions: string[];
    setUserCash: (userCash: number) => void;
    [key: string]: any;
};

const User: FC<UserProps> = ({ permissions, setUserCash, ...rest }) => {
    const classes = useStyles();
    const notify = useNotify();
    const form = useForm();
    const redirect = useRedirect();
    const clipboard = useClipboard();
    const { values, errors } = useFormState();
    const refresh = useRefresh();
    const [subAreaIds, setSubAreaIds] = useState("");
    const [locations, setLocations] = useState(null);
    const [isSmsDialogOpen, setIsSmsDialogOpen] = useState(false);
    const [isConfirmLocationDialogOpen, setIsConfirmLocationDialogOpen] =
        useState(false);
    const [isUpdateLocationDialogOpen, setIsUpdateLocationDialogOpen] =
        useState(false);
    const [isInvalidLocationDialogOpen, setIsInvalidLocationDialogOpen] =
        useState(false);
    const [isConfirmOrderDialogOpen, setIsConfirmOrderDialogOpen] =
        useState(false);
    const [isLocationCreateModalOpen, setIsLocationCreateModalOpen] =
        useState(false);
    const [isLocationCreateBtnClick, setIsLocationCreateBtnClick] =
        useState(false);
    const [isLocationEditBtnClick, setIsLocationEditBtnClick] = useState(false);
    const [isUserNoteUpdateButtonShow, setIsUserNoteUpdateButtonShow] =
        useState(false);
    const [newlyAddedLocation, setNewlyAddedLocation] = useState("");

    const [isAddNewSubAreaDialogOpen, setIsAddNewSubAreaDialogOpen] =
        useState(false);
    const [isSubAreaRefresh, setIsSubAreaRefresh] = useState(false);
    const [hasSubArea, setHasSubArea] = useState(false);

    const { data: userData, refetch: fetchUser } = useRequest(
        `/v1/users/${values.po_user_id}`,
        {},
        {
            onSuccess: ({ data }) => {
                setUserCash(data?.u_cash);
                form.change("userNote", data?.u_note);
            },
            refreshDeps: [values.po_user_id],
        }
    );

    const { refetch: updateUserNote } = useRequest(
        `/v1/tinyUpdate/user/${values.po_user_id}`,
        {
            method: "POST",
            body: {
                u_note: values.userNote,
            },
        },
        {
            successNotify: "Successfully updated user note!",
            onSuccess: () => {
                fetchUser();
                setIsUserNoteUpdateButtonShow(false);
            },
        }
    );

    const { isLoading: isConfirmLocationLoading, refetch: confirmLocation } =
        useRequest(
            `/v1/productOrderAction/${values.po_id}/checkAddressAction`,
            {
                method: "POST",
                body: {
                    po_address_checked: 1,
                    ul_name: values?.full_shipping_address?.ul_name,
                    ul_mobile: values?.full_shipping_address?.ul_mobile,
                    ul_type: values?.full_shipping_address?.ul_type,
                    l_division: values?.full_shipping_address?.l_division,
                    l_district: values?.full_shipping_address?.l_district,
                    l_area: values?.full_shipping_address?.l_area,
                    ul_sa_id: values?.full_shipping_address?.ul_sa_id,
                    ul_address: values?.full_shipping_address?.ul_address,
                },
            },
            {
                isRefresh: true,
                successNotify: "Successfully confirmed location!",
                onSuccess: () => setIsConfirmLocationDialogOpen(false),
            }
        );

    const { isLoading: isConfirmOrderLoading, refetch: confirmOrder } =
        useRequest(
            `/v1/productOrderAction/${values.po_id}/confirmOrderAction`,
            {
                method: "POST",
            },
            {
                successNotify: "Successfully confirmed order!",
                onSuccess: () => {
                    redirect("/v1/productOrder");
                    setIsConfirmOrderDialogOpen(false);
                },
            }
        );

    useEffect(() => {
        const locationsFromStroage = sessionStorage.getItem("locations");

        if (locationsFromStroage) {
            setLocations(
                isJSONParsable(locationsFromStroage)
                    ? JSON.parse(locationsFromStroage)
                    : {}
            );
        } else {
            httpClient("/v1/allLocations/", { isBaseUrl: true })
                .then(({ json }: any) => {
                    if (json.status === Status.SUCCESS) {
                        setLocations(json.data);
                        sessionStorage.setItem(
                            "locations",
                            JSON.stringify(json.data)
                        );
                    }
                })
                .catch((err) => logger(err));
        }
    }, []);

    useEffect(() => {
        if (
            !!locations &&
            !!values?.full_shipping_address?.l_division &&
            !!values?.full_shipping_address?.l_district &&
            !!values?.full_shipping_address?.l_area &&
            !!locations[values?.full_shipping_address?.l_division] &&
            !!locations[values?.full_shipping_address?.l_division][
                values?.full_shipping_address?.l_district
            ] &&
            !!locations[values?.full_shipping_address?.l_division][
                values?.full_shipping_address?.l_district
            ][values?.full_shipping_address?.l_area] &&
            !!locations[values?.full_shipping_address?.l_division][
                values?.full_shipping_address?.l_district
            ][values?.full_shipping_address?.l_area]["l_has_subarea"]
        ) {
            setHasSubArea(true);
        } else {
            setHasSubArea(false);
            // form.change("full_shipping_address.ul_sa_id", '');
        }
    }, [values, locations]);

    const { refetch: changeUserLocation } = useRequest(
        `/v1/userLocations?f=admin&checkValidLocation=1&ids=${values.po_user_location_id}`,
        {},
        {
            isSuccessNotify: false,
            isWarningNotify: false,
            onSuccess: ({ data }) => {
                if (!data[0]?.isValid) {
                    setIsInvalidLocationDialogOpen(true);
                }
            },
        }
    );

    useEffect(() => {
        !isUserNoteUpdateButtonShow &&
            userData?.u_note !== values.userNote &&
            setIsUserNoteUpdateButtonShow(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.userNote]);

    const toChoices = (items: string[]) =>
        items.map((item: string) => ({ id: item, name: item }));

    const mobileNoCopyToClipboard = (mobileNo) => {
        if (!mobileNo) return;

        clipboard.copy(mobileNo);
        notify("Mobile no copied to clipboard!", { type: "success" });
    };

    return (
        <>
            <Grid container className={classes.profileContainer}>
                <Grid item sm={12} md={3}>
                    {userData?.u_pic_url ? (
                        <img
                            src={userData.u_pic_url}
                            alt="Profile_Photo"
                            className={classes.profilePhoto}
                        />
                    ) : (
                        <ProfilePhotoPlaceholderIcon />
                    )}
                </Grid>
                <Grid item sm={12} md={9}>
                    <Grid container>
                        <Grid item md={3}>
                            <p className={classes.profileField}>Name:</p>
                        </Grid>
                        <Grid item>
                            <p className={classes.profileFieldResult}>
                                {userData?.u_name}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={3}>
                            <p className={classes.profileField}>User ID:</p>
                        </Grid>
                        <Grid item>
                            <p className={classes.profileFieldResult}>
                                <Link
                                    to={`/v1/users/${values.po_user_id}/show`}
                                    target="_blank"
                                >
                                    {values.po_user_id}
                                </Link>
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={3}>
                            <p className={classes.profileField}>Mobile No:</p>
                        </Grid>
                        <Grid item>
                            <p className={classes.profileFieldResult}>
                                <span
                                    onClick={() =>
                                        mobileNoCopyToClipboard(
                                            userData?.u_mobile
                                        )
                                    }
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    {userData?.u_mobile}
                                </span>{" "}
                                {/* TODO: Implement this after taking API from Samiron bhai */}
                                <AroggaButton
                                    label="Send SMS"
                                    type="success"
                                    onClick={() => setIsSmsDialogOpen(true)}
                                />
                            </p>
                            <SendSmsDialog
                                open={isSmsDialogOpen}
                                handleClose={() => setIsSmsDialogOpen(false)}
                                {...rest}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={3}>
                            <p className={classes.profileField}>Order Count:</p>
                        </Grid>
                        <Grid item>
                            <p className={classes.profileFieldResult}>
                                {userData?.u_o_count}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={3}>
                            <p className={classes.profileField}>
                                Deliver Order Count:
                            </p>
                        </Grid>
                        <Grid item>
                            <p className={classes.profileFieldResult}>
                                {userData?.u_d_count}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={3}>
                            <p className={classes.profileField}>
                                Note about user:
                            </p>
                        </Grid>
                        <Grid item style={{ position: "relative" }}>
                            <TextInput
                                source="userNote"
                                label=""
                                variant="outlined"
                                style={{ position: "relative", width: 217 }}
                                minRows={2}
                                multiline
                            />
                            {isUserNoteUpdateButtonShow &&
                                userData?.u_note !== values.userNote && (
                                    <AroggaButton
                                        label="Update"
                                        type="success"
                                        style={{
                                            position: "absolute",
                                            right: 0,
                                            bottom: -6,
                                        }}
                                        onClick={updateUserNote}
                                    />
                                )}
                        </Grid>
                    </Grid>
                </Grid>
                {!!rest.record?.po_address_checked &&
                    !isLocationEditBtnClick && (
                        <Grid item sm={12} md={8}>
                            <Box display="flex" alignItems="center" gridGap={5}>
                                <ReferenceInput
                                    source="po_user_location_id"
                                    label="Location"
                                    variant="outlined"
                                    reference="v1/userLocations"
                                    onChange={changeUserLocation}
                                    filter={{
                                        _orderBy: "ul_default",
                                        u_id: values.po_user_id,
                                    }}
                                    fullWidth
                                >
                                    <UserLocationAutocompleteInput
                                        matchSuggestion={() => true}
                                        optionValue="ul_id"
                                        helperText={false}
                                        newlyAddedLocation={newlyAddedLocation}
                                    />
                                </ReferenceInput>
                                {rest.record?.po_address_checked &&
                                    rest.record?.po_user_location_id ===
                                        values.po_user_location_id && (
                                        <AroggaButton
                                            label="Edit"
                                            type="success"
                                            onClick={() =>
                                                setIsLocationEditBtnClick(true)
                                            }
                                        />
                                    )}
                                {!isLocationCreateBtnClick && (
                                    <AroggaButton
                                        label="Create"
                                        type="success"
                                        onClick={() => {
                                            setIsLocationCreateBtnClick(true);
                                            setIsLocationCreateModalOpen(true);
                                        }}
                                    />
                                )}
                                <LocationCreateModal
                                    open={isLocationCreateModalOpen}
                                    onDialogClose={() => {
                                        setIsLocationCreateModalOpen(false);
                                        setIsLocationCreateBtnClick(false);
                                    }}
                                    userId={values.po_user_id}
                                    setNewlyAddedLocation={
                                        setNewlyAddedLocation
                                    }
                                />
                                <LocationInvalidDialog
                                    isInvalidLocationDialogOpen={
                                        isInvalidLocationDialogOpen
                                    }
                                    onDialogClose={() => {
                                        setIsInvalidLocationDialogOpen(false);
                                        refresh();
                                    }}
                                />
                            </Box>
                        </Grid>
                    )}
            </Grid>
            {(!rest.record?.po_address_checked || isLocationEditBtnClick) && (
                <div className={classes.locationArea}>
                    <Grid container spacing={1}>
                        <Grid item sm={6} md={4}>
                            <TextInput
                                source="full_shipping_address.ul_name"
                                label="Shipping Name"
                                variant="outlined"
                                helperText={false}
                                validate={[required()]}
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={6} md={4}>
                            <TextInput
                                source="full_shipping_address.ul_mobile"
                                label="Shipping Mobile"
                                variant="outlined"
                                helperText={false}
                                onFocus={(e) => {
                                    const newValue = e.target.value.startsWith(
                                        "+88"
                                    )
                                        ? e.target.value.replace("+88", "")
                                        : e.target.value;

                                    mobileNoCopyToClipboard(newValue);
                                }}
                                validate={[required()]}
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={6} md={4}>
                            <SelectInput
                                source="full_shipping_address.ul_type"
                                label="Address Type"
                                variant="outlined"
                                helperText={false}
                                choices={[
                                    { id: "Home", name: "Home" },
                                    { id: "Office", name: "Office" },
                                    { id: "Hometown", name: "Hometown" },
                                ]}
                                validate={[required()]}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={12}>
                            <ReferenceInput
                                source="po_user_location_id"
                                label="Location"
                                variant="outlined"
                                reference="v1/userLocations"
                                filter={{
                                    _orderBy: "ul_default",
                                    u_id: values.u_id,
                                }}
                                filterToQuery={(searchText) => ({
                                    _search: searchText,
                                })}
                                fullWidth
                                disabled
                            >
                                <UserLocationAutocompleteInput
                                    matchSuggestion={() => true}
                                    optionValue="ul_id"
                                    helperText={false}
                                    resettable
                                />
                            </ReferenceInput>
                        </Grid>

                        <Grid item sm={6} md={4}>
                            <SelectInput
                                source="full_shipping_address.l_division"
                                label="Shipping Division"
                                variant="outlined"
                                helperText={false}
                                choices={
                                    !!locations
                                        ? toChoices(Object.keys(locations))
                                        : []
                                }
                                allowEmpty
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={6} md={4}>
                            <DistrictInput
                                source="full_shipping_address.l_district"
                                label="Shipping City"
                                variant="outlined"
                                helperText={false}
                                locations={locations}
                                setLocations={setLocations}
                                allowEmpty
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={6} md={4}>
                            <AreaInput
                                source="full_shipping_address.l_area"
                                label="Shipping Area"
                                variant="outlined"
                                helperText={false}
                                locations={locations}
                                setLocations={setLocations}
                                allowEmpty
                                fullWidth
                            />
                        </Grid>
                        {hasSubArea && (
                            <Grid item sm={6} md={4}>
                                <Grid container>
                                    <Grid item sm={8} md={8}>
                                        <SubAreaInput
                                            setSubAreaIds={setSubAreaIds}
                                            source="full_shipping_address.ul_sa_id"
                                            label="Shipping Sub Area"
                                            variant="outlined"
                                            locations={locations}
                                            isSubAreaRefresh={isSubAreaRefresh}
                                            setLocations={setLocations}
                                            translateChoice={false}
                                            allowEmpty
                                            fullWidth
                                        />
                                    </Grid>
                                    {permissions?.includes("subAreaCreate") && (
                                        <Grid item sm={4} md={4}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    marginTop: 10,
                                                    marginLeft: 3,
                                                }}
                                                onClick={() => {
                                                    setIsAddNewSubAreaDialogOpen(
                                                        true
                                                    );
                                                    setIsSubAreaRefresh(false);
                                                }}
                                            >
                                                Add New
                                            </Button>
                                        </Grid>
                                    )}
                                    <AddnewSubArea
                                        subAreaIds={subAreaIds}
                                        locations={locations}
                                        open={isAddNewSubAreaDialogOpen}
                                        setIsSubAreaRefresh={
                                            setIsSubAreaRefresh
                                        }
                                        handleClose={() => {
                                            setIsAddNewSubAreaDialogOpen(false);
                                        }}
                                        {...rest}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Grid item sm={6} md={6}>
                            <TextInput
                                source="full_shipping_address.ul_address"
                                label="Shipping Home Address"
                                variant="outlined"
                                helperText={false}
                                validate={[required()]}
                                multiline
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="flex-end" gridGap={5}>
                        {!!rest.record?.po_address_checked && (
                            <>
                                <AroggaButton
                                    label="Cancel"
                                    type="danger"
                                    onClick={() =>
                                        setIsLocationEditBtnClick(false)
                                    }
                                />
                                <AroggaButton
                                    label="Update"
                                    type="success"
                                    onClick={() =>
                                        setIsUpdateLocationDialogOpen(true)
                                    }
                                    disabled={
                                        !isEmpty(errors?.full_shipping_address)
                                    }
                                />
                                <LocationUpdateDialog
                                    isUpdateLocationDialogOpen={
                                        isUpdateLocationDialogOpen
                                    }
                                    onDialogClose={() =>
                                        setIsUpdateLocationDialogOpen(false)
                                    }
                                />
                            </>
                        )}
                        {!rest.record?.po_address_checked &&
                            rest.record?.po_status === "processing" && (
                                <>
                                    <AroggaButton
                                        label="Confirm Location"
                                        type="success"
                                        onClick={() =>
                                            setIsConfirmLocationDialogOpen(true)
                                        }
                                    />
                                    <Confirm
                                        title={`Order ID #${values.po_id}`}
                                        content="Are you sure you want to confirm this location?"
                                        isOpen={isConfirmLocationDialogOpen}
                                        loading={isConfirmLocationLoading}
                                        onConfirm={confirmLocation}
                                        onClose={() =>
                                            setIsConfirmLocationDialogOpen(
                                                false
                                            )
                                        }
                                    />
                                </>
                            )}
                    </Box>
                </div>
            )}
            {!!values.po_address_checked &&
                values.po_status === "processing" && (
                    <Box textAlign="right" mt={2}>
                        <AroggaButton
                            label="Confirm Order"
                            type="success"
                            onClick={() => setIsConfirmOrderDialogOpen(true)}
                        />
                        <Confirm
                            title={`Order ID #${values.po_id}`}
                            content="Are you sure you want to confirm this order?"
                            isOpen={isConfirmOrderDialogOpen}
                            loading={isConfirmOrderLoading}
                            onConfirm={confirmOrder}
                            onClose={() => setIsConfirmOrderDialogOpen(false)}
                        />
                    </Box>
                )}
            <Box mt={2}>
                <Tracking data={values?.tracking} />
            </Box>
        </>
    );
};

export default User;

const useStyles = makeStyles(() => ({
    profileContainer: {
        border: "1px solid #EAEBEC",
        borderRadius: 6,
        padding: 25,
        marginTop: 10,
    },
    profilePhoto: {
        width: 241,
        height: 262,
        borderRadius: 10,
    },
    profileField: {
        color: "#7C8AA0",
    },
    profileFieldResult: {
        color: "#112950",
    },
    locationArea: {
        margin: "20px 0px",
        padding: "12px 12px",
        border: "1px dashed #3ECBA5",
        borderRadius: 6,
    },
}));
