import { Box } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { FC, useState } from "react";
import {
    AutocompleteInput,
    Button,
    Confirm,
    Datagrid,
    FunctionField,
    ImageField,
    NumberField,
    NumberInput,
    Pagination,
    Record,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    SimpleForm,
    TextField,
    usePermissions,
} from "react-admin";
import { useFormState } from "react-final-form";

import AroggaDateField from "../../../components/AroggaDateField";
import { useRequest } from "../../../hooks";
import {
    getProductTextRenderer,
    getQuantityLabel,
    getReadableSKU,
    getResourcePaginationTrack,
} from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import PurchaseOrderItemAdd from "./PurchaseOrderItemAdd";

type PurchaseOrderExpandProps = {
    from?: "purchaseOrderMenu" | "vendorHistoryTab";
    [key: string]: any;
};

const PurchaseOrderExpand: FC<PurchaseOrderExpandProps> = ({
    from,
    ...rest
}) => {
    const classes = useAroggaStyles();
    const { permissions } = usePermissions();
    const { values } = useFormState();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isQtyDialogOpen, setIsQtyDialogOpen] = useState(false);
    const [isAddButtonShow, setIsAddButtonShow] = useState(true);
    const [purchaseOrderItemId, setPurchaseOrderItemId] = useState("");
    const [status, setStatus] = useState("");
    const [qtyLabelInfo, setQtyLabelInfo] = useState(null);

    const resourcePaginationTrack = getResourcePaginationTrack();

    const { isLoading, refetch } = useRequest(
        `/v1/purchaseOrderItemStatusUpdate/${purchaseOrderItemId}`,
        {
            method: "POST",
            body: {
                _status: status,
            },
        },
        {
            isRefresh: true,
            onSuccess: () => setIsDialogOpen(false),
        }
    );

    const {
        isLoading: isLoadingPurchaseOrderItemUpdate,
        refetch: updatePurchaseOrderItemQty,
    } = useRequest(
        `/v1/purchaseOrderItem/${purchaseOrderItemId}`,
        {
            method: "POST",
            body: {
                poi_quantity: values.qty,
            },
        },
        {
            isRefresh: true,
            onSuccess: () => setIsQtyDialogOpen(false),
        }
    );

    const handleQtyDialogClose = () => {
        values.qty = undefined;
        setQtyLabelInfo(null);
        setIsQtyDialogOpen(false);
    };

    return (
        <>
            <ReferenceInput
                source="productId"
                label="Search By Product"
                variant="outlined"
                reference="general/v3/search"
                sort={{ field: "p_name", order: "ASC" }}
                fullWidth
            >
                <AutocompleteInput
                    optionText={getProductTextRenderer}
                    options={{
                        InputProps: {
                            multiline: true,
                        },
                    }}
                    matchSuggestion={() => true}
                    resettable
                />
            </ReferenceInput>
            <ReferenceManyField
                reference="v1/purchaseOrderItem"
                target="_po_id"
                page={
                    resourcePaginationTrack?.["v1/purchaseOrderItem"]?.[
                        rest?.record?.po_id
                    ]?.currentPage || 1
                }
                pagination={<Pagination />}
                sort={{ field: "p_name", order: "ASC" }}
                filter={{ _product_id: values.productId }}
            >
                <Datagrid>
                    <TextField source="poi_id" label="Item ID" />
                    <TextField source="p_name" label="Product" />
                    <ReferenceField
                        source="pv_id"
                        label="Variant"
                        reference="v1/productVariant"
                        link={false}
                        sortable={false}
                    >
                        <FunctionField
                            render={(record: Record) =>
                                getReadableSKU(record.pv_attribute)
                            }
                        />
                    </ReferenceField>
                    <TextField source="p_form" label="Form" />
                    <TextField source="p_strength" label="Strength" />
                    <FunctionField
                        source="pv_req_qty"
                        label="Qty"
                        sortBy="pv_req_qty"
                        render={({
                            poi_id,
                            poi_status,
                            pv_req_qty: qty,
                            pv_unit_base_label: baseUnit,
                            pv_unit_label: salesUnit,
                            pv_unit_multiplier: salesUnitMultiplier,
                        }: Record) => (
                            <span className={classes.whitespaceNowrap}>
                                {getQuantityLabel({
                                    qty,
                                    salesUnit,
                                    baseUnit,
                                    salesUnitMultiplier,
                                })}
                                {permissions?.includes(
                                    "purchaseOrderItemEdit"
                                ) &&
                                    poi_status === "pending" && (
                                        <EditIcon
                                            fontSize="small"
                                            cursor="pointer"
                                            style={{ color: "#0E7673" }}
                                            onClick={() => {
                                                setPurchaseOrderItemId(poi_id);
                                                setQtyLabelInfo({
                                                    salesUnit,
                                                    baseUnit,
                                                    salesUnitMultiplier,
                                                });
                                                setIsQtyDialogOpen(true);
                                            }}
                                        />
                                    )}
                            </span>
                        )}
                    />
                    <NumberField source="pv_mrp" label="MRP" />
                    <NumberField source="pv_last_tp" label="Purchase Price" />
                    <NumberField source="poi_tax" label="TAX" />
                    <NumberField source="poi_vat" label="VAT" />
                    <NumberField source="poi_discount" label="Discount" />
                    <TextField source="poi_description" label="Description" />
                    <ImageField
                        source="p_image"
                        label="Image"
                        src="src"
                        title="title"
                        className="small__img"
                    />
                    <AroggaDateField
                        source="poi_shelved_at"
                        label="Shelved At"
                    />
                    <ReferenceField
                        source="poi_shelved_by"
                        label="Shelved By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <FunctionField
                        label={
                            from === "vendorHistoryTab" ? "Status" : "Action"
                        }
                        render={({ poi_id, poi_status }) => {
                            if (poi_status === "pending") {
                                return (
                                    <Box display="flex" gridGap={4}>
                                        <Button
                                            label="Receive"
                                            variant="contained"
                                            onClick={() => {
                                                setPurchaseOrderItemId(poi_id);
                                                setStatus("received");
                                                setIsDialogOpen(true);
                                            }}
                                        />
                                        <Button
                                            label="Reject"
                                            variant="contained"
                                            className={classes.bgRed}
                                            onClick={() => {
                                                setPurchaseOrderItemId(poi_id);
                                                setStatus("rejected");
                                                setIsDialogOpen(true);
                                            }}
                                        />
                                    </Box>
                                );
                            } else if (poi_status === "received") {
                                return (
                                    <Button
                                        label="Undo Receive"
                                        variant="contained"
                                        className={classes.whitespaceNowrap}
                                        onClick={() => {
                                            setPurchaseOrderItemId(poi_id);
                                            setStatus("pending");
                                            setIsDialogOpen(true);
                                        }}
                                    />
                                );
                            } else {
                                return (
                                    <span
                                        className={`${classes.capitalize} ${
                                            poi_status === "rejected"
                                                ? classes.textRed
                                                : classes.textGreen
                                        }`}
                                    >
                                        {poi_status}
                                    </span>
                                );
                            }
                        }}
                    />
                </Datagrid>
            </ReferenceManyField>
            {permissions?.includes("purchaseOrderItemCreate") &&
                rest?.record?.po_status === "pending" &&
                from !== "vendorHistoryTab" &&
                isAddButtonShow && (
                    <Button
                        label="Add"
                        variant="contained"
                        onClick={() => setIsAddButtonShow(false)}
                        style={{ position: "relative" }}
                    />
                )}
            {!isAddButtonShow && (
                <SimpleForm toolbar={null} style={{ position: "relative" }}>
                    <PurchaseOrderItemAdd
                        purchaseOrderId={rest?.record?.po_id}
                    />
                </SimpleForm>
            )}
            <Confirm
                isOpen={isDialogOpen}
                loading={isLoading}
                title={`Are you sure you want to ${
                    status === "received"
                        ? "receive"
                        : status === "rejected"
                        ? "reject"
                        : "undo receive"
                } this product #${purchaseOrderItemId}?`}
                content={false}
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
            <Confirm
                title={`Are you sure you want to update qty? #${purchaseOrderItemId}`}
                content={
                    <>
                        <NumberInput
                            source="qty"
                            label="Qty"
                            variant="outlined"
                            helperText={false}
                            fullWidth
                        />
                        {values.qty &&
                            getQuantityLabel({
                                ...qtyLabelInfo,
                                qty: values.qty,
                            })}
                    </>
                }
                isOpen={isQtyDialogOpen}
                loading={isLoadingPurchaseOrderItemUpdate}
                onConfirm={updatePurchaseOrderItemQty}
                onClose={handleQtyDialogClose}
            />
        </>
    );
};

export default PurchaseOrderExpand;
