import { FC } from "react";
import {
    Edit,
    EditProps,
    ImageField,
    NumberInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useEditController,
} from "react-admin";

import { useDocumentTitle } from "../../../hooks";
import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
const CustomToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const IssueEdit: FC<EditProps> = ({ hasEdit, ...rest }) => {
    useDocumentTitle("Arogga | Issue |  Edit");
    const { record } = useEditController(rest);

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm
                redirect="list"
                submitOnEnter={false}
                toolbar={<CustomToolbar />}
            >
                <TextInput
                    source="i_id"
                    label="ID"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
                <NumberInput
                    source="i_shipment_id"
                    label="Shipment ID"
                    disabled
                />
                <NumberInput source="i_order_id" label="Order ID" disabled />
                <TextInput source="i_type" label="Type" disabled />
                <SelectInput
                    source="i_status"
                    label="Status"
                    choices={[
                        { id: "processing", name: "Processing" },
                        { id: "qc", name: "Qc" },
                        {
                            id: "shipment_generated",
                            name: "Shipment Generated",
                        },
                        { id: "settled", name: "Settled" },
                        { id: "approved", name: "Approved" },
                        { id: "rejected", name: "Rejected" },
                        { id: "cancelled", name: "Cancelled" },
                    ]}
                    variant="outlined"
                />
                <NumberInput
                    source="i_total_in_amount"
                    label="Total In Amount"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
                <NumberInput
                    source="i_total_out_amount"
                    label="Total Out Amount"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
                <TextInput
                    source="i_money_or_product"
                    label="Return Money / Product"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
                <FormatedBooleanInput
                    source="i_is_calculate_additions"
                    label="Calculate Additions?"
                    FalseIcon={() => null}
                    looseValue
                    disabled
                />
                <TextInput
                    source="i_details"
                    label="Details"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
                {record?.attachedFiles_i_images?.length > 0 ? (
                    <>
                        <p>Images:</p>
                        {record?.attachedFiles_i_images.map((image, index) => (
                            <ImageField
                                key={index}
                                source={`attachedFiles_i_images[${index}].src`}
                                title="Image"
                            />
                        ))}
                    </>
                ) : null}
            </SimpleForm>
        </Edit>
    );
};

export default IssueEdit;
