import AcUnitIcon from "@material-ui/icons/AcUnit";
import { FC } from "react";
import {
    BooleanField,
    FunctionField,
    ImageField,
    Link,
    List,
    ListProps,
    NumberField,
    Record,
    ReferenceField,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import { useDocumentTitle, useExport } from "../../../hooks";
import { CustomizableDatagrid } from "../../../lib";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import IssueExpand from "./IssueExpand";
import IssueFilter from "./IssueFilter";

const IssueList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Issue List");

    const exporter = useExport(rest);
    const classes = useAroggaStyles();

    return (
        <List
            {...rest}
            title="List of Issue"
            filters={<IssueFilter children={""} />}
            filterDefaultValues={{ _details: 1, _status: "processing" }}
            perPage={25}
            sort={{ field: "i_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={false}
        >
            <CustomizableDatagrid
                rowClick="show"
                expand={<IssueExpand />}
                isRowExpandable={(row) => !!row?.ic?.length}
                classes={{ expandedPanel: classes.expandedPanel }}
                hideableColumns={["i_created_at", "i_created_by"]}
            >
                <TextField source="i_id" label="ID" />
                <FunctionField
                    label="Shipment ID"
                    onClick={(e) => e.stopPropagation()}
                    sortBy="i_shipment_id"
                    render={({
                        i_shipment_id,
                        i_order_id,
                        s_sequence,
                    }: Record) => (
                        <Link to={`/v1/shipment/${i_shipment_id}/show`}>
                            {i_order_id}
                            {s_sequence}
                        </Link>
                    )}
                />
                <FunctionField
                    label="Order ID"
                    onClick={(e) => e.stopPropagation()}
                    sortBy="i_order_id"
                    render={({ i_order_id }: Record) => (
                        <Link to={`/v1/productOrder/${i_order_id}/show`}>
                            {i_order_id}
                        </Link>
                    )}
                />
                <TextField
                    source="i_type"
                    label="Type"
                    className={classes.capitalize}
                />
                <FunctionField
                    label="Status"
                    onClick={(e) => e.stopPropagation()}
                    sortBy="i_status"
                    className={classes.whitespaceNowrap}
                    render={({ i_status }: Record) =>
                        capitalizeFirstLetterOfEachWord(i_status)
                    }
                />
                <ReferenceField
                    source="i_shipment_id"
                    label="Cold"
                    reference="v1/shipment"
                    link={false}
                >
                    <FunctionField
                        render={(record: Record) => {
                            if (!record?.s_m_cold) return;
                            return <AcUnitIcon />;
                        }}
                    />
                </ReferenceField>
                <NumberField
                    source="i_total_in_amount"
                    label="Total In Amount"
                />
                <NumberField
                    source="i_total_out_amount"
                    label="Total Out Amount"
                />
                <TextField
                    source="i_money_or_product"
                    label="Return Money / Product"
                    className={classes.capitalize}
                />
                <BooleanField
                    source="i_is_calculate_additions"
                    label="Calculate Additions?"
                    FalseIcon={() => null}
                    looseValue
                />
                <TextField source="i_details" label="Details" />
                <ImageField
                    source="attachedFiles_i_images"
                    label="Images"
                    src="src"
                    title="title"
                    className="small__img"
                />
                <AroggaDateField source="i_created_at" label="Created At" />
                <ReferenceField
                    source="i_created_by"
                    label="Created By"
                    reference="v1/users"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>
    );
};

export default IssueList;
