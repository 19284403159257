import { FC } from "react";
import { List, ListProps, ReferenceField, TextField } from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import { useDocumentTitle } from "../../../hooks";
import { CustomizableDatagrid } from "../../../lib";
import ContentHistoryFilter from "./ContentHistoryFilter";

const ContentHistoryList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Content History List");

    return (
        <List
            {...rest}
            title="List of Content History"
            perPage={25}
            sort={{ field: "ch_id", order: "DESC" }}
            filters={<ContentHistoryFilter children={""} />}
            bulkActionButtons={false}
            exporter={false}
        >
            <CustomizableDatagrid>
                <TextField source="ch_id" label="ID" />
                <TextField source="ch_entity_id" label="Entity ID" />
                <TextField source="ch_entity_name" label="Entity Name" />
                <TextField source="ch_field_name" label="Field Name" />
                <TextField source="ch_old_value" label="Old Value" />
                <TextField source="ch_new_value" label="New Value" />
                <AroggaDateField source="ch_created_at" label="Created At" />
                <ReferenceField
                    source="ch_created_by"
                    label="Created By"
                    reference="v1/users"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>
    );
};

export default ContentHistoryList;
