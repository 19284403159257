import { FC } from "react";
import {
    DateInput,
    Filter,
    FilterProps,
    SelectInput,
    TextInput,
} from "react-admin";
import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import TreeDropdownInput from "../../../components/TreeDropdownInput";

const AssetFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            resettable
            alwaysOn
        />
        <TreeDropdownInput
            reference="/v1/taxonomiesByVocabulary/department"
            source="_department_id"
            label="Department"
            variant="outlined"
            keyId="t_id"
            keyParent="t_parent_id"
            optionValue="t_id"
            optionTextValue="t_title"
            alwaysOn
        />
        <TaxonomiesByVocabularyInput
            fetchKey="asset_category"
            source="_category"
            label="Category"
            optionValue="t_id"
        />
        <DateInput
            source="_purchase_date"
            label="Purchase Date"
            variant="outlined"
        />
        <SelectInput
            source="_status"
            label="Status"
            variant="outlined"
            choices={[
                { id: "active", name: "Active" },
                {
                    id: "inactive",
                    name: "In Active",
                },
            ]}
        />
    </Filter>
);

export default AssetFilter;
