import { FC } from "react";
import { NumberInput, TextInput } from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

const AdminTab: FC = () => {
    const classes = useAroggaStyles();

    return (
        <>
            <TextInput
                source="yt_video_key"
                label="Youtube Video Key"
                variant="outlined"
                className={classes.block}
            />
            <TextInput
                source="yt_video_title"
                label="Youtube Video Title"
                variant="outlined"
                className={classes.block}
            />
            <NumberInput
                source="prescription_percent"
                label="Prescription Discount"
                variant="outlined"
                className={classes.block}
            />
            <TextInput
                source="call_time"
                label="Call to Order Time"
                variant="outlined"
                className={classes.block}
            />
            <NumberInput
                source="call_percent"
                label="Call Discount"
                variant="outlined"
                className={classes.block}
            />
            <NumberInput
                source="healthcare_percent"
                label="Healthcare Discount"
                variant="outlined"
                className={classes.block}
            />
            <NumberInput
                source="b2b_discount_percent"
                label="B2B Discount"
                variant="outlined"
            />
            <FormatedBooleanInput
                source="req_order_prescription"
                label="Require Order Prescription Check"
            />
        </>
    );
};

export default AdminTab;
