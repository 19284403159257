import { FC } from "react";
import { Filter, FilterProps, SelectInput, TextInput } from "react-admin";

const SupplierFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            resettable
            alwaysOn
        />
        <SelectInput
            source="_status"
            label="Status"
            variant="outlined"
            choices={[
                { id: "active", name: "Active" },
                { id: "inactive", name: "Inactive" },
            ]}
            alwaysOn
        />
        <SelectInput
            source="_payment_method"
            label="Payment Method"
            variant="outlined"
            choices={[
                { id: "cash", name: "Cash" },
                { id: "bank", name: "Bank" },
            ]}
            alwaysOn
        />
    </Filter>
);

export default SupplierFilter;
