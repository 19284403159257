import {
    Datagrid,
    Pagination,
    ReferenceField,
    ReferenceManyField,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../../components/AroggaDateField";
import { useRequest } from "../../../../hooks";
import { useAroggaStyles } from "../../../../utils/useAroggaStyles";

const PaymentTab = ({ record }) => {
    const classes = useAroggaStyles();
    const { data: payment } = useRequest(
        `/v1/officePoPayment?_office_purchase_order_id=${record?.office_purchase_orders?.opo_id}`,
        {},
        {
            isPreFetching: true,
            refreshDeps: [record?.office_purchase_orders?.opo_id],
            isSuccessNotify: false,
            isWarningNotify: false,
        }
    );

    return (
        <>
            {payment?.length ? (
                <ReferenceManyField
                    reference="v1/officePoPayment"
                    target="opp_id"
                    pagination={<Pagination />}
                    filter={{
                        _office_purchase_order_id:
                            record?.office_purchase_orders?.opo_id,
                    }}
                >
                    <Datagrid>
                        <ReferenceField
                            source="opp_created_by"
                            label="Created By"
                            reference="v1/users"
                            sortBy="opp_created_by"
                            link="show"
                        >
                            <TextField source="u_name" />
                        </ReferenceField>
                        <AroggaDateField
                            source="opp_created_at"
                            label="Created At"
                        />
                        <ReferenceField
                            source="opp_supplier_id"
                            label="Supplier"
                            reference="v1/supplier"
                            sortBy="opp_supplier_id"
                            link="show"
                        >
                            <TextField source="s_name" />
                        </ReferenceField>
                        <TextField
                            source="opp_payment_amount"
                            label="Payment Amount"
                        />
                        <TextField
                            source="opp_payment_method"
                            label="Payment Method"
                            className={classes.capitalize}
                        />
                        <TextField
                            source="opp_payment_status"
                            label="Status"
                            className={classes.capitalize}
                        />
                    </Datagrid>
                </ReferenceManyField>
            ) : (
                <div style={{ margin: 20 }}>
                    <p style={{ fontSize: 15 }}>No Result Found</p>
                </div>
            )}
        </>
    );
};

export default PaymentTab;
