const DescriptionIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
    >
        <circle cx={20} cy={20} r={20} fill="#F9FAFB" />
        <mask
            id="mask0_724_4057"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x={8}
            y={8}
            width={24}
            height={24}
        >
            <rect x={8} y={8} width={24} height={24} fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_724_4057)">
            <path
                d="M16 25H21C21.2833 25 21.5208 24.9042 21.7125 24.7125C21.9042 24.5208 22 24.2833 22 24C22 23.7167 21.9042 23.4792 21.7125 23.2875C21.5208 23.0958 21.2833 23 21 23H16C15.7167 23 15.4792 23.0958 15.2875 23.2875C15.0958 23.4792 15 23.7167 15 24C15 24.2833 15.0958 24.5208 15.2875 24.7125C15.4792 24.9042 15.7167 25 16 25ZM16 21H24C24.2833 21 24.5208 20.9042 24.7125 20.7125C24.9042 20.5208 25 20.2833 25 20C25 19.7167 24.9042 19.4792 24.7125 19.2875C24.5208 19.0958 24.2833 19 24 19H16C15.7167 19 15.4792 19.0958 15.2875 19.2875C15.0958 19.4792 15 19.7167 15 20C15 20.2833 15.0958 20.5208 15.2875 20.7125C15.4792 20.9042 15.7167 21 16 21ZM16 17H24C24.2833 17 24.5208 16.9042 24.7125 16.7125C24.9042 16.5208 25 16.2833 25 16C25 15.7167 24.9042 15.4792 24.7125 15.2875C24.5208 15.0958 24.2833 15 24 15H16C15.7167 15 15.4792 15.0958 15.2875 15.2875C15.0958 15.4792 15 15.7167 15 16C15 16.2833 15.0958 16.5208 15.2875 16.7125C15.4792 16.9042 15.7167 17 16 17ZM13 29C12.45 29 11.9792 28.8042 11.5875 28.4125C11.1958 28.0208 11 27.55 11 27V13C11 12.45 11.1958 11.9792 11.5875 11.5875C11.9792 11.1958 12.45 11 13 11H27C27.55 11 28.0208 11.1958 28.4125 11.5875C28.8042 11.9792 29 12.45 29 13V27C29 27.55 28.8042 28.0208 28.4125 28.4125C28.0208 28.8042 27.55 29 27 29H13Z"
                fill="#A0AEC0"
            />
        </g>
    </svg>
);
export default DescriptionIcon;
