import { Box, Dialog, Typography } from "@material-ui/core";
import { FC, useState } from "react";

interface DescriptionWithModalProps {
    description: string;
}

const DescriptionWithModal: FC<DescriptionWithModalProps> = ({
    description,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const handleViewMore = () => {
        setIsDialogOpen(true);
    };
    const handleClose = () => {
        setIsDialogOpen(false);
    };
    const text = description.slice(0, 80);
    return (
        <>
            <Typography variant="body2">
                {text}
                {description?.length > 80 && (
                    <span
                        onClick={handleViewMore}
                        style={{ color: "#489641", cursor: "pointer" }}
                    >
                        ....View More
                    </span>
                )}
            </Typography>
            <Dialog open={isDialogOpen} onClose={handleClose}>
                <Box p={4}>
                    <Typography
                        variant="body2"
                        style={{ wordWrap: "break-word" }}
                    >
                        {description}
                    </Typography>
                </Box>
            </Dialog>
        </>
    );
};

export default DescriptionWithModal;
