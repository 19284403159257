import { NotificationType } from "react-admin";

import { toQueryString } from "../../../../dataProvider/toQueryString";
import {
    convertAttachmentsToBase64,
    isArrayofTypeObject,
    isEmpty,
    logger,
} from "../../../../utils/helpers";
import { httpClient } from "../../../../utils/http";

type ProductOnSaveProps = {
    notify: (
        message: string,
        type?:
            | NotificationType
            | (NotificationOptions & { type: NotificationType })
    ) => void;
    redirect: (action: string, url: string) => void;
    mutate: (data: any, options?: any) => void | Promise<any>;
    values: any;
};

export const productOnSave = async ({
    notify,
    redirect,
    mutate,
    values,
}: ProductOnSaveProps) => {
    if (!values.p_product_category_id)
        return notify("Category must be required!", {
            type: "warning",
        });

    if (!["productList"].includes(values?.cloneActionFrom) && values.p_id) {
        const newlyAddedProductUnits = values?.pu?.filter(
            (item) => !item?.pu_id
        );

        if (
            newlyAddedProductUnits?.length &&
            newlyAddedProductUnits?.some(
                (item) =>
                    !item?.pu_is_b2b_sales_unit && !item?.pu_is_b2c_sales_unit
            )
        )
            return notify("B2B / B2C Sales is required for newly added Unit!", {
                type: "warning",
            });
    }

    if (isEmpty(values.pv))
        return notify("Variant must be required!", {
            type: "warning",
        });

    const {
        id,
        p_id,
        p_formMachineName,
        cloneActionFrom,
        p_product_tags = [],
        p_product_internal_tags = [],
        p_product_keywords = [],
        ...restValues
    } = values;

    const modifiedProductVariants = await convertAttachmentsToBase64(
        "attachedFiles_pv_images",
        restValues.pv.map(
            (
                {
                    id,
                    pv_id,
                    pv_p_id,
                    pv_sku,
                    pv_medicine_id,
                    pvBaseUnitLabel,
                    pv_purchase_price,
                    pv_allow_purchase,
                    pv_allow_sales,
                    pvB2CSummary,
                    pvB2BSummary,
                    ...restProductVariant
                },
                i
            ) => ({
                ...restProductVariant,
                ...(cloneActionFrom
                    ? { pv_sku: pv_sku?.split("-P-")[0] }
                    : { id, pv_id, pv_p_id, pv_sku }),
                pv_allow_purchase:
                    !cloneActionFrom && p_id ? pv_allow_purchase : 1,
                pv_allow_sales: !cloneActionFrom && p_id ? pv_allow_sales : 0,
                attachedFiles_pv_images:
                    restValues?.[`attachedFiles_pv_images-${i}`] === undefined
                        ? restProductVariant?.["attachedFiles_pv_images"]
                        : restValues[`attachedFiles_pv_images-${i}`],
            })
        )
    );

    const validateProductVariantAllValue = (property, errorMsg) => {
        if (!modifiedProductVariants?.every((item) => !!item?.[property])) {
            notify(errorMsg, { type: "warning" });
            return false;
        }
        return true;
    };

    if (
        !validateProductVariantAllValue(
            "pv_mrp",
            "All Variant MRP must be required!"
        )
    )
        return;
    if (
        !validateProductVariantAllValue(
            "pv_b2c_discounted_price",
            "All B2C Price must be required!"
        )
    )
        return;
    if (
        !validateProductVariantAllValue(
            "pv_b2b_discounted_price",
            "All B2B Price must be required!"
        )
    )
        return;

    if (
        isEmpty(values.attachedFiles_p_images) &&
        modifiedProductVariants?.every((item) =>
            isEmpty(item?.attachedFiles_pv_images)
        )
    )
        return notify("Product / All variant image must be required!", {
            type: "warning",
        });

    if (
        isEmpty(values.attachedFiles_p_images) &&
        modifiedProductVariants?.some((item) =>
            isEmpty(item?.attachedFiles_pv_images)
        )
    )
        return notify("All variant image must be required!", {
            type: "warning",
        });

    const getExistingFilteredShapedTagsOrKeywords = (data = []) => {
        return isArrayofTypeObject(data)
            ? data.filter((item) => !item.__isNew__).map((item) => item.value)
            : data;
    };

    const getNewlyAddedTagsOrKeywords = (data) => {
        if (!data?.length) return [];

        return data
            .filter((item) => item.__isNew__)
            .map((item) => ({
                t_title: item.value,
            }));
    };

    const getAllCreatedMachineName = async (fetchKey, data) => {
        if (!getNewlyAddedTagsOrKeywords(data).length) return [];

        try {
            const res: any = await httpClient(
                `/v1/taxonomiesByVocabulary/multi/${fetchKey}`,
                {
                    method: "POST",
                    body: toQueryString({
                        t: getNewlyAddedTagsOrKeywords(data),
                    }),
                }
            );

            return res?.json?.data?.map(({ t_machine_name }) => t_machine_name);
        } catch (err: any) {
            logger(err);
            return notify(
                err?.message || "Something went wrong, Please try again!",
                {
                    type: "error",
                }
            );
        }
    };

    const [
        productTagTaxonomies,
        productInternalTagTaxonomies,
        productKeywordTaxonomies,
    ] = await Promise.all([
        getAllCreatedMachineName("product_tag", p_product_tags),
        getAllCreatedMachineName(
            "product_internal_tag",
            p_product_internal_tags
        ),
        getAllCreatedMachineName("product_keyword", p_product_keywords),
    ]);

    const modifiedProductUnits = restValues?.pu?.map(
        ({ id, pu_id, pu_p_id, ...restProductUnit }, index) => ({
            ...restProductUnit,
            ...(index === 0 && { pu_is_base: 1 }),
            ...(!cloneActionFrom && { id, pu_id, pu_p_id: values.p_id }),
        })
    );

    for (const key in restValues) {
        if (key.startsWith("attachedFiles_pv_images-")) {
            delete restValues[key];
        }
    }

    let type;

    if (["productList"].includes(cloneActionFrom)) {
        type = "create";
    } else {
        type = p_id ? "update" : "create";
    }

    try {
        const { message } = await mutate(
            {
                type,
                resource: "v1/product",
                payload: {
                    ...(!cloneActionFrom && { id: p_id }),
                    data: {
                        ...restValues,
                        p_form: p_formMachineName || restValues.p_form,
                        ...(restValues?.p_type !== "medicine" &&
                            restValues?.p_type !== "veterinary" && {
                                p_generic_id: "",
                            }),
                        ...(restValues?.p_type !== "medicine" && {
                            p_strength: "",
                            p_form: "",
                            p_cold: 0,
                            p_rx_req: 0,
                        }),
                        ...(type === "create" && {
                            p_allow_sales: 0,
                            p_allow_purchase: 0,
                        }),
                        pu: modifiedProductUnits,
                        pv: modifiedProductVariants,
                        p_product_tags: [
                            ...getExistingFilteredShapedTagsOrKeywords(
                                p_product_tags
                            ),
                            ...productTagTaxonomies,
                        ],
                        p_product_internal_tags: [
                            ...getExistingFilteredShapedTagsOrKeywords(
                                p_product_internal_tags
                            ),
                            ...productInternalTagTaxonomies,
                        ],
                        p_product_keywords: [
                            ...getExistingFilteredShapedTagsOrKeywords(
                                p_product_keywords
                            ),
                            ...productKeywordTaxonomies,
                        ],
                    },
                },
            },
            { returnPromise: true }
        );
        notify(message, {
            type: "success",
        });
        redirect("list", "/v1/product");
    } catch (err: any) {
        logger(err);
        notify(err?.message || "Something went wrong, Please try again!", {
            type: "error",
        });
    }
};
