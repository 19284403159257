import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    ReferenceInput,
    SelectInput,
    TextInput,
} from "react-admin";

type PurchaseOrderFilterProps = {
    from?: "purchaseOrderMenu";
    children: any;
};

const PurchaseOrderFilter: FC<PurchaseOrderFilterProps> = ({
    from,
    ...rest
}) => (
    <Filter {...rest}>
        <TextInput
            source="_po_id"
            label="Search By PO ID"
            variant="outlined"
            resettable
            alwaysOn
        />
        <TextInput
            source="_search"
            label="Item Search"
            variant="outlined"
            resettable
            alwaysOn
        />
        <ReferenceInput
            source="_vendor_id"
            label="Vendor"
            variant="outlined"
            reference="v1/vendor"
            filter={{ _status: "active" }}
            alwaysOn
        >
            <AutocompleteInput optionText="v_name" resettable />
        </ReferenceInput>
        {from === "purchaseOrderMenu" && (
            <SelectInput
                source="_vendor_type"
                label="Type"
                variant="outlined"
                choices={[
                    { id: "local", name: "Local" },
                    {
                        id: "company",
                        name: "Official",
                    },
                    {
                        id: "foreign",
                        name: "Foreign",
                    },
                ]}
                alwaysOn
            />
        )}
    </Filter>
);

export default PurchaseOrderFilter;
