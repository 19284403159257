import { FC } from "react";
import { Create, CreateProps, SimpleForm } from "react-admin";

import { useDocumentTitle } from "../../../hooks";
import SupplierForm from "../../../components/manageRequisition/supplier/SupplierForm";

const SupplierCreate: FC<CreateProps> = (rest) => {
    useDocumentTitle("Arogga |Supplier Create");

    return (
        <Create {...rest}>
            <SimpleForm redirect="list">
                <SupplierForm />
            </SimpleForm>
        </Create>
    );
};

export default SupplierCreate;
