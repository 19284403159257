import { ArrayField, Datagrid, Labeled, TextField } from "react-admin";

const OrderProductExpand = () => (
    <Labeled label="Stock Mapping">
        <ArrayField source="poi_stock_mapping">
            <Datagrid>
                <TextField source="sd_id" label="SDID" />
                <TextField source="sd_batch_no" label="Batch No" />
                <TextField source="used_in_order" label="Used In Order" />
            </Datagrid>
        </ArrayField>
    </Labeled>
);

export default OrderProductExpand;
