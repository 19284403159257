import { FC } from "react";
import { Datagrid, List, ListProps, TextField } from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import {
    useDocumentTitle,
    useExport,
    useNavigateFromList,
} from "../../../hooks";

const CurrencyRateList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Currency Rate List");

    const exporter = useExport(rest);
    const navigateFromList = useNavigateFromList(
        "currencyRateView",
        "currencyRateEdit"
    );

    return (
        <List
            {...rest}
            title="List of Currency Rate"
            perPage={25}
            sort={{ field: "cr_id", order: "DESC" }}
            bulkActionButtons={permissions?.includes("currencyRateDelete")}
            exporter={exporter}
        >
            <Datagrid rowClick={navigateFromList}>
                <TextField source="cr_id" label="ID" />
                <TextField source="cr_currency" label="Currency" />
                <TextField source="cr_rate" label="Rate" />
                <AroggaDateField source="cr_date" label="Date" />
            </Datagrid>
        </List>
    );
};

export default CurrencyRateList;
