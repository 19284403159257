import { FC } from "react";
import {
    ReferenceField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

const SupplierShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Supplier Show");
    const classes = useAroggaStyles();

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ColumnShowLayout md={4} simpleShowLayout={false}>
                    <TextField source="s_id" label="ID" />
                    <ReferenceField
                        label="Bank"
                        source="s_bank_id"
                        reference="v1/bank"
                    >
                        <TextField source="b_name" />
                    </ReferenceField>
                    <TextField source="s_name" label="Name" />
                    <TextField source="s_mobile" label="Phone" />
                    <TextField source="s_email" label="email" />
                    <TextField source="s_tin" label="Tin" />
                    <TextField source="s_bin" label="Bin" />
                    <TextField
                        source="s_account_number"
                        label="Account Number"
                    />
                    <TextField source="s_address" label="Address" />
                    <TextField source="s_weight" label="Weight" />
                    <TextField
                        source="s_payment_method"
                        label="Payment Method"
                        className={classes.capitalize}
                    />
                    <TextField
                        source="s_payment_term_condition"
                        label="Payment Condition"
                    />
                    <TextField source="s_payment_terms" label="Payment Terms" />
                    <TextField
                        source="s_status"
                        label="Status"
                        className={classes.capitalize}
                    />
                    <AroggaDateField source="s_created_at" label="Created At" />
                    <ReferenceField
                        source="s_created_by"
                        label="Created By"
                        reference="v1/users"
                        sortBy="s_created_by"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="s_modified_at"
                        label="Modified At"
                    />
                    <ReferenceField
                        source="s_modified_by"
                        label="Modified By"
                        reference="v1/users"
                        sortBy="s_modified_by"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                </ColumnShowLayout>
            </SimpleShowLayout>
        </Show>
    );
};

export default SupplierShow;
