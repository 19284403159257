import { AutocompleteInput, ReferenceInput, required } from "react-admin";
import { useForm, useFormState } from "react-final-form";

const ShiftScheduleInput = (props) => {
    const form = useForm();
    const { values } = useFormState();

    return (
        <ReferenceInput
            source="sb_shift_schedule_id"
            label="Shift Schedule"
            helperText={false}
            reference="v1/shiftSchedule"
            filter={{
                _shift_type: values?.sb_shift_type,
                _from_date: values?.filterByDate,
                _to_date: values?.filterByDate,
            }}
            onChange={() => form.change("sb_deliveryman_id", undefined)}
            validate={[required()]}
            {...props}
        >
            <AutocompleteInput
                matchSuggestion={() => true}
                optionText={(item) => `${item?.s_title} (${item?.ss_date})`}
                resettable
            />
        </ReferenceInput>
    );
};

export default ShiftScheduleInput;
