import { Box, Grid, Button as MuiButton, Typography } from "@material-ui/core";
import ImageEye from "@material-ui/icons/RemoveRedEye";
import { Editor } from "@tinymce/tinymce-react";
import { memo, useEffect, useState } from "react";
import {
    AutocompleteInput,
    Button,
    Datagrid,
    FunctionField,
    Labeled,
    Link,
    NumberInput,
    Pagination,
    Record,
    ReferenceInput,
    ReferenceManyField,
    SelectArrayInput,
    SelectInput,
    TextField,
    TextInput,
    maxLength,
    minLength,
    required,
    usePermissions,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";

import {
    useDebounce,
    useEffectOnDependencyChange,
    useGetTaxonomiesByVocabulary,
    useRequest,
} from "../../../hooks";
import RevisionDialog from "../../../pages/others/revision/RevisionDialog";
import { toAvroTranslate } from "../../../services";
import { TINY_MCE_EDITOR_INIT } from "../../../utils/constants";
import {
    capitalizeFirstLetterOfEachWord,
    isArray,
    isEmpty,
} from "../../../utils/helpers";
import AroggaAccordion from "../../AroggaAccordion";
import AroggaMovableImageInput from "../../AroggaMovableImageInput";
import CascaderCategoryInput from "../../CascaderCategoryInput";
import FormatedBooleanInput from "../../FormatedBooleanInput";
import InlineArrayInput from "../../InlineArrayInput";
import TaxonomiesByVocabularyInput from "../../TaxonomiesByVocabularyInput";
import Tooltip from "../../Tooltip";
import TableWithEditableCells from "./TableWithEditableCells";
import VendorsSuppliers from "./sections/VendorsSuppliers";

const animatedComponents = makeAnimated();

const ProductForm = (props) => {
    const form = useForm();
    const { values } = useFormState();

    const { permissions } = usePermissions();
    const debouncedProductName = useDebounce(values.p_name, 1000);

    const { record } = props;

    const [isRevisionDialogOpen, setIsRevisionDialogOpen] = useState(false);
    const [isGenerateSKU, setIsGenerateSKU] = useState(false);
    const [pricingData, setPricingData] = useState([]);
    const [description, setDescription] = useState("");

    const productTagFromTaxonomies = useGetTaxonomiesByVocabulary({
        fetchKey: "product_tag",
    });
    const productInternalTagFromTaxonomies = useGetTaxonomiesByVocabulary({
        fetchKey: "product_internal_tag",
    });
    const productKeywordFromTaxonomies = useGetTaxonomiesByVocabulary({
        fetchKey: "product_keyword",
    });

    const productType = values?.p_type;

    const { data: variantTypes } = useRequest(
        "/v1/variantType",
        {},
        {
            isPreFetching: true,
        }
    );

    const filteredVariantTypesByAllowedProductType = variantTypes?.filter(
        (item) => item.vt_allowedProductType?.includes(productType)
    );

    useEffect(() => {
        if (values.p_id && isArray(values?.p_attribute)) {
            const formatedAttribute = values?.p_attribute?.map((attribute) => {
                attribute.vt_config = isArray(attribute?.vt_config)
                    ? attribute?.vt_config?.map((item) => item.toString())
                    : [];
                return attribute;
            });
            form.change("p_attribute", formatedAttribute);
        }
        if (!values.p_id) {
            setInitialProductUnit();
            setInitialProductAttribute();
        }
        if (!values.p_id) {
            form.change("p_vendor_company_ids", [{ id: undefined }]);
            form.change(
                "p_vendor_local_ids",
                [480, 507, 508]?.map((id) => ({ id }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        record.p_id && setInitialModifiedTagsAndKeywordsFromRecord();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!pricingData?.length) return;

        setPricingData((prevData) =>
            prevData.map((item) => ({
                ...item,
                pvBaseUnitLabel: values.pu?.[0]?.pu_label,
            }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.pu?.[0]?.pu_label]);

    useEffect(() => {
        if (!record?.pv?.length) return;

        setPricingData(getShapedPricingDataFromRecord(record?.pv));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions, record]);

    useEffectOnDependencyChange(() => {
        if (!isGenerateSKU || !productType) return;

        const filteredExistingProductAttributeByVariantConfig =
            values?.p_attribute?.filter(
                (attribute) => attribute?.vt_config?.length
            );

        if (
            productType === "medicine" ||
            filteredExistingProductAttributeByVariantConfig?.length
        ) {
            processGenerateSKU(
                productType,
                filteredExistingProductAttributeByVariantConfig
            );
        } else {
            setPricingData([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productType, values?.p_attribute]);

    useEffect(() => {
        if (record?.p_id) {
            setDescription(record?.p_description);
        }
    }, [record?.p_description, record?.p_id]);

    useEffect(() => {
        if (values.p_id) {
            setDescription(values?.p_description || record?.p_description);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.p_description]);

    const processGenerateSKU = (productType, attributes = []) => {
        const productSKUWithAttributes = getGenerateSKUWithAttributes(
            attributes,
            productType
        );

        setPricingData(
            productSKUWithAttributes.map(({ sku, attributes }, index) => ({
                pv_attribute: attributes,
                pv_sku: sku,
                pvBaseUnitLabel: values.pu?.[0]?.pu_label,
                pv_mrp: undefined,
                pv_b2c_discounted_price: undefined,
                b2cPercent: undefined,
                pv_b2b_discounted_price: undefined,
                b2bPercent: undefined,
                pv_b2c_min_qty: 1,
                pv_b2c_max_qty: 100,
                pv_b2b_min_qty: 1,
                pv_b2b_max_qty: 100,
                pv_purchase_price: undefined,
                pvB2CSummary: undefined,
                pvB2BSummary: undefined,
                pv_is_base: index === 0 ? 1 : 0,
                attachedFiles_pv_images: [],
            }))
        );

        for (const key in values) {
            if (key.startsWith("attachedFiles_pv_images-")) {
                form.change(key, undefined);
            }
        }
    };

    const getGenerateSKUWithAttributes = (attributes, productType) => {
        const skuWithAttributes = [];
        const productTypeAbbreviation = getProductTypeAbbreviation(productType);

        const generateCombination = (
            currentIndex,
            skuPrefix,
            currentAttributes
        ) => {
            if (skuPrefix && currentIndex === attributes.length) {
                skuWithAttributes.push({
                    sku: `${skuPrefix}-P`.toUpperCase(),
                    attributes: { ...currentAttributes },
                });
                return;
            }

            const { vt_title, vt_config } = attributes[currentIndex] || [];

            vt_config?.forEach((value) => {
                if (!isEmpty(value)) {
                    const updatedAttributes = {
                        ...currentAttributes,
                        [vt_title.toLowerCase()]:
                            capitalizeFirstLetterOfEachWord(value),
                    };

                    generateCombination(
                        currentIndex + 1,
                        `${skuPrefix}-${value.split(" ").join("")}`,
                        updatedAttributes
                    );
                }
            });
        };

        generateCombination(0, `ARG-${productTypeAbbreviation}`, {});

        return skuWithAttributes;
    };

    const getShapedPricingDataFromRecord = (variants = []) => {
        return variants?.map((item) => ({
            id: item.id,
            pv_id: item.pv_id,
            pv_p_id: item.pv_p_id,
            pv_attribute: item.pv_attribute,
            pv_sku: item.pv_sku,
            pvBaseUnitLabel: values.pu?.[0]?.pu_label,
            pv_mrp: item.pv_mrp,
            pv_b2c_discounted_price: item.pv_b2c_discounted_price,
            b2cPercent: undefined,
            pv_b2b_discounted_price: item.pv_b2b_discounted_price,
            b2bPercent: undefined,
            pv_b2c_min_qty: item.pv_b2c_min_qty,
            pv_b2c_max_qty: item.pv_b2c_max_qty,
            pv_b2b_min_qty: item.pv_b2b_min_qty,
            pv_b2b_max_qty: item.pv_b2b_max_qty,
            pv_purchase_price: item.pv_purchase_price,
            pvB2CSummary: undefined,
            pvB2BSummary: undefined,
            ...(!values.cloneActionFrom &&
                values.p_id && {
                    pv_allow_purchase: item.pv_allow_purchase,
                    pv_allow_sales: item.pv_allow_sales,
                }),
            pv_is_base: item.pv_is_base,
            attachedFiles_pv_images: item["attachedFiles_pv_images"],
            pv_deleted_at: item.pv_deleted_at,
        }));
    };

    const getCreatableSelectOptions = (taxonomies) => {
        return taxonomies?.map(({ t_machine_name, t_title }) => ({
            value: t_machine_name,
            label: t_title,
        }));
    };

    const getProductTypeAbbreviation = (productType) => {
        const words = productType.trim().split("_");

        if (words.length === 1) {
            return words[0].substring(0, 3);
        } else {
            return words.map((word) => word.charAt(0)).join("");
        }
    };

    const getAttributeConfigChoicesBySelectedTitle = (scopedFormData) => {
        return filteredVariantTypesByAllowedProductType
            ?.filter((item) => item.vt_title === scopedFormData?.vt_title)[0]
            ?.vt_config?.sort((a, b) => a?.weight - b?.weight)
            ?.map((item) => ({
                vt_config: item.name.toString(),
            }));
    };

    // const getCloneActionRecord = () => ({
    //     cloneActionFrom: "productUpdate",
    //     ...{
    //         ...record,
    //         pv: record?.pv?.filter(
    //             (item) => item.pv_deleted_at === "0000-00-00 00:00:00"
    //         ),
    //     },
    // });

    const setInitialProductUnit = () => {
        form.change("pu", [
            {
                pu_multiplier: 1,
                pu_label: "1's Pack",
                pu_is_base: 1,
                pu_is_b2b_sales_unit: 1,
                pu_is_b2c_sales_unit: 1,
                pu_is_default_purchase_unit: 1,
            },
        ]);
    };

    const setInitialProductAttribute = () => {
        form.change("p_attribute", [
            {
                vt_title: "",
                vt_config: [],
            },
        ]);
    };

    const setInitialModifiedTagsAndKeywordsFromRecord = () => {
        [
            "p_product_tags",
            "p_product_internal_tags",
            "p_product_keywords",
        ].forEach((key) =>
            form.change(
                key,
                record[key]?.length
                    ? record[key].map((value) => ({
                          label: value,
                          value,
                      }))
                    : []
            )
        );
    };

    const handleOnTranslateClick = async (key) => {
        const data = await toAvroTranslate(values[key]);
        data?.[0] && form.change(`translation.bn.[${key}]`, data[0]);
    };

    const handleOnDefaultUnitChange = (key, currentIndex: number) => {
        form.change(
            "pu",
            values.pu?.map((unit, index) => ({
                ...unit,
                [key]: currentIndex === index ? 1 : 0,
            }))
        );
    };

    // const isShowCloneButton =
    //     values.id &&
    //     !values.cloneActionFrom &&
    //     permissions?.includes("productEdit") &&
    //     permissions?.includes("updateProductReference");

    const isUnitLabelDisabled = (getSource, scopedFormData) => {
        if (
            getSource("pu_label") === "pu[0].pu_label" &&
            permissions?.includes("editProductBaseUnitLabel")
        ) {
            return false;
        } else if (!values.cloneActionFrom && scopedFormData?.pu_id) {
            return true;
        }

        return false;
    };

    const handleChangeCreatableSelect = (name, value) => {
        form.change(name, value);
    };

    values.pv = pricingData;

    if (productType && productType !== "medicine") {
        values.p_description = description;
    }

    return (
        <>
            {!!values.isRevision && !values.cloneActionFrom && values.p_id && (
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h5">Revision Content</Typography>
                    {permissions?.includes("productEdit") && (
                        <>
                            <Button
                                label="Revision"
                                onClick={() => setIsRevisionDialogOpen(true)}
                            >
                                <ImageEye />
                            </Button>
                            <RevisionDialog
                                isDialogOpen={isRevisionDialogOpen}
                                setIsDialogOpen={setIsRevisionDialogOpen}
                                selectedRow={{
                                    r_old_row: record,
                                    r_new_row: {
                                        ...values,
                                        pv: values?.pv?.map((item) => {
                                            // TODO:
                                            const {
                                                pvBaseUnitLabel,
                                                b2bPercent,
                                                b2cPercent,
                                                pvB2BSummary,
                                                pvB2CSummary,
                                                ...rest
                                            } = item;
                                            return rest;
                                        }),
                                        p_product_tags:
                                            isArray(values?.p_product_tags) &&
                                            values?.p_product_tags?.map(
                                                (item) => item.value
                                            ),
                                        p_product_internal_tags:
                                            isArray(
                                                values?.p_product_internal_tags
                                            ) &&
                                            values?.p_product_internal_tags?.map(
                                                (item) => item.value
                                            ),
                                        p_product_keywords:
                                            isArray(
                                                values?.p_product_keywords
                                            ) &&
                                            values?.p_product_keywords?.map(
                                                (item) => item.value
                                            ),
                                    },
                                }}
                            />
                        </>
                    )}
                </Box>
            )}
            <AroggaAccordion title="Product Details" isGrid={false}>
                <Grid container spacing={1}>
                    {!values.cloneActionFrom && values.p_id && (
                        <Grid item sm={6} md={2}>
                            <TextInput
                                source="p_id"
                                label="ID"
                                variant="outlined"
                                helperText={false}
                                fullWidth
                                disabled
                            />
                        </Grid>
                    )}
                    <Grid item sm={6} md={3}>
                        <TextInput
                            source="p_name"
                            label="Name"
                            variant="outlined"
                            validate={[
                                required(),
                                minLength(
                                    2,
                                    "Name must be at least 2 characters long"
                                ),
                                maxLength(
                                    500,
                                    "Name cannot be longer than 500 characters"
                                ),
                            ]}
                            helperText={false}
                            multiline
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <MuiButton
                            variant="contained"
                            onClick={() => handleOnTranslateClick("p_name")}
                            style={{ marginTop: 10 }}
                            disabled={!values.p_name}
                        >
                            Translate
                        </MuiButton>
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <TextInput
                            source="translation.bn.p_name"
                            label="Name (BN)"
                            variant="outlined"
                            helperText={false}
                            multiline
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <SelectInput
                            source="p_active_status"
                            label="Status"
                            variant="outlined"
                            choices={[
                                { id: "active", name: "Active" },
                                {
                                    id: "inactive",
                                    name: "Inactive",
                                },
                            ]}
                            initialValue="active"
                            helperText={false}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <NumberInput
                            source="p_weight"
                            label="Weight"
                            variant="outlined"
                            helperText={false}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <TaxonomiesByVocabularyInput
                            fetchKey="product_type"
                            source="p_type"
                            label="Type"
                            helperText={false}
                            validate={[required()]}
                            onChange={() =>
                                !values.p_id &&
                                !isGenerateSKU &&
                                setIsGenerateSKU(true)
                            }
                            fullWidth
                        />
                    </Grid>
                    {(productType === "medicine" ||
                        productType === "veterinary") && (
                        <Grid item sm={6} md={2}>
                            <ReferenceInput
                                source="p_generic_id"
                                label="Generic"
                                variant="outlined"
                                reference="v1/generics"
                                sort={{ field: "g_name", order: "ASC" }}
                                helperText={false}
                                allowEmpty
                                fullWidth
                            >
                                <AutocompleteInput
                                    optionText="g_name"
                                    options={{
                                        InputProps: {
                                            multiline: true,
                                        },
                                    }}
                                    resettable
                                />
                            </ReferenceInput>
                        </Grid>
                    )}
                    {productType === "medicine" && (
                        <>
                            <Grid item sm={6} md={2}>
                                <TextInput
                                    source="p_strength"
                                    label="Strength"
                                    variant="outlined"
                                    helperText={false}
                                    multiline
                                    fullWidth
                                />
                            </Grid>
                            <Grid item>
                                <MuiButton
                                    variant="contained"
                                    onClick={() =>
                                        handleOnTranslateClick("p_strength")
                                    }
                                    style={{ marginTop: 10 }}
                                    disabled={!values.p_strength}
                                >
                                    Translate
                                </MuiButton>
                            </Grid>
                            <Grid item sm={6} md={2}>
                                <TextInput
                                    source="translation.bn.p_strength"
                                    label="Strength (BN)"
                                    variant="outlined"
                                    helperText={false}
                                    multiline
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={6} md={2}>
                                <TaxonomiesByVocabularyInput
                                    fetchKey="product_form"
                                    inputType="referenceInput"
                                    source="p_form"
                                    label="Form"
                                    helperText={false}
                                    isCreateable={
                                        (!values.id &&
                                            permissions?.includes(
                                                "productCreate"
                                            )) ||
                                        permissions?.includes("productEdit")
                                    }
                                    allowEmpty
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={6} md={2}>
                                <FormatedBooleanInput
                                    source="p_cold"
                                    label="Cold Chain Maintained"
                                    style={{
                                        marginTop: 8,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={6} md={2}>
                                <FormatedBooleanInput
                                    source="p_rx_req"
                                    label="Rx Required"
                                    style={{
                                        marginTop: 8,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )}
                    <FormatedBooleanInput
                        source="p_is_not_allowed_outside_dhaka"
                        label="Not Allowed Outside Dhaka"
                        style={{
                            marginTop: 8,
                        }}
                        fullWidth
                    />
                    {/* TODO: Add separate component for this in future */}
                    {!values.cloneActionFrom &&
                        !values.p_id &&
                        debouncedProductName &&
                        values.p_name === debouncedProductName && (
                            <Grid item sm={12}>
                                <Labeled label="Existing Products">
                                    <ReferenceManyField
                                        reference="v1/product"
                                        target="_search"
                                        perPage={5}
                                        pagination={<Pagination />}
                                        filter={{
                                            _search: values.p_name,
                                        }}
                                    >
                                        <Datagrid>
                                            <TextField
                                                source="p_id"
                                                label="ID"
                                            />
                                            <FunctionField
                                                label="Name"
                                                sortBy="p_name"
                                                render={({
                                                    p_id,
                                                    p_name,
                                                }: Record) => (
                                                    <Link
                                                        to={`/v1/product/${p_id}/edit`}
                                                        target="_blank"
                                                    >
                                                        {p_name}
                                                    </Link>
                                                )}
                                            />
                                            <TextField
                                                source="p_form"
                                                label="Form"
                                            />
                                            <TextField
                                                source="p_strength"
                                                label="Strength"
                                            />
                                            <TextField
                                                source="p_brand"
                                                label="Brand"
                                            />
                                        </Datagrid>
                                    </ReferenceManyField>
                                </Labeled>
                            </Grid>
                        )}
                    <Grid item sm={12}>
                        <CascaderCategoryInput
                            source="p_product_category_id"
                            label="Category *"
                        />
                    </Grid>
                </Grid>
            </AroggaAccordion>
            <AroggaAccordion
                title="Unit"
                accordionDetailsStyle={{
                    paddingBottom: values.id ? 45 : 0,
                }}
            >
                <InlineArrayInput
                    source="pu"
                    label="Attribute"
                    validate={[required()]}
                    disableAdd={
                        !values.cloneActionFrom &&
                        values.p_id &&
                        !permissions?.includes("addNewProductUnit")
                    }
                    disableRemove={!!values.id || values?.pu?.length === 1}
                    disableReordering
                    enableRenderProps
                >
                    {({ getSource, scopedFormData }) => (
                        <Box display="flex" gridGap={8}>
                            <NumberInput
                                source={getSource("pu_multiplier")}
                                record={scopedFormData}
                                label="Multiplier / Qty"
                                variant="outlined"
                                validate={[required()]}
                                helperText={false}
                                initialValue={
                                    ((values.cloneActionFrom &&
                                        !values.pu?.[0]?.pu_multiplier) ||
                                        !record?.pu) &&
                                    getSource("pu_multiplier") ===
                                        "pu[0].pu_multiplier"
                                        ? 1
                                        : undefined
                                }
                                disabled={
                                    !values.cloneActionFrom &&
                                    scopedFormData?.pu_id
                                        ? true
                                        : getSource("pu_multiplier") ===
                                          "pu[0].pu_multiplier"
                                }
                            />
                            <TextInput
                                source={getSource("pu_label")}
                                record={scopedFormData}
                                label="Label"
                                variant="outlined"
                                helperText={false}
                                disabled={isUnitLabelDisabled(
                                    getSource,
                                    scopedFormData
                                )}
                                multiline
                            />
                            <Tooltip title="B2B Sales">
                                <FormatedBooleanInput
                                    source={getSource("pu_is_b2b_sales_unit")}
                                    record={scopedFormData}
                                    label="B2B Sales"
                                    onChange={() =>
                                        handleOnDefaultUnitChange(
                                            "pu_is_b2b_sales_unit",
                                            +getSource(
                                                "pu_is_b2b_sales_unit"
                                            ).match(/\d+/)?.[0]
                                        )
                                    }
                                    style={{ marginTop: 9 }}
                                />
                            </Tooltip>
                            <Tooltip title="B2C Sales">
                                <FormatedBooleanInput
                                    source={getSource("pu_is_b2c_sales_unit")}
                                    record={scopedFormData}
                                    label="B2C Sales"
                                    onChange={() =>
                                        handleOnDefaultUnitChange(
                                            "pu_is_b2c_sales_unit",
                                            +getSource(
                                                "pu_is_default_purchase_unit"
                                            ).match(/\d+/)?.[0]
                                        )
                                    }
                                    style={{ marginTop: 9 }}
                                />
                            </Tooltip>
                            <Tooltip title="Default Purchase">
                                <FormatedBooleanInput
                                    source={getSource(
                                        "pu_is_default_purchase_unit"
                                    )}
                                    record={scopedFormData}
                                    label="Default Purchase"
                                    onChange={() =>
                                        handleOnDefaultUnitChange(
                                            "pu_is_default_purchase_unit",
                                            +getSource(
                                                "pu_is_default_purchase_unit"
                                            ).match(/\d+/)?.[0]
                                        )
                                    }
                                    style={{ marginTop: 9 }}
                                />
                            </Tooltip>
                        </Box>
                    )}
                </InlineArrayInput>
                <div></div>
            </AroggaAccordion>
            {productType && (
                <AroggaAccordion title="SKU Details" isGrid={false}>
                    <Grid container spacing={1}>
                        {((!values.cloneActionFrom && !values.p_id) ||
                            values.cloneActionFrom ||
                            (values.p_id &&
                                !!values.p_attribute?.length &&
                                values.p_attribute?.[0]?.vt_config &&
                                values.p_attribute?.[0]?.vt_title)) && (
                            <Grid item sm={12}>
                                <InlineArrayInput
                                    source="p_attribute"
                                    label="Attribute"
                                    removeButtonStyle={{
                                        marginLeft: 8,
                                    }}
                                    disableAdd={
                                        !values.cloneActionFrom &&
                                        (!!values.p_id ||
                                            values?.p_attribute?.length ===
                                                filteredVariantTypesByAllowedProductType?.length)
                                    }
                                    disableRemove={
                                        !values.cloneActionFrom && !!values.p_id
                                    }
                                    enableRenderProps
                                    disableReordering
                                >
                                    {({ getSource, scopedFormData }) => (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gridGap={8}
                                            width={600}
                                        >
                                            <Box width={300}>
                                                <AutocompleteInput
                                                    source={getSource(
                                                        "vt_title"
                                                    )}
                                                    record={scopedFormData}
                                                    label="Title"
                                                    variant="outlined"
                                                    choices={
                                                        filteredVariantTypesByAllowedProductType
                                                    }
                                                    onChange={() =>
                                                        form.change(
                                                            getSource(
                                                                "vt_config"
                                                            ),
                                                            []
                                                        )
                                                    }
                                                    optionValue="vt_title"
                                                    optionText="vt_title"
                                                    helperText={false}
                                                    disabled={
                                                        !values.cloneActionFrom &&
                                                        values.p_id
                                                    }
                                                    fullWidth
                                                />
                                            </Box>
                                            <SelectArrayInput
                                                source={getSource("vt_config")}
                                                record={scopedFormData}
                                                label="Config"
                                                variant="outlined"
                                                choices={getAttributeConfigChoicesBySelectedTitle(
                                                    scopedFormData
                                                )}
                                                onChange={() =>
                                                    !isGenerateSKU &&
                                                    setIsGenerateSKU(true)
                                                }
                                                optionValue="vt_config"
                                                optionText="vt_config"
                                                helperText={false}
                                                disabled={
                                                    (!values.cloneActionFrom &&
                                                        values.p_id) ||
                                                    !scopedFormData?.vt_title
                                                }
                                                fullWidth
                                            />
                                        </Box>
                                    )}
                                </InlineArrayInput>
                            </Grid>
                        )}
                        {!!pricingData?.length && (
                            <Labeled label="Pricing" fullWidth>
                                <>
                                    <TableWithEditableCells
                                        readOnlyColumns={[
                                            "pv_attribute",
                                            "pv_sku",
                                            "pvBaseUnitLabel",
                                            "b2cPercent",
                                            "b2bPercent",
                                            "pvB2CSummary",
                                            "pvB2BSummary",
                                            // TODO: Remove this condition in future
                                            // !values.cloneActionFrom &&
                                            //     values.p_id &&
                                            //     "pv_mrp",
                                        ]}
                                        initialHidableKeys={{
                                            pv_b2c_min_qty: true,
                                            pv_b2c_max_qty: true,
                                            pv_b2b_min_qty: true,
                                            pv_b2b_max_qty: true,
                                        }}
                                        row={pricingData}
                                        setRow={setPricingData}
                                        disableAdd
                                    />
                                    {/* {isShowCloneButton && (
                                        <CloneButton
                                            record={getCloneActionRecord()}
                                            label="Price changed? Create new product"
                                            icon={null}
                                            style={{
                                                top: 10,
                                                color: "white",
                                                backgroundColor: "#027bff",
                                                padding: "5px 8px",
                                            }}
                                        />
                                    )} */}
                                </>
                            </Labeled>
                        )}
                    </Grid>
                </AroggaAccordion>
            )}
            <AroggaAccordion title="Vendors / Suppliers" isGrid={false}>
                <VendorsSuppliers />
            </AroggaAccordion>
            <AroggaAccordion title="Other Info" isGrid={false}>
                <Grid container spacing={1}>
                    <Grid item sm={6} md={3}>
                        <ReferenceInput
                            source="p_brand_id"
                            label="Brand"
                            variant="outlined"
                            reference="v1/productBrand"
                            sort={{ field: "pb_name", order: "ASC" }}
                            helperText={false}
                            validate={[required()]}
                            allowEmpty
                            fullWidth
                        >
                            <AutocompleteInput
                                optionText="pb_name"
                                options={{
                                    InputProps: {
                                        multiline: true,
                                    },
                                }}
                                resettable
                            />
                        </ReferenceInput>
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <TextInput
                            source="p_yt_title"
                            label="Youtube Title"
                            variant="outlined"
                            validate={[
                                minLength(
                                    3,
                                    "Youtube title must be at least 3 characters long"
                                ),
                                maxLength(
                                    200,
                                    "Youtube title cannot be longer than 200 characters"
                                ),
                            ]}
                            helperText={false}
                            multiline
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <TextInput
                            source="p_yt_key"
                            label="Youtube Key"
                            variant="outlined"
                            validate={[
                                maxLength(
                                    200,
                                    "Youtube key cannot be longer than 200 characters"
                                ),
                            ]}
                            helperText={false}
                            multiline
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </AroggaAccordion>
            {permissions?.includes("updateProductMetaData") && (
                <AroggaAccordion title="Meta Data">
                    <Labeled label="Tags" fullWidth>
                        <CreatableSelect
                            options={getCreatableSelectOptions(
                                productTagFromTaxonomies
                            )}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            onChange={(value) =>
                                handleChangeCreatableSelect(
                                    "p_product_tags",
                                    value
                                )
                            }
                            defaultValue={values.p_product_tags}
                            styles={{
                                container: (baseStyles) => ({
                                    ...baseStyles,
                                    zIndex: 999,
                                }),
                            }}
                            isMulti
                        />
                    </Labeled>
                    <Labeled label="Internal Tags" fullWidth>
                        <CreatableSelect
                            options={getCreatableSelectOptions(
                                productInternalTagFromTaxonomies
                            )}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            onChange={(value) =>
                                handleChangeCreatableSelect(
                                    "p_product_internal_tags",
                                    value
                                )
                            }
                            defaultValue={values.p_product_internal_tags}
                            styles={{
                                container: (baseStyles) => ({
                                    ...baseStyles,
                                    zIndex: 999,
                                }),
                            }}
                            isMulti
                        />
                    </Labeled>
                    <Labeled label="Keywords" fullWidth>
                        <CreatableSelect
                            options={getCreatableSelectOptions(
                                productKeywordFromTaxonomies
                            )}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            onChange={(value) =>
                                handleChangeCreatableSelect(
                                    "p_product_keywords",
                                    value
                                )
                            }
                            defaultValue={values.p_product_keywords}
                            styles={{
                                container: (baseStyles) => ({
                                    ...baseStyles,
                                    zIndex: 999,
                                }),
                            }}
                            isMulti
                        />
                    </Labeled>
                    <TextInput
                        source="p_meta_description"
                        label="Description"
                        variant="outlined"
                        helperText={false}
                        style={{ marginTop: 31 }}
                        multiline
                        fullWidth
                    />
                </AroggaAccordion>
            )}
            <AroggaMovableImageInput
                source="attachedFiles_p_images"
                dimentionValidation
            />
            {productType &&
                productType === "medicine" &&
                !!values.p_generic_id && (
                    <Link
                        to={`/v1/generics/${values.p_generic_id}/1`}
                        target="_blank"
                        style={{ display: "block", marginTop: 10 }}
                    >
                        To edit description click here...
                    </Link>
                )}
            {productType && productType !== "medicine" && (
                <Labeled label="Description" fullWidth>
                    <Editor
                        tinymceScriptSrc={
                            process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
                        }
                        init={TINY_MCE_EDITOR_INIT}
                        value={description}
                        onEditorChange={(newValue) => setDescription(newValue)}
                    />
                </Labeled>
            )}
        </>
    );
};

export default memo(ProductForm);
