import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

type Props = {
    data: any;
};

export default function FailedDataTable({ data }: Props) {
    const tableHeading = [
        "S/L",
        "REFID",
        "Invoice No",
        "Product Price",
        "Shipping Price",
        "Collected Amount (Cod)",
        "Payment Date",
        "Case",
    ];

    return (
        <div>
            <>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <h4>Invalid Data List</h4>
                    {/* <Button label="Export" variant="outlined" onClick={handleExport} /> */}
                </div>

                <Table stickyHeader aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            {tableHeading?.map((d: any) => (
                                <TableCell>{d}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row: any, idx: any) => (
                            <TableRow key={row.refid}>
                                <TableCell component="th" scope="row">
                                    {idx + 1}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.refid}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.invoiceNo}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.productPrice}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.shippingPrice}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.collectedAmountCod}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.paymentDate}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.case}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </>
        </div>
    );
}
