import { FC } from "react";
import { Filter, FilterProps, SelectInput } from "react-admin";

const RequisitionPaymentFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <SelectInput
            source="_payment_status"
            label="Payment Status"
            variant="outlined"
            choices={[
                { id: "pending", name: "Pending" },
                { id: "processing", name: "Processing" },
                { id: "paid", name: "Paid" },
            ]}
            alwaysOn
        />
        <SelectInput
            source="_payment_type"
            label="Payment Type"
            variant="outlined"
            choices={[
                { id: "city_to_city", name: "City to City" },
                { id: "cash", name: "Cash" },
                { id: "city_to_others", name: "City to Others" },
            ]}
            alwaysOn
        />
    </Filter>
);

export default RequisitionPaymentFilter;
