import {
    AutocompleteInput,
    DateInput,
    Filter,
    ReferenceInput,
    TextInput,
} from "react-admin";

const LocalVendorFilter = ({ tabValue, ...rest }) => (
    <Filter {...rest}>
        <ReferenceInput
            source="_vendor_id"
            label="Waiting List"
            variant="outlined"
            reference="v1/vendor"
            filter={{
                _type: "local",
            }}
            filterToQuery={(searchText) => ({
                _name: searchText,
            })}
            alwaysOn
        >
            <AutocompleteInput
                optionText="v_name"
                options={{
                    InputProps: {
                        multiline: true,
                    },
                }}
                resettable
            />
        </ReferenceInput>
        {!!tabValue && (
            <TextInput
                source="_po_id"
                label="PO ID Search"
                variant="outlined"
                resettable
                alwaysOn
            />
        )}
        <TextInput
            source="_search"
            label="Item Search"
            variant="outlined"
            resettable
            alwaysOn
        />
        {!tabValue && (
            <ReferenceInput
                source="_brand"
                label="Brand"
                variant="outlined"
                reference="v1/productBrand"
                sort={{ field: "pb_name", order: "ASC" }}
                alwaysOn
            >
                <AutocompleteInput optionText="pb_name" resettable />
            </ReferenceInput>
        )}
        {!tabValue && (
            <DateInput
                source="_start_date"
                label="Start Date"
                variant="outlined"
                alwaysOn
            />
        )}
        {!tabValue && (
            <DateInput
                source="_end_date"
                label="End Date"
                variant="outlined"
                alwaysOn
            />
        )}
    </Filter>
);

export default LocalVendorFilter;
