import { FC } from "react";
import {
    Create,
    CreateProps,
    DateInput,
    NumberInput,
    SimpleForm,
    required,
} from "react-admin";

import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import { useDocumentTitle } from "../../../hooks";

const CurrencyRateCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga | Currency Rate Create");

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TaxonomiesByVocabularyInput
                    fetchKey="currency"
                    source="cr_currency"
                    label="Currency"
                    helperText={false}
                    validate={[required()]}
                    title
                />
                <NumberInput
                    source="cr_rate"
                    label="Rate"
                    variant="outlined"
                    helperText={false}
                    validate={[required()]}
                />
                <DateInput
                    source="cr_date"
                    label="Date"
                    variant="outlined"
                    helperText={false}
                    validate={[required()]}
                />
            </SimpleForm>
        </Create>
    );
};

export default CurrencyRateCreate;
