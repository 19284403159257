import { Box, Button } from "@material-ui/core";
import { useState } from "react";
import {
    ArrayField,
    Datagrid,
    Labeled,
    ReferenceField,
    TextField,
} from "react-admin";

import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import QuotationCreate from "./QuotationCreate";

const QuotationItemsExpand = ({ record }) => {
    const classes = useAroggaStyles();
    const [quotationAction, setQuotationAction] = useState("");
    const [isQuotationDialogOpen, setIsQuotationDialogOpen] = useState(false);
    const [quotationId, setQuotationId] = useState(null);

    return (
        <>
            <Box display="flex" justifyContent="flex-end" padding={2}>
                {record?.q_status === "finance_issue" && (
                    <Button
                        variant="contained"
                        size="small"
                        className={classes.bgGreen}
                        onClick={() => {
                            setQuotationId(record?.q_id);
                            setQuotationAction("edit");
                            setIsQuotationDialogOpen(true);
                        }}
                    >
                        Quotation Edit
                    </Button>
                )}
            </Box>
            <Labeled label="Quotation Item List">
                <ArrayField source="qi">
                    <Datagrid>
                        <TextField source="qi_id" label="ID" />
                        <ReferenceField
                            source="qi_item_id"
                            label="Item Name"
                            reference="v1/quotationItemMapping"
                            sortBy="qi_item_id"
                            link="show"
                        >
                            <TextField source="qim_name" />
                        </ReferenceField>
                        <TextField source="qi_quantity" label="Quantity" />
                        <TextField source="qi_unit" label="Unit" />
                        <TextField source="qi_unit_price" label="Unit Price" />
                        <TextField source="qi_total_price" label="Base Value" />
                        <TextField source="qi_vat" label="VDS" />
                        <TextField source="qi_tax" label="TDS" />
                        <TextField source="qi_discount" label="Discount" />
                        <TextField
                            source="qi_grand_total"
                            label="Invoice Value"
                        />
                        <TextField
                            source="qi_description"
                            label="Description"
                        />
                    </Datagrid>
                </ArrayField>
            </Labeled>
            {isQuotationDialogOpen === true && (
                <QuotationCreate
                    id={quotationId}
                    quotationAction={quotationAction}
                    isQuotationDialogOpen={isQuotationDialogOpen}
                    setIsQuotationDialogOpen={setIsQuotationDialogOpen}
                />
            )}
        </>
    );
};
export default QuotationItemsExpand;
