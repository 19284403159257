import AcUnitIcon from "@material-ui/icons/AcUnit";
import { FC, useEffect, useState } from "react";
import {
    BooleanField,
    CloneButton,
    FunctionField,
    List,
    ListProps,
    Record,
    ReferenceField,
    TextField,
} from "react-admin";

import "../../../assets/style.css";
import AroggaDateField from "../../../components/AroggaDateField";
import {
    useDocumentTitle,
    useExport,
    useNavigateFromList,
} from "../../../hooks";
import CustomizableDatagrid from "../../../lib/ra-customizable-datagrid";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import ProductFilter from "./CampaignFilter";

const CampaignList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Campaign List");

    const exporter = useExport(rest);
    const classes = useAroggaStyles();
    const navigateFromList = useNavigateFromList("productView", "productEdit");

    const [selectedLastCategoryId, setSelectedLastCategoryId] = useState(null);
    const [cascaderCategoryPlaceholder, setCascaderCategoryPlaceholder] =
        useState("");

    const hasSelectedLastCategoryId = window.location?.hash?.includes(
        "selectedLastCategoryId"
    );

    useEffect(() => {
        if (!hasSelectedLastCategoryId && selectedLastCategoryId) {
            setSelectedLastCategoryId(null);
            setCascaderCategoryPlaceholder("Select Category");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSelectedLastCategoryId]);

    return (
        <List
            {...rest}
            title="List of Campaign"
            filter={{
                // _details: 1,
                ...(hasSelectedLastCategoryId &&
                    selectedLastCategoryId && {
                        _product_category_id: selectedLastCategoryId,
                    }),
            }}
            filters={
                <ProductFilter
                    children={""}
                    cascaderCategoryPlaceholder={cascaderCategoryPlaceholder}
                    setSelectedLastCategoryId={setSelectedLastCategoryId}
                />
            }
            sort={{ field: "p_id", order: "DESC" }}
            exporter={exporter}
        >
            <CustomizableDatagrid
                rowClick={navigateFromList}
                hideableColumns={[
                    "p_rx_req",
                    "p_allow_sales",
                    "p_allow_purchase",
                    "p_created_at",
                    "p_created_by",
                ]}
            >
                <TextField source="p_id" label="ID" />
                <TextField source="p_name" label="Name" />
                <TextField
                    source="p_type"
                    label="Type"
                    className={classes.capitalize}
                />
                <ReferenceField
                    source="p_generic_id"
                    label="Generic"
                    reference="v1/generics"
                    sortBy="p_generic_id"
                    link="show"
                >
                    <TextField source="g_name" />
                </ReferenceField>
                <TextField source="p_strength" label="Strength" />
                <FunctionField
                    source="p_form"
                    label="Form"
                    render={(record: Record) =>
                        capitalizeFirstLetterOfEachWord(record?.p_form)
                    }
                />
                <FunctionField
                    source="p_cold"
                    label="Cold"
                    render={(record: Record) => {
                        if (!record.p_cold) return;
                        return <AcUnitIcon />;
                    }}
                />
                <BooleanField
                    source="p_rx_req"
                    label="Rx Required?"
                    FalseIcon={() => null}
                    looseValue
                />
                <FunctionField
                    source="p_active_status"
                    label="Status"
                    render={(record: Record) => (
                        <span
                            className={`${classes.capitalize} ${
                                record.p_active_status === "inactive" &&
                                classes.textRed
                            }`}
                        >
                            {record?.p_active_status}
                        </span>
                    )}
                />
                <ReferenceField
                    source="p_brand_id"
                    label="Brand"
                    reference="v1/productBrand"
                    sortBy="p_brand_id"
                    link="show"
                >
                    <TextField source="pb_name" />
                </ReferenceField>
                <BooleanField
                    source="p_allow_sales"
                    label="Allow Sales?"
                    FalseIcon={() => null}
                    looseValue
                />
                <BooleanField
                    source="p_allow_purchase"
                    label="Allow Purchase?"
                    FalseIcon={() => null}
                    looseValue
                />
                <TextField source="p_weight" label="Weight" />
                <AroggaDateField source="p_created_at" label="Created At" />
                <ReferenceField
                    source="p_created_by"
                    label="Created By"
                    reference="v1/users"
                    sortBy="p_created_by"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
                {permissions?.includes("productCreate") && (
                    <FunctionField
                        label="Clone"
                        render={(record: Record) => (
                            // @ts-ignore
                            <CloneButton
                                {...rest}
                                record={{
                                    cloneActionFrom: "productList",
                                    ...record,
                                    pv: record?.pv?.map?.((variant) => ({
                                        ...variant,
                                        attachedFiles_pv_images: [],
                                    })),
                                    attachedFiles_p_images: [],
                                }}
                            />
                        )}
                    />
                )}
            </CustomizableDatagrid>
        </List>
    );
};

export default CampaignList;
