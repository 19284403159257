import { Table, TableContainer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FC } from "react";

import TableBody from "./TableBody";
import TableHeader from "./TableHeader";

type ProductTableProps = {
    productOrderItems: any[];
    setProductOrderItems: (items: object[]) => void;
};

const ProductTable: FC<ProductTableProps> = ({
    productOrderItems,
    setProductOrderItems,
}) => {
    const classes = useStyles();

    const handleOnKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.target.blur();
        } else if (
            !["Backspace", "ArrowLeft", "ArrowRight"].includes(e.key) &&
            isNaN(e.key)
        ) {
            e.preventDefault();
        }
    };

    const handleOnBlur = ({ value, productVariantId }: any) => {
        const newProductOrderItems = [...productOrderItems];
        const productIndex = newProductOrderItems.findIndex(
            (item) => item.poi_product_variant_id === productVariantId
        );
        newProductOrderItems[productIndex].poi_product_qty = value;
        setProductOrderItems(newProductOrderItems);
    };

    const handleRemove = (productVariantId) => {
        const updatedProducts = productOrderItems?.filter(
            (product) => product.poi_product_variant_id !== productVariantId
        );
        setProductOrderItems(updatedProducts);
    };

    return (
        <>
            {!!productOrderItems?.length && (
                <TableContainer>
                    <Table size="small" className={classes.table}>
                        <TableHeader />
                        <TableBody
                            productOrderItems={productOrderItems}
                            setProductOrderItems={setProductOrderItems}
                            handleOnKeyDown={handleOnKeyDown}
                            handleOnBlur={handleOnBlur}
                            handleRemove={handleRemove}
                        />
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        marginTop: 20,
    },
});

export default ProductTable;
