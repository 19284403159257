import { FC } from "react";
import {
    AutocompleteInput,
    BooleanInput,
    DateInput,
    Filter,
    FilterProps,
    NumberInput,
    ReferenceInput,
    TextInput,
} from "react-admin";

import { getProductTextRenderer } from "../../../utils/helpers";

const PurchaseItemFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            label="Search"
            source="_search"
            variant="outlined"
            resettable
            alwaysOn
        />
        <NumberInput
            source="_product_purchase_id"
            label="Purchase ID"
            variant="outlined"
            alwaysOn
        />
        <ReferenceInput
            source="_product_id"
            label="Product"
            variant="outlined"
            reference="general/v3/search"
            enableGetChoices={({ q }) => !!q && q.trim().length > 0}
            sort={{ field: "p_name", order: "ASC" }}
            alwaysOn
        >
            <AutocompleteInput
                optionText={getProductTextRenderer}
                options={{
                    InputProps: {
                        multiline: true,
                    },
                }}
                matchSuggestion={() => true}
                resettable
            />
        </ReferenceInput>
        <ReferenceInput
            source="_unit_id"
            label="Unit"
            variant="outlined"
            reference="v1/productUnit"
        >
            <AutocompleteInput
                optionValue="ah_id"
                optionText="pu_label"
                resettable
                fullWidth
            />
        </ReferenceInput>
        <NumberInput
            source="_multiplier"
            label="Multiplier"
            variant="outlined"
        />
        <TextInput source="_batch" label="Batch" variant="outlined" />
        <DateInput source="_start_date" label="Start Date" variant="outlined" />
        <DateInput source="_end_date" label="End Date" variant="outlined" />
        <BooleanInput
            source="_is_fixed_discount"
            label="Fixed Discount?"
            variant="outlined"
        />
    </Filter>
);

export default PurchaseItemFilter;
