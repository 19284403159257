import { FC } from "react";
import {
    DateInput,
    Edit,
    EditProps,
    NumberInput,
    SimpleForm,
    TextInput,
    required,
} from "react-admin";

import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import { useDocumentTitle } from "../../../hooks";

const CurrencyRateEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Currency Rate Edit");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm
                redirect="list"
                toolbar={
                    <SaveDeleteToolbar
                        isSave
                        isDelete={permissions?.includes("currencyRateDelete")}
                    />
                }
            >
                <TextInput
                    source="cr_id"
                    label="ID"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
                <TaxonomiesByVocabularyInput
                    fetchKey="currency"
                    source="cr_currency"
                    label="Currency"
                    helperText={false}
                    validate={[required()]}
                    title
                />
                <NumberInput
                    source="cr_rate"
                    label="Rate"
                    variant="outlined"
                    helperText={false}
                    validate={[required()]}
                />
                <DateInput
                    source="cr_date"
                    label="Date"
                    variant="outlined"
                    helperText={false}
                    validate={[required()]}
                />
            </SimpleForm>
        </Edit>
    );
};

export default CurrencyRateEdit;
