import { Box, Button } from "@material-ui/core";
import { FC, useState } from "react";
import {
    Confirm,
    FileField,
    FunctionField,
    Link,
    List,
    ListProps,
    Record,
    ReferenceField,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import {
    useDocumentTitle,
    useExport,
    useNavigateFromList,
    useRequest,
} from "../../../hooks";
import { CustomizableDatagrid } from "../../../lib";
import {
    capitalizeFirstLetterOfEachWord,
    getColorByStatus,
} from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import ExpenseEntryFilter from "./ExpenseEntryFilter";

const ExpenseEntryList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Expense Entry List");

    const classes = useAroggaStyles();
    const exporter = useExport(rest);
    const navigateFromList = useNavigateFromList(
        "expenseEntryView",
        "expenseEntryEdit"
    );
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [expenseEntryId, setExpenseEntryId] = useState(null);
    const [action, setAction] = useState("");

    const { isLoading, refetch } = useRequest(
        `/v1/expenseEntryAction/${expenseEntryId}/${action}`,
        {
            method: "POST",
        },
        {
            isRefresh: true,
            onSuccess: () => setIsDialogOpen(false),
        }
    );

    const confirmTitleAction =
        action === "ApproveAction"
            ? "approve"
            : action === "reverseAction"
                ? "reverse"
                : "reject";

    return (
        <>
            <List
                {...rest}
                title="List of Expense Entry"
                filters={<ExpenseEntryFilter children={""} />}
                perPage={25}
                exporter={exporter}
                bulkActionButtons={false}
                sort={{ field: "id", order: "DESC" }}
            >
                <CustomizableDatagrid
                    rowClick={navigateFromList}
                    hideableColumns={["ee_created_at", "ee_created_by"]}
                >
                    <TextField source="ee_id" label="Entry ID" />
                    <FunctionField
                        source="atd_transaction_id"
                        label="Transaction ID"
                        onClick={(e) => e.stopPropagation()}
                        render={({ atd_transaction_id }: Record) => (
                            <Link
                                to={`/v1/accountingTransaction/${atd_transaction_id}`}
                            >
                                {atd_transaction_id}
                            </Link>
                        )}
                    />
                    <ReferenceField
                        source="ee_expense_head_mapping_id"
                        label="Expense Type"
                        reference="v1/expenseHeadMapping"
                        link={false}
                    >
                        <TextField source="ehm_expense_title" />
                    </ReferenceField>
                    <TextField
                        source="ee_expense_description"
                        label="Description"
                    />
                    <TextField source="ee_expense_amount" label="Amount" />
                    <FunctionField
                        label="Status"
                        sortBy="ee_approval_status"
                        render={(record) => {
                            const color = getColorByStatus(
                                record.ee_approval_status
                            );
                            const text = capitalizeFirstLetterOfEachWord(
                                record.ee_approval_status
                            );

                            return (
                                <span
                                    className={`${classes.statusBtn} ${classes.whitespaceNowrap}`}
                                    style={{
                                        color: color,
                                        backgroundColor: color + "10",
                                    }}
                                >
                                    {text}
                                </span>
                            );
                        }}
                    />
                    <AroggaDateField
                        source="ee_created_at"
                        label="Created At"
                    />
                    <ReferenceField
                        source="ee_created_by"
                        label="Created By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <FileField
                        source="attachedFiles_ee_attachment"
                        src="src"
                        title="Attached Files"
                        target="_blank"
                        label="Attached Files"
                        // @ts-ignore
                        onClick={(e) => e.stopPropagation()}
                    />
                    <FunctionField
                        label="Action"
                        onClick={(e) => e.stopPropagation()}
                        render={({ id, ee_approval_status }: Record) => (
                            <Box display="flex" gridGap={5}>
                                {ee_approval_status === "pending" && (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            setExpenseEntryId(id);
                                            setAction("ApproveAction");
                                            setIsDialogOpen(true);
                                        }}
                                    >
                                        Approve
                                    </Button>
                                )}
                                {ee_approval_status === "approved" &&
                                    permissions?.includes(
                                        "reverseExpenseEntry"
                                    ) && (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                setExpenseEntryId(id);
                                                setAction("reverseAction");
                                                setIsDialogOpen(true);
                                            }}
                                        >
                                            Reverse
                                        </Button>
                                    )}
                                {ee_approval_status === "pending" && (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            setExpenseEntryId(id);
                                            setAction("rejectAction");
                                            setIsDialogOpen(true);
                                        }}
                                        className={classes.bgRed}
                                    >
                                        Reject
                                    </Button>
                                )}
                            </Box>
                        )}
                    />
                </CustomizableDatagrid>
            </List>
            <Confirm
                isOpen={isDialogOpen}
                loading={isLoading}
                title={`Are you sure you want to ${confirmTitleAction} this expense entry #${expenseEntryId}?`}
                content={false}
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
        </>
    );
};

export default ExpenseEntryList;
