import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    ReferenceInput,
    SelectInput,
    TextInput,
} from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import {
    getProductTextRenderer,
    userEmployeeInputTextRenderer,
} from "../../../utils/helpers";

const OrderProductFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            label="Search"
            source="_search"
            variant="outlined"
            resettable
            alwaysOn
        />
        <SelectInput
            source="_po_status"
            label="Status"
            variant="outlined"
            choices={[
                { id: "processing", name: "Processing" },
                { id: "confirmed", name: "Confirmed" },
                { id: "delivered", name: "Delivered" },
                { id: "cancelled", name: "Cancelled" },
            ]}
            alwaysOn
        />
        <ReferenceInput
            source="_user_id"
            label="User"
            variant="outlined"
            reference="v1/users"
            alwaysOn
        >
            <AutocompleteInput
                matchSuggestion={() => true}
                optionValue="u_id"
                helperText={false}
                optionText={<UserEmployeeOptionTextRenderer />}
                inputText={userEmployeeInputTextRenderer}
                resettable
            />
        </ReferenceInput>
        <TextInput
            source="_order_id"
            label="Order ID"
            variant="outlined"
            resettable
            alwaysOn
        />
        <ReferenceInput
            source="_product_id"
            label="Product"
            variant="outlined"
            reference="v1/product"
            sort={{ field: "p_name", order: "ASC" }}
            alwaysOn
        >
            <AutocompleteInput
                optionText={getProductTextRenderer}
                options={{
                    InputProps: {
                        multiline: true,
                    },
                }}
                matchSuggestion={() => true}
                resettable
            />
        </ReferenceInput>
        <FormatedBooleanInput
            source="_po_is_b2b_order"
            label="B2B Order"
            alwaysOn
        />
        <TextInput
            source="_product_variant_id"
            label="Variant ID"
            variant="outlined"
            resettable
        />
    </Filter>
);

export default OrderProductFilter;
