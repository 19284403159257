import { useState } from "react";
import {
    FileField,
    FileInput,
    RadioButtonGroupInput,
    SimpleForm,
    Title,
    useNotify,
    required,
} from "react-admin";
import AroggaBackdrop from "../../../components/AroggaBackdrop";
import { Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { httpClient } from "../../../utils/http";
import { useRequest } from "../../../hooks";

const IMAGE_FILE_MAX_SIZE = 156630814; // 150MB;

interface PresignedUrlResponse {
    status: string;
    json: any;
    data: {
        url: string;
    };
}

const useStyles = makeStyles({
    root: {
        backgroundColor: "#f0f0f0",
        borderRadius: "4px",
        padding: "10px 5px 10px 5px",
    },
    dropZone: {
        border: "2px dashed #3f51b5",
        borderRadius: "4px",
        padding: "20px",
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    preview: {
        marginTop: "10px",
    },
    currentVBlock: {
        paddingLeft: 16,
        paddingRight: 16,
        display: "flex",
        flexDirection: "column",
        borderBottom: "1px solid #e0e0e3",
    },
    currentVHeader: {
        marginTop: 0,
        fontSize: 16,
    },
    item: {
        width: "40%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 15,
        "& label": {
            width: "20%",
        },
        "& a": {
            textDecoration: "none",
            color: "#008069",
            "&:hover": {
                textDecoration: "underline",
            },
        },
    },
});

export const PrintingAppFileUplaod = () => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [formValues, setFormValues] = useState({
        platform: "win32",
        version: "",
        fileName: "",
    });

    const handleChange = (event) => {
        setFormValues({
            ...formValues,
            platform: event,
        });
    };

    const { data, refetch } = useRequest(
        "/v1/getPrintingAppCurrentVersion",
        {},
        { isPreFetching: true }
    );

    const handleSubmit = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        if (!selectedFile) return;
        setLoading(true);

        try {
            const response = await httpClient(
                `/admin/v1/generatePrintingAppUploadUrl?fileName=${
                    selectedFile.name
                }&platform=${formValues.platform}&version=${extractVersion(
                    selectedFile.name
                )}`,
                { isBaseUrl: true }
            );

            if (!response) {
                throw new Error("No response from httpClient");
            }

            // Check if response has a json method or if it is already parsed
            const data: PresignedUrlResponse =
                typeof response.json === "function"
                    ? await response.json()
                    : response;

            if (data?.json.status === "success") {
                await uploadFileToS3(data?.json.data.url);
                notify("Files uploaded successfully!", { type: "success" });
            } else {
                notify("Failed to generate presigned URL", { type: "error" });
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            notify("Error uploading file", { type: "error" });
        } finally {
            refetch();
            setLoading(false);
        }
    };

    const uploadFileToS3 = async (presignedUrl) => {
        try {
            const uploadResponse = await fetch(presignedUrl, {
                method: "PUT",
                headers: {
                    "Content-Type": selectedFile.type,
                },
                body: selectedFile,
            });

            if (!uploadResponse.ok) {
                notify("Failed to upload file to S3!", { type: "error" });
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            notify("Error uploading file!", { type: "error" });
        }
    };

    function extractVersion(filename) {
        if (filename) {
            const versionPattern = /(\d+\.\d+\.\d+)/;
            const match = filename.match(versionPattern);
            return match ? match[0] : null;
        }
    }

    return (
        <Paper style={{ marginTop: 15 }}>
            <Title title="Printing App Version File Upload" />
            <div
                style={{ marginTop: "10px", paddingBottom: 20, height: "100%" }}
            >
                <div className={classes.currentVBlock}>
                    <h3 className={classes.currentVHeader}>
                        Printing App Current version
                    </h3>
                    <div className={classes.item}>
                        <label>Windows :</label>
                        <strong>{data?.win32?.version}</strong>
                        <a href={data?.win32?.downloadUrl}>
                            {data?.win32?.downloadUrl ? "Download" : ""}
                        </a>
                    </div>
                    <div className={classes.item}>
                        <label>Mac :</label>
                        <strong>{data?.darwin?.version}</strong>
                        <a href={data?.darwin?.downloadUrl}>
                            {data?.darwin?.downloadUrl ? "Download" : ""}
                        </a>
                    </div>
                    <div className={classes.item}>
                        <label>Linux :</label>
                        <strong>{data?.linux?.version}</strong>
                        <a href={data?.linux?.downloadUrl}>
                            {data?.linux?.downloadUrl ? "Download" : ""}
                        </a>
                    </div>
                </div>
                <SimpleForm
                    save={handleSubmit}
                    toolbar={false}
                    initialValues={{ platform: "win32" }}
                >
                    <AroggaBackdrop isLoading={loading} />

                    <RadioButtonGroupInput
                        label="Os Platform"
                        source="platform"
                        choices={[
                            { id: "win32", name: "Windows" },
                            { id: "darwin", name: "Mac" },
                            { id: "linux", name: "Linux" },
                        ]}
                        validate={required()}
                        onChange={handleChange}
                    />
                    <FileInput
                        classes={classes}
                        source="fileName"
                        maxSize={IMAGE_FILE_MAX_SIZE}
                        label="Only .deb, .exe, .dmg files are allowed."
                        helperText={false}
                        accept=".deb,.exe,.dmg"
                        placeholder={
                            "Drag and drop some files here, or click to select files. Only .deb, .exe, .dmg files are allowed."
                        }
                        onChange={(e) => {
                            setSelectedFile(e);
                        }}
                        validate={required()}
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                        style={{ marginTop: 20 }}
                    >
                        <span>Submit</span>
                    </Button>
                </SimpleForm>
            </div>
        </Paper>
    );
};
