import { FC, useCallback } from "react";
import {
    Create,
    CreateProps,
    SimpleForm,
    Toolbar,
    useMutation,
    useNotify,
    useRedirect,
} from "react-admin";

import ProductForm from "../../../components/manageDatabase/products/ProductForm";
import { useDocumentTitle } from "../../../hooks";
import { productOnSave } from "./utils/productOnSave";

const ProductCreate: FC<CreateProps> = ({ hasCreate, ...rest }) => {
    useDocumentTitle("Arogga | Product Create");

    const redirect = useRedirect();
    const notify = useNotify();
    const [mutate, { loading }] = useMutation();

    const onSave = useCallback(
        async (values) => {
            productOnSave({
                notify,
                redirect,
                mutate,
                values,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mutate]
    );

    return (
        <Create {...rest}>
            <SimpleForm
                submitOnEnter={false}
                toolbar={loading ? null : <Toolbar alwaysEnableSaveButton />}
                save={onSave}
            >
                <ProductForm />
            </SimpleForm>
        </Create>
    );
};

export default ProductCreate;
