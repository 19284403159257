const LabReportDisable = (props) => (
    <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width="59" height="59" rx="29.5" fill="#E0E0E0" />
        <path
            d="M21.6667 42C20.9333 42 20.3056 41.7389 19.7833 41.2167C19.2611 40.6944 19 40.0667 19 39.3333V20.6667C19 19.9333 19.2611 19.3056 19.7833 18.7833C20.3056 18.2611 20.9333 18 21.6667 18H36.3333L43 24.6667V39.3333C43 40.0667 42.7389 40.6944 42.2167 41.2167C41.6944 41.7389 41.0667 42 40.3333 42H21.6667ZM24.3333 36.6667H37.6667V34H24.3333V36.6667ZM24.3333 31.3333H37.6667V28.6667H24.3333V31.3333ZM35 26H40.3333L35 20.6667V26ZM24.3333 26H31V23.3333H24.3333V26Z"
            fill="#BDBDBD"
        />
    </svg>
);

export default LabReportDisable;
