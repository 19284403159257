import { Box, Button, makeStyles } from "@material-ui/core";
import {
    Datagrid,
    FileField,
    FunctionField,
    Record,
    ReferenceField,
    TextField,
} from "react-admin";

import QuotationItemsExpand from "../../../../components/manageRequisition/purchaseRequisition/QuotationItemsExpand";
import { useAroggaStyles } from "../../../../utils/useAroggaStyles";
import {
    capitalizeFirstLetterOfEachWord,
    getColorByStatus,
} from "../../../../utils/helpers";
import DescriptionWithModal from "../../../../components/DescriptionWithModal";

const QuotationListTable = ({
    record,
    procurementActionButtonShow,
    setQuotationId,
    setActionType,
    setActionContext,
    setIsDialogOpen,
    financeActionButtonShow,
    financeIssueShow,
    capApprovalButtonShow,
}) => {
    const classes = useAroggaStyles();
    const classes2 = useStyles();

    return (
        <Datagrid
            expand={<QuotationItemsExpand record={record} />}
            isRowExpandable={(row) => !!row?.qi?.length}
            classes={{ expandedPanel: classes.expandedPanel }}
        >
            <TextField source="id" label="ID" />
            <ReferenceField
                source="q_supplier_id"
                label="Supplier"
                reference="v1/supplier"
                sortBy="q_supplier_id"
                link="show"
            >
                <TextField source="s_name" />
            </ReferenceField>
            <ReferenceField
                source="q_created_by"
                label="Created By"
                reference="v1/users"
                sortBy="q_created_by"
                link="show"
            >
                <TextField source="u_name" />
            </ReferenceField>
            <FileField
                source="attachedFiles_q_attachment"
                src="src"
                title="title"
                target="_blank"
                label="Quotation Attachment"
                // @ts-ignore
                onClick={(e) => e.stopPropagation()}
                sortable={false}
            />
            <FunctionField
                label="Purchase Type"
                render={({ q_procurement_title }: Record) =>
                    capitalizeFirstLetterOfEachWord(q_procurement_title)
                }
            />
            <TextField source="q_grand_total" label="Invoice Value" />
            <TextField source="q_advance_pay" label="Advance Pay" />
            <FunctionField
                label="Payment Terms & Condition"
                onClick={(e) => e.stopPropagation()}
                render={({ q_payment_term_cond }: Record) => (
                    <DescriptionWithModal description={q_payment_term_cond} />
                )}
            />
            <TextField source="q_payment_terms" label="Payment Time" />
            <TextField
                source="q_payment_method"
                label="Payment Method"
                className={classes.capitalize}
            />
            <FunctionField
                render={(record) => {
                    const color = getColorByStatus(record.q_status);
                    return (
                        <div
                            className={`${classes2.statusButton} ${classes.whitespaceNowrap}`}
                            style={{
                                color: color,
                            }}
                        >
                            {record.q_status === "finance_cleared"
                                ? "Finance Cleared"
                                : record.q_status === "finance_issue"
                                ? "Finance Issue"
                                : record.q_status}
                        </div>
                    );
                }}
                label="Procurement Approval Status"
            />
            <FunctionField
                render={(record) => {
                    const color = getColorByStatus(record.q_cap_status);
                    return (
                        <div
                            className={classes2.statusButton}
                            style={{
                                color: color,
                            }}
                        >
                            {record.q_cap_status}
                        </div>
                    );
                }}
                label="Management Approval Status"
            />
            {procurementActionButtonShow && (
                <FunctionField
                    label="Procurement Approval"
                    onClick={(e) => e.stopPropagation()}
                    render={({ id }: Record) => {
                        return (
                            <Box display="flex" gridGap={5}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        setQuotationId(id);
                                        setActionType("approved");
                                        setActionContext("statusAction");
                                        setIsDialogOpen(true);
                                    }}
                                >
                                    Approve
                                </Button>
                            </Box>
                        );
                    }}
                />
            )}
            {financeActionButtonShow && (
                <FunctionField
                    label="Finance Clearance"
                    onClick={(e) => e.stopPropagation()}
                    render={({
                        q_id,
                        is_show_finance_action_button,
                    }: Record) => {
                        return (
                            <>
                                {is_show_finance_action_button === true && (
                                    <Box display="flex" gridGap={5}>
                                        <Button
                                            variant="contained"
                                            className={classes.whitespaceNowrap}
                                            size="small"
                                            onClick={() => {
                                                setQuotationId(q_id);
                                                setActionType(
                                                    "finance_cleared"
                                                );
                                                setActionContext(
                                                    "accountStatusAction"
                                                );
                                                setIsDialogOpen(true);
                                            }}
                                        >
                                            Finance Cleared
                                        </Button>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            className={`${classes.whitespaceNowrap} ${classes.bgRed} `}
                                            onClick={() => {
                                                setQuotationId(q_id);
                                                setActionType("finance_issue");
                                                setActionContext(
                                                    "accountStatusAction"
                                                );
                                                setIsDialogOpen(true);
                                            }}
                                        >
                                            Finance Issue
                                        </Button>
                                    </Box>
                                )}
                            </>
                        );
                    }}
                />
            )}
            {financeIssueShow && (
                <TextField
                    source="q_finance_issue_reason"
                    label="Finance Issue Reason"
                />
            )}
            {capApprovalButtonShow && (
                <FunctionField
                    label="Cap Approval"
                    onClick={(e) => e.stopPropagation()}
                    render={({
                        id,
                        is_show_management_action_button,
                    }: Record) => {
                        return (
                            <>
                                {is_show_management_action_button === true && (
                                    <Box display="flex" gridGap={5}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                                setQuotationId(id);
                                                setActionType("approved");
                                                setActionContext(
                                                    "capStatusAction"
                                                );
                                                setIsDialogOpen(true);
                                            }}
                                        >
                                            Approve
                                        </Button>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                setQuotationId(id);
                                                setActionType("rejected");
                                                setActionContext(
                                                    "capStatusAction"
                                                );
                                                setIsDialogOpen(true);
                                            }}
                                            className={classes.bgRed}
                                        >
                                            Reject
                                        </Button>
                                    </Box>
                                )}
                            </>
                        );
                    }}
                />
            )}
        </Datagrid>
    );
};
export default QuotationListTable;

const useStyles = makeStyles({
    statusButton: {
        textTransform: "uppercase",
        fontWeight: "bolder",
        fontSize: 14,
    },
});
