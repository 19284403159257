import {
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@material-ui/core";
import { FC } from "react";
import { ReferenceField, TextField } from "react-admin";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import AroggaDialogActions from "../../AroggaDialogActions";

type LocationUpdateDialogProps = {
    isUpdateLocationDialogOpen: boolean;
    onDialogClose: () => void;
};

const LocationUpdateDialog: FC<LocationUpdateDialogProps> = ({
    isUpdateLocationDialogOpen,
    onDialogClose,
}) => {
    const classes = useAroggaStyles();
    const { values } = useFormState();

    const { full_shipping_address } = values;

    const { isLoading, refetch } = useRequest(
        `/v1/updateUserLocation/${values.po_user_location_id}`,
        {
            method: "POST",
            body: {
                po_id: values?.po_id,
                ul_name: full_shipping_address?.ul_name,
                ul_mobile: full_shipping_address?.ul_mobile,
                ul_type: full_shipping_address?.ul_type,
                l_division: full_shipping_address?.l_division,
                l_district: full_shipping_address?.l_district,
                l_area: full_shipping_address?.l_area,
                ul_sa_id: full_shipping_address?.ul_sa_id,
                ul_address: full_shipping_address?.ul_address,
            },
        },
        {
            isRefresh: true,
            successNotify: "Successfully updated location!",
            onSuccess: () => onDialogClose(),
        }
    );

    return (
        <Dialog open={isUpdateLocationDialogOpen} onClose={onDialogClose}>
            <DialogTitle>Are you sure this location is correct?</DialogTitle>
            <DialogContent>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.fontBold}>
                                Division
                            </TableCell>
                            <TableCell>
                                {values?.full_shipping_address?.l_division}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.fontBold}>
                                City
                            </TableCell>
                            <TableCell>
                                {values?.full_shipping_address?.l_district}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.fontBold}>
                                Area
                            </TableCell>
                            <TableCell>
                                {values?.full_shipping_address?.l_area}
                            </TableCell>
                        </TableRow>
                        {values?.full_shipping_address?.ul_sa_id ? (
                            <TableRow>
                                <TableCell className={classes.fontBold}>
                                    Sub Area
                                </TableCell>
                                <TableCell>
                                    <ReferenceField
                                        record={values?.full_shipping_address}
                                        source="ul_sa_id"
                                        label="Sub Area"
                                        reference="v1/subArea"
                                        link={false}
                                    >
                                        <TextField source="sa_title" />
                                    </ReferenceField>
                                </TableCell>
                            </TableRow>
                        ) : null}

                        <TableRow>
                            <TableCell className={classes.fontBold}>
                                Home Address
                            </TableCell>
                            <TableCell>
                                {values?.full_shipping_address?.ul_address}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={onDialogClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default LocationUpdateDialog;
