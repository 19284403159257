import { FC } from "react";
import { Create, CreateProps, SimpleForm } from "react-admin";

import { useDocumentTitle } from "../../../hooks";
import ExpenseEntryForm from "../../../components/manageAccounting/expenseEntry/ExpenseEntryForm";

const ExpenseEntryCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga |Expense Entry Create");

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <ExpenseEntryForm />
            </SimpleForm>
        </Create>
    );
};

export default ExpenseEntryCreate;
