import { FC } from "react";
import { Edit, EditProps, SimpleForm } from "react-admin";

import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import NotificationForm from "../../../components/managePromotion/notifications/NotificationForm";
import { useDocumentTitle } from "../../../hooks";

const NotificationEdit: FC<EditProps> = ({ hasEdit, ...rest }) => {
    useDocumentTitle("Arogga | Notification Edit");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm
                redirect="list"
                toolbar={
                    <SaveDeleteToolbar
                        isSave
                        isDelete={rest.permissions?.includes(
                            "notificationDelete"
                        )}
                    />
                }
            >
                <NotificationForm />
            </SimpleForm>
        </Edit>
    );
};

export default NotificationEdit;
