const AddressIcon = (props) => (
    <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect
            width="59"
            height="59"
            rx="29.5"
            fill="#1DA099"
            fillOpacity="0.1"
        />
        <path
            d="M29.5003 14.9167C23.8712 14.9167 19.292 19.4958 19.292 25.125C19.292 32.7812 29.5003 44.0833 29.5003 44.0833C29.5003 44.0833 39.7087 32.7812 39.7087 25.125C39.7087 19.4958 35.1295 14.9167 29.5003 14.9167ZM29.5003 17.8333C31.1045 17.8333 32.417 19.1458 32.417 20.75C32.417 22.3687 31.1045 23.6667 29.5003 23.6667C27.8962 23.6667 26.5837 22.3687 26.5837 20.75C26.5837 19.1458 27.8962 17.8333 29.5003 17.8333ZM29.5003 32.4167C27.0649 32.4167 24.9212 31.1771 23.667 29.2812C23.6962 27.3562 27.5607 26.2917 29.5003 26.2917C31.4399 26.2917 35.3045 27.3562 35.3337 29.2812C34.0795 31.1771 31.9357 32.4167 29.5003 32.4167Z"
            fill="#1DA099"
        />
    </svg>
);
export default AddressIcon;
