import { FC } from "react";
import {
    Edit,
    EditProps,
    ImageField,
    ImageInput,
    NumberInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    Toolbar,
} from "react-admin";

import { useDocumentTitle } from "../../../hooks";
const CustomToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);
const LabBannerEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Banner |  Edit");
    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm submitOnEnter={false} toolbar={<CustomToolbar />}>
                <NumberInput
                    source="sortOrder"
                    variant="outlined"
                    label="Sort Order"
                />
                <SelectInput
                    variant="outlined"
                    source="status"
                    choices={[
                        { id: "active", name: "Active" },
                        { id: "inactive", name: "InActive" },
                    ]}
                />
                <ImageInput
                    source="attachedFiles-imageUrl"
                    label="Pictures (1800*945 px)"
                    accept="image/*"
                    maxSize={5000000}
                >
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    );
};

export default LabBannerEdit;
