import { Box } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { useState } from "react";
import { Confirm, TextInput } from "react-admin";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import CustomField from "../../CustomField";

const InternalNote = () => {
    const { values } = useFormState();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { isLoading, refetch } = useRequest(
        `/v1/productOrderAction/${values?.po_id}/updateInternalNoteAction`,
        {
            method: "POST",
            body: {
                po_internal_notes: values.internalNote,
            },
        },
        {
            isRefresh: true,
            onSuccess: () => setIsDialogOpen(false),
        }
    );

    return (
        <>
            <Box display="flex" gridGap={3}>
                <CustomField
                    label="Internal Note (Not visible to Customer)"
                    value={values.po_internal_notes}
                />
                <span
                    style={{ marginTop: 16, cursor: "pointer" }}
                    onClick={() => setIsDialogOpen(true)}
                >
                    <AddBoxIcon color="primary" />
                </span>
            </Box>
            <Confirm
                isOpen={isDialogOpen}
                loading={isLoading}
                title="Create internal note"
                content={
                    <TextInput
                        source="internalNote"
                        label="Internal Note"
                        variant="outlined"
                        helperText={false}
                        minRows={2}
                        multiline
                        fullWidth
                    />
                }
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
        </>
    );
};

export default InternalNote;
