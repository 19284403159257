import { Divider, Tab, Tabs } from "@material-ui/core";
import { FC, useCallback, useState } from "react";
import {
    CreateButton,
    List,
    ListContextProvider,
    ListProps,
    TopToolbar,
    useListContext,
} from "react-admin";

import { useDocumentTitle, useExport } from "../../../hooks";
import PurchaseOrderDatagrid from "../purchaseOrders/PurchaseOrderDatagrid";
import PurchaseOrderFilter from "../purchaseOrders/PurchaseOrderFilter";
import PurchaseDatagrid from "../purchases/PurchaseDatagrid";
import PurchaseFilter from "../purchases/PurchaseFilter";
import OfficialItemDatagrid from "./OfficialItemDatagrid";
import OfficialVendorFilter from "./OfficialVendorFilter";

const OfficialVendorList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Official Vendors");

    const exporter = useExport(rest);
    const [tabValue, setTabValue] = useState(0);

    let filters;
    let resource;
    let isBulkActionButtons;

    if (tabValue === 1 || tabValue === 3) {
        filters = <PurchaseOrderFilter children={""} />;
        resource = "v1/purchaseOrder";
        isBulkActionButtons = false;
    } else if (tabValue === 2) {
        filters = <PurchaseFilter children={""} />;
        resource = "v1/productPurchase";
        isBulkActionButtons = false;
    } else {
        filters = <OfficialVendorFilter children={""} />;
        resource = undefined;
        isBulkActionButtons = permissions?.includes("officialItemDelete");
    }

    const ListActions = () => (
        <TopToolbar>
            {/* <FilterButton /> */}
            {permissions?.includes("productPurchaseCreate") &&
                tabValue === 2 && (
                    <CreateButton basePath="/v1/productPurchase" />
                )}
        </TopToolbar>
    );

    return (
        <List
            {...rest}
            title="Official Vendors"
            filters={filters}
            filterDefaultValues={{ tabValue }}
            resource={resource}
            sort={{ field: "id", order: "DESC" }}
            exporter={exporter}
            actions={<ListActions />}
            bulkActionButtons={isBulkActionButtons}
        >
            <TabbedDatagrid
                tabValue={tabValue}
                setTabValue={setTabValue}
                permissions={permissions}
            />
        </List>
    );
};

export default OfficialVendorList;

const TabbedDatagrid = ({ tabValue, setTabValue, permissions, ...rest }) => {
    const listContext = useListContext();
    const { filterValues, setFilters, displayedFilters } = listContext;

    const tabs = [
        { id: 0, name: "Official Items" },
        permissions?.includes("purchaseOrderView") && {
            id: 1,
            name: "P.O",
        },
        permissions?.includes("productPurchaseView") && {
            id: 2,
            name: "Purchased",
        },
        permissions?.includes("purchaseOrderView") && {
            id: 3,
            name: "History",
        },
    ].filter(Boolean);

    const handleOnChange = useCallback(
        (_, value) => {
            setTabValue(value);
            setFilters &&
                setFilters(
                    {
                        tabValue: value,
                        ...(value === 1 && {
                            _vendor_type: "company",
                        }),
                        ...(value === 3 && {
                            _status: "received,approved",
                            _vendor_type: "company",
                        }),
                    },
                    displayedFilters
                );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <>
            <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleOnChange}
                centered
            >
                {tabs.map((tab) => (
                    <Tab key={tab.id} label={tab.name} value={tab.id} />
                ))}
            </Tabs>
            <Divider />
            <ListContextProvider value={listContext}>
                {!tabValue && <OfficialItemDatagrid {...rest} />}
                {tabValue === 1 && <PurchaseOrderDatagrid {...rest} />}
                {tabValue === 2 && <PurchaseDatagrid {...rest} />}
                {tabValue === 3 && (
                    <PurchaseOrderDatagrid from="vendorHistoryTab" {...rest} />
                )}
            </ListContextProvider>
        </>
    );
};
