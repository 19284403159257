import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { TextInput } from "react-admin";

import { useForm, useFormState } from "react-final-form";
import { useRequest } from "../hooks";
import { required } from "../utils/helpers";
import AroggaDialogActions from "./AroggaDialogActions";

type SendTestSmsDialogProps = {
    open: boolean;
    handleClose: () => void;
    [key: string]: any;
    gateWay: string;
};

const SendTestSmsDialog: FC<SendTestSmsDialogProps> = ({
    open,
    handleClose,
    gateWay,
}) => {
    const form = useForm();
    const { values } = useFormState();
    const [smsStatus, setSmsStatus] = useState("");

    useEffect(() => {
        setSmsStatus("");
        form.change("toNumber", "");
    }, [open, form]);

    const { isLoading, refetch } = useRequest(
        `/v1/check-sms-api/`,
        {
            method: "POST",
            body: {
                message: "Arogga: SMS checking for testing purpose!",
                mobile: values.toNumber,
                gateWay: gateWay,
            },
        },
        {
            onSuccess: () => setSmsStatus("success"),
            onFailure: () => setSmsStatus("fail"),
        }
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Send SMS to check status</DialogTitle>
            <DialogContent>
                <p>Gateway: {gateWay}</p>
                <TextInput
                    source="toNumber"
                    label="Mobile number"
                    variant="outlined"
                    helperText={false}
                    validate={[required()]}
                    fullWidth
                />
                {smsStatus === "success" && (
                    <span style={{ color: "#008000" }}>
                        SMS has been sent successfully!
                    </span>
                )}
                {smsStatus === "fail" && (
                    <span style={{ color: "#FF0000" }}>
                        SMS has sending failed!
                    </span>
                )}
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                confirmLabel="SEND"
                disabled={!values.toNumber}
                onDialogClose={handleClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default SendTestSmsDialog;
