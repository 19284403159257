import {
    AutocompleteInput,
    Filter,
    ReferenceInput,
    TextInput,
} from "react-admin";

const OfficialVendorFilter = (props) => (
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            resettable
            alwaysOn
        />
        <ReferenceInput
            source="_brand_id"
            label="Brand"
            variant="outlined"
            reference="v1/productBrand"
            sort={{ field: "pb_name", order: "ASC" }}
            alwaysOn
        >
            <AutocompleteInput optionText="pb_name" resettable />
        </ReferenceInput>
        <ReferenceInput
            source="_product_category_id"
            label="Category (L1)"
            variant="outlined"
            reference="v1/taxonomy"
            filter={{
                _v_machine_name: "product_category",
                _parent_id: 0,
                _order: "ASC",
                _orderBy: "t_weight",
            }}
            alwaysOn
        >
            <AutocompleteInput optionText="t_title" resettable />
        </ReferenceInput>
    </Filter>
);

export default OfficialVendorFilter;
