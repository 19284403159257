import { Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

interface IZonePagination {
    rowsPerPage: any;
    handleChangeRowsPerPage: any;
    total: any;
    currentPage: any;
    handleChangePage: any;
}

export default function ZonePagination({
    rowsPerPage,
    handleChangeRowsPerPage,
    total,
    currentPage,
    handleChangePage,
}: IZonePagination) {
    const classes = useStyles();
    return (
        <div className={classes.paginationRoot}>
            <div className={classes.pagination}>
                <div className={classes.rowsPerPage}>
                    <p style={{ fontSize: "14px" }}>Rows per page: </p>
                    <Select
                        native
                        value={rowsPerPage}
                        onChange={handleChangeRowsPerPage}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                    </Select>
                    <Pagination
                        count={Math.ceil(total / rowsPerPage)}
                        siblingCount={0}
                        boundaryCount={1}
                        page={currentPage}
                        onChange={handleChangePage}
                        color="primary"
                    />
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTableRow-root:hover": {
            backgroundColor: "#f6f6f6", // Inherit background color to disable hover effect
        },
    },
    switchBase: {
        color: theme.palette.grey[500], // Default (inactive) color
        "&$checked": {
            color: theme.palette.success.main, // Checked (active) color
        },
        "&$checked + $track": {
            backgroundColor: theme.palette.success.main, // Track color when active
        },
    },
    checked: {},
    track: {},
    dialogContent: {
        paddingBottom: theme.spacing(2), // Add some padding to the bottom of the dialog content
    },
    outsideDhakaForm: {
        display: "flex",
        gap: 4,
        marginBottom: 0,
        width: "100%",
    },
    paginationRoot: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "14px",
    },
    pagination: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 4,
    },
    rowsPerPage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 14,
    },
}));
