import { TableCell, makeStyles } from "@material-ui/core";
import { Children, FC, ReactNode } from "react";

type CustomTableCellProps = {
    data?: any;
    children?: ReactNode;
    [key: string]: any;
};

const CustomTableCell: FC<CustomTableCellProps> = ({
    data,
    children,
    ...rest
}) => {
    const classes = useStyles();

    const isValidChild = Children.count(children) === 1;

    return (
        <TableCell
            classes={{
                root: classes.tableCell,
            }}
            {...rest}
        >
            {isValidChild ? children : data}
        </TableCell>
    );
};

export default CustomTableCell;

const useStyles = makeStyles({
    tableCell: {
        borderLeft: "1px solid #ddd",
        borderBottom: "none",
    },
});
