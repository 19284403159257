import { DateTime } from "luxon";
import { FC } from "react";
import {
    Datagrid,
    DateField,
    FunctionField,
    Link,
    List,
    ListProps,
    NumberField,
    Record,
    ReferenceField,
    TextField,
} from "react-admin";

import { useDocumentTitle, useExport } from "../../../hooks";
import { getQuantityLabel, getReadableSKU } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import PurchaseItemFilter from "./PurchaseItemFilter";

const PurchaseItemList: FC<ListProps> = (props) => {
    useDocumentTitle("Arogga | Purchase Item List");

    const classes = useAroggaStyles();
    const exporter = useExport(props);

    return (
        <List
            {...props}
            title="List of Purchase Item"
            filters={<PurchaseItemFilter children={""} />}
            perPage={25}
            sort={{ field: "pii_id", order: "DESC" }}
            exporter={exporter}
        >
            <Datagrid>
                <TextField source="ppi_id" label="ID" />
                <FunctionField
                    label="Purchase ID"
                    onClick={(e) => e.stopPropagation()}
                    sortBy="ppi_product_purchase_id"
                    render={({ ppi_product_purchase_id }: Record) => (
                        <Link
                            to={`/v1/productPurchase/${ppi_product_purchase_id}/edit`}
                        >
                            {ppi_product_purchase_id}
                        </Link>
                    )}
                />
                <ReferenceField
                    source="ppi_product_id"
                    label="Product"
                    reference="v1/product"
                    link="show"
                >
                    <TextField source="p_name" />
                </ReferenceField>
                <ReferenceField
                    source="ppi_product_variant_id"
                    label="Variant"
                    reference="v1/productVariant"
                    link={false}
                >
                    <FunctionField
                        render={(record: Record) =>
                            getReadableSKU(record.pv_attribute)
                        }
                    />
                </ReferenceField>
                <ReferenceField
                    source="ppi_product_id"
                    label="Form"
                    reference="v1/product"
                    link={false}
                >
                    <TextField source="p_form" className={classes.capitalize} />
                </ReferenceField>
                <ReferenceField
                    source="ppi_product_id"
                    label="Strength"
                    reference="v1/product"
                    link={false}
                >
                    <TextField source="p_strength" />
                </ReferenceField>
                <FunctionField
                    source="ppi_qty"
                    label="Qty"
                    sortBy="ppi_qty"
                    className={classes.whitespaceNowrap}
                    render={({
                        ppi_qty: qty,
                        product_base_unit: baseUnit,
                    }: Record) => (
                        <ReferenceField
                            source="ppi_unit_id"
                            reference="v1/productUnit"
                            link={false}
                        >
                            <FunctionField
                                render={({
                                    pu_label: salesUnit,
                                    pu_multiplier: salesUnitMultiplier,
                                }: Record) =>
                                    getQuantityLabel({
                                        qty,
                                        salesUnit,
                                        baseUnit,
                                        salesUnitMultiplier,
                                    })
                                }
                            />
                        </ReferenceField>
                    )}
                />
                <NumberField
                    source="ppi_purchase_price"
                    label="Purchase Price"
                />
                <NumberField
                    source="ppi_price_with_vat"
                    label="Total Purchase Price"
                />
                <NumberField source="ppi_mrp" label="MRP" />
                <NumberField source="ppi_vat" label="VAT" />
                <NumberField source="ppi_discount" label="Discount" />
                <TextField source="ppi_multiplier" label="Multiplier" />
                <FunctionField
                    source="ppi_expiry"
                    label="Expiry"
                    sortBy="ppi_expiry"
                    render={(record: Record) => {
                        if (record.ppi_expiry === "0000-00-00") return;

                        const date = DateTime.fromSQL(record.ppi_expiry);
                        const diff = date
                            .diff(DateTime.local(), ["months"])
                            .toObject();

                        let color = "black";

                        if (diff.months <= 0) {
                            color = "#EF1962";
                        } else if (diff.months <= 6) {
                            color = "blue";
                        }

                        return (
                            <DateField
                                source="ppi_expiry"
                                label="Expiry"
                                record={record}
                                options={{ year: "numeric", month: "short" }}
                                className={classes.whitespaceNowrap}
                                style={{ color }}
                            />
                        );
                    }}
                />
                <TextField source="ppi_batch" label="Batch" />
            </Datagrid>
        </List>
    );
};

export default PurchaseItemList;
