import { FC } from "react";
import {
    Edit,
    EditProps,
    FormTab,
    TabbedForm,
    useEditController,
} from "react-admin";

import { useDocumentTitle, useGetCurrentUser } from "../../../hooks";
import CapApprovalHistoryTab from "./tabs/CapApprovalHistoryTab";
import HistoryTab from "./tabs/HistoryTab";
import OfficePurchaseListTab from "./tabs/OfficePurchaseListTab";
import PaymentTab from "./tabs/PaymentTab";
import QuotationListTab from "./tabs/QuotationListTab";

const PurchaseRequisitionEdit: FC<EditProps> = ({ ...rest }) => {
    useDocumentTitle("Arogga |  Purchase Requisition Show");
    const { record } = useEditController(rest);

    const { permissions } = useGetCurrentUser();
    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <TabbedForm toolbar={null}>
                <FormTab label="Purchase Requisition">
                    <QuotationListTab record={record} />
                </FormTab>
                {record?.office_purchase_orders &&
                    Object.keys(record.office_purchase_orders).length > 0 && (
                        <FormTab label="Office Purchase Order">
                            <OfficePurchaseListTab record={record} />
                        </FormTab>
                    )}
                <FormTab label="Payment">
                    <PaymentTab record={record} />
                </FormTab>
                {permissions?.includes("requisitionActivityLogView") && (
                    <FormTab label="History">
                        <HistoryTab record={record} />
                    </FormTab>
                )}
                {permissions?.includes("quotationApprovalHistoryView") && (
                    <FormTab label="Cap Approval History">
                        <CapApprovalHistoryTab record={record} />
                    </FormTab>
                )}
            </TabbedForm>
        </Edit>
    );
};

export default PurchaseRequisitionEdit;
