import { TableCell, TableHead, TableRow } from "@material-ui/core";
import { useFormState } from "react-final-form";

const TableHeader = () => {
    const { values } = useFormState();

    const hasItemUnavailable = values.poi?.some(
        (item) => item?.poi_item_unavailable
    );

    return (
        <TableHead>
            <TableRow>
                <TableCell>Sl No</TableCell>
                {process.env.REACT_APP_NODE_ENV === "development" && (
                    <TableCell>ID</TableCell>
                )}
                <TableCell>Name</TableCell>
                <TableCell>Variant</TableCell>
                <TableCell>Form</TableCell>
                <TableCell>Strength</TableCell>
                <TableCell>Cold</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell align="right">Qty</TableCell>
                <TableCell>Total Qty</TableCell>
                <TableCell align="right">Total MRP</TableCell>
                <TableCell align="right">Total Discount Price</TableCell>
                {hasItemUnavailable && <TableCell>Action</TableCell>}
                <TableCell>Remove</TableCell>
            </TableRow>
        </TableHead>
    );
};

export default TableHeader;
