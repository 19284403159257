import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
} from "@material-ui/core";
import { FC, useState } from "react";
import { Confirm, ReferenceField, TextField, TextInput } from "react-admin";
import { useFormState } from "react-final-form";

import AroggaDialogActions from "../../../components/AroggaDialogActions";
import NoDataFound from "../../../components/NoDataFound";
import { useRequest } from "../../../hooks";
import {
    capitalizeFirstLetter,
    capitalizeFirstLetterOfEachWord,
    isArray,
    isArrayOfTypeString,
    isEmpty,
    isObject,
} from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

type RevisionDialogProps = {
    isDialogOpen: boolean;
    setIsDialogOpen: (isDialogOpen: boolean) => void;
    selectedRow: any;
    blacklistedKeys?: string[];
    blacklistedMappingKeys?: {
        [key: string]: string[];
    };
};

const RevisionDialog: FC<RevisionDialogProps> = ({
    isDialogOpen,
    setIsDialogOpen,
    selectedRow,
    blacklistedKeys = [],
    blacklistedMappingKeys = {},
}) => {
    const classes = useStyles();
    const aroggaClasses = useAroggaStyles();
    const { values } = useFormState();

    const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);

    const { isLoading, refetch: approved } = useRequest(
        `/v1/revisionAction/${
            selectedRow?.r_id || selectedRow?.r_old_row?.r_id
        }/ApproveAction`,
        {
            method: "POST",
        },
        {
            isRefresh: true,
            onSuccess: () => onDialogClose(),
        }
    );

    const { isLoading: isRejectLoading, refetch: rejected } = useRequest(
        `/v1/revisionAction/${
            selectedRow?.r_id || selectedRow?.r_new_row?.r_id
        }/RejectAction`,
        {
            method: "POST",
            body: {
                r_comment: values.revisionComment,
            },
        },
        {
            isRefresh: true,
            onSuccess: () => {
                onDialogClose();
                onRejectDialogClose();
            },
        }
    );

    const onDialogClose = () => setIsDialogOpen(false);
    const onRejectDialogClose = () => {
        values.revisionComment = undefined;
        setIsRejectDialogOpen(false);
    };

    const BLACKLISTED_KEYS = [
        "_created_at",
        "_modified_at",
        "_deleted_at",
        "_supervised_at",
        "isRevision",
        ...blacklistedKeys,
    ];

    const getFilterBlacklistedKeys = (keys) => {
        return keys.reduce((acc, key) => {
            const splittedKey = key.split("_");

            if (splittedKey.length === 2 && splittedKey[1] === "id") return acc;

            if (
                key !== "id" &&
                !BLACKLISTED_KEYS.some((blacklistedKey) =>
                    key.includes(blacklistedKey)
                )
            ) {
                acc.push(key);
            }

            return acc;
        }, []);
    };

    // TODO: Remove in future
    // const getUniqueKeys = (oldRow = {}, newRow = {}) => [
    //     ...new Set([...Object.keys(oldRow), ...Object.keys(newRow)]),
    // ];

    // TODO: Remove in future
    // const keys = getFilterBlacklistedKeys(
    //     getUniqueKeys(selectedRow?.r_old_row, selectedRow?.r_new_row)
    // );

    const compareData = (oldData = {}, newData = {}, parentKey = "") => {
        return getFilterBlacklistedKeys(Object.keys(newData))?.reduce(
            (acc, key) => {
                const oldValue = oldData?.[key] || "";
                const newValue = newData?.[key] || "";

                const currentKey = parentKey ? `${parentKey}.${key}` : key;

                const pushComparison = (fieldName, oldValue, newValue) => {
                    acc.push({ fieldName, oldValue, newValue });
                };

                if (
                    isArrayOfTypeString(oldValue) ||
                    isArrayOfTypeString(newValue)
                ) {
                    const changedNewValue =
                        isArray(newValue) &&
                        newValue.filter(
                            (item) => item && !oldValue.includes(item)
                        );

                    if (
                        oldValue.length !== newValue.length ||
                        changedNewValue?.length
                    ) {
                        pushComparison(
                            transformFieldName(currentKey),
                            oldValue,
                            newValue
                        );
                    }
                } else if (isObject(oldValue) || isObject(newValue)) {
                    if (key?.includes("attachedFiles_")) {
                        const changedNewValue =
                            isArray(newValue) &&
                            newValue.filter((item) => item.rawFile);

                        if (
                            oldValue.length !== newValue.length ||
                            changedNewValue?.length
                        ) {
                            pushComparison(currentKey, oldValue, newValue);
                        }
                    } else {
                        acc.push(
                            ...compareData(oldValue, newValue, currentKey)
                        );
                    }
                    // eslint-disable-next-line eqeqeq
                } else if (oldValue != newValue) {
                    pushComparison(
                        transformFieldName(currentKey),
                        oldValue,
                        newValue
                    );
                }

                return acc;
            },
            []
        );
    };

    // TODO: Implement it in future
    // const blacklistedMappingKeys = {
    //     entries: ["purchase_price"],
    //     pu: ["puBaseUnitLabel"],
    //     pv: ["pvBaseUnitLabel"],
    // };
    // const data = {
    //     pv_weekly_requirement: 10,
    //     entries: {
    //         dimension: 20,
    //         purchase_price: 50,
    //     },
    //     pu: ["puBaseUnitLabel", "baseUnitLabel2"],
    //     pv: [{ pvBaseUnitLabel: "Tablet", baseUnitLabel2: 20 }],
    // };
    // const expectedOutput = {
    //     pv_weekly_requirement: 10,
    //     entries: {
    //         dimension: 20,
    //     },
    //     pu: ["baseUnitLabel2"],
    //     pv: [{ baseUnitLabel2: 20 }],
    // };

    const comparedData = compareData(
        selectedRow?.r_old_row,
        selectedRow?.r_new_row
    );

    const haveComparedData = !isEmpty(comparedData);

    return (
        <Dialog open={isDialogOpen} onClose={onDialogClose} maxWidth="xl">
            <DialogTitle>
                {selectedRow?.r_entity
                    ? `Content: ${selectedRow?.r_entity}, Revision ID #${selectedRow?.r_id}`
                    : "Revision"}
            </DialogTitle>
            <DialogContent>
                {!haveComparedData ? (
                    <NoDataFound message="No changes found!" />
                ) : (
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.borderRight}>
                                    No.
                                </TableCell>
                                <TableCell className={classes.borderRight}>
                                    Field
                                </TableCell>
                                {selectedRow?.r_action !== "INSERT" && (
                                    <TableCell className={classes.borderRight}>
                                        Old Value
                                    </TableCell>
                                )}
                                <TableCell>
                                    {selectedRow?.r_action === "INSERT"
                                        ? "Value"
                                        : "New Value"}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!comparedData?.length &&
                                comparedData.map(
                                    (
                                        { fieldName, oldValue, newValue },
                                        index
                                    ) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                className={classes.borderRight}
                                            >
                                                {index + 1}
                                            </TableCell>
                                            <TableCell
                                                className={classes.borderRight}
                                            >
                                                {fieldName.includes(".")
                                                    ? fieldName
                                                    : capitalizeFirstLetterOfEachWord(
                                                          fieldName
                                                      )}
                                            </TableCell>
                                            {selectedRow?.r_action !==
                                                "INSERT" && (
                                                <TableCell
                                                    className={
                                                        classes.borderRight
                                                    }
                                                >
                                                    {renderData(
                                                        fieldName,
                                                        oldValue
                                                    )}
                                                </TableCell>
                                            )}
                                            <TableCell>
                                                {renderData(
                                                    fieldName,
                                                    newValue
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                        </TableBody>
                    </Table>
                )}
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                hasConfirmBtn={
                    selectedRow?.r_status !== "APPROVED" && haveComparedData
                }
                confirmLabel="Approve"
                childrenPosition="center"
                onDialogClose={onDialogClose}
                onConfirm={approved}
            >
                {selectedRow?.r_status !== "REJECTED" && haveComparedData && (
                    <Button
                        variant="contained"
                        style={{
                            fontWeight: 600,
                            fontSize: 16,
                        }}
                        className={aroggaClasses.bgRed}
                        onClick={() => setIsRejectDialogOpen(true)}
                    >
                        Reject
                    </Button>
                )}
            </AroggaDialogActions>
            <Confirm
                title="Are you sure you want to reject this revision?"
                content={
                    <TextInput
                        source="revisionComment"
                        label="Comment"
                        variant="outlined"
                        helperText={false}
                        minRows={2}
                        multiline
                        fullWidth
                    />
                }
                isOpen={isRejectDialogOpen}
                loading={!values.revisionComment || isRejectLoading}
                onConfirm={rejected}
                onClose={onRejectDialogClose}
            />
        </Dialog>
    );
};

export default RevisionDialog;

const useStyles = makeStyles({
    borderRight: {
        borderRight: "1px solid #EAEBEC",
    },
});

const renderData = (fieldName, value) => {
    const isBy = fieldName?.includes("_by");
    const isAttachedFiles = fieldName?.includes("attachedFiles_");

    if (isBy) {
        return renderUser({
            source: fieldName,
            record: {
                [fieldName]: value,
            },
        });
    } else if (isAttachedFiles) {
        return renderImage(value);
    } else if (isArrayOfTypeString(value)) {
        return renderList(value);
    } else {
        return isObject(value) ? (
            JSON.stringify(value)
        ) : (
            <span dangerouslySetInnerHTML={{ __html: value }} />
        );
    }
};

const transformFieldName = (fieldName) => {
    // TODO:
    if (fieldName.includes(".") || fieldName.includes("_by")) return fieldName;

    if (fieldName.split("_").length === 1) {
        return fieldName.toUpperCase();
    }

    if (fieldName.includes("attachedFiles_")) {
        return capitalizeFirstLetter(fieldName);
    }

    return capitalizeFirstLetterOfEachWord(
        fieldName.split("_").slice(1).join(" ")
    );
};

const renderUser = (params) => (
    <ReferenceField reference="v1/users" link="show" {...params}>
        <TextField source="u_name" />
    </ReferenceField>
);

const renderImage = (value) => (
    <ul
        style={{
            display: "flex",
            listStyleType: "none",
        }}
    >
        {!!value?.length &&
            value.map((item, index) => (
                <li key={index}>
                    <img
                        src={item?.src}
                        title={item?.title}
                        alt={item?.title}
                        style={{
                            margin: "0.5rem",
                            maxHeight: "10rem",
                        }}
                    />
                </li>
            ))}
    </ul>
);

const renderList = (value) => {
    const valueLength = value?.length;

    return (
        <ul
            style={{
                margin: 0,
                paddingLeft: valueLength === 1 ? 0 : 16,
                ...(valueLength === 1 && { listStyleType: "none" }),
            }}
        >
            {!!valueLength && value.map((item, i) => <li key={i}>{item}</li>)}
        </ul>
    );
};
