import {
    AutocompleteInput,
    Filter,
    ReferenceInput,
    SelectInput,
    TextInput,
} from "react-admin";

import CascaderCategoryInput from "../../../components/CascaderCategoryInput";
import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import { userEmployeeInputTextRenderer } from "../../../utils/helpers";

const ProductFilter = ({
    cascaderCategoryPlaceholder,
    setSelectedLastCategoryId,
    ...rest
}) => (
    // @ts-ignore
    <Filter {...rest}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            resettable
            alwaysOn
        />
        <ReferenceInput
            source="_brand_id"
            label="Brand"
            variant="outlined"
            reference="v1/productBrand"
            alwaysOn
        >
            <AutocompleteInput
                optionText="pb_name"
                options={{
                    InputProps: {
                        multiline: true,
                    },
                }}
                resettable
            />
        </ReferenceInput>
        <TaxonomiesByVocabularyInput
            fetchKey="product_type"
            source="_product_type"
            label="Type"
            alwaysOn
        />
        <SelectInput
            source="_active_status"
            label="Status"
            variant="outlined"
            choices={[
                { id: "active", name: "Active" },
                {
                    id: "inactive",
                    name: "Inactive",
                },
            ]}
            alwaysOn
        />
        <FormatedBooleanInput
            source="_stock_status"
            label="Stock Status"
            alwaysOn
        />
        <TextInput source="ids" label="Ids" variant="outlined" resettable />
        <TextInput source="_form" label="Form" variant="outlined" resettable />
        <TextInput
            source="_strength"
            label="Strength"
            variant="outlined"
            resettable
        />
        <ReferenceInput
            source="_generic_id"
            label="Generic"
            variant="outlined"
            reference="v1/generics"
            sort={{ field: "g_name", order: "ASC" }}
        >
            <AutocompleteInput optionText="g_name" resettable />
        </ReferenceInput>
        <ReferenceInput
            source="_vendor_local"
            label="Local Vendor"
            variant="outlined"
            reference="v1/vendor"
            filter={{
                _status: "active",
                _type: "local",
            }}
            filterToQuery={(searchText) => ({
                _name: searchText,
            })}
        >
            <AutocompleteInput
                optionText="v_name"
                options={{
                    InputProps: {
                        multiline: true,
                    },
                }}
                resettable
            />
        </ReferenceInput>
        <ReferenceInput
            source="_vendor_official"
            label="Official Vendor"
            variant="outlined"
            reference="v1/vendor"
            filter={{
                _status: "active",
                _type: "company",
            }}
            filterToQuery={(searchText) => ({
                _name: searchText,
            })}
        >
            <AutocompleteInput
                optionText="v_name"
                options={{
                    InputProps: {
                        multiline: true,
                    },
                }}
                resettable
            />
        </ReferenceInput>
        <ReferenceInput
            source="_created_by"
            label="Created By"
            variant="outlined"
            reference="v1/users"
        >
            <AutocompleteInput
                matchSuggestion={() => true}
                optionValue="u_id"
                optionText={<UserEmployeeOptionTextRenderer />}
                inputText={userEmployeeInputTextRenderer}
                resettable
            />
        </ReferenceInput>
        <CascaderCategoryInput
            source="selectedLastCategoryId"
            label="Category"
            placeholder={cascaderCategoryPlaceholder}
            addLabel={false}
            setSelectedLastCategoryId={setSelectedLastCategoryId}
            style={{ paddingBottom: 5 }}
        />
    </Filter>
);

export default ProductFilter;
