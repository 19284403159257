import { FC } from "react";
import {
    Datagrid,
    List,
    ListProps,
    ReferenceField,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import {
    useDocumentTitle,
    useExport,
    useNavigateFromList,
} from "../../../hooks";

const QuotationItemMappingList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Quotation Item Mapping List");

    const exporter = useExport(rest);
    const navigateFromList = useNavigateFromList(
        "quotationItemMappingView",
        "quotationItemMappingEdit"
    );
    return (
        <List
            {...rest}
            title="List of Quotation Item Mapping"
            perPage={25}
            sort={{ field: "qim_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={false}
        >
            <Datagrid rowClick={navigateFromList}>
                <TextField source="qim_id" label={"ID"} />
                <TextField source="qim_name" label="Name" />
                <ReferenceField
                    source="qim_head_id"
                    label="Accounting To"
                    reference="v1/accountingHead"
                    link="show"
                >
                    <TextField source="ah_name" />
                </ReferenceField>
                <AroggaDateField source="qim_created_at" label="Created At" />
                <ReferenceField
                    source="qim_created_by"
                    label="Created By"
                    reference="v1/users"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export default QuotationItemMappingList;
