import { Box } from "@material-ui/core";
import { FC, useState } from "react";
import {
    BooleanField,
    Button,
    Confirm,
    FunctionField,
    Link,
    Pagination,
    Record,
    ReferenceField,
    ReferenceManyField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
    useShowController,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import ColumnShowLayout from "../../../components/ColumnShowLayout";
import ShipmentDetailsDatagrid from "../../../components/manageDelivery/bags/ShipmentDetailsDatagrid";
import { useDocumentTitle, useRequest } from "../../../hooks";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

const BagShow: FC<ShowProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Bag Show");

    const classes = useAroggaStyles();
    const { record } = useShowController(rest);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { isLoading, refetch } = useRequest(
        `/employeeApp/v1/shipmentAction/${rest.id}/bagReceiveAction`,
        {
            method: "POST",
        },
        {
            isBaseUrl: true,
            isRefresh: true,
            onSuccess: () => setIsDialogOpen(false),
        }
    );

    return (
        <>
            <Show {...rest}>
                <SimpleShowLayout>
                    <ColumnShowLayout md={4} simpleShowLayout={false}>
                        <TextField source="sb_id" label="ID" />
                        <FunctionField
                            label="Warehouse"
                            render={({
                                sb_warehouse_id,
                                sb_warehouse_name,
                            }: Record) => (
                                <Link to={`warehouse/${sb_warehouse_id}/show`}>
                                    {sb_warehouse_name}
                                </Link>
                            )}
                        />
                        <ReferenceField
                            source="sb_zone_id"
                            label="Zone"
                            reference="v1/zone"
                            link={false}
                        >
                            <TextField source="z_name" />
                        </ReferenceField>
                        <TextField
                            source="sb_status"
                            label="Status"
                            className={classes.capitalize}
                        />
                        <TextField
                            source="sb_shift_type"
                            label="Shift Type"
                            className={classes.capitalize}
                        />
                        <ReferenceField
                            source="sb_shift_schedule_id"
                            label="Shift Schedule"
                            reference="v1/shiftSchedule"
                            link="show"
                        >
                            <FunctionField
                                render={({ s_title, ss_date }: Record) =>
                                    `${s_title} (${ss_date})`
                                }
                            />
                        </ReferenceField>
                        <TextField
                            source="sb_total_shipments"
                            label="Total Shipment"
                        />
                        <TextField
                            source="sb_shipment_cancel_count"
                            label="Shipment Cancel Count"
                        />
                        <TextField
                            source="sb_shipment_reschedule_count"
                            label="Shipment Reschedule Count"
                        />
                        <FunctionField
                            label="Assign"
                            render={({
                                sb_deliveryman_id,
                                sb_deliveryman_name,
                            }: Record) => (
                                <Link
                                    to={`/v1/users/${sb_deliveryman_id}/show`}
                                >
                                    {sb_deliveryman_name}
                                </Link>
                            )}
                        />
                        <BooleanField
                            source="sb_received"
                            label="Received?"
                            looseValue
                        />
                        <AroggaDateField
                            source="sb_received_at"
                            label="Received At"
                        />
                        <AroggaDateField
                            source="sb_created_at"
                            label="Created At"
                        />
                        <ReferenceField
                            source="sb_created_by"
                            label="Created By"
                            reference="v1/users"
                            link="show"
                        >
                            <TextField source="u_name" />
                        </ReferenceField>
                        <AroggaDateField
                            source="sb_modified_at"
                            label="Modified At"
                        />
                        <ReferenceField
                            source="sb_modified_by"
                            label="Modified By"
                            reference="v1/users"
                            link="show"
                        >
                            <TextField source="u_name" />
                        </ReferenceField>
                    </ColumnShowLayout>
                    {!!record?.sbd?.length && (
                        <ReferenceManyField
                            source="sbd"
                            label="Bag Details"
                            reference="v1/shipment"
                            target="ids"
                            filter={{
                                ids: record.sbd
                                    .map((item) => item.sbd_shipment_id)
                                    .join(","),
                            }}
                            pagination={
                                <Pagination
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                />
                            }
                            perPage={50}
                            sort={{ field: "s_id", order: "DESC" }}
                        >
                            <ShipmentDetailsDatagrid />
                        </ReferenceManyField>
                    )}
                    <FunctionField
                        addLabel={false}
                        render={({ sb_received }: Record) => (
                            <Box display="flex" gridGap={5}>
                                {permissions?.includes("assignOthersBag") &&
                                    !sb_received && (
                                        <Button
                                            label="Bag Receive"
                                            variant="contained"
                                            onClick={() =>
                                                setIsDialogOpen(true)
                                            }
                                        />
                                    )}
                            </Box>
                        )}
                    />
                </SimpleShowLayout>
            </Show>
            <Confirm
                title={`Bag #${rest.id}`}
                content={`Are you sure you want to receive this bag?`}
                isOpen={isDialogOpen}
                loading={isLoading}
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
        </>
    );
};

export default BagShow;
