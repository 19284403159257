import { Box } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";

import { useRequest } from "../../../hooks";
import {
    capitalizeFirstLetterOfEachWord,
    getProductTextRenderer,
    getReadableSKU,
    groupBy,
    required,
} from "../../../utils/helpers";
import InlineArrayInput from "../../InlineArrayInput";

const ProductSideScrollInput = ({ values }) => {
    const { data, refetch } = useRequest("");

    const inputRef = useRef<HTMLInputElement>(null!);
    const [selectedProductVariants, setSelectedProductVariants] =
        useState(null);

    useEffect(() => {
        fetchProductData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.b_config]);

    useEffect(() => {
        if (!data?.length) return;

        const mergeIndividualVariants = data?.reduce((acc, currentValue) => {
            acc.push(...currentValue.pv);
            return acc;
        }, []);

        // TODO: Add support specific variant selection in groupBy function
        // pv_id, pv_sku, pv_attribute
        const groupByProductVariantProductId = groupBy(
            mergeIndividualVariants,
            (data) => data.pv_p_id
        );

        setSelectedProductVariants(groupByProductVariantProductId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const fetchProductData = async () => {
        if (
            !values.b_config?.length ||
            !values.b_id ||
            (values.b_type !== "product" && values.b_type !== "side_scroll")
        )
            return;

        const productIds = values.b_config?.map((item) => item.p_id)?.join(",");
        refetch({
            endpoint: `/v1/product?_details=1&ids=${productIds}`,
        });
    };

    const handleProductSelect = ({ p_id, pv }) => {
        setSelectedProductVariants((prevData) => ({
            ...prevData,
            [p_id]: pv,
        }));

        const individualProductVariantsLength =
            selectedProductVariants?.[p_id]?.length;

        if (individualProductVariantsLength !== 1) {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 1);
        }
    };

    return (
        <InlineArrayInput
            source={`b_config-${values.b_type}`}
            label={capitalizeFirstLetterOfEachWord(values.b_type)}
            validate={[required()]}
            disableRemove={values[`b_config-${values.b_type}`]?.length === 1}
            enableRenderProps
        >
            {({ getSource, scopedFormData }) => {
                const individualProductVariantsLength =
                    selectedProductVariants?.[scopedFormData?.p_id]?.length;

                return (
                    <Box display="flex" gridGap={8}>
                        <ReferenceInput
                            source={getSource("p_id")}
                            record={scopedFormData}
                            label="Product *"
                            variant="outlined"
                            helperText={false}
                            reference="general/v3/search"
                            onSelect={({ p_id, pv }) =>
                                handleProductSelect({ p_id, pv })
                            }
                            sort={{ field: "p_name", order: "ASC" }}
                            validate={[required()]}
                        >
                            <AutocompleteInput
                                optionValue="p_id"
                                optionText={getProductTextRenderer}
                                options={{
                                    InputProps: { multiline: true },
                                }}
                                matchSuggestion={() => true}
                                resettable
                            />
                        </ReferenceInput>
                        {scopedFormData?.p_id &&
                            !!individualProductVariantsLength && (
                                <AutocompleteInput
                                    source={getSource("pv_id")}
                                    record={scopedFormData}
                                    label="Variant *"
                                    variant="outlined"
                                    helperText={false}
                                    options={{
                                        InputProps: { inputRef: inputRef },
                                    }}
                                    style={{ width: 256 }}
                                    choices={
                                        selectedProductVariants?.[
                                            scopedFormData?.p_id
                                        ]
                                    }
                                    optionText={(item) =>
                                        getReadableSKU(item?.pv_attribute)
                                    }
                                    optionValue="pv_id"
                                    initialValue={
                                        individualProductVariantsLength === 1 &&
                                        selectedProductVariants?.[
                                            scopedFormData?.p_id
                                        ][0]?.pv_id
                                    }
                                    validate={[required()]}
                                    disabled={
                                        individualProductVariantsLength === 1
                                    }
                                />
                            )}
                    </Box>
                );
            }}
        </InlineArrayInput>
    );
};

export default ProductSideScrollInput;
