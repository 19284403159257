import {
    Datagrid,
    Pagination,
    ReferenceField,
    ReferenceManyField,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../../components/AroggaDateField";
import { useAroggaStyles } from "../../../../utils/useAroggaStyles";

const CapApprovalHistoryTab = ({ record }) => {
    const classes = useAroggaStyles();
    const capApprovalData = record?.quotations?.find(
        (item) => item.q_status === "finance_cleared"
    );
    return (
        <>
            {capApprovalData !== undefined ? (
                <ReferenceManyField
                    reference="v1/quotationApprovalHistory"
                    target="qah_id"
                    filter={{
                        _quotation_id: capApprovalData?.q_id,
                    }}
                    pagination={<Pagination />}
                >
                    <Datagrid>
                        <ReferenceField
                            source="qah_created_by"
                            label="Created By"
                            reference="v1/users"
                            sortBy="qah_created_by"
                            link="show"
                        >
                            <TextField source="u_name" />
                        </ReferenceField>
                        <AroggaDateField
                            source="qah_created_at"
                            label="Created At"
                        />
                        <TextField
                            source="qah_quotation_id"
                            label="Quotation Id"
                        />
                        <TextField
                            source="qah_status"
                            label="Status"
                            className={classes.capitalize}
                        />
                    </Datagrid>
                </ReferenceManyField>
            ) : (
                <div style={{ margin: 20 }}>
                    <p style={{ fontSize: 15 }}>No Result Found</p>
                </div>
            )}
        </>
    );
};

export default CapApprovalHistoryTab;
