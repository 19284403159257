import { FC, useCallback } from "react";
import {
    Edit,
    EditProps,
    SimpleForm,
    useMutation,
    useNotify,
    useRedirect,
} from "react-admin";

import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import ProductForm from "../../../components/manageDatabase/products/ProductForm";
import { useDocumentTitle } from "../../../hooks";
import { campaignOnSave } from "./utils/campaignOnSave";

const CampaignEdit: FC<EditProps> = ({ hasEdit, ...rest }) => {
    useDocumentTitle("Arogga | Campaign Edit");

    const redirect = useRedirect();
    const notify = useNotify();
    const [mutate, { loading }] = useMutation();

    const onSave = useCallback(
        async (values) => {
            campaignOnSave({
                notify,
                redirect,
                mutate,
                values,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mutate]
    );

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm
                submitOnEnter={false}
                toolbar={
                    rest.permissions?.includes("productEdit") ? (
                        <SaveDeleteToolbar
                            isSave
                            isSaveDisabled={loading}
                            isDelete={rest.permissions?.includes(
                                "productDelete"
                            )}
                        />
                    ) : null
                }
                save={onSave}
            >
                <ProductForm />
            </SimpleForm>
        </Edit>
    );
};

export default CampaignEdit;
