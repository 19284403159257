import { FC } from "react";
import { Create, CreateProps, SimpleForm } from "react-admin";

import { useDocumentTitle } from "../../../hooks";
import AddressCreateEdit from "../../../components/manageUser/addresses/AddressCreateEdit";

const AddressCreate: FC<CreateProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Address Create");

    return (
        <Create {...rest}>
            <SimpleForm redirect="list">
                <AddressCreateEdit permissions={permissions} />
            </SimpleForm>
        </Create>
    );
};

export default AddressCreate;
