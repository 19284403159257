import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
    BooleanInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    required,
} from "react-admin";
import { FormSpy } from "react-final-form";

import { Status } from "../../utils/enums";
import { isEmpty, isJSONParsable, logger } from "../../utils/helpers";
import { httpClient } from "../../utils/http";
import AddressTypeInput from "../AddressTypeInput";
import AreaInput from "./AreaInput";
import DistrictInput from "./DistrictInputProps";
import UserLocationAutocompleteInput from "./Order/UserLocationAutocompleteInput";

const CheckOrder = ({
    isUserChecked,
    locations,
    setLocations,
    setHasLocationField,
    hasLocationField,
}) => {
    const [formValues, setFormValues] = useState<any>([]);

    const toChoiceDivisions = (items: string[]) =>
        items.map((item) => ({
            id: item,
            name: item,
        }));

    useEffect(() => {
        const locationsFromStroage = sessionStorage.getItem("locations");

        if (locationsFromStroage) {
            setLocations(
                isJSONParsable(locationsFromStroage)
                    ? JSON.parse(locationsFromStroage)
                    : {}
            );
        } else {
            httpClient("/v1/allLocations/", { isBaseURL: true })
                .then(({ json }: any) => {
                    if (json.status === Status.SUCCESS) {
                        setLocations(json.data);
                        sessionStorage.setItem(
                            "locations",
                            JSON.stringify(json.data)
                        );
                    }
                })
                .catch((err) => logger(err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <>
                <Grid container spacing={1}>
                    {isUserChecked && (
                        <>
                            <Grid item xl={4}>
                                <TextInput
                                    source="u_name"
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )}
                    {!isEmpty(formValues.user) && (
                        <Grid
                            item
                            md={8}
                            style={{
                                display: !hasLocationField ? "none" : "block",
                            }}
                        >
                            <ReferenceInput
                                source="o_ul_id"
                                label="Location"
                                variant="outlined"
                                reference="v1/userLocations"
                                filter={{
                                    _orderBy: "ul_default",
                                    u_id: formValues.user?.u_id,
                                }}
                                emptyText="Add New Location"
                                allowEmpty
                                fullWidth
                            >
                                <UserLocationAutocompleteInput
                                    matchSuggestion={() => true}
                                    optionValue="ul_id"
                                    helperText={false}
                                    setHasLocationField={setHasLocationField}
                                    resettable
                                />
                            </ReferenceInput>
                        </Grid>
                    )}
                </Grid>
            </>
            {(isEmpty(formValues.user) || !formValues.o_ul_id) &&
                isUserChecked && (
                    <>
                        <Grid container spacing={1}>
                            <Grid item lg={4}>
                                <TextInput
                                    source="userLocation.address"
                                    label="Address"
                                    variant="outlined"
                                    validate={[required()]}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item lg={4}>
                                <SelectInput
                                    variant="outlined"
                                    label="Division"
                                    source="userLocation.division"
                                    alwaysOn
                                    choices={
                                        !!locations
                                            ? toChoiceDivisions(
                                                  Object.keys(locations)
                                              )
                                            : []
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item lg={4}>
                                <DistrictInput
                                    source="userLocation.district"
                                    label="District"
                                    variant="outlined"
                                    validate={[required()]}
                                    locations={locations}
                                    setLocations={setLocations}
                                    allowEmpty
                                    fullWidth
                                />
                            </Grid>
                            <Grid item lg={4}>
                                <AreaInput
                                    source="userLocation.area"
                                    label="Shipping Area"
                                    variant="outlined"
                                    validate={[required()]}
                                    locations={locations}
                                    setLocations={setLocations}
                                    allowEmpty
                                    fullWidth
                                />
                            </Grid>
                            <Grid item lg={4}>
                                <BooleanInput
                                    source="userLocation.isDefault"
                                    label="Default"
                                    initialValue={true}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item lg={2}>
                                <AddressTypeInput
                                    source="userLocation.type"
                                    variant="outlined"
                                    validate={[required()]}
                                    initialValue="Home"
                                    allowEmpty
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            <FormSpy
                subscription={{ values: true }}
                onChange={({ values }) =>
                    setTimeout(() => {
                        setFormValues(values);
                    }, 0)
                }
            />
        </div>
    );
};

export default CheckOrder;
