import { FC } from "react";
import { DateInput, Filter, FilterProps, SelectInput } from "react-admin";

const ExpenseEntryFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <SelectInput
            source="_status"
            label="Status"
            variant="outlined"
            choices={[
                { id: "pending", name: "Pending" },
                { id: "approved", name: "Approved" },
                { id: "rejected", name: "Rejected" },
            ]}
            alwaysOn
        />
        <DateInput
            source="_start_date"
            label="Start Date"
            variant="outlined"
            alwaysOn
        />
        <DateInput
            source="_end_date"
            label="End Date"
            variant="outlined"
            alwaysOn
        />
    </Filter>
);

export default ExpenseEntryFilter;
