import { Box, Grid, Typography } from "@material-ui/core";
import { FC, memo, useCallback, useState } from "react";
import {
    AutocompleteInput,
    Button,
    Create,
    CreateProps,
    DateInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    required,
    useMutation,
    useNotify,
    useRedirect,
} from "react-admin";
import { FormSpy } from "react-final-form";

import AroggaMovableImageInput from "../../../components/AroggaMovableImageInput";
import Form from "../../../components/Form";
import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import WarehouseInput from "../../../components/WarehouseInput";
import LocationCreateModal from "../../../components/manageOrder/orders/LocationCreateModal";
import OrderCalculate from "../../../components/manageOrder/orders/OrderCalculate";
import PopulateUserInfo from "../../../components/manageOrder/orders/PopulateUserInfo";
import ProductEntryForm from "../../../components/manageOrder/orders/ProductEntryForm";
import UserLocationAutocompleteInput from "../../../components/manageOrder/orders/UserLocationAutocompleteInput";
import ProductTable from "../../../components/manageOrder/orders/productTable";
import { useDocumentTitle } from "../../../hooks";
import { toFormattedDateTime } from "../../../utils/helpers";
import { orderOnSave } from "./utils/orderOnSave";

const OrderCreate: FC<CreateProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Order Create");

    const notify = useNotify();
    const redirect = useRedirect();
    const [mutate, { loading }] = useMutation();

    const [productOrderItems, setProductOrderItems] = useState([]);
    const [isLocationCreateModalOpen, setIsLocationCreateModalOpen] =
        useState(false);
    const [newlyAddedLocation, setNewlyAddedLocation] = useState("");
    const [formValues, setFormValues] = useState<any>({});

    const onSave = useCallback(
        async (values) => {
            orderOnSave({
                notify,
                redirect,
                mutate,
                values,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mutate]
    );

    return (
        <Create {...rest}>
            <SimpleForm
                save={onSave}
                submitOnEnter={false}
                toolbar={loading ? null : <Toolbar />}
            >
                <PopulateUserInfo />
                <Grid container spacing={1} style={{ width: "100%" }}>
                    {formValues.isUserChecked && (
                        <>
                            <Grid item md={2}>
                                <TextInput
                                    source="po_user_name"
                                    label="Name"
                                    variant="outlined"
                                    helperText={false}
                                    style={{ width: 256 }}
                                    validate={[required()]}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <Box mt={1}>
                                    <Typography>
                                        Total Order: {formValues.totalOrder}
                                    </Typography>
                                    <Typography>
                                        Total Delivered Order:{" "}
                                        {formValues.totalDeliveredOrder}
                                    </Typography>
                                </Box>
                            </Grid>
                        </>
                    )}
                    {formValues.userId && (
                        <Grid item md={6}>
                            <ReferenceInput
                                source="po_user_location_id"
                                label="Location"
                                variant="outlined"
                                helperText={false}
                                reference="v1/userLocations"
                                filter={{
                                    _orderBy: "ul_default",
                                    u_id: formValues.userId,
                                }}
                                fullWidth
                            >
                                <UserLocationAutocompleteInput
                                    matchSuggestion={() => true}
                                    optionValue="ul_id"
                                    helperText={false}
                                    newlyAddedLocation={newlyAddedLocation}
                                    resettable
                                />
                            </ReferenceInput>
                        </Grid>
                    )}
                    {formValues.userId && (
                        <Grid item md={2}>
                            <Button
                                label="Create New User Location"
                                variant="contained"
                                style={{
                                    marginTop: 12,
                                }}
                                onClick={() =>
                                    setIsLocationCreateModalOpen(true)
                                }
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={1} style={{ width: "100%" }}>
                    <Grid item sm={6} md={4}>
                        <WarehouseInput
                            source="po_warehouse_id"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <Form>
                            {({ form }) => (
                                <TaxonomiesByVocabularyInput
                                    fetchKey="shift_type"
                                    source="po_delivery_option"
                                    label="Delivery Option"
                                    helperText={false}
                                    initialValue="regular"
                                    excludeChoiceByMachineName={["general"]}
                                    onChange={() => {
                                        form.change("deliveryDate", null);
                                        form.change(
                                            "po_delivery_shift_schedule_id",
                                            null
                                        );
                                    }}
                                    fullWidth
                                />
                            )}
                        </Form>
                    </Grid>
                    {formValues.po_delivery_option === "express" && (
                        <Grid item sm={6} md={4}>
                            <Form>
                                {({ form }) => (
                                    <DateInput
                                        source="deliveryDate"
                                        label="Delivery Date"
                                        variant="outlined"
                                        helperText={false}
                                        inputProps={{
                                            min: toFormattedDateTime({
                                                isDate: true,
                                                dateString:
                                                    new Date().toString(),
                                            }),
                                        }}
                                        onChange={() =>
                                            form.change(
                                                "po_delivery_shift_schedule_id",
                                                null
                                            )
                                        }
                                        fullWidth
                                    />
                                )}
                            </Form>
                        </Grid>
                    )}
                    {formValues.deliveryDate && (
                        <Grid item sm={6} md={4}>
                            <ReferenceInput
                                source="po_delivery_shift_schedule_id"
                                label="Delivery Shift Schedule"
                                variant="outlined"
                                helperText={false}
                                reference="v1/shiftSchedule"
                                filter={{
                                    _shift_type: formValues.po_delivery_option,
                                    _from_date: formValues.deliveryDate,
                                    _to_date: formValues.deliveryDate,
                                }}
                                fullWidth
                            >
                                <AutocompleteInput
                                    matchSuggestion={() => true}
                                    optionText={(item) =>
                                        `${item?.s_title} (${item?.ss_date})`
                                    }
                                    resettable
                                />
                            </ReferenceInput>
                        </Grid>
                    )}
                    <Grid item sm={6} md={4}>
                        <SelectInput
                            source="po_payment_method"
                            label="Payment Method"
                            variant="outlined"
                            helperText={false}
                            initialValue="cod"
                            choices={[
                                { id: "online", name: "Online" },
                                { id: "cod", name: "Cash On Delivery" },
                            ]}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <SelectInput
                            source="po_subscription_frequency"
                            label="Subscription Frequency"
                            variant="outlined"
                            helperText={false}
                            choices={[{ id: "Monthly", name: "Monthly" }]}
                            allowEmpty
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <ReferenceInput
                            source="po_product_discount_id"
                            label="Coupon"
                            variant="outlined"
                            helperText={false}
                            reference="v1/productDiscount"
                            filter={{
                                _status: "active",
                                _discount_type: "coupon",
                            }}
                            allowEmpty
                            fullWidth
                        >
                            <AutocompleteInput
                                matchSuggestion={() => true}
                                optionText="pd_name"
                                resettable
                            />
                        </ReferenceInput>
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <TextInput
                            source="po_user_notes"
                            label="User Note"
                            variant="outlined"
                            helperText={false}
                            multiline
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <TextInput
                            source="po_internal_notes"
                            label="Internal Note"
                            variant="outlined"
                            helperText={false}
                            multiline
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <ProductEntryForm
                            productOrderItems={productOrderItems}
                            setProductOrderItems={setProductOrderItems}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <ProductTable
                            productOrderItems={productOrderItems}
                            setProductOrderItems={setProductOrderItems}
                        />
                    </Grid>
                    {formValues.po_user_mobile &&
                        formValues.po_user_location_id &&
                        formValues.po_payment_method && (
                            <Grid item sm={12}>
                                <Box mt={3} />
                                <OrderCalculate
                                    productOrderItems={productOrderItems}
                                />
                            </Grid>
                        )}
                </Grid>
                <AroggaMovableImageInput
                    source="attachedFiles_po_prescriptions"
                    label="Prescription Pictures"
                />
                <LocationCreateModal
                    open={isLocationCreateModalOpen}
                    onDialogClose={() => setIsLocationCreateModalOpen(false)}
                    userId={formValues.userId}
                    setNewlyAddedLocation={setNewlyAddedLocation}
                />
                <FormSpy
                    subscription={{ values: true }}
                    onChange={({ values }) =>
                        // Fix bad setState() call inside `FormSpy` error using setTimeout
                        setTimeout(() => {
                            setFormValues(values);
                        }, 0)
                    }
                />
            </SimpleForm>
        </Create>
    );
};

export default memo(OrderCreate);
