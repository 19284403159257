import { Box, Button } from "@material-ui/core";
import { FC, useState } from "react";
import {
    Confirm,
    Datagrid,
    FunctionField,
    List,
    ListProps,
    Record,
    ReferenceField,
    SimpleForm,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import AssignUser from "../../../components/manageAccounting/AssignUser";
import {
    useDocumentTitle,
    useExport,
    useNavigateFromList,
    useRequest,
} from "../../../hooks";
import AssetFilter from "./AssetFilter";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import { getColorByStatus } from "../../../utils/helpers";

const AssetList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Assets List");
    const classes = useAroggaStyles();
    const exporter = useExport(rest);
    const navigateFromList = useNavigateFromList("assetView", "assetEdit");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
    const [assetsId, setAssetsId] = useState(null);

    const { isLoading, refetch } = useRequest(
        `/v1/assetAction/${assetsId}/hrAssetReceivedAction`,
        {
            method: "POST",
        },
        {
            isRefresh: true,
            onSuccess: () => setIsDialogOpen(false),
        }
    );

    return (
        <>
            <List
                {...rest}
                title="List of Assets"
                perPage={25}
                filters={<AssetFilter children={""} />}
                sort={{ field: "a_id", order: "DESC" }}
                bulkActionButtons={permissions?.includes("assetDelete")}
                exporter={exporter}
            >
                <Datagrid rowClick={navigateFromList}>
                    <TextField source="a_id" label="ID" />
                    <TextField source="a_asset_name" label="Name" />
                    <FunctionField
                        label="Status"
                        sortBy="a_status"
                        render={(record) => {
                            const color = getColorByStatus(record.a_status);
                            return (
                                <span
                                    className={classes.statusBtn}
                                    style={{
                                        color: color,
                                        backgroundColor: color + "10",
                                    }}
                                >
                                    {record.a_status}
                                </span>
                            );
                        }}
                    />
                    <AroggaDateField source="a_created_at" label="Created At" />
                    <ReferenceField
                        source="a_created_by"
                        label="Created By"
                        reference="v1/users"
                        sortBy="a_created_by"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <FunctionField
                        label="Action"
                        onClick={(e) => e.stopPropagation()}
                        render={({ id, a_cap_ex_status }: Record) => {
                            return (
                                <Box display="flex" gridGap={5}>
                                    {a_cap_ex_status === "pending" && (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                setAssetsId(id);
                                                setIsDialogOpen(true);
                                            }}
                                        >
                                            Receive
                                        </Button>
                                    )}
                                    {a_cap_ex_status !== "pending" && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disableElevation
                                            onClick={() => {
                                                setAssetsId(id);
                                                setIsAssignDialogOpen(true);
                                            }}
                                        >
                                            Assign
                                        </Button>
                                    )}{" "}
                                </Box>
                            );
                        }}
                    />
                </Datagrid>
            </List>
            <Confirm
                isOpen={isDialogOpen}
                loading={isLoading}
                title={`Are you sure you want to receive #${assetsId}?`}
                content={null}
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
            {isAssignDialogOpen === true && (
                <SimpleForm toolbar={null}>
                    <AssignUser
                        url={`/v1/assetAction/${assetsId}/employeeAssetAssignAction`}
                        isDialogOpen={isAssignDialogOpen}
                        setIsDialogOpen={setIsAssignDialogOpen}
                    />
                </SimpleForm>
            )}
        </>
    );
};

export default AssetList;
