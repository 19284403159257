import { Chip, Typography } from "@material-ui/core";
import { FC } from "react";
import {
    Datagrid,
    FunctionField,
    Link,
    Pagination,
    Record,
    ReferenceField,
    ReferenceManyField,
    RichTextField,
    Show,
    ShowProps,
    SimpleShowLayout,
    Tab,
    TabbedShowLayout,
    TextField,
    useShowController,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import AroggaImageField from "../../../components/AroggaImageField";
import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle, useGetCurrentUser } from "../../../hooks";
import { isEmpty } from "../../../utils/helpers";
import ProductActions from "./ProductActions";

const ProductShow: FC<ShowProps> = ({ hasShow, ...rest }) => {
    useDocumentTitle("Arogga | Product Show");

    const { permissions } = useGetCurrentUser();
    const { record } = useShowController(rest);

    return (
        <Show {...rest} actions={<ProductActions />}>
            <TabbedShowLayout>
                <Tab label="Product Details">
                    <SimpleShowLayout>
                        {record?.isRevision && (
                            <Typography
                                variant="h5"
                                style={{ marginBottom: 10 }}
                            >
                                Revision Content
                            </Typography>
                        )}
                        <ColumnShowLayout md={2} simpleShowLayout={false}>
                            <TextField source="p_id" label="ID" />
                            <AroggaDateField
                                source="p_created_at"
                                label="Created At"
                            />
                            <ReferenceField
                                source="p_created_by"
                                label="Created By"
                                reference="v1/users"
                                link="show"
                            >
                                <TextField source="u_name" />
                            </ReferenceField>
                            <AroggaDateField
                                source="p_modified_at"
                                label="Modified At"
                            />
                            <ReferenceField
                                source="p_modified_by"
                                label="Modified By"
                                reference="v1/users"
                                link="show"
                            >
                                <TextField source="u_name" />
                            </ReferenceField>
                            <AroggaDateField
                                source="p_supervised_at"
                                label="Supervised At"
                            />
                            <ReferenceField
                                source="p_supervised_by"
                                label="Supervised By"
                                reference="v1/users"
                                link="show"
                            >
                                <TextField source="u_name" />
                            </ReferenceField>
                            <AroggaDateField
                                source="p_deleted_at"
                                label="Deleted At"
                            />
                            <ReferenceField
                                source="p_deleted_by"
                                label="Deleted By"
                                reference="v1/users"
                                link="show"
                            >
                                <TextField source="u_name" />
                            </ReferenceField>
                        </ColumnShowLayout>
                        <FunctionField
                            label="Tags"
                            render={({ p_product_tags }: Record) => {
                                if (isEmpty(p_product_tags)) return;

                                return p_product_tags.map((tag, i) => (
                                    <Chip
                                        key={i}
                                        label={tag}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        style={{
                                            marginRight: 6,
                                        }}
                                    />
                                ));
                            }}
                        />
                        <FunctionField
                            label="Internal Tags"
                            render={({ p_product_internal_tags }: Record) => {
                                if (isEmpty(p_product_internal_tags)) return;

                                return p_product_internal_tags.map((tag, i) => (
                                    <Chip
                                        key={i}
                                        label={tag}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        style={{
                                            marginRight: 6,
                                        }}
                                    />
                                ));
                            }}
                        />
                        <FunctionField
                            label="Keywords"
                            render={({ p_product_keywords }: Record) => {
                                if (isEmpty(p_product_keywords)) return;

                                return p_product_keywords.map((keyword, i) => (
                                    <Chip
                                        key={i}
                                        label={keyword}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        style={{
                                            marginRight: 6,
                                        }}
                                    />
                                ));
                            }}
                        />
                        <TextField
                            source="p_meta_description"
                            label="Meta Description"
                        />
                        <TextField source="p_yt_title" label="Youtube Title" />
                        <FunctionField
                            label="Youtube Video"
                            render={({ p_yt_key }: Record) => {
                                if (!p_yt_key) return null;

                                return (
                                    <iframe
                                        title={p_yt_key}
                                        src={`https://www.youtube.com/embed/${p_yt_key}`}
                                        width={640}
                                        height={360}
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                );
                            }}
                        />
                        <AroggaImageField
                            source="attachedFiles_p_images"
                            label="Attached Images"
                        />
                        <FunctionField
                            label="Description"
                            render={({
                                p_type,
                                p_generic_id,
                                p_description,
                            }: Record) => {
                                if (p_description)
                                    return (
                                        <RichTextField
                                            source="p_description"
                                            label="Description"
                                        />
                                    );

                                if (p_type === "medicine" && p_generic_id)
                                    return (
                                        <Link
                                            to={`/v1/generics/${p_generic_id}/show`}
                                            target="_blank"
                                        >
                                            To view description click here
                                        </Link>
                                    );

                                return null;
                            }}
                        />
                    </SimpleShowLayout>
                </Tab>
                {permissions?.includes("fileView") && (
                    <Tab label="Image History">
                        <ReferenceManyField
                            reference="v1/file"
                            target="ch_id"
                            pagination={<Pagination />}
                            filter={{
                                _entity_id: record?.id,
                            }}
                            addLabel={false}
                            sort={{ field: "ch_created_at", order: "DESC" }}
                        >
                            <Datagrid>
                                <TextField source="ch_id" label="Id" />
                                <TextField source="f_entity" label="Entity" />
                                <TextField
                                    source="f_entity_field"
                                    label="Entity Field"
                                />
                                <ReferenceField
                                    source="ch_created_by"
                                    label="Created By"
                                    reference="v1/users"
                                    link="show"
                                >
                                    <TextField source="u_name" />
                                </ReferenceField>
                                <AroggaDateField
                                    source="ch_created_at"
                                    label="Created At"
                                />
                            </Datagrid>
                        </ReferenceManyField>
                    </Tab>
                )}
            </TabbedShowLayout>
        </Show>
    );
};

export default ProductShow;
