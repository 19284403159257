import { Box } from "@material-ui/core";
import { useState } from "react";
import {
    ArrayField,
    Button,
    Datagrid,
    FunctionField,
    Labeled,
    Record,
    ReferenceField,
    SimpleForm,
    TextField,
} from "react-admin";

import { getQuantityLabel, getReadableSKU } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import IssueApprovalDialog from "./IssueApprovalDialog";

const IssueExpand = (props) => {
    const classes = useAroggaStyles();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [status, setStatus] = useState(null);

    const issueType = props?.record?.i_type;

    const renderQtyLabel = (source: string, record: Record) => (
        <span className={classes.whitespaceNowrap}>
            {getQuantityLabel({
                qty: record?.[source],
                salesUnit:
                    issueType === "replacement" && source === "ic_in_count"
                        ? record?.replacement_unit_label
                        : record?.unit_label,
                baseUnit:
                    issueType === "replacement" && source === "ic_in_count"
                        ? record?.product_replacement_base_unit
                        : record?.product_base_unit,
                salesUnitMultiplier:
                    issueType === "replacement" && source === "ic_in_count"
                        ? record?.replacement_unit_multiplier
                        : record?.unit_multiplier,
            })}
        </span>
    );

    const renderApprovalButtons = (issueStatus) => {
        if (issueStatus !== "processing") return;

        return (
            <Box display="flex" gridGap={5} mt={1}>
                <Button
                    label="Approve"
                    variant="contained"
                    onClick={() => handleApproval("approved")}
                />
                <Button
                    label="Reject"
                    variant="contained"
                    style={{ backgroundColor: "#dc3545" }}
                    onClick={() => handleApproval("rejected")}
                />
            </Box>
        );
    };

    const handleApproval = (status) => {
        setStatus(status);
        setIsDialogOpen(true);
    };

    return (
        <Labeled label="Details">
            <>
                <ArrayField source="ic">
                    <Datagrid>
                        <TextField source="ic_id" label="Details ID" />
                        <ReferenceField
                            source="ic_product_id"
                            label="Given Product"
                            reference="v1/product"
                            link="show"
                        >
                            <TextField source="p_name" />
                        </ReferenceField>
                        <ReferenceField
                            source="ic_variant_id"
                            label="Given Variant"
                            reference="v1/productVariant"
                            link={false}
                        >
                            <FunctionField
                                render={(record: Record) =>
                                    getReadableSKU(record.pv_attribute)
                                }
                            />
                        </ReferenceField>
                        {/* TODO: */}
                        {props?.record?.i_type === "damage_refund" ? (
                            <FunctionField
                                label="Given Qty"
                                render={(record: Record) => (
                                    <p>
                                        {record?.ic_out_count}
                                        <span>
                                            {" "}
                                            {record?.product_base_unit}
                                        </span>
                                    </p>
                                )}
                            />
                        ) : (
                            <FunctionField
                                source="ic_out_count"
                                label="Given Qty"
                                render={(record: Record) =>
                                    renderQtyLabel("ic_out_count", record)
                                }
                            />
                        )}
                        <ReferenceField
                            source="ic_in_product_id"
                            label="Taken Product"
                            reference="v1/product"
                            link="show"
                        >
                            <TextField source="p_name" />
                        </ReferenceField>
                        <ReferenceField
                            source="ic_in_variant_id"
                            label="Taken Variant"
                            reference="v1/productVariant"
                            link={false}
                        >
                            <FunctionField
                                render={(record: Record) =>
                                    getReadableSKU(record.pv_attribute)
                                }
                            />
                        </ReferenceField>
                        <FunctionField
                            source="ic_in_count"
                            label="Taken Qty"
                            render={(record: Record) =>
                                renderQtyLabel("ic_in_count", record)
                            }
                        />
                    </Datagrid>
                </ArrayField>
                <FunctionField
                    addLabel={false}
                    render={({ i_status }: Record) =>
                        renderApprovalButtons(i_status)
                    }
                />
                <SimpleForm toolbar={null}>
                    <IssueApprovalDialog
                        isDialogOpen={isDialogOpen}
                        setIsDialogOpen={setIsDialogOpen}
                        status={status}
                        {...props}
                    />
                </SimpleForm>
            </>
        </Labeled>
    );
};

export default IssueExpand;
