import { Box, Button, Paper, Typography } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import {
    AutocompleteInput,
    DateInput,
    ReferenceInput,
    SimpleForm,
    Title,
} from "react-admin";
import { FormSpy } from "react-final-form";
import { useReactToPrint } from "react-to-print";

import AroggaButton from "../../../components/AroggaButton";
import AroggaProgress from "../../../components/AroggaProgress";
import NoDataFound from "../../../components/NoDataFound";
import LedgerTable from "../../../components/manageAccounting/ledgers/LedgerTable";
import { useRequest, useXLSXDownloader } from "../../../hooks";
import {
    getFormattedDateString,
    toFixedNumber,
    toFormattedDateTime,
} from "../../../utils/helpers";

const LedgersPage = (props) => {
    const { onExportToXLSX } = useXLSXDownloader();

    const printableRef = useRef(null);
    const [formValues, setFormValues] = useState(null);
    const [headIdsData, setHeadIdsData] = useState({});

    const RENDER_REACT_WINDOW_LIMIT = 5000;
    const currentDate = new Date();
    const headIdFromProps = props.history.location?.state?.headId;
    const { accountingHeadId, startDate, endDate } = formValues || {};
    const initialStartDate = toFormattedDateTime({
        isDate: true,
        dateString: new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
        ).toString(),
    });
    const initialEndDate = toFormattedDateTime({
        isDate: true,
        dateString: new Date().toString(),
    });

    const { refetch: fetchAccountingHead } = useRequest(
        "",
        {},
        {
            onSuccess: ({ data }) => {
                const obj = {};
                data?.forEach((item) => (obj[item.ah_id] = item.ah_name));
                setHeadIdsData(obj);
            },
        }
    );

    const { data, isLoading, isSuccess, refetch, reset } = useRequest(
        "",
        {},
        {
            onSuccess: ({ data }) => {
                const headIds = [
                    ...new Set(
                        [...data?.debit_entries, ...data?.credit_entries]?.map(
                            (item) => item.account_head_id
                        )
                    ),
                ]
                    .filter((item) => item !== "opening_balance")
                    .join(",");

                fetchAccountingHead({
                    endpoint: `/v1/accountingHead?ids=${headIds}`,
                });
            },
        }
    );

    useEffect(() => {
        if (headIdFromProps) {
            refetch({
                endpoint: generateEndpoint({
                    id: headIdFromProps,
                    startDate: initialStartDate,
                    endDate: initialEndDate,
                }),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        isSuccess && reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountingHeadId, startDate, endDate]);

    const handleGo = () => {
        accountingHeadId &&
            startDate &&
            endDate &&
            refetch({
                endpoint: generateEndpoint({
                    id: accountingHeadId,
                    startDate,
                    endDate,
                }),
            });
    };

    let renderLength = 0;

    if (isSuccess) {
        renderLength = Math.max(
            data.credit_entries?.length || 0,
            data.debit_entries?.length || 0
        );
    }

    let filename;

    if (startDate && endDate && renderLength) {
        filename = `Ledger (${data?.head_info?.ah_name}) - ${startDate}_${endDate}`;
    }

    const handlePrint = useReactToPrint({
        content: () => printableRef.current,
        documentTitle: filename,
        pageStyle: `
                @page {
                margin: 3mm;
            }
        `,
    });

    const handleExport = () => onExportToXLSX(getExcelData(), filename);

    const getExcelData = () => {
        const excelData = [];

        Array.from({ length: renderLength }).forEach((_, index) => {
            excelData.push({
                "Dr ID": data?.debit_entries?.[index]?.transaction_id,
                "Dr Date": getFormattedDateString(
                    data?.debit_entries?.[index]?.date
                ),
                "Dr Particulars":
                    data?.debit_entries?.[index]?.account_head_id ===
                    "opening_balance"
                        ? "Opening Balance"
                        : headIdsData[
                              data?.debit_entries?.[index]?.account_head_id
                          ],
                "Dr Balance": data?.debit_entries?.[index]?.amount,
                "Cr ID": data?.credit_entries?.[index]?.transaction_id,
                "Cr Date": getFormattedDateString(
                    data?.credit_entries?.[index]?.date
                ),
                "Cr Particulars":
                    data?.credit_entries?.[index]?.account_head_id ===
                    "opening_balance"
                        ? "Opening Balance"
                        : headIdsData[
                              data?.credit_entries?.[index]?.account_head_id
                          ],
                "Cr Balance": data?.credit_entries?.[index]?.amount,
            });
        });

        const total = toFixedNumber(
            Math.max(data?.debit_sum || 0, data?.credit_sum || 0)
        );

        const difference = Math.abs(data?.debit_sum - data?.credit_sum);

        excelData.push({
            ...(data?.credit_sum < data?.debit_sum && {
                "Dr Date": getFormattedDateString(endDate),
            }),
            ...(data?.credit_sum < data?.debit_sum && {
                "Dr Particulars": "Balance C/D",
            }),
            ...(data?.credit_sum < data?.debit_sum && {
                "Dr Balance": difference,
            }),
            ...(data?.credit_sum > data?.debit_sum && {
                "Cr Date": getFormattedDateString(endDate),
            }),
            ...(data?.credit_sum > data?.debit_sum && {
                "Cr Particulars": "Balance C/D",
            }),
            ...(data?.credit_sum > data?.debit_sum && {
                "Cr Balance": difference,
            }),
        });

        excelData.push({
            "Dr Particulars": "Total",
            "Dr Balance": total,
            "Cr Particulars": "Total",
            "Cr Balance": total,
        });

        return excelData;
    };

    const generateEndpoint = ({ id, startDate, endDate }) => {
        return `/v1/accounts/ledger/${id}?_start_date=${startDate}&_end_date=${endDate}`;
    };

    return (
        <Paper style={{ marginTop: 25 }}>
            <Title title="Ledger View" />
            <Box position="relative" p={3}>
                <SimpleForm toolbar={null}>
                    <Box
                        display="flex"
                        position="absolute"
                        top={10}
                        left={24}
                        gridGap={8}
                        style={{ width: 600 }}
                    >
                        <ReferenceInput
                            source="accountingHeadId"
                            label="Select Ledger"
                            variant="outlined"
                            reference="v1/accountingHead"
                            defaultValue={headIdFromProps}
                            helperText={false}
                            fullWidth
                        >
                            <AutocompleteInput
                                optionValue="ah_id"
                                optionText="ah_name"
                                resettable
                            />
                        </ReferenceInput>
                        <DateInput
                            source="startDate"
                            label="Start Date"
                            variant="outlined"
                            initialValue={initialStartDate}
                            helperText={false}
                        />
                        <DateInput
                            source="endDate"
                            label="End Date"
                            variant="outlined"
                            initialValue={initialEndDate}
                            helperText={false}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleGo}
                            style={{
                                marginTop: 8,
                                height: 38,
                            }}
                            disabled={
                                isLoading ||
                                !accountingHeadId ||
                                !startDate ||
                                !endDate
                            }
                        >
                            Go
                        </Button>
                    </Box>
                    <FormSpy
                        subscription={{ values: true }}
                        onChange={({ values }) =>
                            // Fix bad setState() call inside `FormSpy` error using setTimeout
                            setTimeout(() => {
                                setFormValues(values);
                            }, 0)
                        }
                    />
                </SimpleForm>
                {!!renderLength && (
                    <Box
                        display="flex"
                        position="absolute"
                        top={22}
                        right={20}
                        gridGap={5}
                    >
                        {renderLength <= RENDER_REACT_WINDOW_LIMIT && (
                            <AroggaButton
                                type="success"
                                label="Print"
                                onClick={handlePrint}
                            />
                        )}
                        <AroggaButton
                            type="success"
                            label="Export"
                            onClick={handleExport}
                        />
                    </Box>
                )}
                {isLoading && (
                    <Box mt={20}>
                        <AroggaProgress />
                    </Box>
                )}
                {isSuccess && !renderLength && <NoDataFound />}
                {!!renderLength && (
                    <div ref={printableRef}>
                        <Box mt={2}>
                            <Typography variant="h5" align="center">
                                Ledger: {data?.head_info?.ah_name}
                            </Typography>
                            {data?.head_info?.ah_description && (
                                <Typography variant="h6" align="center">
                                    Description: {data.head_info.ah_description}
                                </Typography>
                            )}
                        </Box>
                        <LedgerTable
                            RENDER_REACT_WINDOW_LIMIT={
                                RENDER_REACT_WINDOW_LIMIT
                            }
                            renderLength={renderLength}
                            endDate={endDate}
                            data={data}
                            headIdsData={headIdsData}
                        />
                    </div>
                )}
            </Box>
        </Paper>
    );
};

export default LedgersPage;
