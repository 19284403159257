import {
    Button,
    Grid,
    Checkbox,
    FormControlLabel,
    makeStyles,
} from "@material-ui/core";
import { FC, useCallback, useEffect, useState } from "react";
import {
    ArrayInput,
    Create,
    CreateProps,
    FormDataConsumer,
    ImageField,
    ImageInput,
    NumberInput,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    useMutation,
    useNotify,
    useRedirect,
} from "react-admin";
import Typography from "@material-ui/core/Typography";

import { useDocumentTitle, useRequest } from "../../../hooks";

const LabTestPckgCreate: FC<CreateProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Lab Test| Create");
    const classes = useStyles();
    const redirect = useRedirect();
    const notify = useNotify();
    const [mutate] = useMutation();
    const { data } = useRequest(
        "/misc/api/v1/admin/lab-items/sample-requirements",
        {},
        { isBaseUrl: true, isPreFetching: true, isSuccessNotify: false }
    );
    const { data: categoryIds } = useRequest(
        "/misc/api/v1/admin/category/?page=1&limit=100",
        {},
        { isBaseUrl: true, isPreFetching: true, isSuccessNotify: false }
    );
    const save = useCallback(
        async (...rest: any) => {
            try {
                await mutate({
                    type: "create",
                    resource: "misc/api/v1/lab-items",
                    payload: { data: JSON.stringify(rest) },
                });
                notify("Successfully lab test created!", { type: "success" });
                redirect("/misc/api/v1/admin/lab-items");
            } catch (err: any) {
                notify(err.message || "Failed!", { type: "warning" });
            }
        },
        [mutate, notify, redirect]
    );
    const [translate, setTranslate] = useState("");
    const [testNametranslate, setTestNameTranslate] = useState("");
    const [testDescriptionTanslate, setDescriptionTranslate] = useState("");
    const [recordImagesPayload, setRecordImagesPayload] = useState([]);
    const [testRecordImagesPayload, setTestRecordImagesPayload] = useState([]);
    const [testDescription, setDescription] = useState([]);
    const [selectedItemType, setSelectedItemType] = useState("");
    const handleItemTypeChange = (event) => {
        setSelectedItemType(event.target.value);
    };
    const [basePrice, setBasePrice] = useState("");
    useEffect(() => {
        //@ts-ignore
        setBasePrice(selectedItemType === "package" ? 0 : null);
    }, [selectedItemType]);
    const [materialCost, setMaterialCost] = useState("");
    const [margin, setMargin] = useState("");
    const [total, setTotal] = useState<number | null>(null);

    const handleCalculateTotal = () => {
        //@ts-ignore
        const basePriceValue = parseFloat(basePrice) || 0;
        const materialCostValue = parseFloat(materialCost) || 0;
        const marginValue = parseFloat(margin) || 0;

        const calculatedTotal =
            basePriceValue + materialCostValue + marginValue;
        setTotal(calculatedTotal);
    };
    const handleChange = (event: any) => {
        setTranslate(event.target.value as string);
    };

    const handleChange2 = (event: any) => {
        setTestNameTranslate(event.target.value as string);
    };
    const handleChange3 = (event: any) => {
        setDescriptionTranslate(event.target.value as string);
    };
    const { refetch: toGoogleTranslate } = useRequest(
        `/translator/api/v1/translate`,
        {
            method: "POST",
            body: {
                text: translate,
            },
        },
        {
            onSuccess: (response) => {
                setRecordImagesPayload(response.data);
            },
        }
    );
    const { refetch: nameTranslate } = useRequest(
        `/translator/api/v1/translate`,
        {
            method: "POST",
            body: {
                text: testNametranslate,
            },
        },
        {
            onSuccess: (response) => {
                setTestRecordImagesPayload(response.data);
            },
        }
    );
    const { refetch: descriptionTranslate } = useRequest(
        `/translator/api/v1/translate`,
        {
            method: "POST",
            body: {
                text: testDescriptionTanslate,
            },
        },
        {
            onSuccess: (response) => {
                setDescription(response.data);
            },
        }
    );
    // @ts-ignore
    const bangla = recordImagesPayload?.translatedText;
    // @ts-ignore
    const bangla2 = testRecordImagesPayload?.translatedText;
    // @ts-ignore
    const bangla3 = testDescription?.translatedText;

    const [selectAll, setSelectAll] = useState(false);

    const categoryChoices =
        categoryIds?.map((category: { tag: any; name: { en: any } }) => ({
            id: category.tag,
            name: category.name.en,
        })) || [];

    const choices = selectAll ? categoryChoices.map((choice) => choice.id) : [];

    const handleSelectAllChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSelectAll(event.target.checked);
    };

    return (
        <Create {...rest}>
            <SimpleForm
                transform={save}
                redirect="list"
                // sanitizeEmptyValues={false}
            >
                <Grid container spacing={1} style={{ width: "100%" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <SelectInput
                                variant="outlined"
                                fullWidth
                                source="itemType"
                                choices={[
                                    { id: "test", name: "Test" },
                                    { id: "package", name: "Package" },
                                ]}
                                onChange={handleItemTypeChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput
                                variant="outlined"
                                fullWidth
                                source="targetGender"
                                choices={[
                                    { id: "male", name: "Male" },
                                    { id: "female", name: "Female" },
                                    { id: "male_female", name: "Both" },
                                ]}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextInput
                                source="name[en]"
                                value={testRecordImagesPayload}
                                onChange={handleChange2}
                                variant="outlined"
                                label={
                                    selectedItemType === "package"
                                        ? "Package Name (EN)"
                                        : "Test Name (EN)"
                                }
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <div style={{ marginLeft: 30, marginTop: 10 }}>
                                <Button
                                    className={classes.translateBtn}
                                    variant="contained"
                                    onClick={nameTranslate}
                                    disableElevation
                                >
                                    Translate
                                </Button>
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <TextInput
                                source="name[bn]"
                                initialValue={bangla2}
                                variant="outlined"
                                label={
                                    selectedItemType === "package"
                                        ? "Package Name (BN)"
                                        : "Test Name (BN)"
                                }
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput
                                source="subTitle[en]"
                                value={translate}
                                onChange={handleChange}
                                variant="outlined"
                                label={
                                    selectedItemType === "package"
                                        ? "Package Sub Title (EN)"
                                        : "Test Sub Title (EN)"
                                }
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <div style={{ marginLeft: 30, marginTop: 10 }}>
                                <Button
                                    className={classes.translateBtn}
                                    variant="contained"
                                    onClick={toGoogleTranslate}
                                    disableElevation
                                >
                                    Translate
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                source="subTitle[bn]"
                                initialValue={bangla}
                                variant="outlined"
                                label={
                                    selectedItemType === "package"
                                        ? "Package Sub Title (BN)"
                                        : "Test Sub Title (BN)"
                                }
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput
                                source="description[en]"
                                value={testDescriptionTanslate}
                                onChange={handleChange3}
                                variant="outlined"
                                label={
                                    selectedItemType === "package"
                                        ? "Package Description (EN)"
                                        : "Test Description (EN)"
                                }
                                minRows={3}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <div className={classes.translateDiv}>
                                <Button
                                    className={classes.translateBtn}
                                    variant="contained"
                                    onClick={descriptionTranslate}
                                    disableElevation
                                >
                                    Translate
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                source="description[bn]"
                                initialValue={bangla3}
                                variant="outlined"
                                label={
                                    selectedItemType === "package"
                                        ? "Package Description (BN)"
                                        : "Test Description (BN)"
                                }
                                minRows={3}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <NumberInput
                                    source="targetAge.start"
                                    variant="outlined"
                                    fullWidth
                                    label="Target Age Start"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <NumberInput
                                    source="targetAge.end"
                                    variant="outlined"
                                    fullWidth
                                    label="Target Age End"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <NumberInput
                                source="basePrice"
                                variant="outlined"
                                label="Base Price"
                                initialValue={
                                    selectedItemType === "package" ? 0 : null
                                }
                                disabled={selectedItemType === "package"}
                                fullWidth
                                onChange={(e) => setBasePrice(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput
                                source="materialCost"
                                variant="outlined"
                                label="Material Cost"
                                initialValue={
                                    selectedItemType === "package" ? 0 : null
                                }
                                disabled={selectedItemType === "package"}
                                fullWidth
                                onChange={(e) =>
                                    setMaterialCost(e.target.value)
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput
                                source="margin"
                                variant="outlined"
                                label="Margin"
                                initialValue={
                                    selectedItemType === "package" ? 0 : null
                                }
                                disabled={selectedItemType === "package"}
                                fullWidth
                                onChange={(e) => setMargin(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <div style={{ display: "flex" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCalculateTotal}
                                    style={{ marginTop: 10 }}
                                >
                                    Calculate Total
                                </Button>
                                <Typography
                                    variant="h6"
                                    component="h2"
                                    style={{ marginTop: 15, marginLeft: 20 }}
                                >
                                    Total Amount={" "}
                                    {total !== null ? total : "0.00"}
                                </Typography>
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <TextInput
                                source="externalMaterialCost"
                                variant="outlined"
                                label="External Material Cost"
                                initialValue={
                                    selectedItemType === "package" ? 0 : null
                                }
                                disabled={selectedItemType === "package"}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput
                                source="discountPrice"
                                fullWidth
                                label="Discount Price"
                                initialValue={
                                    selectedItemType === "package" ? 0 : null
                                }
                                disabled={selectedItemType === "package"}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <NumberInput
                                source="reportAvailableHour"
                                variant="outlined"
                                fullWidth
                                label="Report Available Hour"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectAll}
                                        onChange={handleSelectAllChange}
                                    />
                                }
                                label="Select All"
                            />
                            <SelectArrayInput
                                source="tags"
                                label="Tags"
                                variant="outlined"
                                helperText={false}
                                choices={categoryChoices}
                                fullWidth
                                initialValue={choices}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                source="fastingRequired"
                                variant="outlined"
                                label="Fasting Required (Yes/ No/ (as like 10 -12 Hrs))"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ArrayInput source="knownAs">
                                <SimpleFormIterator>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData }) => (
                                            <>
                                                <TextInput
                                                    source={getSource("en")}
                                                    record={scopedFormData}
                                                    variant="outlined"
                                                    label="knownAs (EN)"
                                                    fullWidth
                                                />
                                                <TextInput
                                                    source={getSource("bn")}
                                                    variant="outlined"
                                                    record={scopedFormData}
                                                    label="knownAs (BN)"
                                                    fullWidth
                                                />
                                            </>
                                        )}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Grid>
                        {selectedItemType !== "package" && (
                            <>
                                {/* <Grid item xs={6}>
                                    <ArrayInput source="testRequirements">
                                        <SimpleFormIterator>
                                            <FormDataConsumer>
                                                {({ getSource }) => (
                                                    <>
                                                        <TextInput
                                                            source={getSource(
                                                                "en"
                                                            )}
                                                            variant="outlined"
                                                            label="Test Requirements (EN)"
                                                            fullWidth
                                                        />
                                                        <TextInput
                                                            source={getSource(
                                                                "bn"
                                                            )}
                                                            variant="outlined"
                                                            label="Test Requirements (BN)"
                                                            fullWidth
                                                        />
                                                    </>
                                                )}
                                            </FormDataConsumer>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Grid> */}
                                <Grid item xs={6}>
                                    <ArrayInput source="sampleRequirements">
                                        <SimpleFormIterator>
                                            <FormDataConsumer>
                                                {({
                                                    getSource,
                                                    scopedFormData,
                                                }) => (
                                                    <>
                                                        <SelectInput
                                                            variant="outlined"
                                                            label="sampleRequirements (EN)"
                                                            fullWidth
                                                            record={
                                                                scopedFormData
                                                            }
                                                            source={getSource(
                                                                "en"
                                                            )}
                                                            choices={data?.map(
                                                                (requirement: {
                                                                    en: any;
                                                                }) => ({
                                                                    id: requirement.en,
                                                                    name: requirement.en,
                                                                })
                                                            )}
                                                        />
                                                        <SelectInput
                                                            variant="outlined"
                                                            label="sampleRequirements (BN)"
                                                            fullWidth
                                                            record={
                                                                scopedFormData
                                                            }
                                                            source={getSource(
                                                                "bn"
                                                            )}
                                                            choices={data?.map(
                                                                (requirement: {
                                                                    bn: any;
                                                                }) => ({
                                                                    id: requirement.bn,
                                                                    name: requirement.bn,
                                                                })
                                                            )}
                                                        />
                                                    </>
                                                )}
                                            </FormDataConsumer>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Grid>
                            </>
                        )}
                        {selectedItemType === "package" && (
                            <Grid item xs={12}>
                                <ImageInput
                                    source="attachedFiles-homeIcon"
                                    label="Home Icon (120*120 px)"
                                    accept="image/*"
                                    maxSize={10000000}
                                >
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <ImageInput
                                source="attachedFiles-bannerImage"
                                label="Product Image( APP 1080*1080 px )"
                                accept="image/*"
                                maxSize={10000000}
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </Grid>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};
const useStyles = makeStyles(() => ({
    translateDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 30,
    },
    translateBtn: {
        width: 180,
        height: 40,
    },
}));

export default LabTestPckgCreate;
