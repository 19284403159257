import { FC } from "react";
import {
    BooleanField,
    ImageField,
    List,
    ListProps,
    ReferenceField,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import {
    useDocumentTitle,
    useExport,
    useNavigateFromList,
} from "../../../hooks";
import { CustomizableDatagrid } from "../../../lib";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import NotificationFilter from "./NotificationFilter";

const NotificationList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Notification List");

    const exporter = useExport(rest);
    const classes = useAroggaStyles();
    const navigateFromList = useNavigateFromList(
        "notificationView",
        "notificationEdit"
    );

    return (
        <List
            {...rest}
            title="List of Notification"
            filters={<NotificationFilter children={""} />}
            perPage={25}
            sort={{ field: "n_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={permissions?.includes("notificationDelete")}
        >
            <CustomizableDatagrid
                rowClick={navigateFromList}
                hideableColumns={[
                    "n_button_link",
                    "n_created_at",
                    "n_created_by",
                ]}
            >
                <TextField source="n_id" label="ID" />
                <ReferenceField
                    source="n_true_user"
                    label="User"
                    reference="v1/users"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
                <TextField source="n_title" label="Title" />
                <TextField
                    source="n_type"
                    label="Type"
                    className={classes.capitalize}
                />
                <TextField source="n_button_title" label="Button Title" />
                <TextField source="n_button_link" label="Button Link" />
                <TextField
                    source="n_status"
                    label="Status"
                    className={classes.capitalize}
                />
                <BooleanField
                    source="n_is_read"
                    label="Read?"
                    FalseIcon={() => null}
                    looseValue
                />
                <BooleanField
                    source="n_is_public"
                    label="Public?"
                    FalseIcon={() => null}
                    looseValue
                />
                <BooleanField
                    source="n_is_active"
                    label="Active?"
                    FalseIcon={() => null}
                    looseValue
                />
                <ImageField
                    source="attachedFiles_n_images"
                    label="Attached Images"
                    className="small__img"
                    src="src"
                    title="title"
                />
                <AroggaDateField source="n_created_at" label="Created At" />
                <ReferenceField
                    source="n_created_by"
                    label="Created By"
                    reference="v1/users"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>
    );
};

export default NotificationList;
