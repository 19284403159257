const ExpandIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={48}
        height={48}
        viewBox="0 0 48 48"
        fill="none"
        {...props}
    >
        <circle
            cx={24}
            cy={24}
            r={24}
            transform="rotate(90 24 24)"
            fill="#1DA099"
        />
        <mask
            id="mask0_895_4891"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x={11}
            y={12}
            width={25}
            height={24}
        >
            <rect
                x="11.0039"
                y={36}
                width={24}
                height={24}
                transform="rotate(-90 11.0039 36)"
                fill="#D9D9D9"
            />
        </mask>
        <g mask="url(#mask0_895_4891)">
            <path
                d="M23.0047 25.2L26.9047 21.3C27.088 21.1167 27.3214 21.025 27.6047 21.025C27.888 21.025 28.1214 21.1167 28.3047 21.3C28.488 21.4833 28.5797 21.7167 28.5797 22C28.5797 22.2833 28.488 22.5167 28.3047 22.7L23.7047 27.3C23.6047 27.4 23.4964 27.4708 23.3797 27.5125C23.263 27.5542 23.138 27.575 23.0047 27.575C22.8714 27.575 22.7464 27.5542 22.6297 27.5125C22.513 27.4708 22.4047 27.4 22.3047 27.3L17.7047 22.7C17.5214 22.5167 17.4297 22.2833 17.4297 22C17.4297 21.7167 17.5214 21.4833 17.7047 21.3C17.888 21.1167 18.1214 21.025 18.4047 21.025C18.688 21.025 18.9214 21.1167 19.1047 21.3L23.0047 25.2Z"
                fill="white"
            />
        </g>
    </svg>
);
export default ExpandIcon;
