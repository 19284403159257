import { FC } from "react";
import { List, ListProps, ReferenceField, TextField } from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import { useDocumentTitle, useExport } from "../../../hooks";
import { CustomizableDatagrid } from "../../../lib";
import { toFormattedDateTime } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import TransactionFilter from "./TransactionFilter";

const TransactionList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Transaction List");

    const exporter = useExport(rest);
    const classes = useAroggaStyles();

    return (
        <List
            {...rest}
            title="List of Transaction"
            perPage={25}
            filters={<TransactionFilter children={""} />}
            filterDefaultValues={{
                _date: toFormattedDateTime({
                    isDate: true,
                    dateString: new Date().toString(),
                }),
            }}
            sort={{ field: "at_id", order: "DESC" }}
            bulkActionButtons={false}
            exporter={exporter}
        >
            <CustomizableDatagrid
                rowClick="edit"
                hideableColumns={["at_created_at", "at_created_by"]}
            >
                <TextField source="at_id" label="ID" />
                <TextField
                    source="at_type"
                    label="Type"
                    className={classes.capitalize}
                />
                <AroggaDateField source="at_date" label="Date" />
                <TextField source="at_narration" label="Narration" />
                <AroggaDateField source="at_created_at" label="Created At" />
                <ReferenceField
                    source="at_created_by"
                    label="Created By"
                    reference="v1/users"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>
    );
};

export default TransactionList;
