import { Grid } from "@material-ui/core";
import {
    AutocompleteInput,
    SelectInput,
    TextInput,
    required,
    maxLength,
    minLength,
    ReferenceInput,
    email,
    NumberInput,
    minValue,
} from "react-admin";
import { useFormState, useForm } from "react-final-form";

const validateBankId = (value, allValues) => {
    if (allValues.s_payment_method === "bank" && (!value || value === 0)) {
        return "Bank ID is required when payment method is bank";
    }
    return undefined;
};
const SupplierForm = () => {
    const { values } = useFormState();
    const form = useForm();

    return (
        <Grid container spacing={1}>
            {!!values.s_id && (
                <Grid item sm={6} md={3}>
                    <TextInput
                        source="s_id"
                        label="ID"
                        variant="outlined"
                        helperText={false}
                        fullWidth
                        disabled
                    />
                </Grid>
            )}
            <Grid item sm={6} md={3}>
                <TextInput
                    source="s_name"
                    label="Name"
                    variant="outlined"
                    helperText={false}
                    validate={[
                        required(),
                        minLength(2, "Name can't be negative or less than 2"),
                        maxLength(100, "Name can't be greater than 100"),
                    ]}
                    fullWidth
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <TextInput
                    source="s_email"
                    label="Email"
                    variant="outlined"
                    helperText={false}
                    validate={[email("Invalid email address")]}
                    fullWidth
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <TextInput
                    source="s_mobile"
                    label="Phone"
                    variant="outlined"
                    helperText={false}
                    validate={[required()]}
                    fullWidth
                    initialValue="+88"
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <TextInput
                    source="s_address"
                    label="Address"
                    variant="outlined"
                    helperText={false}
                    validate={[
                        minLength(
                            5,
                            "Address can't be negative or less than 5"
                        ),
                        maxLength(
                            100,
                            "Address count can't be greater than 1000"
                        ),
                    ]}
                    fullWidth
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <TextInput
                    source="s_account_number"
                    label="Account Number"
                    variant="outlined"
                    helperText={false}
                    validate={[
                        validateBankId,
                        minLength(
                            5,
                            "Account Number count can't be negative or less than 5"
                        ),
                        maxLength(
                            50,
                            "Account Number can't be greater than 50"
                        ),
                    ]}
                    fullWidth
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <TextInput
                    source="s_tin"
                    label="Tin"
                    variant="outlined"
                    helperText={false}
                    validate={[
                        minLength(
                            5,
                            "Tin Number count can't be negative or less than 5"
                        ),
                        maxLength(100, "Tin Number can't be greater than 100"),
                    ]}
                    fullWidth
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <TextInput
                    source="s_bin"
                    label="Bin"
                    variant="outlined"
                    helperText={false}
                    validate={[
                        minLength(
                            5,
                            "Bin Number count can't be negative or less than 5"
                        ),
                        maxLength(100, "Bin Number can't be greater than 100"),
                    ]}
                    fullWidth
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <TextInput
                    source="s_payment_terms"
                    label="Payment Terms"
                    variant="outlined"
                    helperText={false}
                    validate={[
                        minLength(
                            5,
                            "Payment Terms can't be negative or less than 5"
                        ),
                        maxLength(
                            100,
                            "Payment Terms can't be greater than 100"
                        ),
                    ]}
                    fullWidth
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <TextInput
                    source="s_payment_term_condition"
                    label="Payment Terms & Conditions"
                    variant="outlined"
                    helperText={false}
                    validate={[
                        minLength(
                            5,
                            "Payment Terms & Conditions can't be negative or less than 5"
                        ),
                        maxLength(
                            100,
                            "Payment Terms & Conditions can't be greater than 100"
                        ),
                    ]}
                    fullWidth
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <SelectInput
                    source="s_payment_method"
                    label="Payment Method"
                    variant="outlined"
                    helperText={false}
                    choices={[
                        { id: "bank", name: "Bank" },
                        {
                            id: "cash",
                            name: "Cash",
                        },
                    ]}
                    fullWidth
                    onChange={() => form.change("s_bank_id", undefined)}
                />
            </Grid>
            {values.s_payment_method === "bank" && (
                <Grid item sm={6} md={3}>
                    <ReferenceInput
                        source="s_bank_id"
                        label="Bank"
                        variant="outlined"
                        filter={{
                            _active: 1,
                        }}
                        helperText={false}
                        reference="v1/bank"
                        fullWidth
                        validate={[required(), validateBankId]}
                    >
                        <AutocompleteInput
                            matchSuggestion={() => true}
                            optionText={(record) =>
                                `${record?.b_name} (${record?.b_branch})`
                            }
                            options={{
                                InputProps: {
                                    multiline: true,
                                },
                            }}
                            resettable
                        />
                    </ReferenceInput>
                </Grid>
            )}
            <Grid item sm={6} md={3}>
                <NumberInput
                    source="s_weight"
                    label="Weight"
                    variant="outlined"
                    helperText={false}
                    validate={[minValue(0, "Negative number not allowed")]}
                    min={0}
                    fullWidth
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <SelectInput
                    source="s_status"
                    label="Status"
                    variant="outlined"
                    helperText={false}
                    choices={[
                        { id: "active", name: "Active" },
                        {
                            id: "inactive",
                            name: "Inactive",
                        },
                    ]}
                    fullWidth
                />
            </Grid>
        </Grid>
    );
};

export default SupplierForm;
