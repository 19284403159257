const RegularPriceIcon = (props) => (
    <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect
            width="59"
            height="59"
            rx="29.5"
            fill="#1DA099"
            fillOpacity="0.1"
        />
        <path
            d="M41.167 17.8333H17.8337C16.2149 17.8333 14.9316 19.1312 14.9316 20.75L14.917 38.25C14.917 39.8687 16.2149 41.1667 17.8337 41.1667H41.167C42.7857 41.1667 44.0837 39.8687 44.0837 38.25V20.75C44.0837 19.1312 42.7857 17.8333 41.167 17.8333ZM29.5003 26.5833H23.667V28.0417H28.042C28.8441 28.0417 29.5003 28.6979 29.5003 29.5V33.875C29.5003 34.6771 28.8441 35.3333 28.042 35.3333H26.5837V36.7917H23.667V35.3333H20.7503V32.4167H26.5837V30.9583H22.2087C21.4066 30.9583 20.7503 30.3021 20.7503 29.5V25.125C20.7503 24.3229 21.4066 23.6667 22.2087 23.6667H23.667V22.2083H26.5837V23.6667H29.5003V26.5833ZM35.3337 35.6979L32.417 32.7812H38.2503L35.3337 35.6979ZM32.417 26.5833L35.3337 23.6667L38.2503 26.5833H32.417Z"
            fill="#1DA099"
        />
    </svg>
);
export default RegularPriceIcon;
