import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    makeStyles,
} from "@material-ui/core";

import { useEffect, useState } from "react";
import {
    AutocompleteInput,
    Confirm,
    DateInput,
    FileField,
    FileInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    maxLength,
    minValue,
    required,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import { IMAGE_FILE_MAX_SIZE } from "../../../utils/constants";
import InlineArrayInput from "../../InlineArrayInput";
import EditIcon from "../../icons/EditIcon";
import ReqSupplierForm from "./ReqSupplierForm";

const validateMaxFiles = (files) => {
    if (files && files.length > 5) {
        return "You can only upload a maximum of 5 files.";
    }
    return undefined;
};
const QuotationForm = ({ id }) => {
    const classes = useStyles();
    const form = useForm();
    const { values, hasValidationErrors } = useFormState();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isQuotationDialogOpen, setIsQuotationDialogOpen] = useState(false);
    const [quotationId, setQuotationId] = useState(null);
    const [purchaseItemName, setPurchaseItemName] = useState("");
    const [quotationItemTrack, setQuotationItemTrack] = useState("");
    const [supplierItemTrack, setSupplierItemTrack] = useState("");
    const [selectedUser, setSelectedUser] = useState<any>({});

    const [selectedUserInfo, setSelectedUserInfo] = useState<any>({});
    const { data } = useRequest(
        `/v1/supplier?ids=${values?.q_supplier_id}`,
        {},
        {
            isPreFetching: true,
            isWarningNotify: false,
            onSuccess: ({ data }) => {
                setSelectedUser(data);
            },
            refreshDeps: [values?.q_supplier_id],
        }
    );
    const { isLoading, refetch } = useRequest(
        `/v1/quotationItemMapping`,
        {
            method: "POST",
            body: {
                qim_name: purchaseItemName,
                qim_head_id: values.qim_head_id,
            },
        },

        {
            onSuccess: ({ data }) => {
                setIsDialogOpen(false);
                form.change(quotationItemTrack, data?.qim_id);
                form.change("qim_name", undefined);
                form.change("qim_head_id", undefined);
            },
        }
    );
    useEffect(() => {
        if (!values.qi || values.qi.length === 0) {
            form.change("qi", [{}]);
        }
    }, [values.qi, form, data, values?.q_supplier_id]);
    const handleSupplierChange = (user) => {
        setSelectedUserInfo(user);
        form.change("q_payment_method", undefined);
        selectedUser[0] = "";
    };

    return (
        <>
            {" "}
            <Accordion expanded style={{ marginTop: 10 }}>
                <AccordionSummary
                    expandIcon={false}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.label}
                >
                    Quotation
                </AccordionSummary>
                <AccordionDetails>
                    {" "}
                    <Grid container spacing={1}>
                        <Grid item sm={6} md={1}>
                            <TextInput
                                source="q_pr_id"
                                variant="outlined"
                                label="ID"
                                disabled
                                initialValue={id}
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={6} md={2}>
                            <Box display={"flex"}>
                                <ReferenceInput
                                    source="q_supplier_id"
                                    label="Supplier"
                                    reference="v1/supplier"
                                    variant="outlined"
                                    filter={{
                                        _status: "active",
                                    }}
                                    validate={[required()]}
                                    fullWidth
                                >
                                    <AutocompleteInput
                                        optionText={(record) =>
                                            `${record?.s_name}${record?.s_mobile}`
                                        }
                                        matchSuggestion={() => true}
                                        helperText={false}
                                        onSelect={handleSupplierChange}
                                        resettable
                                    />
                                </ReferenceInput>
                                <Button
                                    variant="outlined"
                                    className={classes.editButton}
                                    size="small"
                                    onClick={() => {
                                        setQuotationId(id);
                                        setIsQuotationDialogOpen(true);
                                        setSupplierItemTrack("q_supplier_id");
                                    }}
                                >
                                    <EditIcon />
                                </Button>
                            </Box>
                        </Grid>

                        {selectedUser?.[0]?.s_payment_method === "bank" ||
                        values?.s_payment_method === "bank" ? (
                            <Grid item sm={6} md={2}>
                                <SelectInput
                                    source="q_payment_method"
                                    label="Payment Method"
                                    variant="outlined"
                                    choices={[
                                        {
                                            id: "bank",
                                            name: "Bank",
                                        },
                                        { id: "cash", name: "Cash" },
                                    ]}
                                    fullWidth
                                    validate={[required()]}
                                />
                            </Grid>
                        ) : (
                            <Grid item sm={6} md={2}>
                                <SelectInput
                                    source="q_payment_method"
                                    label="Payment Method"
                                    variant="outlined"
                                    choices={[{ id: "cash", name: "Cash" }]}
                                    fullWidth
                                    validate={[required()]}
                                    disabled={!values.q_supplier_id}
                                />
                            </Grid>
                        )}
                        <Grid item sm={6} md={2}>
                            <SelectInput
                                variant="outlined"
                                source="q_item_type"
                                label="Type"
                                choices={[
                                    { id: "cap_ex", name: "Cap Ex" },
                                    { id: "op_ex", name: "Op Ex" },
                                ]}
                                fullWidth
                                validate={[required()]}
                            />
                        </Grid>
                        <Grid item sm={6} md={2}>
                            <SelectInput
                                source="q_payment_terms"
                                label="Payment Terms"
                                variant="outlined"
                                choices={[
                                    {
                                        id: "instant",
                                        name: "Instant",
                                    },
                                    { id: "15 days", name: "15 days" },
                                    { id: "30 days", name: "30 days" },
                                    { id: "45 days", name: "45 days" },
                                    { id: "90 days", name: "90 days" },
                                ]}
                                fullWidth
                                validate={[required()]}
                            />
                        </Grid>
                        <Grid item sm={6} md={2}>
                            <NumberInput
                                source="q_advance_pay"
                                variant="outlined"
                                label="Advance Total"
                            />
                        </Grid>
                        <Grid item sm={6} md={2}>
                            {" "}
                            <DateInput
                                source="q_delivery_date"
                                variant="outlined"
                                label="Delivery Date"
                                fullWidth
                            />
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={6} md={6}>
                                <TextInput
                                    source="q_payment_term_cond"
                                    variant="outlined"
                                    label="Terms and Conditions"
                                    fullWidth
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            <Grid item sm={6} md={6}>
                                {" "}
                                <TextInput
                                    source="q_description"
                                    variant="outlined"
                                    label="Description"
                                    fullWidth
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                        </Grid>

                        <FileInput
                            source="attachedFiles_q_attachment"
                            label="Attached File"
                            accept="image/*, application/pdf"
                            maxSize={IMAGE_FILE_MAX_SIZE}
                            multiple
                            helperText={false}
                            validate={validateMaxFiles}
                        >
                            <FileField source="src" title="title" />
                        </FileInput>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded style={{ marginTop: 10 }}>
                <AccordionSummary
                    expandIcon={false}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className={classes.label}
                >
                    Quotation Item
                </AccordionSummary>
                <AccordionDetails>
                    {" "}
                    <InlineArrayInput
                        source="qi"
                        label=""
                        enableRenderProps
                        disableAdd={values?.qi?.length === 1}
                        disableRemove
                    >
                        {({ getSource, scopedFormData }) => {
                            const total =
                                scopedFormData?.qi_quantity *
                                scopedFormData?.qi_unit_price;
                            const grandTotal2 =
                                total + (scopedFormData?.qi_vat || 0);
                            const grandTotalDiscount =
                                grandTotal2 -
                                (scopedFormData?.qi_discount || 0);

                            if (
                                scopedFormData?.qi_quantity &&
                                scopedFormData?.qi_unit_price
                            ) {
                                scopedFormData.qi_total_price =
                                    total.toFixed(2);
                            }

                            if (scopedFormData?.qi_discount !== undefined) {
                                scopedFormData.qi_grand_total =
                                    grandTotalDiscount.toFixed(2);
                            } else if (
                                scopedFormData?.qi_discount === 0 ||
                                scopedFormData?.qi_discount === undefined
                            ) {
                                scopedFormData.qi_grand_total =
                                    grandTotal2.toFixed(2);
                            } else if (
                                total &&
                                scopedFormData?.qi_vat !== undefined
                            ) {
                                scopedFormData.qi_grand_total =
                                    grandTotal2.toFixed(2);
                            }
                            return (
                                <>
                                    <Grid container spacing={1}>
                                        <Grid item sm={6} md={4}>
                                            <Box display={"flex"}>
                                                <div>
                                                    <ReferenceInput
                                                        source={getSource(
                                                            "qi_item_id"
                                                        )}
                                                        label="Purchase Item"
                                                        reference="v1/quotationItemMapping"
                                                        variant="outlined"
                                                        filter={{
                                                            _not_head_id: 0,
                                                        }}
                                                        helperText={false}
                                                        validate={[required()]}
                                                    >
                                                        <AutocompleteInput
                                                            optionValue="qim_id"
                                                            optionText="qim_name"
                                                            resettable
                                                        />
                                                    </ReferenceInput>
                                                </div>
                                                <Button
                                                    variant="outlined"
                                                    className={
                                                        classes.editButton
                                                    }
                                                    size="small"
                                                    onClick={() => {
                                                        setQuotationId(id);
                                                        setIsDialogOpen(true);
                                                        setQuotationItemTrack(
                                                            getSource(
                                                                "qi_item_id"
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <EditIcon />
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6} md={2}>
                                            <NumberInput
                                                source={getSource(
                                                    "qi_quantity"
                                                )}
                                                record={scopedFormData}
                                                variant="outlined"
                                                label="Quantity"
                                                fullWidth
                                                validate={[
                                                    required(),
                                                    minValue(
                                                        1,
                                                        "Per user usable count can't be 0 or negative"
                                                    ),
                                                ]}
                                                min={1}
                                            />{" "}
                                        </Grid>
                                        <Grid item sm={6} md={2}>
                                            <TextInput
                                                source={getSource("qi_unit")}
                                                record={scopedFormData}
                                                variant="outlined"
                                                label="Unit"
                                                fullWidth
                                                validate={[required()]}
                                            />{" "}
                                        </Grid>

                                        <Grid item sm={6} md={2}>
                                            <NumberInput
                                                source={getSource(
                                                    "qi_unit_price"
                                                )}
                                                record={scopedFormData}
                                                variant="outlined"
                                                label="Unit Price"
                                                fullWidth
                                                validate={[
                                                    required(),
                                                    minValue(
                                                        0,
                                                        "Per user usable count can't be negative"
                                                    ),
                                                ]}
                                                min={0}
                                            />{" "}
                                        </Grid>
                                        <Grid item sm={6} md={2}>
                                            <NumberInput
                                                source={getSource(
                                                    "qi_total_price"
                                                )}
                                                record={scopedFormData}
                                                variant="outlined"
                                                label="Base Value"
                                                fullWidth
                                                disabled
                                            />{" "}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item sm={6} md={2}>
                                            <NumberInput
                                                source={getSource("qi_tax")}
                                                record={scopedFormData}
                                                variant="outlined"
                                                label="TDS"
                                                fullWidth
                                                validate={[
                                                    minValue(
                                                        0,
                                                        "Per user usable count can't be negative"
                                                    ),
                                                ]}
                                                initialValue={0}
                                            />{" "}
                                        </Grid>
                                        <Grid item sm={6} md={2}>
                                            <NumberInput
                                                source={getSource("qi_vat")}
                                                record={scopedFormData}
                                                variant="outlined"
                                                label="VDS"
                                                fullWidth
                                                validate={[
                                                    minValue(
                                                        0,
                                                        "Per user usable count can't be negative"
                                                    ),
                                                ]}
                                                initialValue={0}
                                            />{" "}
                                        </Grid>
                                        <Grid item sm={6} md={2}>
                                            <NumberInput
                                                source={getSource(
                                                    "qi_discount"
                                                )}
                                                record={scopedFormData}
                                                variant="outlined"
                                                label="Discount"
                                                fullWidth
                                                validate={[
                                                    minValue(
                                                        0,
                                                        "Per user usable count can't be negative"
                                                    ),
                                                ]}
                                                initialValue={0}
                                            />{" "}
                                        </Grid>
                                        <Grid item sm={6} md={2}>
                                            <NumberInput
                                                source={getSource(
                                                    "qi_grand_total"
                                                )}
                                                record={scopedFormData}
                                                variant="outlined"
                                                label="Invoice Value"
                                                fullWidth
                                                validate={[
                                                    minValue(
                                                        0,
                                                        "Per user usable count can't be negative"
                                                    ),
                                                ]}
                                                disabled
                                            />{" "}
                                        </Grid>
                                        <Grid item sm={6} md={4}>
                                            <TextInput
                                                source={getSource(
                                                    "qi_description"
                                                )}
                                                record={scopedFormData}
                                                variant="outlined"
                                                label="Description"
                                                fullWidth
                                            />{" "}
                                        </Grid>
                                    </Grid>
                                </>
                            );
                        }}
                    </InlineArrayInput>
                </AccordionDetails>
            </Accordion>
            {isQuotationDialogOpen === true && (
                <ReqSupplierForm
                    isQuotationDialogOpen={isQuotationDialogOpen}
                    setIsQuotationDialogOpen={setIsQuotationDialogOpen}
                    supplierItemTrack={supplierItemTrack}
                />
            )}
            <Confirm
                isOpen={isDialogOpen}
                loading={hasValidationErrors && isLoading}
                title={`Are you sure you want to add purchase Item name at quotation #${quotationId}?`}
                content={
                    <Box display={"flex"}>
                        <TextInput
                            source="qim_name"
                            label="Name"
                            variant="outlined"
                            helperText={false}
                            onChange={(e) =>
                                setPurchaseItemName(e?.target?.value)
                            }
                            validate={[
                                required(),
                                maxLength(50, "Maximum 50 characters"),
                            ]}
                            className={classes.title}
                        />
                        <ReferenceInput
                            source="qim_head_id"
                            label="CAPEX OPEX Head"
                            reference="v1/accountingHead"
                            variant="outlined"
                            helperText={false}
                            className={classes.title}
                        >
                            <AutocompleteInput
                                optionValue="ah_id"
                                optionText="ah_name"
                                resettable
                            />
                        </ReferenceInput>
                    </Box>
                }
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
        </>
    );
};
export default QuotationForm;

const useStyles = makeStyles(() => ({
    flex: {
        margin: "0px 120px",
    },
    editButton: {
        marginLeft: 5,
        height: 40,
        marginTop: 7,
    },
    label: {
        background: "#f6f6f6",
        fontWeight: "bold",
        fontSize: 20,
    },
    title: {
        marginRight: 30,
    },
}));
