/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { FC, useEffect, useState } from "react";
import { useRecordContext } from "react-admin";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import { isEmpty, numberFormat } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import FormatedBooleanInput from "../../FormatedBooleanInput";
import LoaderOrButton from "../../LoaderOrButton";

type OrderCalculateProps = {
    userCash?: number;
    productOrderItems?: object[];
    setProductOrderItems?: (items: object[]) => void;
};

const OrderCalculate: FC<OrderCalculateProps> = ({
    userCash,
    productOrderItems,
    setProductOrderItems,
}) => {
    const classes = useStyles();
    const classesArogga = useAroggaStyles();
    const record = useRecordContext();
    const { values } = useFormState();

    const [isShowTable, setIsShowTable] = useState(false);
    const [isWarning, setIsWarning] = useState(false);
    const [isApiCall, setIsApiCall] = useState(false);

    const { data, isLoading, refetch } = useRequest(
        `/v1/productOrder/${
            values.po_id ? values.po_id : ""
        }?_need_calculation=1`,
        {
            method: "POST",
            body: {
                ...values,
                poi: !!values?.poi?.length ? JSON.stringify(values.poi) : "",
            },
        },
        {
            onSuccess: ({ data }) => handleRequestSuccess(data),
        }
    );

    useEffect(() => {
        if (!isApiCall && !isShowTable && !isWarning) return;

        setIsApiCall(false);
        setIsShowTable(false);
        setIsWarning(false);
    }, [values, productOrderItems]);

    const handleRequestSuccess = (data) => {
        setIsShowTable(true);

        if (
            !values.po_manual_addition &&
            data?.is_extra_delivery_fee_added &&
            values.po_id
        ) {
            setIsWarning(true);
        }
    };

    const renderTable = () => (
        <TableContainer>
            <Table size="small" align="center" className={classes.table}>
                <TableBody>
                    {renderTableRow("Subtotal", data?.po_subtotal)}
                    {renderDeductions()}
                    {!!data?.po_user_cash_used &&
                        renderTableRow(
                            "User Cash Used",
                            -Math.abs(data?.po_user_cash_used)
                        )}
                    {!!data?.po_user_bonus_used &&
                        renderTableRow(
                            "User Bonus Used",
                            -Math.abs(data?.po_user_bonus_used)
                        )}
                    {renderAdditions()}
                    {renderTableRow(
                        "Amount Payable",
                        data?.po_payable_total,
                        true
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderTableRow = (label, value, isBold = false) => (
        <TableRow key={label}>
            <TableCell className={isBold ? classesArogga.fontBold : ""}>
                {label}
            </TableCell>
            <TableCell
                align="right"
                className={isBold ? classesArogga.fontBold : ""}
            >
                {numberFormat(value)}
            </TableCell>
        </TableRow>
    );

    const renderDeductions = () =>
        !isEmpty(data?.deductions) &&
        Object.values(data.deductions).map((item: any) =>
            renderTableRow(`${item.text}\n${item.info}`, -Math.abs(item.amount))
        );

    const renderAdditions = () =>
        !isEmpty(data?.additions) &&
        Object.values(data.additions).map((item: any) =>
            renderTableRow(`${item.text}\n${item.info}`, item.amount)
        );

    const renderAlertAction = (
        cancelAction: () => void,
        okAction: () => void
    ) => (
        <>
            <Button color="inherit" size="small" onClick={cancelAction}>
                Cancel
            </Button>
            <Button color="inherit" size="small" onClick={okAction}>
                Ok
            </Button>
        </>
    );

    return (
        <>
            {!isShowTable && !!values?.poi && (
                <>
                    {!!values.po_id && !!userCash && (
                        <Box display="flex" justifyContent="center" mb={1}>
                            <FormatedBooleanInput
                                source="po_include_user_cash"
                                label="Include Arogga Cash (if any)"
                            />
                        </Box>
                    )}
                    {!!values.po_id && (
                        <Box display="flex" justifyContent="center" mb={1}>
                            <FormatedBooleanInput
                                source="calculate_delivery_fee"
                                label="Calculate Delivery Fee"
                                defaultValue={1}
                            />
                        </Box>
                    )}
                    <LoaderOrButton
                        label="Calculate"
                        isLoading={isLoading}
                        display="flex"
                        justifyContent="center"
                        onClick={refetch}
                    />
                </>
            )}
            {isShowTable && renderTable()}
            {isWarning && (
                <Alert
                    classes={{ root: classes.root }}
                    severity="warning"
                    action={renderAlertAction(
                        () => {
                            setIsWarning(false);
                            setProductOrderItems(record?.poi);
                        },
                        () => setIsWarning(false)
                    )}
                >
                    ওয়ার্নিং, {numberFormat(data.po_delivery_fee)} টাকা ডেলিভারি
                    চার্জ এড হবে এই অর্ডারে।
                </Alert>
            )}
        </>
    );
};

export default OrderCalculate;

const useStyles = makeStyles({
    table: {
        width: 362,
    },
    root: {
        width: "50%",
        margin: "0 auto",
        marginTop: 25,
    },
});
