import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    makeStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Title } from "react-admin";
import { useReactToPrint } from "react-to-print";

import AroggaButton from "../../../components/AroggaButton";
import AroggaProgress from "../../../components/AroggaProgress";
import NoDataFound from "../../../components/NoDataFound";
import CustomTableCell from "../../../components/manageAccounting/CustomTableCell";
import { useRequest, useXLSXDownloader } from "../../../hooks";
import {
    buildTreeFromList,
    flattenTree,
    numberFormat,
    toFormattedDateTime,
} from "../../../utils/helpers";

const BalanceSheetPage = () => {
    const classes = useStyles();

    const printableRef = useRef(null);
    const { onExportToXLSX } = useXLSXDownloader();

    const [balanceSheetData, setBalanceSheetData] = useState([]);
    const [date, setDate] = useState(
        toFormattedDateTime({
            isDate: true,
            dateString: new Date().toString(),
        })
    );

    const { data, isLoading, isSuccess, refetch } = useRequest(
        `/v1/accounts/balanceSheet?_date=${date}`,
        {},
        {
            onSuccess: ({ data }) => {
                const tree = buildTreeFromList(
                    data?.filter(
                        (item) =>
                            item.account_head_group_name !==
                            "Total Equity & Retained Earnings"
                    ),
                    {
                        keyId: "account_head_id",
                        keyParent: "account_head_parent_id",
                        marginLeft: 5,
                        isExpand: false,
                        isOpen: false,
                    }
                );

                const flattenData = flattenTree(tree);

                setBalanceSheetData(
                    toFormattedData([
                        ...flattenData,
                        ...data?.filter(
                            (item) =>
                                item.account_head_group_name ===
                                "Total Equity & Retained Earnings"
                        ),
                    ])
                );
            },
        }
    );

    useEffect(() => {
        if (!date) return;

        const debouncedHandleDateChange = debounce(() => refetch(), 1000);
        debouncedHandleDateChange();
        return () => debouncedHandleDateChange.cancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    const toFormattedData = (flattenData) => {
        if (!flattenData?.length) return [];

        const particularGroups = {
            "Total Asset": 0,
            "Non Current Assets": 0,
            "Current Assets": 0,
            "Total Liability": 0,
            "Long-term Liabilities": 0,
            "Current Liabilities": 0,
            "Total Equity & Retained Earnings": 0,
            Equity: 0,
            // "Net Profit": 0,
            // "Net Loss": 0,
        };

        const formattedData = [];

        Object.keys(particularGroups).forEach((particular) => {
            let groupData = [];
            let totalCategoryBalance = 0;

            if (
                particular !== "Total Asset" &&
                particular !== "Total Liability"
                // particular !== "Total Equity & Retained Earnings" &&
                // particular !== "Equity" &&
                // particular !== "Net Profit" &&
                // particular !== "Net Loss"
            ) {
                groupData = flattenData.filter(
                    (item) => item.account_head_group_name === particular
                );
                totalCategoryBalance = groupData.reduce((sum, item) => {
                    if (item.account_head_parent_id === 0) {
                        return sum + item.debit_amount;
                    }
                    return sum;
                }, 0);
            }

            switch (particular) {
                case "Non Current Assets":
                case "Current Assets":
                    particularGroups["Total Asset"] += totalCategoryBalance;
                    break;
                case "Long-term Liabilities":
                case "Current Liabilities":
                    particularGroups["Total Liability"] += totalCategoryBalance;
                    break;
                case "Total Equity & Retained Earnings":
                    particularGroups["Total Equity & Retained Earnings"] +=
                        totalCategoryBalance;
            }

            const newObj = {
                particular,
                account_head_parent_id: 0,
                // totalBalance: particularGroups[particular],
                totalCategoryBalance: totalCategoryBalance,
            };

            // if (particular === "Net Profit" || particular === "Net Loss") {
            //     formattedData.push(...groupData, newObj);
            // } else {
            formattedData.push(newObj, ...groupData);
            // }
        });

        return formattedData;
    };

    const exportedData = balanceSheetData.map(
        ({ particular, account_head_name, debit_amount, totalBalance }) => ({
            account_head_name: particular || account_head_name,
            balance: debit_amount,
            totalBalance,
        })
    );

    const handleDateChange = (e) => {
        setDate(e.target.value);
        !e.target.value && setBalanceSheetData([]);
    };

    const handleExpandCollapse = (action, id) => {
        const newBalanceSheetData = [...balanceSheetData];

        newBalanceSheetData.forEach((item) => {
            if (item.account_head_id === id) {
                item.isExpand = action === "expand";
            }
            if (item.account_head_parent_id === id) {
                item.isOpen = action === "expand";
            }
        });

        setBalanceSheetData(newBalanceSheetData);
    };

    const totalTopParticularBalance = {
        "Total Asset": 0,
        "Total Liability": 0,
        "Total Equity & Retained Earnings": 0,
    };

    balanceSheetData.forEach((item) => {
        if (
            (item.account_head_group_name === "Non Current Assets" ||
                item.account_head_group_name === "Current Assets") &&
            item.account_head_parent_id === 0
        ) {
            totalTopParticularBalance["Total Asset"] += item.debit_amount;
        } else if (
            (item.account_head_group_name === "Long-term Liabilities" ||
                item.account_head_group_name === "Current Liabilities") &&
            item.account_head_parent_id === 0
        ) {
            totalTopParticularBalance["Total Liability"] += item.debit_amount;
        } else if (
            (item.account_head_group_name === "Equity" ||
                item.account_head_group_name === "Net Profit" ||
                item.account_head_group_name ===
                    "Total Equity & Retained Earnings" ||
                item.account_head_group_name === "Net Loss") &&
            item.account_head_parent_id === 0
        ) {
            totalTopParticularBalance["Total Equity & Retained Earnings"] +=
                item.debit_amount;
        }
    });

    const isTotalBalanceMismatch =
        totalTopParticularBalance["Total Asset"] ===
        totalTopParticularBalance["Total Liability"] +
            totalTopParticularBalance["Total Equity & Retained Earnings"];

    const isTopParticular = (particular) => {
        return [
            "Total Asset",
            "Total Liability",
            "Total Equity & Retained Earnings",
        ].includes(particular);
    };

    const isChildParticular = (particular) => {
        return [
            "Non Current Assets",
            "Current Assets",
            "Long-term Liabilities",
            "Current Liabilities",
            "Equity",
            "Net Profit",
            "Net Loss",
        ].includes(particular);
    };

    const formattedAmount = (debit_amount) => {
        if (debit_amount === 0) {
            return "";
        } else if (!debit_amount) {
            return "";
        }

        return numberFormat(debit_amount);
    };

    let filename;

    if (date && balanceSheetData?.length) {
        filename = `Balance Sheet - ${date}`;
    }

    const handlePrint = useReactToPrint({
        content: () => printableRef.current,
        documentTitle: filename,
        pageStyle: `
                @page {
                margin: 3mm;
            }
        `,
    });

    return (
        <Paper style={{ marginTop: 25, padding: 20 }}>
            <Title title="Balance Sheet" />
            {!isLoading && !!balanceSheetData?.length && (
                <Box display="flex" justifyContent="right" gridGap={5}>
                    <AroggaButton
                        type="success"
                        label="Print"
                        onClick={handlePrint}
                    />
                    <AroggaButton
                        type="success"
                        label="Export"
                        onClick={() => onExportToXLSX(exportedData, filename)}
                    />
                </Box>
            )}
            <div ref={printableRef}>
                <Typography variant="h5" align="center">
                    Balance Sheet
                </Typography>
                <Box textAlign="center" mt={2} mb={2}>
                    <label htmlFor="date">Date: </label>
                    <input
                        type="date"
                        id="date"
                        value={date}
                        onChange={handleDateChange}
                    />
                </Box>
                {isLoading && (
                    <Box position="relative" mt={10}>
                        <AroggaProgress />
                    </Box>
                )}
                {isSuccess && !data?.length && <NoDataFound />}
                {!isLoading && !!balanceSheetData?.length && (
                    <Table className={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.borderRight}>
                                    Particulars
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className={classes.borderRight}
                                >
                                    Head Total
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className={classes.borderRight}
                                >
                                    Category Total
                                </TableCell>
                                <TableCell align="right">
                                    Total Balance
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {balanceSheetData
                                .filter(
                                    (item) =>
                                        item?.account_head_parent_id === 0 ||
                                        item?.isOpen
                                )
                                .map(
                                    (
                                        {
                                            particular,
                                            account_head_name,
                                            debit_amount,
                                            totalBalance,
                                            marginLeft = 0,
                                            color,
                                            isExpand,
                                            hasChildren,
                                            account_head_id,
                                            totalCategoryBalance,
                                        },
                                        index
                                    ) => {
                                        return (
                                            <TableRow key={index}>
                                                <CustomTableCell
                                                    className={
                                                        particular
                                                            ? classes.fontBold
                                                            : ""
                                                    }
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        marginLeft={
                                                            isTopParticular(
                                                                particular
                                                            )
                                                                ? 0
                                                                : isChildParticular(
                                                                      particular
                                                                  )
                                                                ? 5
                                                                : marginLeft +
                                                                  10
                                                        }
                                                    >
                                                        {isExpand ? (
                                                            <ArrowDropDownIcon
                                                                style={{
                                                                    display:
                                                                        hasChildren
                                                                            ? ""
                                                                            : "none",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    handleExpandCollapse(
                                                                        "collapse",
                                                                        account_head_id
                                                                    )
                                                                }
                                                            />
                                                        ) : (
                                                            <ArrowRightIcon
                                                                style={{
                                                                    display:
                                                                        hasChildren
                                                                            ? ""
                                                                            : "none",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    handleExpandCollapse(
                                                                        "expand",
                                                                        account_head_id
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                        {particular ||
                                                            account_head_name}
                                                    </Box>
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    data={formattedAmount(
                                                        debit_amount
                                                    )}
                                                    align="right"
                                                />
                                                <CustomTableCell
                                                    data={formattedAmount(
                                                        totalCategoryBalance
                                                    )}
                                                    align="right"
                                                />
                                                <CustomTableCell
                                                    data={formattedAmount(
                                                        totalTopParticularBalance[
                                                            particular
                                                        ]
                                                    )}
                                                    align="right"
                                                    className={classes.fontBold}
                                                    style={
                                                        isTotalBalanceMismatch
                                                            ? { color: "green" }
                                                            : { color: "red" }
                                                    }
                                                />
                                            </TableRow>
                                        );
                                    }
                                )}
                        </TableBody>
                    </Table>
                )}
            </div>
        </Paper>
    );
};

export default BalanceSheetPage;

const useStyles = makeStyles({
    table: {
        width: "100%",
        maxWidth: 960,
        margin: "0 auto",
    },
    borderRight: {
        borderRight: "1px solid #ddd",
    },
    fontBold: {
        fontWeight: "bold",
    },
});
