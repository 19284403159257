import { FC } from "react";
import { Edit, EditProps, SimpleForm, Toolbar } from "react-admin";

import LocationCreateEdit from "../../../components/manageUser/locations/LocationCreateEdit";
import { useDocumentTitle } from "../../../hooks";

const LocationEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Location Edit");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm
                submitOnEnter={false}
                toolbar={<Toolbar alwaysEnableSaveButton />}
            >
                <LocationCreateEdit page="edit" />
            </SimpleForm>
        </Edit>
    );
};

export default LocationEdit;
