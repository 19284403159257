import { Box } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Fragment, useState } from "react";
import {
    Datagrid,
    FunctionField,
    ImageField,
    Link,
    NumberField,
    Record,
    ReferenceField,
    SimpleForm,
    TextField,
} from "react-admin";

import { getQuantityLabel, toFixedNumber } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import RequiredQtyUpdateDialog from "./RequiredQtyUpdateDialog";

const OfficialItemDatagrid = (props) => {
    const classes = useAroggaStyles();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [officialItemId, setOfficialItemId] = useState(null);
    const [quantityLabelInfo, setQuantityLabelInfo] = useState(null);

    return (
        <>
            <Datagrid {...props}>
                <TextField source="s_id" label="ID" />
                <TextField source="p_name" label="Item Name" />
                <ImageField
                    source="image"
                    label="Image"
                    src="src"
                    title="title"
                    className="small__img"
                />
                <TextField source="pv_id" label="Variant ID" />
                <TextField
                    source="p_type"
                    label="Category (L1)"
                    className={classes.capitalize}
                />
                <TextField source="p_form" label="Form" />
                <TextField source="p_strength" label="Strength" />
                <ReferenceField
                    source="p_brand_id"
                    label="Brand"
                    reference="v1/productBrand"
                    sortBy="p_brand_id"
                    link="show"
                >
                    <TextField source="pb_name" />
                </ReferenceField>
                <FunctionField
                    label="Official Vendor"
                    sortBy="p_vendor_company_names"
                    render={({
                        p_vendor_company_ids,
                        p_vendor_company_names,
                    }: Record) => {
                        return p_vendor_company_names?.map((name, i) => (
                            <Fragment key={i}>
                                <Link
                                    to={`/v1/vendor/${p_vendor_company_ids?.[i]?.id}/show`}
                                >
                                    {name}
                                </Link>
                                {p_vendor_company_names?.length > 1 && " "}
                            </Fragment>
                        ));
                    }}
                />
                <NumberField source="pv_mrp" label="MRP" />
                <FunctionField
                    source="stock_qty"
                    label="In Stock Qty"
                    sortBy="stock_qty"
                    render={({
                        stock_qty: qty,
                        pv_unit_base_label: baseUnit,
                        pv_unit_label: salesUnit,
                        pv_unit_multiplier: salesUnitMultiplier,
                    }: Record) => (
                        <span className={classes.whitespaceNowrap}>
                            {getQuantityLabel({
                                qty,
                                salesUnit,
                                baseUnit,
                                salesUnitMultiplier,
                            })}
                        </span>
                    )}
                />
                <FunctionField
                    label="Required Qty"
                    sortBy="s_weekly_requirement"
                    render={({
                        s_id,
                        s_weekly_requirement,
                        pv_unit_label,
                        pv_unit_base_label,
                        pv_unit_multiplier,
                    }: Record) => (
                        <Box
                            display="flex"
                            gridGap={4}
                            className={classes.whitespaceNowrap}
                        >
                            {getQuantityLabel({
                                qty: s_weekly_requirement,
                                salesUnit: pv_unit_label,
                                baseUnit: pv_unit_base_label,
                                salesUnitMultiplier: pv_unit_multiplier,
                            })}
                            <EditIcon
                                fontSize="small"
                                cursor="pointer"
                                onClick={() => {
                                    setIsDialogOpen(true);
                                    setOfficialItemId(s_id);
                                    setQuantityLabelInfo({
                                        salesUnit: pv_unit_label,
                                        baseUnit: pv_unit_base_label,
                                        salesUnitMultiplier: pv_unit_multiplier,
                                    });
                                }}
                            />
                        </Box>
                    )}
                />
                <NumberField
                    source="p_last_pp_price"
                    label="Last Official TP"
                />
                <FunctionField
                    label="Aprox. Amount"
                    render={({
                        s_weekly_requirement,
                        p_last_pp_price,
                    }: Record) =>
                        toFixedNumber(s_weekly_requirement * p_last_pp_price)
                    }
                />
            </Datagrid>
            <SimpleForm toolbar={null}>
                <RequiredQtyUpdateDialog
                    isDialogOpen={isDialogOpen}
                    setIsDialogOpen={setIsDialogOpen}
                    officialItemId={officialItemId}
                    quantityLabelInfo={quantityLabelInfo}
                />
            </SimpleForm>
        </>
    );
};

export default OfficialItemDatagrid;
