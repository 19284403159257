import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import { FC, KeyboardEvent, useEffect } from "react";
import { TextInput, required } from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import AroggaDialogActions from "../../AroggaDialogActions";

type UserCreateModalProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
};

const UserCreateModal: FC<UserCreateModalProps> = ({ open, setOpen }) => {
    const form = useForm();
    const { values } = useFormState();

    const { data, isLoading, refetch } = useRequest(
        "/v1/users",
        {
            method: "POST",
            body: {
                u_name: values.po_user_name,
                u_mobile: values.po_user_mobile,
            },
        },
        {
            onSuccess: () => {
                form.change("isCreateNewUser", true);
                setOpen(false);
            },
        }
    );

    useEffect(() => {
        data?.u_id && form.change("userId", data.u_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (
            e.key === "Enter" &&
            !isLoading &&
            values.po_user_name &&
            values.po_user_mobile
        ) {
            refetch();
        }
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
                <Typography>Create New User</Typography>
            </DialogTitle>
            <DialogContent>
                <TextInput
                    source="po_user_name"
                    label="Name"
                    variant="outlined"
                    helperText={false}
                    validate={[required()]}
                    onKeyDown={handleOnKeyDown}
                    autoFocus
                    fullWidth
                />
                <TextInput
                    source="po_user_mobile"
                    label="Mobile"
                    variant="outlined"
                    helperText={false}
                    validate={[required()]}
                    disabled={values.po_user_mobile}
                    fullWidth
                />
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={() => setOpen(false)}
                onConfirm={refetch}
                disabled={!values.po_user_name || !values.po_user_mobile}
            />
        </Dialog>
    );
};

export default UserCreateModal;
