import { FC } from "react";
import {
    Create,
    CreateProps,
    DateTimeInput,
    NumberInput,
    SelectInput,
    SimpleForm,
    TextInput,
    TransformData,
    minLength,
    required,
} from "react-admin";
import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import TreeDropdownInput from "../../../components/TreeDropdownInput";
import { useDocumentTitle } from "../../../hooks";
import { toFormattedDateTime } from "../../../utils/helpers";

const AssetCreate: FC<CreateProps> = ({ hasCreate, ...rest }) => {
    useDocumentTitle("Arogga | Asset Create");

    const transform: TransformData = (data) => ({
        ...data,
        a_purchase_date: toFormattedDateTime({
            dateString: data.a_purchase_date,
        }),
    });

    return (
        <Create {...rest} transform={transform}>
            <SimpleForm redirect="list">
                <TextInput
                    source="a_asset_name"
                    label="Name"
                    variant="outlined"
                    helperText={false}
                    validate={[
                        required(),
                        minLength(2, "Name must be at least 2 characters long"),
                    ]}
                />
                <TaxonomiesByVocabularyInput
                    fetchKey="asset_category"
                    source="a_category_id"
                    label="Category"
                    helperText={false}
                    optionValue="t_id"
                    validate={[required()]}
                />
                <DateTimeInput
                    source="a_purchase_date"
                    label="Purchase Date"
                    variant="outlined"
                    helperText={false}
                />
                <NumberInput
                    source="a_purchase_cost"
                    label="Purchase Cost"
                    variant="outlined"
                    helperText={false}
                />
                <NumberInput
                    source="a_asset_life"
                    label="Life"
                    variant="outlined"
                    helperText={false}
                />
                <NumberInput
                    source="a_residual_value"
                    label="Residual Value"
                    variant="outlined"
                    helperText={false}
                />
                <SelectInput
                    source="a_depreciation_method"
                    label="Depreciation Method"
                    variant="outlined"
                    helperText={false}
                    defaultValue="Straight-line"
                    choices={[
                        { id: "Straight-line", name: "Straight-line" },
                        {
                            id: "Double declining balance",
                            name: "Double declining balance",
                        },
                        {
                            id: "Units of production",
                            name: "Units of production",
                        },
                        {
                            id: "Sum of years digits",
                            name: "Sum of years digits",
                        },
                    ]}
                />
                <TreeDropdownInput
                    reference="/v1/taxonomiesByVocabulary/department"
                    source="a_department_id"
                    label="Department"
                    variant="outlined"
                    keyId="t_id"
                    keyParent="t_parent_id"
                    optionValue="t_id"
                    optionTextValue="t_title"
                />
                <SelectInput
                    source="a_status"
                    label="Status"
                    variant="outlined"
                    helperText={false}
                    defaultValue="active"
                    choices={[
                        { id: "active", name: "Active" },
                        {
                            id: "inactive",
                            name: "In Active",
                        },
                    ]}
                />
                <TextInput
                    source="a_location"
                    label="Location"
                    variant="outlined"
                    helperText={false}
                    validate={[
                        minLength(
                            2,
                            "Location must be at least 2 characters long"
                        ),
                    ]}
                    minRows={2}
                    multiline
                />
            </SimpleForm>
        </Create>
    );
};

export default AssetCreate;
