import { FC } from "react";
import AceEditor from "react-ace";
import { Labeled } from "react-admin";

import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-twilight";

type AceEditorFieldProps = {
    value: string;
    label: string;
    [key: string]: any;
};

const AceEditorField: FC<AceEditorFieldProps> = ({ value, label, ...rest }) => (
    <Labeled label={label}>
        <AceEditor
            width="1000px"
            theme="twilight"
            fontSize={14}
            setOptions={{
                showLineNumbers: true,
                tabSize: 4,
            }}
            editorProps={{ $blockScrolling: true }}
            value={value}
            wrapEnabled
            showPrintMargin
            readOnly
            {...rest}
        />
    </Labeled>
);

export default AceEditorField;
