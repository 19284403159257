import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import { FC, useRef, useState } from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { useFormState } from "react-final-form";

import { useEffectOnDependencyChange } from "../../../hooks";
import { getProductTextRenderer, getReadableSKU } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import AroggaDialogActions from "../../AroggaDialogActions";
import ProductVariantUnitInput from "../../ProductVariantUnitInput";

type ChangeableProduct = {
    name: string;
    variantId: number;
    unitId: number;
    qty: number;
};

type ChangeProductDialogProps = {
    isDialogOpen: boolean;
    setIsDialogOpen: (isDialogOpen: boolean) => void;
    changeableProduct: ChangeableProduct;
    productOrderItems: any[];
    setProductOrderItems: (items: object[]) => void;
};

const ChangeProductDialog: FC<ChangeProductDialogProps> = ({
    isDialogOpen,
    setIsDialogOpen,
    changeableProduct,
    productOrderItems,
    setProductOrderItems,
}) => {
    const classes = useAroggaStyles();
    const { values } = useFormState();
    const inputRef = useRef<HTMLInputElement>(null!);

    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffectOnDependencyChange(() => {
        if (!values.sameGenericProductId) {
            setSelectedProduct({});
        }
    }, [values.sameGenericProductId]);

    const onDialogClose = () => {
        setIsDialogOpen(false);
        values.sameGenericProductId = undefined;
        values.sameGenericVariantId = undefined;
        values.sameGenericUnitId = undefined;
    };

    const onConfirm = () => {
        const newProductOrderItems = [...productOrderItems];

        const productIndex = newProductOrderItems.findIndex(
            (item) =>
                item.poi_product_variant_id === changeableProduct?.variantId
        );
        const oldItem = newProductOrderItems[productIndex];

        const {
            p_id,
            p_name,
            p_brand,
            p_brand_id,
            p_form,
            p_strength,
            p_cold,
        } = selectedProduct;

        const selectedProductVariant = selectedProduct?.pv?.find(
            (item) => item.pv_id === values.sameGenericVariantId
        );
        const selectedProductUnit = selectedProduct?.pu?.find(
            (item) => item.pu_id === values.sameGenericUnitId
        );
        const baseUnit = selectedProductVariant?.pu_base_unit_label;
        const salesUnitMultiplier =
            selectedProductVariant?.[
                values.po_is_b2b_order
                    ? "pu_b2b_base_unit_multiplier"
                    : "pu_b2c_base_unit_multiplier"
            ];

        newProductOrderItems[productIndex] = {
            p_name,
            poi_product_id: p_id,
            p_brand,
            p_brand_id,
            productVariant: getReadableSKU(
                selectedProductVariant?.pv_attribute
            ),
            poi_product_variant_id: values.sameGenericVariantId,
            productUnit: selectedProductUnit?.pu_label,
            poi_product_unit_id: values.sameGenericUnitId,
            baseUnit,
            salesUnitMultiplier,
            p_form,
            p_strength,
            p_cold,
            poi_product_qty: oldItem.poi_product_qty,
            poi_mrp_price:
                selectedProductVariant?.[
                    values.po_is_b2b_order ? "pv_b2b_mrp" : "pv_b2c_mrp"
                ],
            poi_discount_price:
                selectedProductVariant?.[
                    values.po_is_b2b_order ? "pv_b2b_price" : "pv_b2c_price"
                ],
            is_antibiotics: selectedProductVariant?.is_antibiotics,
            is_controlled: selectedProductVariant?.is_controlled,
            oldData: oldItem,
        };

        setProductOrderItems(newProductOrderItems);
        onDialogClose();
    };

    return (
        <Dialog open={isDialogOpen} onClose={onDialogClose}>
            <DialogTitle>
                <Typography>
                    Change Product For{" "}
                    <span className={classes.fontBold}>
                        {changeableProduct?.name}
                    </span>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <ReferenceInput
                    source="sameGenericProductId"
                    label="Product"
                    variant="outlined"
                    helperText={false}
                    reference="general/v1/sameGenericProducts"
                    filter={{
                        _product_variant_id: changeableProduct?.variantId,
                        _pu_id: changeableProduct?.unitId,
                        _quantity: changeableProduct?.qty,
                    }}
                    sort={{ field: "p_name", order: "ASC" }}
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={getProductTextRenderer}
                        options={{
                            InputProps: {
                                multiline: true,
                            },
                        }}
                        matchSuggestion={() => true}
                        onSelect={(product) => {
                            setSelectedProduct(product);
                            setTimeout(() => {
                                inputRef.current?.focus();
                            }, 1);
                        }}
                        resettable
                    />
                </ReferenceInput>
                {!!values.sameGenericProductId && (
                    <>
                        <ProductVariantUnitInput
                            source="sameGenericVariantId"
                            label="Variant"
                            data={selectedProduct?.pv}
                            inputRef={inputRef}
                            multiline
                        />
                        <ProductVariantUnitInput
                            source="sameGenericUnitId"
                            label="Unit"
                            data={selectedProduct?.pu}
                            isB2BUser={!!values.po_is_b2b_order}
                            isB2CUser={!values.po_is_b2b_order}
                            disabled
                        />
                    </>
                )}
            </DialogContent>
            <AroggaDialogActions
                isLoading={false}
                onDialogClose={onDialogClose}
                onConfirm={onConfirm}
                disabled={
                    !values.sameGenericProductId ||
                    !values.sameGenericVariantId ||
                    !values.sameGenericUnitId
                }
            />
        </Dialog>
    );
};

export default ChangeProductDialog;
