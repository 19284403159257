const DiscountPriceIcon = (props) => (
    <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect
            width="59"
            height="59"
            rx="29.5"
            fill="#1DA099"
            fillOpacity="0.1"
        />
        <path
            d="M39.7083 16.375H19.2917C17.6875 16.375 16.375 17.6875 16.375 19.2917V39.7083C16.375 41.3125 17.6875 42.625 19.2917 42.625H39.7083C41.3125 42.625 42.625 41.3125 42.625 39.7083V19.2917C42.625 17.6875 41.3125 16.375 39.7083 16.375ZM31.0021 22.2958L32.5479 20.75L34.6042 22.8063L36.6604 20.75L38.2062 22.2958L36.15 24.3521L38.2062 26.4083L36.6604 27.9542L34.6042 25.9125L32.5479 27.9688L31.0021 26.4229L33.0583 24.3667L31.0021 22.2958ZM21.1146 23.2583H28.4062V25.4458H21.1146V23.2583ZM28.7708 35.3333H25.8542V38.25H23.6667V35.3333H20.75V33.1458H23.6667V30.2292H25.8542V33.1458H28.7708V35.3333ZM38.25 37.1562H30.9583V34.9688H38.25V37.1562ZM38.25 33.5104H30.9583V31.3229H38.25V33.5104Z"
            fill="#1DA099"
        />
    </svg>
);
export default DiscountPriceIcon;
