import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { NumberInput } from "react-admin";
import { useFormState } from "react-final-form";

import AroggaDialogActions from "../../../components/AroggaDialogActions";
import { useRequest } from "../../../hooks";
import { getQuantityLabel } from "../../../utils/helpers";

type RequiredQtyUpdateDialogProps = {
    isDialogOpen: boolean;
    setIsDialogOpen: (value: boolean) => void;
    officialItemId: number;
    quantityLabelInfo: {
        salesUnit: string;
        baseUnit: string;
        salesUnitMultiplier: number;
    };
};

const RequiredQtyUpdateDialog: FC<RequiredQtyUpdateDialogProps> = ({
    isDialogOpen,
    setIsDialogOpen,
    officialItemId,
    quantityLabelInfo,
}) => {
    const { values } = useFormState();

    const { isLoading, refetch } = useRequest(
        `/v1/officialPoControl/${officialItemId}`,
        {
            method: "POST",
            body: { _quantity: values.requiredQty },
        },
        {
            isRefresh: true,
            onSuccess: () => handleClose(),
        }
    );

    const handleClose = () => {
        setIsDialogOpen(false);
        values.requiredQty = undefined;
    };

    return (
        <Dialog open={isDialogOpen} onClose={handleClose}>
            <DialogTitle>
                Are you sure you want to update required qty? #{officialItemId}
            </DialogTitle>
            <DialogContent>
                <NumberInput
                    source="requiredQty"
                    label="Required Qty *"
                    variant="outlined"
                    helperText={false}
                    min={0}
                    fullWidth
                />
                {getQuantityLabel({
                    qty: values.requiredQty,
                    salesUnit: quantityLabelInfo?.salesUnit,
                    baseUnit: quantityLabelInfo?.baseUnit,
                    salesUnitMultiplier: quantityLabelInfo?.salesUnitMultiplier,
                })}
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                disabled={!values.requiredQty}
                onDialogClose={handleClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default RequiredQtyUpdateDialog;
