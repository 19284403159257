import {
    Datagrid,
    FunctionField,
    Pagination,
    Record,
    ReferenceField,
    ReferenceManyField,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../../components/AroggaDateField";
import { capitalizeFirstLetterOfEachWord } from "../../../../utils/helpers";

const HistoryTab = ({ record }) => {
    return (
        <ReferenceManyField
            reference="v1/requisitionActivityLog"
            target="ral_id"
            pagination={<Pagination />}
            filter={{
                _requisition_id: record?.pr_id,
            }}
            sort={{ field: "ral_id", order: "DESC" }}
        >
            <Datagrid>
                <ReferenceField
                    source="ral_created_by"
                    label="Created By"
                    reference="v1/users"
                    sortBy="ral_created_by"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
                <AroggaDateField source="ral_created_at" label="Created At" />
                <FunctionField
                    label="Title"
                    render={({ ral_title }: Record) =>
                        capitalizeFirstLetterOfEachWord(ral_title)
                    }
                />
                <TextField source="ral_entity" label="Entity" />
                <TextField source="ral_description" label="Description" />
            </Datagrid>
        </ReferenceManyField>
    );
};

export default HistoryTab;
