import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Paper,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { useState } from "react";
import { SimpleForm, Title } from "react-admin";

import AroggaButton from "../../../components/AroggaButton";
import AroggaProgress from "../../../components/AroggaProgress";
import SendTestSmsDialog from "../../../components/SendTestSmsDialog";
import { useDocumentTitle, useRequest } from "../../../hooks";
import { capitalizeFirstLetter } from "../../../utils/helpers";

const SystemStatusPage = () => {
    useDocumentTitle("Arogga | System Status");

    const [isSmsDialogOpen, setIsSmsDialogOpen] = useState(false);
    const [gateWay, setgateWay] = useState("");
    const { data, isLoading } = useRequest(
        "/v1/system-status",
        {},
        { isPreFetching: true }
    );

    const formattedData = {
        ...data,
    };

    if (isLoading) return <AroggaProgress />;

    return (
        <Paper style={{ marginTop: 25 }}>
            <Title title="System Status" />
            <Box p={2}>
                {!!formattedData &&
                    Object.keys(formattedData)?.map((row, i) => (
                        <Accordion defaultExpanded style={{ marginTop: 10 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls={`${row}-content`}
                                id={`${row}-header`}
                                style={{
                                    background: "#f6f6f6",
                                    fontWeight: "bold",
                                    fontSize: 20,
                                }}
                            >
                                {capitalizeFirstLetter(row)}
                            </AccordionSummary>
                            <AccordionDetails
                                id={`${row}-content`}
                                aria-labelledby={`${row}-header`}
                            >
                                <div style={{ width: "100%" }}>
                                    {!!formattedData[row] &&
                                        formattedData[row]?.map((item, j) => {
                                            return (
                                                <>
                                                    {!!item &&
                                                        Object.keys(item)?.map(
                                                            (
                                                                element,
                                                                rowIndex
                                                            ) => {
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            width: "calc(100% - 40px)",
                                                                            display:
                                                                                "flex",
                                                                            alignItems:
                                                                                "center",
                                                                            padding:
                                                                                "6px 0",
                                                                        }}
                                                                    >
                                                                        {element ===
                                                                        "sectionTitle" ? (
                                                                            <>
                                                                                <div
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        padding:
                                                                                            "6px 20px",
                                                                                        fontWeight:
                                                                                            "bold",
                                                                                        background:
                                                                                            "#f6f6f6",
                                                                                        marginTop:
                                                                                            j !==
                                                                                            0
                                                                                                ? "20px"
                                                                                                : "0px",
                                                                                    }}
                                                                                >
                                                                                    {capitalizeFirstLetter(
                                                                                        item[
                                                                                            element
                                                                                        ]
                                                                                    )}
                                                                                    {row ===
                                                                                        "sms" && (
                                                                                        <AroggaButton
                                                                                            label="Send SMS"
                                                                                            type="secondary"
                                                                                            onClick={() => {
                                                                                                setIsSmsDialogOpen(
                                                                                                    true
                                                                                                );
                                                                                                setgateWay(
                                                                                                    item[
                                                                                                        element
                                                                                                    ]
                                                                                                );
                                                                                            }}
                                                                                            style={{
                                                                                                marginLeft: 5,
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div
                                                                                    style={{
                                                                                        width: "30%",
                                                                                        padding:
                                                                                            "0px 20px",
                                                                                    }}
                                                                                >
                                                                                    {capitalizeFirstLetter(
                                                                                        element
                                                                                    )}
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        width: "70%",
                                                                                        padding:
                                                                                            "0 20px",
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        item[
                                                                                            element
                                                                                        ]
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                </>
                                            );
                                        })}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
            </Box>
            <SimpleForm toolbar={null}>
                <SendTestSmsDialog
                    open={isSmsDialogOpen}
                    handleClose={() => setIsSmsDialogOpen(false)}
                    gateWay={gateWay}
                />
            </SimpleForm>
        </Paper>
    );
};

export default SystemStatusPage;
