import { useEffect, useRef, useState } from "react";
import { Grid, Typography, Card, CardContent } from "@material-ui/core";
import {
    GoogleMap,
    LoadScript,
    Marker,
    InfoWindow,
    MarkerClusterer,
} from "@react-google-maps/api";
import { useFormState } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import { useRequest } from "../../../../hooks";

const containerStyle = {
    width: "100%",
    height: "800px",
};

const offsetCoordinates = (lat, lng, index) => {
    const offsetValue = 0.00008;
    const angle = index * 45 * (Math.PI / 200);
    const offsetLat = lat + offsetValue * Math.sin(angle);
    const offsetLng = lng + offsetValue * Math.cos(angle);
    return { lat: offsetLat, lng: offsetLng };
};

const GoogleMapComponent = () => {
    const classes = useStyles();
    const { values } = useFormState();
    const [markers, setMarkers] = useState([]);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [selectedMarker, setSelectedMarker] = useState(null);

    const { data: gpsData } = useRequest(
        `/v1/actionGpsData?_order_id=${values.id}`,
        {},
        {
            isPreFetching: true,
            isSuccessNotify: false,
            isWarningNotify: false,
        }
    );

    useEffect(() => {
        if (gpsData && gpsData.length > 0) {
            const sortedGpsData = gpsData.sort((a, b) => a.agd_id - b.agd_id);

            const newMarkers = sortedGpsData.map((data, index) => ({
                id: data?.agd_id,
                lat: data?.agd_latitude,
                lng: data?.agd_longitude,
                action: data.agd_action,

                date: data.agd_created_at,
                shipmentId: data.agd_shipment_id,
                offsetLatLng: offsetCoordinates(
                    data?.agd_latitude,
                    data?.agd_longitude,
                    index
                ),
            }));

            setMarkers(newMarkers);
            setCenter({
                lat: newMarkers[0].lat,
                lng: newMarkers[0].lng,
            });
        }
    }, [gpsData]);
    const markerRef = useRef<Marker>(null);
    const getIconUrl = (marker, index) => {
        if (marker.action === "delivered") {
            return "https://maps.google.com/mapfiles/kml/pal2/icon10.png";
        } else if (marker.action === "canceledRequested") {
            return "https://maps.google.com/mapfiles/kml/pal3/icon37.png";
        }

        const iconColor = getIconColorByShipment(marker, index);
        switch (iconColor) {
            case "green":
                return "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
            case "yellow":
                return "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
            case "red":
            default:
                return "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
        }
    };

    const getIconColorByShipment = (marker, index) => {
        let currentShipmentId = markers[0]?.shipmentId;
        let color = "green";

        for (let i = 0; i <= index; i++) {
            if (markers[i]?.shipmentId !== currentShipmentId) {
                color =
                    color === "green"
                        ? "yellow"
                        : color === "yellow"
                        ? "red"
                        : "green";
                currentShipmentId = markers[i]?.shipmentId;
            }
        }
        return color;
    };

    const getShipmentLabelByColor = (marker, index) => {
        const iconColor = getIconColorByShipment(marker, index);
        switch (iconColor) {
            case "green":
                return "A";
            case "yellow":
                return "B";
            case "red":
                return "C";
            default:
                return "Unknown Shipment";
        }
    };

    return (
        <>
            {gpsData && gpsData.length > 0 ? (
                <LoadScript
                    id="google-map-script"
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                >
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={15}
                    >
                        <MarkerClusterer
                            options={{
                                imagePath:
                                    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                            }}
                        >
                            {(clusterer) =>
                                //@ts-ignore
                                markers.map((marker, index) => {
                                    return (
                                        <>
                                            <Marker
                                                key={`marker-${index}`}
                                                position={marker.offsetLatLng}
                                                onClick={() =>
                                                    setSelectedMarker(marker)
                                                }
                                                ref={markerRef}
                                                icon={{
                                                    url: getIconUrl(
                                                        marker,
                                                        index
                                                    ),
                                                }}
                                                clusterer={clusterer}
                                                label={{
                                                    text: `${index}. ${
                                                        marker.action
                                                    } - ${getShipmentLabelByColor(
                                                        marker,
                                                        index
                                                    )} `,
                                                    className:
                                                        classes.marginTop,
                                                }}
                                                animation={
                                                    google.maps.Animation.DROP
                                                }
                                            />
                                        </>
                                    );
                                })
                            }
                        </MarkerClusterer>

                        {selectedMarker && (
                            <InfoWindow
                                position={selectedMarker.offsetLatLng}
                                onCloseClick={() => setSelectedMarker(null)}
                            >
                                <Card
                                    style={{
                                        maxWidth: 300,
                                        borderRadius: "10px",
                                    }}
                                >
                                    <CardContent>
                                        <Typography
                                            variant="h6"
                                            color="textPrimary"
                                            gutterBottom
                                        >
                                            {selectedMarker.action}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                        >
                                            <strong>Date: </strong>{" "}
                                            {new Date(
                                                selectedMarker.date
                                            ).toLocaleString()}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                        >
                                            <strong>
                                                Shipment:{" "}
                                                {selectedMarker.shipmentId}
                                            </strong>
                                            {getShipmentLabelByColor(
                                                selectedMarker,
                                                markers.indexOf(selectedMarker)
                                            )}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </InfoWindow>
                        )}
                    </GoogleMap>
                </LoadScript>
            ) : (
                <Grid
                    container
                    spacing={1}
                    style={{ paddingTop: 20, paddingBottom: 20 }}
                >
                    <Grid item md={12}>
                        <Typography variant="body2" color="textSecondary">
                            No Record Found
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default GoogleMapComponent;
const useStyles = makeStyles({
    marginTop: {
        marginTop: 50,
    },
});
