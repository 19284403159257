import { useState } from "react";
import {
    Box,
    Button,
    Grid,
    Typography,
    makeStyles,
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {
    Confirm,
    DateField,
    FileField,
    FunctionField,
    Record,
    ReferenceField,
    TextField,
} from "react-admin";

import ColumnShowLayout from "../../../../components/ColumnShowLayout";
import { useRequest } from "../../../../hooks";
import { useAroggaStyles } from "../../../../utils/useAroggaStyles";
import {
    capitalizeFirstLetterOfEachWord,
    getColorByStatus,
} from "../../../../utils/helpers";
import DescriptionWithModal from "../../../../components/DescriptionWithModal";

const Label = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography variant="body1" className={classes.label}>
            {children}
        </Typography>
    );
};
const OfficePurchaseListTab = ({ record }) => {
    const classes = useStyles();
    const classes2 = useAroggaStyles();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [quotationId, setQuotationId] = useState(null);
    const [action, setAction] = useState("");

    const purchaseItem = record?.office_purchase_orders?.opoi;

    const { data } = useRequest(
        "/v1/quotationItemMapping",
        {},
        { isPreFetching: true }
    );

    const { isLoading, refetch } = useRequest(
        `/v1/officePurchaseOrderAction/${quotationId}/${action}`,
        {
            method: "POST",
            body: {
                ...(action === "productReceiveAction"
                    ? { purchase_status: "product_received" }
                    : { purchase_status: "completed" }),
            },
        },
        {
            isRefresh: true,
            onSuccess: () => setIsDialogOpen(false),
        }
    );
    const confirmTitleAction =
        action === "productReceiveAction" ? "Product Receive" : "complete";

    return (
        <div>
            <div className={classes.buttongrp}>
                {/* permissions?.includes("officePurchaseOrderReceived") && */}
                {record?.office_purchase_orders?.opo_purchase_status ===
                    "pending" && (
                    <Box display="flex">
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            className={classes.button}
                            onClick={() => {
                                setQuotationId(
                                    record?.office_purchase_orders?.id
                                );
                                setAction("productReceiveAction");
                                setIsDialogOpen(true);
                            }}
                        >
                            Product Receive
                        </Button>{" "}
                    </Box>
                )}
                {/* {permissions?.includes("officePurchaseOrderCompleted") && */}
                {record?.office_purchase_orders?.opo_purchase_status ===
                    "product_received" && (
                    <Box display="flex">
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            className={classes.button}
                            onClick={() => {
                                setQuotationId(
                                    record?.office_purchase_orders?.id
                                );
                                setAction("purchaseCompletedAction");
                                setIsDialogOpen(true);
                            }}
                        >
                            Purchase Complete
                        </Button>{" "}
                    </Box>
                )}
            </div>
            <div style={{ padding: 10, margin: "30px 0px" }}>
                <ColumnShowLayout md={2} simpleShowLayout={false}>
                    <TextField
                        source="office_purchase_orders.id"
                        label={<Label>ID</Label>}
                    />
                    <ReferenceField
                        source="office_purchase_orders.opo_supplier_id"
                        label={<Label>Supplier</Label>}
                        reference="v1/supplier"
                        sortBy="opo_supplier_id"
                        link="show"
                    >
                        <TextField source="s_name" />
                    </ReferenceField>
                    <TextField
                        source="office_purchase_orders.opo_item_type"
                        label={<Label>Item Type</Label>}
                    />

                    <TextField
                        source="office_purchase_orders.opo_item_count"
                        label={<Label>Item Count</Label>}
                    />
                    <TextField
                        source="office_purchase_orders.opo_grand_total"
                        label={<Label>Invoice Value</Label>}
                    />
                    <TextField
                        source="office_purchase_orders.opo_paid_amount"
                        label={<Label>Paid Amount</Label>}
                    />
                    <TextField
                        source="office_purchase_orders.opo_due_amount"
                        label={<Label>Due Amount</Label>}
                    />
                    <TextField
                        source="office_purchase_orders.opo_payment_method"
                        label={<Label>Payment Method</Label>}
                        className={classes2.capitalize}
                    />
                    <TextField
                        source="office_purchase_orders.opo_payment_terms"
                        label={<Label>Payment Terms</Label>}
                    />
                    <FunctionField
                        label={<Label>Purchase Status</Label>}
                        onClick={(e) => e.stopPropagation()}
                        render={({ office_purchase_orders }: Record) => {
                            const text = capitalizeFirstLetterOfEachWord(
                                office_purchase_orders.opo_purchase_status
                            );
                            const color = getColorByStatus(
                                office_purchase_orders.opo_purchase_status
                            );
                            return (
                                <Typography
                                    variant="body2"
                                    className={classes.statusButton}
                                    style={{ color: color }}
                                >
                                    {text}
                                </Typography>
                            );
                        }}
                    />
                    <DateField
                        source="office_purchase_orders.opo_created_at"
                        label={<Label>Delivery At</Label>}
                    />
                    <DateField
                        source="office_purchase_orders.opo_created_at"
                        label={<Label>Created At</Label>}
                    />
                    <ReferenceField
                        source="office_purchase_orders.opo_created_by"
                        label={<Label>Created By</Label>}
                        reference="v1/users"
                        sortBy="opo_created_by"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <FunctionField
                        label={<Label>Description</Label>}
                        onClick={(e) => e.stopPropagation()}
                        render={({ office_purchase_orders }: Record) => (
                            <DescriptionWithModal
                                description={
                                    office_purchase_orders.opo_description
                                }
                            />
                        )}
                    />
                    <FunctionField
                        label={<Label>Payment Terms & Condition</Label>}
                        onClick={(e) => e.stopPropagation()}
                        render={({ office_purchase_orders }: Record) => (
                            <DescriptionWithModal
                                description={
                                    office_purchase_orders.opo_payment_term_condition
                                }
                            />
                        )}
                    />
                    <FileField
                        source="office_purchase_orders.attachedFiles_opo_attachment"
                        src="src"
                        title="title"
                        target="_blank"
                        label={<Label>Attachment Files</Label>}
                        // @ts-ignore
                        onClick={(e) => e.stopPropagation()}
                        sortable={false}
                    />
                </ColumnShowLayout>
                {purchaseItem?.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Quantity </TableCell>
                                    <TableCell>Unit</TableCell>
                                    <TableCell>Unit Price</TableCell>
                                    <TableCell>Base Value</TableCell>
                                    <TableCell>VDS</TableCell>
                                    <TableCell>TDS</TableCell>
                                    <TableCell>Discount</TableCell>
                                    <TableCell>Invoice Value</TableCell>
                                    <TableCell>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {purchaseItem?.map((row, index) => {
                                    const main = data?.find(
                                        (item) =>
                                            item?.qim_id === row?.opoi_item_id
                                    );

                                    return (
                                        <TableRow key={index}>
                                            <TableCell
                                                component="th"
                                                align="left"
                                            >
                                                {row.id}
                                            </TableCell>
                                            <TableCell align="left">
                                                {main?.qim_name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.opoi_quantity}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.opoi_unit}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.opoi_unit_price}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.opoi_total_price}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.opoi_vat}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.opoi_tax}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.opoi_discount}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.opoi_grand_total}
                                            </TableCell>
                                            <TableCell align="left">
                                                <DescriptionWithModal
                                                    description={
                                                        row.opoi_description
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Grid
                        style={{
                            borderBottom: "1px solid #E0E0E0",
                            paddingTop: 20,
                            paddingBottom: 20,
                        }}
                        container
                        spacing={1}
                    >
                        <Grid alignItems="center" item md={2} container>
                            <Typography variant="body2" color="textSecondary">
                                No Record Found
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </div>

            <Confirm
                isOpen={isDialogOpen}
                loading={isLoading}
                title={`Are you sure you want to move status to  ${confirmTitleAction} for this quotations #${quotationId}?`}
                content={false}
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
        </div>
    );
};
const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: 10,
        textTransform: "capitalize",
    },
    buttongrp: {
        display: "flex",
        marginTop: 20,
        justifyContent: "end",
    },
    statusButton: {
        textTransform: "uppercase",
        fontWeight: "bolder",
    },
    label: {
        fontWeight: "bolder",
        color: "black",
        fontSize: 16,
    },
}));

export default OfficePurchaseListTab;
