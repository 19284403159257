import { Box } from "@material-ui/core";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import { useState } from "react";
import {
    ArrayField,
    Button,
    Confirm,
    Datagrid,
    FunctionField,
    Link,
    Record,
    SelectInput,
    TextField,
    TextInput,
    usePermissions,
} from "react-admin";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import { isEmpty } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import AroggaDateField from "../../AroggaDateField";
import LoaderOrButton from "../../LoaderOrButton";
import Tracking from "../../Tracking";
import ShipmentExpand from "./ShipmentExpand";

const Shipment = (props) => {
    const classes = useAroggaStyles();
    const { permissions } = usePermissions();
    const { values } = useFormState();

    const [isLoadShipment, setIsLoadShipment] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [shipmentId, setShipmentId] = useState(null);
    const [action, setAction] = useState("");

    const orderId = props?.record?.id;

    const { data: shipments, refetch: refetchShipments } = useRequest(
        `/v1/shipment?_page=1&_perPage=2000&_order=DESC&_orderBy=id&_details=1&_order_id=${orderId}`
    );

    let restEndpoint, body;

    if (action === "create parcel") {
        restEndpoint = "manualParcelCreateAction";
    } else if (action === "mark as delivered") {
        restEndpoint = "markAsDelivered";
    } else if (action === "Courier status update") {
        restEndpoint = "courierStatusUpdate";
    } else {
        restEndpoint = action.includes("reschedule")
            ? "reScheduleApprovalAction"
            : "shipmentCancelRequestApprovalAction";
    }

    if (action === "create parcel") {
        body = {
            courier: values.courier,
            tracking_id: values.trackingId,
        };
    } else if (action === "Courier status update") {
        body = {};
    } else if (action !== "mark as delivered") {
        body = {
            [action.includes("reschedule")
                ? "s_re_schedule_approval_status"
                : "s_cancel_requested_approval_status"]:
                action.includes("approve") || action === "mark as cancelled"
                    ? "approved"
                    : "rejected",
        };
    }

    const { isLoading, refetch } = useRequest(
        `/v1/shipmentAction/${shipmentId}/${restEndpoint}`,
        {
            method: "POST",
            body,
        },
        {
            isRefresh: true,
            onSuccess: () => handleDialogClose(),
        }
    );

    const rowStyle = (row) =>
        ["cancelled", "rescheduled"].includes(row?.s_status)
            ? { backgroundColor: "rgb(255 229 229)" }
            : {};

    const handleDialogClose = () => {
        values.courier = undefined;
        values.trackingId = undefined;
        setIsDialogOpen(false);
    };

    const renderActionButton = ({
        s_id,
        by,
        approvalStatus,
        label,
        action,
        className,
    }: {
        s_id: number;
        by: number;
        approvalStatus: string;
        label: string;
        action: string;
        className?: string;
    }) =>
        !!by &&
        approvalStatus === "" && (
            <Button
                label={label}
                variant="contained"
                className={`${className} ${classes.whitespaceNowrap}`}
                onClick={() => {
                    setShipmentId(s_id);
                    setAction(action);
                    setIsDialogOpen(true);
                }}
            />
        );

    return (
        <>
            {!isLoadShipment && (
                <LoaderOrButton
                    label="Load Shipment"
                    isLoading={false}
                    display="flex"
                    justifyContent="center"
                    mt={3}
                    mb={4}
                    onClick={() => {
                        refetchShipments();
                        setIsLoadShipment(true);
                    }}
                />
            )}
            {isLoadShipment && (
                <ArrayField
                    source="shipments"
                    record={{ id: orderId, shipments }}
                >
                    <Datagrid
                        expand={<ShipmentExpand />}
                        isRowExpandable={(row) => !!row?.si?.length}
                        rowStyle={rowStyle}
                        classes={{ expandedPanel: classes.expandedPanel }}
                    >
                        <FunctionField
                            label="ID"
                            onClick={(e) => e.stopPropagation()}
                            render={({
                                s_id,
                                s_order_id,
                                s_sequence,
                            }: Record) => (
                                <Link to={`/v1/shipment/${s_id}/show`}>
                                    {s_order_id}
                                    {s_sequence}
                                </Link>
                            )}
                        />
                        <FunctionField
                            label="Cold"
                            sortBy="s_m_cold"
                            render={(record: Record) => {
                                if (!record.s_m_cold) return;
                                return <AcUnitIcon />;
                            }}
                        />
                        <TextField
                            source="s_type"
                            label="Shipment Type"
                            className={classes.capitalize}
                        />
                        <TextField
                            source="i_type"
                            label="Issue Type"
                            className={classes.capitalize}
                        />
                        <FunctionField
                            label="Shipment Status"
                            sortBy="s_status"
                            render={(record: Record) => (
                                <span
                                    className={`${classes.capitalize} ${
                                        record.s_status === "pending" &&
                                        classes.textRed
                                    }`}
                                >
                                    {record?.s_status}
                                </span>
                            )}
                        />
                        <AroggaDateField
                            source="s_re_scheduled_at"
                            label="Rescheduled At"
                            addLabel={false}
                        />
                        <TextField
                            source="s_re_scheduled_reason"
                            label="Rescheduled Reason"
                        />
                        <AroggaDateField
                            source="s_cancel_requested_at"
                            label="Cancel At"
                            addLabel={false}
                        />
                        <TextField
                            source="s_cancel_request_reason"
                            label="Cancel Reason"
                        />
                        <FunctionField
                            label="Tracking"
                            render={({ tracking }: Record) => (
                                <Tracking data={tracking} />
                            )}
                        />
                        <FunctionField
                            label="Action"
                            render={({
                                s_id,
                                s_status,
                                s_re_scheduled_by,
                                s_re_schedule_approval_status,
                                s_cancel_requested_by,
                                s_cancel_requested_approval_status,
                                s_is_outside_dhaka,
                                tracking,
                            }: Record) => (
                                <Box display="flex" gridGap={5}>
                                    {renderActionButton({
                                        s_id,
                                        by: s_re_scheduled_by,
                                        approvalStatus:
                                            s_re_schedule_approval_status,
                                        label: "Reschedule ✔",
                                        action: "approve reschedule",
                                    })}
                                    {renderActionButton({
                                        s_id,
                                        by: s_re_scheduled_by,
                                        approvalStatus:
                                            s_re_schedule_approval_status,
                                        label: "Reschedule ✖",
                                        action: "reject reschedule",
                                        className: classes.bgRed,
                                    })}
                                    {renderActionButton({
                                        s_id,
                                        by: s_cancel_requested_by,
                                        approvalStatus:
                                            s_cancel_requested_approval_status,
                                        label: "Cancel Req ✔",
                                        action: "approve cancel request",
                                    })}
                                    {renderActionButton({
                                        s_id,
                                        by: s_cancel_requested_by,
                                        approvalStatus:
                                            s_cancel_requested_approval_status,
                                        label: "Cancel Req ✖",
                                        action: "reject cancel request",
                                        className: classes.bgRed,
                                    })}
                                    {permissions?.includes(
                                        "adminMarkAsDelivered"
                                    ) &&
                                        values.po_payment_status === "paid" &&
                                        (s_status === "sorted" ||
                                            s_status === "in_bag" ||
                                            s_status === "delivering") && (
                                            <Button
                                                label="Mark As Delivered"
                                                variant="contained"
                                                className={
                                                    classes.whitespaceNowrap
                                                }
                                                onClick={() => {
                                                    setShipmentId(s_id);
                                                    setAction(
                                                        "mark as delivered"
                                                    );
                                                    setIsDialogOpen(true);
                                                }}
                                            />
                                        )}
                                    {permissions?.includes("shipmentView") &&
                                        !isEmpty(tracking) &&
                                        (s_status === "in_bag" ||
                                            s_status === "delivering") && (
                                            <Button
                                                label="Courier status update"
                                                variant="contained"
                                                className={`${classes.capitalize} ${classes.whitespaceNowrap}`}
                                                onClick={() => {
                                                    setShipmentId(s_id);
                                                    setAction(
                                                        "Courier status update"
                                                    );
                                                    setIsDialogOpen(true);
                                                }}
                                            />
                                        )}
                                    {permissions?.includes(
                                        "adminMarkAsCancelled"
                                    ) &&
                                        s_status !== "cancelled" && (
                                            <Button
                                                label="Mark As Cancelled"
                                                variant="contained"
                                                className={
                                                    classes.whitespaceNowrap
                                                }
                                                onClick={() => {
                                                    setShipmentId(s_id);
                                                    setAction(
                                                        "mark as cancelled"
                                                    );
                                                    setIsDialogOpen(true);
                                                }}
                                            />
                                        )}
                                    {!!s_is_outside_dhaka &&
                                        (s_status === "in_bag" ||
                                            s_status === "delivering") &&
                                        isEmpty(tracking) && (
                                            <Button
                                                label="Create Parcel"
                                                variant="contained"
                                                className={`${classes.capitalize} ${classes.whitespaceNowrap}`}
                                                onClick={() => {
                                                    setShipmentId(s_id);
                                                    setAction("create parcel");
                                                    setIsDialogOpen(true);
                                                }}
                                            />
                                        )}
                                </Box>
                            )}
                        />
                    </Datagrid>
                </ArrayField>
            )}
            <Confirm
                title={
                    action === "create parcel"
                        ? `Are you sure you want to ${action} this shipment? #${shipmentId}`
                        : `Shipment #${shipmentId}`
                }
                content={
                    action !== "create parcel" ? (
                        `Are you sure you want to ${action} this shipment?`
                    ) : (
                        <>
                            <SelectInput
                                source="courier"
                                label="Courier"
                                variant="outlined"
                                helperText={false}
                                choices={[
                                    {
                                        id: "ecourier",
                                        name: "eCourier",
                                    },
                                    {
                                        id: "pathao",
                                        name: "Pathao",
                                    },
                                    {
                                        id: "redx",
                                        name: "Redx",
                                    },
                                ]}
                                fullWidth
                            />
                            <TextInput
                                source="trackingId"
                                label="Tracking ID"
                                variant="outlined"
                                helperText={false}
                                fullWidth
                            />
                        </>
                    )
                }
                isOpen={isDialogOpen}
                loading={isLoading}
                onConfirm={refetch}
                onClose={handleDialogClose}
            />
        </>
    );
};

export default Shipment;
