import { FC, useEffect, useState } from "react";
import {
    AutocompleteInput,
    DateInput,
    Edit,
    EditProps,
    FileField,
    FileInput,
    NumberInput,
    ReferenceInput,
    SimpleForm,
    TextInput,
} from "react-admin";
import { FormSpy } from "react-final-form";

import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import UserNameRankOptionTextRenderer from "../../../components/UserNameRankOptionTextRenderer";
import LedgerMethodInput from "../../../components/manageFinance/ledgers/LedgerMethodInput";
import dataProvider from "../../../dataProvider/dataProvider";
import { useDocumentTitle } from "../../../hooks";
import { IMAGE_FILE_MAX_SIZE } from "../../../utils/constants";
import { autoGeneratedLedgerReason, logger } from "../../../utils/helpers";

const LedgerEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Ledger Edit");

    const [username, setUsername] = useState("");
    const [rank, setRank] = useState("");
    const [formValues, setFormValues] = useState<any>({});

    const { l_a_date, l_a_uid } = formValues;

    useEffect(() => {
        if (!l_a_uid) return;

        dataProvider
            .getOne("v1/users", { id: l_a_uid })
            .then(({ data }) => {
                setUsername(data?.u_name);
                setRank(data?.u_rank);
            })
            .catch((err) => logger(err));
    }, [l_a_uid]);

    if (formValues?.l_type === "salary_and_allowances") {
        formValues.l_reason = autoGeneratedLedgerReason({
            l_a_date,
            username,
            rank,
            l_a_uid,
        });
    }

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm>
                <TextInput
                    source="l_created"
                    label="Date"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
                <TextInput
                    source="u_name"
                    label="Added By"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
                <TaxonomiesByVocabularyInput
                    fetchKey="ledger_types"
                    source="l_type"
                    label="Type"
                    helperText={false}
                />
                <LedgerMethodInput
                    source="l_method"
                    defaultValue="Cash"
                    variant="outlined"
                    choices={[
                        {
                            id: "Payable-paid",
                            name: "Payable-paid",
                        },
                    ]}
                />
                {formValues?.l_type === "salary_and_allowances" ? (
                    <div style={{ width: 256 }}>
                        <ReferenceInput
                            source="l_a_uid"
                            label="User"
                            variant="outlined"
                            helperText={false}
                            reference="v1/users"
                            filter={{
                                _isEmployee: true,
                            }}
                            onSelect={(item) => {
                                setUsername(item.u_name);
                                setRank(item.u_rank);
                            }}
                            fullWidth
                        >
                            <AutocompleteInput
                                matchSuggestion={() => true}
                                optionValue="u_id"
                                inputText={(value) => value?.u_name}
                                optionText={<UserNameRankOptionTextRenderer />}
                                resettable
                            />
                        </ReferenceInput>
                        <DateInput
                            source="l_a_date"
                            label="Date"
                            variant="outlined"
                            helperText={false}
                            fullWidth
                        />
                    </div>
                ) : (
                    <TextInput
                        source="l_reason"
                        label="Reason"
                        variant="outlined"
                        helperText={false}
                        minRows={2}
                        multiline
                    />
                )}
                <NumberInput
                    source="l_amount"
                    label="Amount"
                    variant="outlined"
                    helperText={false}
                    disabled={!permissions?.includes("updateLedgerAmount")}
                />
                <FileInput
                    source="attachedFiles"
                    label="Related Files"
                    accept="image/*, application/pdf"
                    maxSize={IMAGE_FILE_MAX_SIZE}
                    options={{ maxSize: 5 }}
                    multiple
                >
                    <FileField source="src" title="title" />
                </FileInput>
                <FormSpy
                    subscription={{ values: true }}
                    onChange={({ values }) =>
                        // Fix bad setState() call inside `FormSpy` error using setTimeout
                        setTimeout(() => {
                            setFormValues(values);
                        }, 0)
                    }
                />
            </SimpleForm>
        </Edit>
    );
};

export default LedgerEdit;
