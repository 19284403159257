import { FC } from "react";
import { Create, CreateProps, SimpleForm } from "react-admin";

import { useDocumentTitle } from "../../../hooks";
import ExpenseHeadMappingForm from "../../../components/manageAccounting/expenseHeadMapping/ExpenseHeadMappingForm";

const ExpenseHeadMappingCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga |Expense Head Mapping Create");

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <ExpenseHeadMappingForm />
            </SimpleForm>
        </Create>
    );
};

export default ExpenseHeadMappingCreate;
