import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";
import {
    SaveButton,
    SimpleForm,
    Toolbar,
    useNotify,
    useRefresh,
} from "react-admin";

import { uploadDataProvider } from "../../../dataProvider";
import PurchaseRequisitionForm from "./PurchaseRequisitionForm";

const PurchaseRequisitionEdit = ({
    record,
    isRequisitionDialogOpen,
    setIsRequisitionDialogOpen,
}) => {
    const classes = useStyles();
    const refresh = useRefresh();
    const notify = useNotify();
    const handleDialogClose = () => {
        setIsRequisitionDialogOpen(false);
    };

    const onSave = async (data) => {
        const payload = {
            ...data,
        };
        try {
            await uploadDataProvider.create(
                `v1/purchaseRequisition/${record.pr_id}`,
                {
                    data: payload,
                }
            );
            notify("Successfully Created!", { type: "success" });
            handleDialogClose();
            refresh();
        } catch (err: any) {
            notify(err.message || "Failed!", { type: "warning" });
        }
    };
    const CustomToolbar = (props) => (
        <Toolbar {...props}>
            <SaveButton label="Confirm" />
            <Box>
                <Button
                    onClick={handleDialogClose}
                    className={classes.closeButton}
                >
                    Cancel
                </Button>
            </Box>
        </Toolbar>
    );
    return (
        <Dialog
            open={isRequisitionDialogOpen}
            onClose={handleDialogClose}
            maxWidth="sm"
        >
            <DialogTitle>Purchase Requisition Edit</DialogTitle>
            <DialogContent>
                <SimpleForm save={onSave} toolbar={<CustomToolbar />}>
                    <PurchaseRequisitionForm />
                </SimpleForm>
            </DialogContent>
        </Dialog>
    );
};

export default PurchaseRequisitionEdit;

const useStyles = makeStyles((theme) => ({
    closeButton: {
        marginLeft: 10,
        backgroundColor: "red",
        color: "white",
        "&:hover": {
            backgroundColor: "#f58989",
        },
    },
}));
