import { FC, useEffect, useState } from "react";
import { AutocompleteInput, Record } from "react-admin";

import { isEmpty } from "../../../utils/helpers";

type UserLocationAutocompleteInputProps = {
    newlyAddedLocation?: any;
    [key: string]: any;
};

const UserLocationAutocompleteInput: FC<UserLocationAutocompleteInputProps> = ({
    newlyAddedLocation,
    ...rest
}) => {
    const [userLocationId, setUserLocationId] = useState("");

    if (!isEmpty(newlyAddedLocation)) {
        rest.choices = [newlyAddedLocation, ...rest.choices];
    }

    useEffect(() => {
        setUserLocationId(rest?.choices?.[0]?.ul_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest?.choices?.[0]?.ul_id]);

    return (
        <AutocompleteInput
            initialValue={userLocationId}
            optionText={<OptionTextRenderer />}
            inputText={(record) =>
                !!record && record.ul_id
                    ? `${record.ul_location} (${record.ul_name}, ${record.ul_mobile})`
                    : ""
            }
            options={{
                InputProps: { multiline: true },
            }}
            {...rest}
        />
    );
};

export default UserLocationAutocompleteInput;

const OptionTextRenderer = ({ record }: { record?: Record }) => (
    <span style={{ color: !record?.ul_id ? "#EF1962" : "" }}>
        {!!record?.ul_id
            ? `${record.ul_location} (${record.ul_name}, ${record.ul_mobile})`
            : ""}
    </span>
);
