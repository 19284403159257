import { FC } from "react";
import {
    FunctionField,
    NumberField,
    Record,
    ReferenceField,
    Show,
    ShowProps,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

const HeadShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Head Show");

    const classes = useAroggaStyles();

    return (
        <Show {...props}>
            <ColumnShowLayout>
                <TextField source="ah_id" label="ID" />
                <TextField source="ah_acc_code" label="Accounting Code" />
                <TextField source="ah_name" label="Account Name" />
                <FunctionField
                    label="Ledger Type"
                    render={({ ah_ledger_type }: Record) =>
                        capitalizeFirstLetterOfEachWord(ah_ledger_type)
                    }
                />
                <ReferenceField
                    source="ah_parent_id"
                    label="Parent Name"
                    reference="v1/accountingHead"
                >
                    <TextField source="ah_name" />
                </ReferenceField>
                <TextField source="ah_has_child" label="Has Child" />
                <TextField
                    source="ah_type"
                    label="Type"
                    className={classes.capitalize}
                />
                <ReferenceField
                    source="ah_group_id"
                    label="Group"
                    reference="v1/accountingHeadGroup"
                    link={false}
                >
                    <TextField source="ahg_name" />
                </ReferenceField>
                <NumberField
                    source="ah_opening_balance_type"
                    label="Balance Type"
                    className={classes.capitalize}
                />
                <NumberField
                    source="ah_opening_balance"
                    label="Opening Balance"
                />
                <NumberField
                    source="ah_current_balance"
                    label="Curr. Head Balance"
                />
                <TextField
                    source="ah_opening_balance_type"
                    label="Opening Balance Type"
                    className={classes.capitalize}
                />
                <TextField source="ah_weight" label="Weight" />
                <AroggaDateField source="ah_created_at" label="Created At" />
                <ReferenceField
                    source="ah_created_by"
                    label="Created By"
                    reference="v1/users"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
                <AroggaDateField source="ah_modified_at" label="Modified At" />
                <ReferenceField
                    source="ah_modified_by"
                    label="Modified By"
                    reference="v1/users"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
                <AroggaDateField source="ah_deleted_at" label="Deleted At" />
                <ReferenceField
                    source="ah_deleted_by"
                    label="Deleted By"
                    reference="v1/users"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
            </ColumnShowLayout>
        </Show>
    );
};

export default HeadShow;
