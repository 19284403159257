import { FC } from "react";
import {
    ArrayField,
    Datagrid,
    NumberField,
    ReferenceField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

const TransactionShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Transaction Show");

    const classes = useAroggaStyles();

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ColumnShowLayout md={6} simpleShowLayout={false}>
                    <TextField source="at_id" label="ID" />
                    <TextField
                        source="at_type"
                        label="Type"
                        className={classes.capitalize}
                    />
                    <AroggaDateField source="at_date" label="Date" />
                    <TextField source="at_narration" label="Narration" />
                    <AroggaDateField
                        source="at_created_at"
                        label="Created At"
                    />
                    <ReferenceField
                        source="at_created_by"
                        label="Created By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="at_modified_at"
                        label="Modified At"
                    />
                    <ReferenceField
                        source="at_modified_by"
                        label="Modified By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="at_deleted_at"
                        label="Deleted At"
                    />
                    <ReferenceField
                        source="at_deleted_by"
                        label="Deleted By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                </ColumnShowLayout>
                <ArrayField source="atd" label="Transaction Details">
                    <Datagrid>
                        <TextField source="atd_id" label="ID" />
                        <TextField
                            source="atd_type"
                            label="Type"
                            className={classes.capitalize}
                        />
                        <ReferenceField
                            source="atd_account_head_id"
                            label="Ledger"
                            reference="v1/accountingHead"
                            link="show"
                        >
                            <TextField source="ah_name" />
                        </ReferenceField>
                        <NumberField source="atd_amount" label="Amount" />
                        <AroggaDateField
                            source="atd_created_at"
                            label="Created At"
                        />
                        <ReferenceField
                            source="atd_created_by"
                            label="Created By"
                            reference="v1/users"
                            link="show"
                        >
                            <TextField source="u_name" />
                        </ReferenceField>
                        <AroggaDateField
                            source="atd_modified_at"
                            label="Modified At"
                        />
                        <ReferenceField
                            source="atd_modified_by"
                            label="Modified By"
                            reference="v1/users"
                            link="show"
                        >
                            <TextField source="u_name" />
                        </ReferenceField>
                        <AroggaDateField
                            source="atd_deleted_at"
                            label="Deleted At"
                        />
                        <ReferenceField
                            source="atd_deleted_by"
                            label="Deleted By"
                            reference="v1/users"
                            link="show"
                        >
                            <TextField source="u_name" />
                        </ReferenceField>
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>
    );
};

export default TransactionShow;
