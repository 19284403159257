import { FC } from "react";
import {
    FunctionField,
    List,
    ListProps,
    ReferenceField,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import {
    useDocumentTitle,
    useExport,
    useNavigateFromList,
} from "../../../hooks";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import SupplierFilter from "./SupplierFilter";
import { CustomizableDatagrid } from "../../../lib";
import { getColorByStatus } from "../../../utils/helpers";

const SupplierList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Supplier List");

    const classes = useAroggaStyles();
    const exporter = useExport(rest);
    const navigateFromList = useNavigateFromList(
        "supplierView",
        "supplierEdit"
    );

    return (
        <>
            <List
                {...rest}
                title="List of Supplier"
                filters={<SupplierFilter children={""} />}
                perPage={25}
                sort={{ field: "s_id", order: "DESC" }}
                exporter={exporter}
                bulkActionButtons={false}
            >
                <CustomizableDatagrid
                    rowClick={navigateFromList}
                    hideableColumns={["s_created_at", "s_created_by"]}
                >
                    <TextField source="s_id" label="ID" />
                    <TextField source="s_name" label="Name" />
                    <TextField source="s_mobile" label="Phone" />
                    <TextField source="s_email" label="Email" />
                    <TextField
                        source="s_payment_method"
                        label="Payment Method"
                        className={classes.capitalize}
                    />
                    <ReferenceField
                        label="Bank"
                        source="s_bank_id"
                        reference="v1/bank"
                    >
                        <TextField source="b_name" />
                    </ReferenceField>
                    <FunctionField
                        label="Status"
                        sortBy="s_status"
                        render={(record) => {
                            const color = getColorByStatus(record.s_status);
                            return (
                                <span
                                    className={classes.statusBtn}
                                    style={{
                                        color: color,
                                        backgroundColor: color + "10",
                                    }}
                                >
                                    {record.s_status}
                                </span>
                            );
                        }}
                    />
                    <AroggaDateField source="s_created_at" label="Created At" />
                    <ReferenceField
                        source="s_created_by"
                        label="Created By"
                        reference="v1/users"
                        sortBy="s_created_by"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                </CustomizableDatagrid>
            </List>
        </>
    );
};

export default SupplierList;
