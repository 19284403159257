import AcUnitIcon from "@material-ui/icons/AcUnit";
import {
    BooleanField,
    CloneButton,
    FunctionField,
    Record,
    ReferenceField,
    TextField,
} from "react-admin";

import { useNavigateFromList } from "../../../hooks";
import { CustomizableDatagrid } from "../../../lib";
import {
    capitalizeFirstLetterOfEachWord,
    getReadableSKU,
} from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import AroggaDateField from "../../AroggaDateField";

const ProductDatagrid = (props): any => {
    const classes = useAroggaStyles();
    const navigateFromList = useNavigateFromList("productView", "productEdit");

    const { permissions, ...rest } = props || {};

    const isCampaign = !!props?.isCampaign;

    return (
        <CustomizableDatagrid
            {...rest}
            rowClick={navigateFromList}
            hideableColumns={[
                "p_rx_req",
                "p_allow_sales",
                "p_allow_purchase",
                "p_created_at",
                "p_created_by",
            ]}
        >
            <TextField source="p_id" label={isCampaign ? "Product ID" : "ID"} />
            {isCampaign && (
                <FunctionField
                    label="Variant ID"
                    render={(record: Record) => {
                        return record?.pv?.[0]?.pv_id;
                    }}
                />
            )}
            <TextField source="p_name" label="Name" />
            {isCampaign && (
                <FunctionField
                    label="Attribute"
                    render={(record: Record) =>
                        getReadableSKU(record?.pv?.[0]?.pv_attribute)
                    }
                />
            )}
            <TextField
                source="p_type"
                label="Type"
                className={classes.capitalize}
            />
            <ReferenceField
                source="p_generic_id"
                label="Generic"
                reference="v1/generics"
                sortBy="p_generic_id"
                link="show"
            >
                <TextField source="g_name" />
            </ReferenceField>
            <TextField source="p_strength" label="Strength" />
            <FunctionField
                source="p_form"
                label="Form"
                render={(record: Record) =>
                    capitalizeFirstLetterOfEachWord(record?.p_form)
                }
            />
            <FunctionField
                source="p_cold"
                label="Cold"
                render={(record: Record) => {
                    if (!record.p_cold) return;
                    return <AcUnitIcon />;
                }}
            />
            <BooleanField
                source="p_rx_req"
                label="Rx Required?"
                FalseIcon={() => null}
                looseValue
            />
            <FunctionField
                source="p_active_status"
                label="Status"
                render={(record: Record) => (
                    <span
                        className={`${classes.capitalize} ${
                            record.p_active_status === "inactive" &&
                            classes.textRed
                        }`}
                    >
                        {record?.p_active_status}
                    </span>
                )}
            />
            <ReferenceField
                source="p_brand_id"
                label="Brand"
                reference="v1/productBrand"
                sortBy="p_brand_id"
                link="show"
            >
                <TextField source="pb_name" />
            </ReferenceField>
            <BooleanField
                source="p_allow_sales"
                label="Allow Sales?"
                FalseIcon={() => null}
                looseValue
            />
            <BooleanField
                source="p_allow_purchase"
                label="Allow Purchase?"
                FalseIcon={() => null}
                looseValue
            />
            <TextField source="p_weight" label="Weight" />
            <AroggaDateField source="p_created_at" label="Created At" />
            <ReferenceField
                source="p_created_by"
                label="Created By"
                reference="v1/users"
                sortBy="p_created_by"
                link="show"
            >
                <TextField source="u_name" />
            </ReferenceField>
            {!isCampaign && permissions?.includes("productCreate") && (
                <FunctionField
                    label="Clone"
                    render={(record: Record) => (
                        // @ts-ignore
                        <CloneButton
                            {...rest}
                            record={{
                                cloneActionFrom: "productList",
                                ...record,
                                pv: record?.pv?.map?.((variant) => ({
                                    ...variant,
                                    attachedFiles_pv_images: [],
                                })),
                                attachedFiles_p_images: [],
                            }}
                        />
                    )}
                />
            )}
        </CustomizableDatagrid>
    );
};

export default ProductDatagrid;
