import { Button, TableBody, TableCell, TableRow } from "@material-ui/core";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import ClearIcon from "@material-ui/icons/Clear";
import { FC, KeyboardEvent, useState } from "react";
import { Link } from "react-admin";

import {
    getQuantityLabel,
    getReadableSKU,
    isEmpty,
    numberFormat,
    toFixedNumber,
} from "../../../../utils/helpers";
import { useAroggaStyles } from "../../../../utils/useAroggaStyles";
import ChangeProductDialog from "../ChangeProductDialog";

type ProductTableBodyProps = {
    productOrderItems: any[];
    setProductOrderItems: (items: object[]) => void;
    handleOnKeyDown: (e: KeyboardEvent) => void;
    handleOnBlur: ({
        value,
        productVariantId,
    }: {
        value: any;
        productVariantId: number;
    }) => void;
    handleRemove: (productVariantId: number) => void;
};

const ProductTableBody: FC<ProductTableBodyProps> = ({
    productOrderItems,
    setProductOrderItems,
    handleOnKeyDown,
    handleOnBlur,
    handleRemove,
}) => {
    const classes = useAroggaStyles();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [changeableProduct, setChangeableProduct] = useState(null);

    const hasItemUnavailable = productOrderItems?.some(
        (item) => item?.poi_item_unavailable
    );

    const renderComparison = (oldValue, newValue) => {
        if (
            oldValue &&
            newValue &&
            oldValue.toString().toLowerCase() ===
                newValue.toString().toLowerCase()
        )
            return;

        return (
            <span
                style={{
                    color: "rgb(156 156 156)",
                    textDecoration: "line-through",
                }}
            >
                {oldValue}
            </span>
        );
    };

    const renderRow = (item, i) => {
        const {
            p_name,
            p_brand,
            pv_attribute,
            p_unit_label,
            p_form,
            p_strength,
            p_cold,
            baseUnit,
            salesUnitMultiplier,
            poi_mrp_price,
            poi_discount_price,
        } = item?.oldData || {};

        return (
            <TableRow key={i}>
                <TableCell
                    className={
                        isEmpty(item.poi_stock_mapping)
                            ? classes.whitespaceNowrap
                            : ""
                    }
                >
                    {i + 1} {renderStockStatus(item)}
                </TableCell>
                {process.env.REACT_APP_NODE_ENV === "development" && (
                    <TableCell>{item.poi_id}</TableCell>
                )}
                <TableCell>
                    <Link
                        to={`/v1/product/${item.poi_product_id}/edit`}
                        target="_blank"
                    >
                        {item.p_name} {renderUnavailableStatus(item)}
                    </Link>
                    <br />
                    {p_name && renderComparison(p_name, item.p_name)}
                    {renderDrugInfo(item)}
                </TableCell>
                <TableCell>
                    {item.productVariant || getReadableSKU(item.pv_attribute)}
                    <br />
                    {pv_attribute &&
                        renderComparison(
                            getReadableSKU(pv_attribute),
                            item.productVariant ||
                                getReadableSKU(item.pv_attribute)
                        )}
                </TableCell>
                <TableCell className={classes.capitalize}>
                    {item.p_form}
                    <br />
                    {p_form && renderComparison(p_form, item.p_form)}
                </TableCell>
                <TableCell>
                    {item.p_strength}
                    <br />
                    {p_strength &&
                        renderComparison(p_strength, item.p_strength)}
                </TableCell>
                <TableCell>
                    {!!item.p_cold && <AcUnitIcon />}
                    <br />
                    {!!p_cold && renderComparison(p_cold, item.p_cold)}
                </TableCell>
                <TableCell>
                    <Link to={`/v1/productBrand/${item.p_brand_id}/show`}>
                        {item.p_brand}
                        <br />
                        {p_brand && renderComparison(p_brand, item.p_brand)}
                    </Link>
                </TableCell>
                <TableCell
                    align="right"
                    contentEditable="true"
                    suppressContentEditableWarning={true}
                    onKeyDown={handleOnKeyDown}
                    onBlur={(e) =>
                        handleOnBlur({
                            value: +e.currentTarget.innerText.trim(),
                            productVariantId: item.poi_product_variant_id,
                        })
                    }
                >
                    {item.poi_product_qty}
                </TableCell>
                <TableCell className={classes.whitespaceNowrap}>
                    {getQuantityLabel({
                        qty: item.poi_product_qty,
                        salesUnit: item.productUnit || item.p_unit_label,
                        baseUnit: item.baseUnit || item.product_base_unit,
                        salesUnitMultiplier:
                            item.salesUnitMultiplier || item.pu_multiplier,
                    })}
                    <br />
                    {p_unit_label &&
                        renderComparison(
                            getQuantityLabel({
                                qty: item.poi_product_qty,
                                salesUnit: p_unit_label,
                                baseUnit,
                                salesUnitMultiplier,
                            }),
                            getQuantityLabel({
                                qty: item.poi_product_qty,
                                salesUnit:
                                    item.productUnit || item.p_unit_label,
                                baseUnit:
                                    item.baseUnit || item.product_base_unit,
                                salesUnitMultiplier:
                                    item.salesUnitMultiplier ||
                                    item.pu_multiplier,
                            })
                        )}
                </TableCell>
                <TableCell align="right">
                    {numberFormat(
                        toFixedNumber(item.poi_product_qty * item.poi_mrp_price)
                    )}
                    <br />
                    {!!poi_mrp_price &&
                        renderComparison(
                            numberFormat(
                                toFixedNumber(
                                    item.poi_product_qty * poi_mrp_price
                                )
                            ),
                            numberFormat(
                                toFixedNumber(
                                    item.poi_product_qty * item.poi_mrp_price
                                )
                            )
                        )}
                </TableCell>
                <TableCell align="right">
                    {numberFormat(
                        toFixedNumber(
                            item.poi_product_qty * item.poi_discount_price
                        )
                    )}
                    <br />
                    {!!poi_discount_price &&
                        renderComparison(
                            numberFormat(
                                toFixedNumber(
                                    item.poi_product_qty * poi_discount_price
                                )
                            ),
                            numberFormat(
                                toFixedNumber(
                                    item.poi_product_qty *
                                        item.poi_discount_price
                                )
                            )
                        )}
                </TableCell>
                {renderChangeButton(item)}
                {renderRemoveButton(item)}
            </TableRow>
        );
    };

    const renderStockStatus = (item) => {
        if (!item?.poi_stock_mapping || !isEmpty(item?.poi_stock_mapping))
            return;

        return <span className={classes.textRed}>(Out of Stock)</span>;
    };

    const renderUnavailableStatus = (item) => {
        if (!item.poi_item_unavailable) return;

        return <span className={classes.textRed}>(Unavailable)</span>;
    };

    const renderDrugInfo = (item) => (
        <div className={classes.textRed}>
            {item.is_antibiotics && item.is_controlled
                ? "(Antibiotics, Controlled Drug)"
                : item.is_antibiotics
                ? "(Antibiotics)"
                : item.is_controlled
                ? "(Controlled Drug)"
                : ""}
        </div>
    );

    const renderChangeButton = (item) => {
        if (!hasItemUnavailable) return;

        return (
            <TableCell>
                {!!item.poi_item_unavailable && (
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            setChangeableProduct({
                                name: item.p_name,
                                variantId: item.poi_product_variant_id,
                                unitId: item.poi_product_unit_id,
                                qty: item.poi_product_qty,
                            });
                            setIsDialogOpen(true);
                        }}
                    >
                        Change
                    </Button>
                )}
            </TableCell>
        );
    };

    const renderRemoveButton = (item) => {
        return (
            <TableCell>
                <Button
                    onClick={() => handleRemove(item.poi_product_variant_id)}
                >
                    <ClearIcon />
                </Button>
            </TableCell>
        );
    };

    return (
        <TableBody>
            {!!productOrderItems?.length &&
                productOrderItems.map((item, i) => renderRow(item, i))}
            <ChangeProductDialog
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                changeableProduct={changeableProduct}
                productOrderItems={productOrderItems}
                setProductOrderItems={setProductOrderItems}
            />
        </TableBody>
    );
};

export default ProductTableBody;
