import { Box } from "@material-ui/core";
import { useState } from "react";
import {
    AutocompleteInput,
    Button,
    ReferenceInput,
    required,
} from "react-admin";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../../hooks";
import FormatedBooleanInput from "../../../FormatedBooleanInput";
import InlineArrayInput from "../../../InlineArrayInput";
import AddKamDialog from "./AddKamDialog";

const VendorsSuppliers = () => {
    const { values } = useFormState();

    const [vendors, setVendors] = useState(null);
    const [selectedVendorIdForKam, setSelectedVendorIdForKam] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useRequest(
        `/v1/vendor?ids=${values?.p_vendor_company_ids
            ?.map((item) => item?.id)
            ?.join(",")}`,
        {},
        {
            isPreFetching: values.p_id,
            onSuccess: ({ data }) => {
                const obj = {};

                data?.forEach((item) => {
                    obj[item.v_id] = {
                        v_kam_user_id: item?.v_kam_user_id,
                    };
                });

                setVendors((prev) => ({
                    ...prev,
                    ...obj,
                }));
            },
        }
    );

    return (
        <div>
            <h3>Official Vendor</h3>
            <InlineArrayInput
                source="p_vendor_company_ids"
                label=""
                validate={[required()]}
                disableRemove={values?.p_vendor_company_ids?.length === 1}
                enableRenderProps
            >
                {({ getSource, scopedFormData }) => (
                    <Box display="flex" gridGap={8} width={1200}>
                        <ReferenceInput
                            source={getSource("id")}
                            record={scopedFormData}
                            label="Vendor"
                            variant="outlined"
                            reference="v1/vendor"
                            filter={{
                                _status: "active",
                                _type: "company",
                            }}
                            filterToQuery={(searchText) => ({
                                _name: searchText,
                            })}
                            onSelect={(item) =>
                                setVendors((prev) => ({
                                    ...prev,
                                    [item.v_id]: {
                                        v_name: item?.v_name,
                                        v_kam_user_id: item?.v_kam_user_id,
                                    },
                                }))
                            }
                            helperText={false}
                            validate={[required()]}
                            fullWidth
                        >
                            <AutocompleteInput
                                matchSuggestion={() => true}
                                optionText="v_name"
                                options={{
                                    InputProps: {
                                        multiline: true,
                                    },
                                }}
                                resettable
                            />
                        </ReferenceInput>
                        {!!scopedFormData?.id && (
                            <ReferenceInput
                                source={getSource("id")}
                                record={scopedFormData}
                                label="Official Vendor ID"
                                variant="outlined"
                                reference="v1/vendor"
                                helperText={false}
                                disabled
                                fullWidth
                            >
                                <AutocompleteInput
                                    optionText={(record) => {
                                        const userName = record?.v_user_name;
                                        const userMobileNo =
                                            record?.v_user_phone || "";

                                        return userName && userMobileNo
                                            ? `${userName} (${userMobileNo})`
                                            : userMobileNo;
                                    }}
                                    options={{
                                        InputProps: {
                                            multiline: true,
                                        },
                                    }}
                                />
                            </ReferenceInput>
                        )}
                        {!!scopedFormData?.id &&
                            !!vendors?.[scopedFormData?.id]?.v_kam_user_id && (
                                <ReferenceInput
                                    source={getSource("id")}
                                    record={scopedFormData}
                                    label="KAM"
                                    variant="outlined"
                                    reference="v1/vendor"
                                    helperText={false}
                                    disabled
                                    fullWidth
                                >
                                    <AutocompleteInput
                                        optionText="v_kam_user_name"
                                        options={{
                                            InputProps: {
                                                multiline: true,
                                            },
                                        }}
                                    />
                                </ReferenceInput>
                            )}
                        {!!scopedFormData?.id &&
                            !vendors?.[scopedFormData?.id]?.v_kam_user_id && (
                                <Button
                                    label="Add Kam"
                                    variant="contained"
                                    style={{
                                        marginTop: 9,
                                        height: 38,
                                    }}
                                    onClick={() => {
                                        setSelectedVendorIdForKam(
                                            scopedFormData?.id
                                        );
                                        setIsDialogOpen(true);
                                    }}
                                />
                            )}
                    </Box>
                )}
            </InlineArrayInput>
            <AddKamDialog
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                setVendors={setVendors}
                selectedVendorIdForKam={selectedVendorIdForKam}
                selectedVendorName={vendors?.[selectedVendorIdForKam]?.v_name}
            />
            <FormatedBooleanInput
                source="p_is_simultaneous"
                label="Send purchase order simultaneously"
                style={{ marginTop: 15, marginLeft: 26 }}
            />
            <Box display="flex">
                <div style={{ width: 999 }}>
                    <h3>Local Vendor</h3>
                    <InlineArrayInput
                        source="p_vendor_local_ids"
                        label=""
                        validate={[required()]}
                    >
                        <ReferenceInput
                            source="id"
                            label="Vendor"
                            variant="outlined"
                            reference="v1/vendor"
                            filter={{
                                _status: "active",
                                _type: "local",
                            }}
                            filterToQuery={(searchText) => ({
                                _name: searchText,
                            })}
                            helperText={false}
                            validate={[required()]}
                        >
                            <AutocompleteInput
                                optionText="v_name"
                                options={{
                                    InputProps: {
                                        multiline: true,
                                    },
                                }}
                                resettable
                            />
                        </ReferenceInput>
                    </InlineArrayInput>
                </div>
                <div style={{ width: "100%" }}>
                    <h3>Foreign Vendor</h3>
                    <InlineArrayInput source="p_vendor_foreign_ids" label="">
                        <ReferenceInput
                            source="id"
                            label="Vendor"
                            variant="outlined"
                            reference="v1/vendor"
                            filter={{
                                _status: "active",
                                _type: "foreign",
                            }}
                            filterToQuery={(searchText) => ({
                                _name: searchText,
                            })}
                            helperText={false}
                            validate={[required()]}
                        >
                            <AutocompleteInput
                                optionText="v_name"
                                options={{
                                    InputProps: {
                                        multiline: true,
                                    },
                                }}
                                resettable
                            />
                        </ReferenceInput>
                    </InlineArrayInput>
                </div>
            </Box>
        </div>
    );
};

export default VendorsSuppliers;
