import { FC } from "react";
import { Filter, FilterProps, SelectInput } from "react-admin";

const PendingReorderFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <SelectInput
            source="_status"
            label="Status"
            variant="outlined"
            choices={[
                { id: "pending", name: "Pending" },
                { id: "approved", name: "Approved" },
                { id: "cancelled", name: "Cancelled" },
            ]}
            alwaysOn
        />
    </Filter>
);

export default PendingReorderFilter;
