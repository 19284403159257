import { Box, Chip } from "@material-ui/core";
import { FC } from "react";
import { FunctionField, Labeled, Record } from "react-admin";

import {
    capitalizeFirstLetterOfEachWord,
    isArrayOfTypeString,
    isEmpty,
} from "../utils/helpers";

type CustomChipFieldProps = {
    source: string;
    label?: string;
    id?: string;
    value?: string;
    page?: "list" | "show";
};

const CustomChipField: FC<CustomChipFieldProps> = ({
    source,
    label = "Label",
    id,
    value,
    page = "show",
}) => {
    const CustomChip = ({ keyIndex, label }) => (
        <Chip
            key={keyIndex}
            label={capitalizeFirstLetterOfEachWord(label)}
            variant="outlined"
            color="primary"
            size="small"
            style={{
                marginRight: 6,
            }}
        />
    );

    const Field = () => (
        <FunctionField
            render={(record: Record) => {
                if (isEmpty(record?.[source])) return;

                if (isArrayOfTypeString(record?.[source]))
                    return (
                        <Box display="flex">
                            {record?.[source]?.map((item, i) => (
                                <CustomChip
                                    keyIndex={i}
                                    label={capitalizeFirstLetterOfEachWord(
                                        item
                                    )}
                                />
                            ))}
                        </Box>
                    );

                return record?.[source]?.map((item, i) => (
                    <CustomChip
                        keyIndex={item?.[id] || i}
                        label={capitalizeFirstLetterOfEachWord(item?.[value])}
                    />
                ));
            }}
        />
    );

    if (page === "show")
        return (
            <Labeled label={label}>
                <Field />
            </Labeled>
        );

    return <Field />;
};

export default CustomChipField;
