import { Box, Button, Typography } from "@material-ui/core";
import { FC, useState } from "react";
import { Confirm, SelectInput, TextInput } from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import { getApiBaseUrl } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

const CallTab: FC = () => {
    const classes = useAroggaStyles();
    const form = useForm();
    const { values } = useFormState();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { method, base, text } = values;

    const { isLoading, refetch } = useRequest(
        base === "onetime" ? `/${base}/${text}` : `/v1/SQS/${base}/${text}`,
        {
            method,
        },
        {
            isBaseUrl: true,
            onSuccess: () => setIsDialogOpen(false),
        }
    );

    const url =
        base === "onetime"
            ? `${getApiBaseUrl().split("/admin")[0]}`
            : `${getApiBaseUrl().split("/admin")[0]}/v1/SQS`;

    const DialogContent = () => (
        <div className={classes.breakAll}>
            <Typography>Method: {method}</Typography>
            <br />
            <Typography>URL: {`${url}/${base}/${text}`}</Typography>
        </div>
    );

    return (
        <Box display="flex" alignItems="center" gridGap={8}>
            <SelectInput
                source="method"
                label="Method"
                variant="outlined"
                helperText={false}
                defaultValue="POST"
                onChange={() => form.change("text", undefined)}
                choices={[
                    {
                        id: "GET",
                        name: "GET",
                    },
                    {
                        id: "POST",
                        name: "POST",
                    },
                ]}
            />
            <Typography>{url}/</Typography>
            <SelectInput
                source="base"
                label="Base"
                variant="outlined"
                helperText={false}
                defaultValue="cron"
                onChange={() => form.change("text", undefined)}
                choices={[
                    {
                        id: "cron",
                        name: "cron",
                    },
                    {
                        id: "onetime",
                        name: "onetime",
                    },
                ]}
            />
            <Typography>/</Typography>
            {process.env.REACT_APP_NODE_ENV === "development" ? (
                <SelectInput
                    source="text"
                    label="Text"
                    variant="outlined"
                    helperText={false}
                    choices={
                        base === "onetime"
                            ? [
                                  {
                                      id: "v1/attendanceEnvironment",
                                      name: "attendanceEnvironment",
                                  },
                              ]
                            : [
                                  {
                                      id: "fiveMinute/createShipment",
                                      name: "createShipment",
                                  },
                                  {
                                      id: "fiveMinute/createShipmentFromIssues",
                                      name: "createShipmentFromIssues",
                                  },
                                  {
                                      id: "hourly/createParcelFromShipment",
                                      name: "createParcelFromShipment",
                                  },
                                  {
                                      id: "fiveMinute/pickerAssign",
                                      name: "pickerAssign",
                                  },
                                  {
                                      id: "hourly/createProcurementStatus",
                                      name: "createProcurementStatus",
                                  },
                                  {
                                      id: "hourly/toVendorProcurementStatus",
                                      name: "toVendorProcurementStatus",
                                  },
                                  {
                                      id: "hourly/createVendorPurchaseOrder",
                                      name: "createVendorPurchaseOrder",
                                  },
                                  {
                                      id: "hourly/createCompanyPurchaseOrder",
                                      name: "createCompanyPurchaseOrder",
                                  },
                                  {
                                      id: "monthly/monthlySalary",
                                      name: "monthlySalary",
                                  },
                                  {
                                      id: "monthly/monthlyReport",
                                      name: "monthlyReport",
                                  },
                                  {
                                      id: "monthly/salaryApproved",
                                      name: "salaryApproved",
                                  },
                                  {
                                      id: "daily/rebuildBalance",
                                      name: "rebuildBalance",
                                  },
                                  {
                                      id: "hourly/attendanceCheck",
                                      name: "attendanceCheck",
                                  },
                                  {
                                      id: "daily/attendanceSchedule",
                                      name: "attendanceSchedule",
                                  },
                                  {
                                      id: "daily/addTimeSlot",
                                      name: "addTimeSlot",
                                  },
                                  {
                                      id: "daily/pendingReOrderCron",
                                      name: "pendingReOrderCron",
                                  },
                                  {
                                      id: "yearly/holidayCreate",
                                      name: "holidayCreate",
                                  },
                              ]
                    }
                />
            ) : (
                <TextInput
                    source="text"
                    label="Text"
                    variant="outlined"
                    autoComplete="on"
                    helperText={false}
                />
            )}
            <Button
                variant="contained"
                onClick={() => setIsDialogOpen(true)}
                disabled={!method || !base || !text}
            >
                Call
            </Button>
            <Confirm
                isOpen={isDialogOpen}
                loading={isLoading}
                title="Are you sure you want to call this?"
                content={<DialogContent />}
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
        </Box>
    );
};

export default CallTab;
