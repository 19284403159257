import { SaveButton, Toolbar } from "react-admin";

const OrderEditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton
            {...props}
            disabled={
                props?.isLoading ||
                (!props.record?.po_address_checked &&
                    props.record?.po_status === "processing") ||
                props.record?.po_status === "cancelled"
            }
        />
    </Toolbar>
);

export default OrderEditToolbar;
