import { FC } from "react";
import {
    DateInput,
    Filter,
    FilterProps,
    TextInput,
    useNotify,
} from "react-admin";

const TransactionFilter: FC<FilterProps> = (props) => {
    const notify = useNotify();

    const validateDateRange: any = (value, allValues) => {
        if (
            value &&
            allValues &&
            allValues["_start_date"] &&
            value < allValues["_start_date"]
        ) {
            notify(
                "The end date should be later than the start date. Please adjust the dates accordingly!",
                "warning"
            );
        }
    };

    return (
        <Filter {...props}>
            <TextInput
                source="_search"
                label="ID"
                variant="outlined"
                resettable
                alwaysOn
            />
            <DateInput
                source="_start_date"
                label="Start Date"
                variant="outlined"
                alwaysOn
            />
            <DateInput
                source="_end_date"
                label="End Date"
                variant="outlined"
                validate={[validateDateRange]}
                alwaysOn
            />
            <TextInput
                source="_narration"
                label="Narration"
                variant="outlined"
                alwaysOn
                multiline
            />
        </Filter>
    );
};

export default TransactionFilter;
