import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";

import {
    SaveButton,
    SimpleForm,
    Toolbar,
    useNotify,
    useRefresh,
} from "react-admin";

import { uploadDataProvider } from "../../../dataProvider";
import QuotationForm from "./QuotationForm";
import { toFormattedDateTime } from "../../../utils/helpers";

const QuotationCreate = ({
    id,
    isQuotationDialogOpen,
    setIsQuotationDialogOpen,
    quotationAction,
}) => {
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const handleDialogClose = () => {
        setIsQuotationDialogOpen(false);
    };

    const onSave = async (data) => {
        const payload = {
            q_pr_id: data.q_pr_id,
            q_supplier_id: data.q_supplier_id,
            q_payment_method: data.q_payment_method,
            q_item_type: data.q_item_type,
            q_payment_terms: data.q_payment_terms,
            q_advance_pay: data.q_advance_pay,
            q_delivery_date: toFormattedDateTime({
                dateString: data.q_delivery_date,
            }),
            q_payment_term_cond: data.q_payment_term_cond,
            q_description: data.q_description,
            attachedFiles_q_attachment: data.attachedFiles_q_attachment,
            qi: data.qi?.map((item) => ({
                qi_item_id: item.qi_item_id,
                qi_quantity: item.qi_quantity,
                qi_unit: item.qi_unit,
                qi_unit_price: item.qi_unit_price,
                qi_total_price: item.qi_total_price,
                qi_tax: item.qi_tax ?? 0,
                qi_vat: item.qi_vat ?? 0,
                qi_discount: item.qi_discount ?? 0,
                qi_grand_total: item.qi_grand_total,
                qi_description: item.qi_description,
            })),
        };

        try {
            if (quotationAction === "create") {
                await uploadDataProvider.create(`v1/quotation`, {
                    data: payload,
                });
            } else {
                await uploadDataProvider.create(`v1/quotation/${id}`, {
                    data: payload,
                });
            }

            notify("Successfully save!", { type: "success" });
            refresh();
            handleDialogClose();
        } catch (err) {
            notify(`${err}`, { type: "error" });
        }
    };

    const CustomToolbar = (props) => (
        <Toolbar {...props}>
            <SaveButton label="Confirm" />
            <Box>
                <Button
                    onClick={handleDialogClose}
                    className={classes.closeButton}
                >
                    Cancel
                </Button>
            </Box>
        </Toolbar>
    );

    return (
        <Dialog
            open={isQuotationDialogOpen}
            onClose={handleDialogClose}
            maxWidth="xl"
            className={classes.dialogSize}
        >
            <DialogTitle>
                {quotationAction === "create" ? "Add" : "Edit"} Quotation
            </DialogTitle>
            <DialogContent>
                <SimpleForm save={onSave} toolbar={<CustomToolbar />}>
                    <QuotationForm id={id} />
                </SimpleForm>
            </DialogContent>
        </Dialog>
    );
};
export default QuotationCreate;

const useStyles = makeStyles((theme) => ({
    closeButton: {
        marginLeft: 10,
        backgroundColor: "red",
        color: "white",
        "&:hover": {
            backgroundColor: "#f58989",
        },
    },
    dialogSize: {
        [theme.breakpoints.up("xl")]: {
            padding: "0px 120px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "0px 20px",
        },
    },
}));
