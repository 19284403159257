import { FC } from "react";
import {
    BooleanField,
    List,
    ListProps,
    ReferenceField,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import {
    useDocumentTitle,
    useExport,
    useNavigateFromList,
} from "../../../hooks";
import { CustomizableDatagrid } from "../../../lib";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import VariantFilter from "./VariantFilter";

const VariantList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Variant List");

    const exporter = useExport(rest);
    const classes = useAroggaStyles();
    const navigateFromList = useNavigateFromList(
        "variantTypeView",
        "variantTypeEdit"
    );

    return (
        <List
            {...rest}
            title="List of Variant"
            filters={<VariantFilter children={""} />}
            perPage={25}
            sort={{ field: "vt_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={permissions?.includes("variantTypeDelete")}
        >
            <CustomizableDatagrid
                rowClick={navigateFromList}
                hideableColumns={["vt_created_at", "vt_created_by"]}
            >
                <TextField source="vt_id" label="ID" />
                <TextField source="vt_title" label="Title" />
                <TextField
                    source="vt_field_type"
                    label="Field Type"
                    className={classes.capitalize}
                />
                <BooleanField
                    source="vt_status"
                    label="Status?"
                    FalseIcon={() => null}
                    looseValue
                />
                <AroggaDateField source="vt_created_at" label="Created At" />
                <ReferenceField
                    source="vt_created_by"
                    label="Created By"
                    reference="v1/users"
                    sortBy="u_name"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>
    );
};

export default VariantList;
