import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
} from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import {
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    maxValue,
    minValue,
    required,
    useRedirect,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { useEffectOnDependencyChange, useRequest } from "../../../hooks";
import {
    getProductTextRenderer,
    getQuantityLabel,
    getReadableSKU,
    groupBy,
    isArray,
} from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import AroggaDialogActions from "../../AroggaDialogActions";
import InlineArrayInput from "../../InlineArrayInput";

type IssueCreateModalProps = {
    open: boolean;
    onDialogClose: () => void;
    shipmentId: number;
    orderShipmentItemVariantIds: number[];
    groupShipmentOrderItemsByVariantId: any;
};

const IssueCreateModal: FC<IssueCreateModalProps> = ({
    open,
    onDialogClose,
    shipmentId,
    orderShipmentItemVariantIds,
    groupShipmentOrderItemsByVariantId,
}) => {
    const form = useForm();
    const redirect = useRedirect();
    const classes = useAroggaStyles();
    const { values, hasValidationErrors } = useFormState();

    const [replacementGroupInfo, setReplacementGroupInfo] = useState({});

    const orderShipmentItemProductIds = values.s_order_items
        ?.filter((item) =>
            orderShipmentItemVariantIds.includes(item.poi_product_variant_id)
        )
        ?.map((item) => item.poi_product_id);

    const { data, refetch: fetchProducts } = useRequest(
        `/v1/product?ids=${orderShipmentItemProductIds}`
    );

    const { refetch: fetchReplacementInfo } = useRequest(
        "",
        {},
        {
            onSuccess: ({ data }) =>
                setReplacementGroupInfo((prev) => ({
                    ...prev,
                    ...groupBy([data], (data) => data?.product_variant_id),
                })),
        }
    );

    const groupShipmentOrderItemsByProductId = groupBy(
        values.s_order_items,
        (data) => data.poi_product_id
    );

    const issueType = values.issueType;
    const moneyCollect = values.i_money_or_product; // TODO:

    const { isLoading, refetch } = useRequest(
        "/v1/issue",
        {
            method: "POST",
            body: {
                // TODO: i_type
                i_type: (() => {
                    if (
                        issueType === "damage_refund" &&
                        moneyCollect === "product"
                    ) {
                        return "replacement";
                    } else {
                        return issueType;
                    }
                })(),
                ...((issueType === "missing" ||
                    issueType === "damage_refund") &&
                    values.i_money_or_product && {
                        i_money_or_product: values.i_money_or_product,
                    }),
                i_order_id: values.s_order_id,
                i_shipment_id: shipmentId,
                i_details: values.i_details,
                ic: values.ic?.map((item) => {
                    const {
                        poi_product_variant_id,
                        poi_product_unit_id,
                        poi_stock_mapping,
                    } =
                        groupShipmentOrderItemsByProductId?.[
                            item?.ic_product_id
                        ]?.[0] || {};

                    return {
                        ...(["missing", "damage_refund"].includes(
                            issueType
                        ) && {
                            ic_product_id: item?.ic_product_id,
                            ic_variant_id: poi_product_variant_id,
                        }),
                        ...(issueType === "damage_refund" && {
                            ic_in_product_id: item?.ic_in_product_id,
                            ic_in_variant_id: item?.ic_in_variant_id,
                        }),
                        ...(issueType === "return" && {
                            ic_in_product_id: item?.ic_product_id,
                            ic_in_variant_id: poi_product_variant_id,
                        }),
                        ic_in_count: item?.ic_in_count,
                        ic_out_count: item?.ic_out_count,
                        ic_unit_id: poi_product_unit_id,

                        // TOOD:
                        ...(["missing", "damage_refund", "return"].includes(
                            issueType
                        ) && {
                            ic_stock_mapping: poi_stock_mapping,
                        }),
                        ...(issueType === "damage_refund" &&
                            moneyCollect === "money" && {
                                ic_stock_mapping:
                                    (isArray(poi_stock_mapping) &&
                                        poi_stock_mapping?.map(
                                            ({ sd_id, sd_batch_no }) => ({
                                                sd_id,
                                                sd_batch_no,

                                                used_in_order:
                                                    item?.ic_out_count,
                                            })
                                        )) ||
                                    [],
                            }),

                        //   ...(["missing", "damage_refund"].includes(
                        //     issueType
                        // ) && {
                        //     ic_stock_mapping: item?.ic_out_count,
                        // }),
                    };
                }),
            },
        },
        {
            successNotify: "Issue created successfully!",
            onSuccess: () => {
                onDialogClose();
                redirect("/v1/productOrder");
            },
        }
    );

    useEffect(() => {
        setDetailItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderShipmentItemVariantIds]);

    useEffectOnDependencyChange(() => {
        !data?.length && fetchProducts();
        setDetailItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueType, moneyCollect]);

    const setDetailItems = () => {
        form.change(
            "ic",
            orderShipmentItemVariantIds?.map((id) => {
                const {
                    poi_product_id,
                    poi_product_qty,
                    unit_label: salesUnit,
                    product_base_unit: baseUnit,
                    multiplier: salesUnitMultiplier,
                    total: salesUnitTotal,
                } = groupShipmentOrderItemsByVariantId?.[id]?.[0] || {};

                return {
                    ic_product_id: poi_product_id,
                    ic_variant_id: id,
                    ...(["missing", "damage_refund"].includes(issueType) && {
                        ic_out_count: poi_product_qty,
                    }),
                    ...(issueType === "return" && {
                        ic_in_count: poi_product_qty,
                    }),

                    ...(issueType === "damage_refund" &&
                        moneyCollect === "money" && {
                            ic_out_count: poi_product_qty * salesUnitMultiplier,
                        }),

                    poi_product_qty,
                    salesUnit,
                    baseUnit,
                    salesUnitMultiplier,
                    // TODO:
                    salesUnitTotal:
                        salesUnitTotal || poi_product_qty * salesUnitMultiplier,
                };
            })
        );
    };

    return (
        <Dialog open={open} onClose={onDialogClose} maxWidth="xl">
            <DialogTitle>
                {`Create Issue (Shipment ID #${values.s_order_id}${values.s_sequence})`}
            </DialogTitle>
            <DialogContent>
                <Box display="flex" gridGap={8}>
                    <SelectInput
                        source="issueType"
                        label="Type *"
                        variant="outlined"
                        helperText={false}
                        choices={[
                            { id: "missing", name: "Missing" },
                            { id: "return", name: "Return" },
                            { id: "damage_refund", name: "Replacement" },
                        ]}
                    />
                    {(issueType === "missing" ||
                        issueType === "damage_refund") && (
                        <SelectInput
                            source="i_money_or_product"
                            label="কী নিবেন? *"
                            variant="outlined"
                            helperText={false}
                            choices={[
                                { id: "money", name: "টাকা" },
                                { id: "product", name: "পণ্য" },
                            ]}
                        />
                    )}
                </Box>
                <TextInput
                    source="i_details"
                    label="Note"
                    variant="outlined"
                    helperText={false}
                    style={{ display: "block" }}
                    minRows={2}
                    multiline
                    fullWidth
                />
                {issueType && (
                    <InlineArrayInput
                        source="ic"
                        label="Details"
                        disableAdd
                        disableRemove
                        disableReordering
                        enableRenderProps
                    >
                        {({ getSource, scopedFormData }) => (
                            <Grid container spacing={1}>
                                <Grid
                                    item
                                    sm={
                                        issueType === "missing" ||
                                        issueType === "return"
                                            ? 6
                                            : issueType === "damage_refund" &&
                                              moneyCollect === "product"
                                            ? 3
                                            : 6
                                    }
                                >
                                    <ProductAutocompleteInput
                                        source={getSource("ic_product_id")}
                                        label={`${
                                            [
                                                "missing",
                                                "damage_refund",
                                            ].includes(issueType)
                                                ? "দিবেন"
                                                : "আনবেন"
                                        } Product`}
                                        record={scopedFormData}
                                        choices={data}
                                        disabled
                                    />
                                </Grid>
                                {issueType !== "return" && (
                                    <Grid
                                        item
                                        sm={
                                            issueType === "missing" ||
                                            issueType === "return"
                                                ? 6
                                                : issueType ===
                                                      "damage_refund" &&
                                                  moneyCollect === "product"
                                                ? 3
                                                : 6
                                        }
                                    >
                                        <NumberInput
                                            source={getSource("ic_out_count")}
                                            record={scopedFormData}
                                            label="দিবেন QTY"
                                            variant="outlined"
                                            helperText={false}
                                            className={classes.whitespaceNowrap}
                                            validate={[
                                                required(),
                                                minValue(1),
                                                // TODO:
                                                issueType === "missing" ||
                                                (issueType ===
                                                    "damage_refund" &&
                                                    moneyCollect === "product")
                                                    ? maxValue(
                                                          scopedFormData?.poi_product_qty
                                                      )
                                                    : maxValue(
                                                          scopedFormData?.poi_product_qty *
                                                              scopedFormData.salesUnitMultiplier
                                                      ),
                                            ]}
                                            min={1}
                                            // TODO:
                                            max={
                                                scopedFormData?.poi_product_qty *
                                                (issueType === "missing" ||
                                                (issueType ===
                                                    "damage_refund" &&
                                                    moneyCollect === "product")
                                                    ? 1
                                                    : scopedFormData.salesUnitMultiplier)
                                            }
                                            initialValue={
                                                issueType === "damage_refund"
                                                    ? 0
                                                    : null
                                            }
                                            fullWidth
                                        />
                                        {issueType === "missing" ||
                                        moneyCollect === "product" ? (
                                            <div
                                                className={
                                                    classes.whitespaceNowrap
                                                }
                                            >
                                                {getQuantityLabel({
                                                    qty: scopedFormData.ic_out_count,
                                                    salesUnit:
                                                        scopedFormData.salesUnit,
                                                    baseUnit:
                                                        scopedFormData.baseUnit,
                                                    salesUnitMultiplier:
                                                        scopedFormData.salesUnitMultiplier,
                                                })}
                                            </div>
                                        ) : (
                                            <div
                                                className={
                                                    classes.whitespaceNowrap
                                                }
                                            >
                                                {/* @ts-ignore */}
                                                {getQuantityLabel({
                                                    qty: scopedFormData.ic_out_count,
                                                    isBaseUnit: true,
                                                    baseUnit:
                                                        scopedFormData.baseUnit,
                                                })}
                                            </div>
                                        )}
                                    </Grid>
                                )}
                                {issueType === "damage_refund" &&
                                    moneyCollect === "product" && (
                                        <Grid item sm={3}>
                                            <ReferenceInput
                                                source={getSource(
                                                    "ic_in_product_id"
                                                )}
                                                record={scopedFormData}
                                                label="আনবেন Product"
                                                variant="outlined"
                                                helperText={false}
                                                reference="general/v3/search"
                                                enableGetChoices={({ q }) =>
                                                    !!q && q.trim().length > 0
                                                }
                                                onSelect={(selectedData) => {
                                                    selectedData?.pv?.length ===
                                                        1 &&
                                                        form.change(
                                                            getSource(
                                                                "ic_in_variant_id"
                                                            ),
                                                            selectedData
                                                                ?.pv?.[0]?.pv_id
                                                        );
                                                    form.change(
                                                        getSource(
                                                            "ic_in_count"
                                                        ),
                                                        undefined
                                                    );
                                                }}
                                                sort={{
                                                    field: "p_name",
                                                    order: "ASC",
                                                }}
                                                validate={[required()]}
                                                fullWidth
                                            >
                                                <AutocompleteInput
                                                    optionValue="p_id"
                                                    optionText={
                                                        getProductTextRenderer
                                                    }
                                                    options={{
                                                        InputProps: {
                                                            multiline: true,
                                                        },
                                                    }}
                                                    matchSuggestion={() => true}
                                                    resettable
                                                />
                                            </ReferenceInput>
                                        </Grid>
                                    )}
                                {scopedFormData?.ic_in_product_id && (
                                    <Grid item sm={2}>
                                        <ReferenceInput
                                            source={getSource(
                                                "ic_in_variant_id"
                                            )}
                                            label="Variant"
                                            variant="outlined"
                                            helperText={false}
                                            reference="v1/productVariant"
                                            filter={{
                                                _product_id:
                                                    scopedFormData?.ic_in_product_id,
                                                _perPage: 100,
                                            }}
                                            fullWidth
                                        >
                                            <AutocompleteInput
                                                options={{
                                                    InputProps: {
                                                        multiline: true,
                                                    },
                                                }}
                                                optionText={(item) =>
                                                    getReadableSKU(
                                                        item?.pv_attribute
                                                    )
                                                }
                                                matchSuggestion={() => true}
                                                resettable
                                            />
                                        </ReferenceInput>
                                    </Grid>
                                )}
                                {(issueType === "return" ||
                                    (issueType === "damage_refund" &&
                                        moneyCollect === "product")) && (
                                    <Grid
                                        item
                                        sm={issueType === "return" ? 6 : 2}
                                    >
                                        <NumberInput
                                            source={getSource("ic_in_count")}
                                            record={scopedFormData}
                                            label="আনবেন QTY"
                                            variant="outlined"
                                            helperText={false}
                                            className={classes.whitespaceNowrap}
                                            validate={[required(), minValue(1)]}
                                            onChange={() =>
                                                fetchReplacementInfo({
                                                    endpoint: `/v3/replacementInfo/${scopedFormData?.ic_in_variant_id}`,
                                                })
                                            }
                                            min={1}
                                            disabled={
                                                issueType === "damage_refund" &&
                                                !scopedFormData?.ic_in_variant_id
                                            }
                                            fullWidth
                                        />
                                        <div
                                            className={classes.whitespaceNowrap}
                                        >
                                            {getQuantityLabel({
                                                qty: scopedFormData?.ic_in_count,
                                                salesUnit:
                                                    issueType ===
                                                    "damage_refund"
                                                        ? replacementGroupInfo?.[
                                                              scopedFormData
                                                                  ?.ic_in_variant_id
                                                          ]?.[0]
                                                              ?.replacement_unit_label
                                                        : scopedFormData?.salesUnit,
                                                baseUnit:
                                                    issueType ===
                                                    "damage_refund"
                                                        ? replacementGroupInfo?.[
                                                              scopedFormData
                                                                  ?.ic_in_variant_id
                                                          ]?.[0]
                                                              ?.product_replacement_base_unit
                                                        : scopedFormData?.baseUnit,
                                                salesUnitMultiplier:
                                                    issueType ===
                                                    "damage_refund"
                                                        ? replacementGroupInfo?.[
                                                              scopedFormData
                                                                  ?.ic_in_variant_id
                                                          ]?.[0]
                                                              ?.replacement_unit_multiplier
                                                        : scopedFormData?.salesUnitMultiplier,
                                            })}
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </InlineArrayInput>
                )}
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={onDialogClose}
                onConfirm={refetch}
                disabled={
                    !issueType ||
                    ((issueType === "missing" ||
                        issueType === "damage_refund") &&
                        !values.i_money_or_product) ||
                    hasValidationErrors
                }
            />
        </Dialog>
    );
};

export default IssueCreateModal;

const ProductAutocompleteInput = (props) => (
    <AutocompleteInput
        label="Product"
        variant="outlined"
        helperText={false}
        optionValue="p_id"
        optionText={getProductTextRenderer}
        options={{
            InputProps: {
                multiline: true,
            },
        }}
        matchSuggestion={() => true}
        fullWidth
        {...props}
    />
);
