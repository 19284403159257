import {
    Box,
    Grid,
    Button as MuiButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import { FC, useState } from "react";
import {
    BooleanField,
    Button,
    DateTimeInput,
    Labeled,
    NumberInput,
    SelectInput,
    SimpleForm,
    TextInput,
    Title,
    required,
    useRedirect,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { Editor } from "@tinymce/tinymce-react";
import { useLocation } from "react-router-dom";
import AroggaAccordion from "../../../components/AroggaAccordion";
import AroggaMovableImageInput from "../../../components/AroggaMovableImageInput";
import { useDocumentTitle, useRequest } from "../../../hooks";
import { toAvroTranslate } from "../../../services";
import { TINY_MCE_EDITOR_INIT } from "../../../utils/constants";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

const CampaignPage: FC = () => {
    const location = useLocation();
    const redirect = useRedirect();

    const queryParams = new URLSearchParams(location.search);
    const campaignType = queryParams.get("campaignType");

    useDocumentTitle(
        `Arogga | Create ${capitalizeFirstLetterOfEachWord(
            campaignType
        )} Campaign`
    );

    return (
        <div style={{ marginTop: 20 }}>
            <Title title="Promotional Message" />
            <Box display="flex" mt={3}>
                <div
                    style={{
                        marginTop: 2,
                        cursor: "pointer",
                    }}
                >
                    <ArrowBackIcon
                        onClick={() => redirect("product-list-campaign/combo")}
                    />
                </div>
                <Typography
                    style={{
                        fontSize: 20,
                        fontWeight: 600,
                        marginLeft: 15,
                    }}
                >
                    {`Create ${capitalizeFirstLetterOfEachWord(
                        campaignType
                    )} Campaign`}{" "}
                    / Product Details
                </Typography>
            </Box>
            <Paper style={{ marginTop: 10, padding: 30 }}>
                <SimpleForm toolbar={null}>
                    <CampaignForm campaignType={campaignType} />
                </SimpleForm>
            </Paper>
        </div>
    );
};

export default CampaignPage;

const CampaignForm = ({ campaignType }) => {
    const { values } = useFormState();
    const location = useLocation();
    const form = useForm();
    const classes = useAroggaStyles();

    console.log(values);

    const [description, setDescription] = useState("");

    const queryParams = new URLSearchParams(location.search);

    const selectedVariantIds = queryParams.get("selectedVariantIds");

    const [selectedVariantData, setSelectedVariantData] = useState([]);

    useRequest(
        `/general/v3/search?_product_variant_id=${selectedVariantIds}`,
        {},
        {
            isBaseUrl: true,
            isPreFetching: true,
            isWarningNotify: false,
            onSuccess: ({ data }) => {
                const mappedData = data?.map((item) => ({
                    id: item.id,
                    p_name: item.p_name,
                    p_type: item.p_type,
                    pv_mrp: item?.pv?.[0]?.pv_mrp,
                    pv_b2b_price: item?.pv?.[0]?.pv_b2b_price,
                    pv_b2c_price: item?.pv?.[0]?.pv_b2c_price,
                    p_generic_name: item.p_generic_name,
                    p_strength: item.p_strength,
                    p_form: item.p_form,
                    p_cold: item.p_cold,
                    p_active_status: item.p_active_status,
                    p_brand: item.p_brand,
                }));
                setSelectedVariantData(mappedData);
            },
        }
    );

    const { refetch: createCampaign } = useRequest("", { method: "POST" });

    console.log(selectedVariantData);

    const handleOnTranslateClick = async () => {
        const data = await toAvroTranslate(values.pc_name_en);
        data?.[0] && form.change("pc_name_bn", data[0]);
    };

    // useEffect(() => {
    //     if (record?.p_id) {
    //         setDescription(record?.pc_description);
    //     }
    // }, [record?.pc_description, record?.p_id]);

    // useEffect(() => {
    //     if (values.p_id) {
    //         setDescription(values?.pc_description || record?.pc_description);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [values.pc_description]);

    values.pc_description = description;

    const handleRemove = (id) => {
        const updatedData = selectedVariantData.filter(
            (item) => item.id !== id
        );
        setSelectedVariantData(updatedData);
    };

    const handleCreateCampaign = () => {
        const pci = Object.keys(values)
            .filter((key) => key.includes("pci_is_free"))
            .map((key) => {
                const id = parseInt(key);
                return {
                    pci_product_id: id,
                    pci_is_free: +values[`${id}-pci_is_free`],
                };
            });

        createCampaign({
            endpoint: "/v1/productCampaign",
            body: {
                pc_type_id: values?.pc_type_id,
                pc_name_en: values?.pc_name_en,
                pc_name_bn: values?.pc_name_bn,
                pc_mrp: values?.pc_mrp,
                pc_b2b_price: values?.pc_b2b_price,
                pc_d2c_price: values?.pc_d2c_price,
                pc_weight: values?.pc_weight,
                pc_start_date: values?.pc_start_date,
                pc_end_date: values?.pc_end_date,
                pc_status: values?.pc_status,
                pc_description: values?.pc_description,
                attachedFiles_pc_images: values?.attachedFiles_pc_images,
                pci,
            },
        });
    };

    return (
        <>
            <AroggaAccordion title="Product Details" isGrid={false}>
                <Grid container spacing={1}>
                    <Grid item sm={6} md={3}>
                        <TextInput
                            source="pc_type_id"
                            label="Campaign Type"
                            variant="outlined"
                            helperText={false}
                            disabled
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <TextInput
                            source="pc_name_en"
                            label="Product Name English"
                            variant="outlined"
                            helperText={false}
                            validate={[required()]}
                            multiline
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <MuiButton
                            variant="contained"
                            onClick={handleOnTranslateClick}
                            style={{ marginTop: 10 }}
                            disabled={!values.pc_name_en}
                        >
                            Translate
                        </MuiButton>
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <TextInput
                            source="pc_name_bn"
                            label="Product Name Bangla"
                            variant="outlined"
                            helperText={false}
                            multiline
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <NumberInput
                            source="pc_mrp"
                            label="MRP"
                            variant="outlined"
                            helperText={false}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <NumberInput
                            source="pc_b2b_price"
                            label="B2B"
                            variant="outlined"
                            helperText={false}
                            validate={[required()]}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <NumberInput
                            source="pc_d2c_price"
                            label="B2C"
                            variant="outlined"
                            helperText={false}
                            validate={[required()]}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <NumberInput
                            source="pc_weight"
                            label="Weight"
                            variant="outlined"
                            helperText={false}
                            validate={[required()]}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <DateTimeInput
                            source="pc_start_date"
                            label="Start Date"
                            variant="outlined"
                            helperText={false}
                            validate={[required()]}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <DateTimeInput
                            source="pc_end_date"
                            label="End Date"
                            variant="outlined"
                            helperText={false}
                            validate={[required()]}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} md={2}>
                        <SelectInput
                            source="pc_status"
                            label="Status"
                            variant="outlined"
                            choices={[
                                { id: "Active", name: "Active" },
                                {
                                    id: "Inactive",
                                    name: "Inactive",
                                },
                                {
                                    id: "Pending",
                                    name: "Pending",
                                },
                            ]}
                            initialValue="Active"
                            helperText={false}
                            validate={[required()]}
                            fullWidth
                        />
                    </Grid>
                    <AroggaMovableImageInput
                        source="attachedFiles_pc_images"
                        dimentionValidation
                    />
                    <Labeled label="Description" fullWidth>
                        <Editor
                            tinymceScriptSrc={
                                process.env.PUBLIC_URL +
                                "/tinymce/tinymce.min.js"
                            }
                            init={TINY_MCE_EDITOR_INIT}
                            value={description}
                            onEditorChange={(newValue) =>
                                setDescription(newValue)
                            }
                        />
                    </Labeled>
                </Grid>
            </AroggaAccordion>
            <AroggaAccordion
                title={`${capitalizeFirstLetterOfEachWord(
                    campaignType
                )} Product List`}
                isGrid={false}
            >
                <Table>
                    <TableHead>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>MRP</TableCell>
                        <TableCell>B2B Price</TableCell>
                        <TableCell>B2C Price</TableCell>
                        <TableCell>Generic</TableCell>
                        <TableCell>Strength</TableCell>
                        <TableCell>Form</TableCell>
                        <TableCell>Cold</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Brand</TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell>Remove</TableCell>
                    </TableHead>
                    <TableBody>
                        {selectedVariantData?.map((item, i) => (
                            <TableRow>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.p_name}</TableCell>
                                <TableCell className={classes.capitalize}>
                                    {item.p_type}
                                </TableCell>
                                <TableCell>{item.pv_mrp}</TableCell>
                                <TableCell>{item.pv_b2b_price}</TableCell>
                                <TableCell>{item.pv_b2c_price}</TableCell>
                                <TableCell>{item.p_generic_name}</TableCell>
                                <TableCell>{item.p_strength}</TableCell>
                                <TableCell>{item.p_form}</TableCell>
                                <TableCell>
                                    <BooleanField
                                        source="p_cold"
                                        record={item}
                                        looseValue
                                    />
                                </TableCell>
                                <TableCell className={classes.capitalize}>
                                    {item.p_active_status}
                                </TableCell>
                                <TableCell>{item.p_brand}</TableCell>
                                <TableCell>
                                    <SelectInput
                                        source={`${item.id}-pci_is_free`}
                                        label=""
                                        variant="outlined"
                                        helperText={false}
                                        choices={[
                                            { id: "1", name: "Free" },
                                            {
                                                id: "0",
                                                name: "Buy",
                                            },
                                        ]}
                                    />
                                </TableCell>
                                <TableCell>
                                    <span
                                        className={classes.cursorPointer}
                                        onClick={() => handleRemove(item.id)}
                                    >
                                        <CloseIcon color="error" />
                                    </span>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </AroggaAccordion>
            <Button
                label="Create Now"
                variant="contained"
                onClick={handleCreateCampaign}
            />
        </>
    );
};
