import { Button, Grid } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { useForm, useFormState } from "react-final-form";

import LabAddnewSubArea from "../../../../components/manageLabTest/Order/LabAddnewSubArea";
import LabSubAreaInput from "../../../../components/manageLabTest/Order/LabSubAreaInput";
import { Status } from "../../../../utils/enums";
import { isJSONParsable, logger } from "../../../../utils/helpers";
import { httpClient } from "../../../../utils/http";

type UserTabProps = {
    permissions: string[];
    [key: string]: any;
};

const LabTestSubAreaUser: FC<UserTabProps> = ({
    permissions,
    setSubAreaId,
    hasSubArea,
    setHasSubArea,
    currentSubArea,
    setCurrentSubArea,
    ...rest
}) => {
    const form = useForm();
    const { values } = useFormState();
    const [locations, setLocations] = useState(null);
    const [subArea, setSubArea] = useState([]);
    const [isSubAreaRefresh, setIsSubAreaRefresh] = useState(false);

    const currentSubAreaMain = subArea?.find(
        (item) => item?.id === values?.userLocation?.subareaId
    );

    const [isAddNewSubAreaDialogOpen, setIsAddNewSubAreaDialogOpen] =
        useState(false);

    useEffect(() => {
        setCurrentSubArea(currentSubAreaMain);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, currentSubArea, subArea]);

    useEffect(() => {
        const locationsFromStroage = sessionStorage.getItem("locations");
        if (locationsFromStroage) {
            setLocations(
                isJSONParsable(locationsFromStroage)
                    ? JSON.parse(locationsFromStroage)
                    : {}
            );
        } else {
            httpClient("/v1/allLocations/", { isBaseUrl: true })
                .then(({ json }: any) => {
                    if (json.status === Status.SUCCESS) {
                        setLocations(json.data);
                        sessionStorage.setItem(
                            "locations",
                            JSON.stringify(json.data)
                        );
                    }
                })
                .catch((err) => logger(err));
        }
    }, []);
    useEffect(() => {
        form.change("location", {
            ...values?.location,
            district: values?.location?.district,
            division: values?.location?.division,
            area: values?.location?.area,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSubArea]);

    useEffect(() => {
        if (
            !!locations &&
            !!values?.location?.division &&
            !!values?.location?.district &&
            !!values?.location?.area &&
            !!locations[values?.location?.division] &&
            !!locations[values?.location?.division][
                values?.location?.district
            ] &&
            !!locations[values?.location?.division][values?.location?.district][
                values?.location?.area
            ] &&
            !!locations[values?.location?.division][values?.location?.district][
                values?.location?.area
            ]["l_has_subarea"]
        ) {
            setHasSubArea(true);
        } else {
            setHasSubArea(false);

            // form.change("full_shipping_address.ul_sa_id", '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        values?.location?.division,
        values?.location?.district,
        values?.location?.area,
        locations,
    ]);

    return (
        <Grid container spacing={2}>
            {hasSubArea && (
                <Grid item sm={6} md={4}>
                    <Grid container>
                        <Grid item sm={8} md={8}>
                            <LabSubAreaInput
                                source="userLocation.subareaId"
                                label="Shipping Sub Area"
                                variant="outlined"
                                locations={locations}
                                isSubAreaRefresh={isSubAreaRefresh}
                                subArea={subArea}
                                hasSubArea={hasSubArea}
                                setSubArea={setSubArea}
                                setLocations={setLocations}
                                allowEmpty
                                fullWidth
                                onSelect={(item) => {
                                    setSubAreaId(item);
                                }}
                            />
                        </Grid>
                        {permissions?.includes("subAreaCreate") && (
                            <Grid item sm={4} md={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        marginTop: 10,
                                        marginLeft: 3,
                                    }}
                                    onClick={() => {
                                        setIsAddNewSubAreaDialogOpen(true);
                                        setIsSubAreaRefresh(false);
                                    }}
                                >
                                    Add New
                                </Button>
                            </Grid>
                        )}
                        <LabAddnewSubArea
                            locations={locations}
                            open={isAddNewSubAreaDialogOpen}
                            setIsSubAreaRefresh={setIsSubAreaRefresh}
                            handleClose={() => {
                                setIsAddNewSubAreaDialogOpen(false);
                            }}
                            {...rest}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default LabTestSubAreaUser;
