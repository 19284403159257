import { Box } from "@material-ui/core";
import { FileField, TextInput, useNotify } from "react-admin";
import { useFormState } from "react-final-form";

import AroggaButton from "../../../../components/AroggaButton";
import AroggaMovableImageInput from "../../../../components/AroggaMovableImageInput";
import { useClipboard } from "../../../../hooks";
import { useAroggaStyles } from "../../../../utils/useAroggaStyles";

const Admin = () => {
    const { values } = useFormState();

    const notify = useNotify();
    const classes = useAroggaStyles();
    const clipboard = useClipboard();

    const copyURLToClipboard = (paymentUrl) => {
        if (!paymentUrl) return;

        clipboard.copy(paymentUrl);
        notify("Payment URL copied to clipboard!", { type: "success" });
    };

    return (
        <>
            {values.payment_url && (
                <Box position="relative">
                    <TextInput
                        source="payment_url"
                        label="Payment URL"
                        variant="outlined"
                        helperText={false}
                        disabled
                    />
                    <AroggaButton
                        label="Copy URL"
                        onClick={() => copyURLToClipboard(values.payment_url)}
                        style={{
                            position: "absolute",
                            left: 190,
                            marginTop: 7,
                            height: 42,
                            borderColor: "Background",
                        }}
                    />
                </Box>
            )}
            {values.invoice_url && (
                <FileField
                    source="invoice_url"
                    target="_blank"
                    rel="noopener noreferrer"
                    label="Invoice"
                    title="Invoice"
                />
            )}
            {values.payment_response && (
                <>
                    <p className={classes.label}>Payment Response</p>
                    <div
                        style={{
                            padding: 8,
                            border: "0.5px dashed #112950",
                            borderRadius: 4,
                            color: "#112950",
                            fontSize: 16,
                        }}
                    >
                        {JSON.stringify(values.payment_response, undefined, 4)}
                    </div>
                </>
            )}
            <Box mt={1} />
            <AroggaMovableImageInput
                source="attachedFiles_po_prescriptions"
                label="Prescription Pictures"
            />
        </>
    );
};

export default Admin;
