import { Box, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useEffect, useState } from "react";
import { Button, List, usePermissions, useRedirect } from "react-admin";
import { useParams } from "react-router-dom";

import ProductDatagrid from "../../../components/manageDatabase/products/ProductDatagrid";
import { useDocumentTitle } from "../../../hooks";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/helpers";
import ProductFilter from "../products/ProductFilter";

const ProductListCampaign = () => {
    useDocumentTitle("Arogga | Campaign Product List");

    const { permissions } = usePermissions();
    const params: any = useParams();
    const redirect = useRedirect();

    const [selectedProductVariantIds, setSelectedProductVariantIds] = useState(
        []
    );
    const [selectedLastCategoryId, setSelectedLastCategoryId] = useState(null);
    const [cascaderCategoryPlaceholder, setCascaderCategoryPlaceholder] =
        useState("");

    const hasSelectedLastCategoryId = window.location?.hash?.includes(
        "selectedLastCategoryId"
    );

    useEffect(() => {
        if (!hasSelectedLastCategoryId && selectedLastCategoryId) {
            setSelectedLastCategoryId(null);
            setCascaderCategoryPlaceholder("Select Category");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSelectedLastCategoryId]);

    const BulkActionButtons = ({
        selectedIds,
        setSelectedProductVariantIds,
    }: any) => {
        useEffect(() => {
            setSelectedProductVariantIds(selectedIds);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedIds]);

        return null;
    };

    const handleContinue = (e) => {
        e.preventDefault();

        redirect(
            `/create-campaign?campaignType=${params.campaignType}&selectedVariantIds=${selectedProductVariantIds}`
        );
    };

    return (
        <div>
            <Box display="flex" mt={3}>
                <div
                    style={{
                        marginTop: 2,
                        cursor: "pointer",
                    }}
                >
                    <ArrowBackIcon
                        onClick={() =>
                            redirect("create", "/v1/productCampaign")
                        }
                    />
                </div>
                <Typography
                    style={{
                        fontSize: 20,
                        fontWeight: 600,
                        marginLeft: 15,
                    }}
                >
                    {`Create ${capitalizeFirstLetterOfEachWord(
                        params.campaignType
                    )}`}
                </Typography>
            </Box>
            <List
                title="List of Product"
                resource="general/v3/search"
                basePath="/search"
                perPage={25}
                filter={{
                    isVariantIdAsID: true,
                    ...(hasSelectedLastCategoryId &&
                        selectedLastCategoryId && {
                            _product_category_id: selectedLastCategoryId,
                        }),
                }}
                filters={
                    <ProductFilter
                        children={""}
                        cascaderCategoryPlaceholder={
                            cascaderCategoryPlaceholder
                        }
                        setSelectedLastCategoryId={setSelectedLastCategoryId}
                        isCampaign
                    />
                }
                bulkActionButtons={
                    <BulkActionButtons
                        setSelectedProductVariantIds={
                            setSelectedProductVariantIds
                        }
                    />
                }
                sort={{ field: "p_id", order: "DESC" }}
            >
                <ProductDatagrid isCampaign {...{ permissions }} />
            </List>
            {!!selectedProductVariantIds?.length && (
                <Button
                    label="Continue"
                    variant="contained"
                    onClick={handleContinue}
                    style={{
                        position: "absolute",
                        right: 0,
                        marginTop: 15,
                        marginRight: 24,
                    }}
                />
            )}
        </div>
    );
};

export default ProductListCampaign;
