import { Grid } from "@material-ui/core";
import { FC } from "react";
import {
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    minValue,
} from "react-admin";
import { useFormState } from "react-final-form";

import CustomField from "../../../../components/CustomField";
import { getFormattedDateString } from "../../../../utils/helpers";
import { useAroggaStyles } from "../../../../utils/useAroggaStyles";

type OrderProps = {
    permissions: string[];
    [key: string]: any;
};

const Order: FC<OrderProps> = ({ permissions, record }) => {
    const classes = useAroggaStyles();
    const { values } = useFormState();

    const isRoleAdministrator = permissions?.includes("role:administrator");

    return (
        <Grid container spacing={1}>
            <Grid item sm={6} md={3}>
                <CustomField label="ID" value={values.po_id} />
            </Grid>
            <Grid item sm={6} md={3}>
                <CustomField
                    label="Order Created"
                    value={getFormattedDateString(values.po_created_at)}
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <CustomField
                    label="Order Updated"
                    value={getFormattedDateString(values.po_modified_at)}
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <CustomField
                    label="Payment Status"
                    value={values.po_payment_status}
                    className={`${classes.capitalize} ${
                        record.po_payment_status === "pending" &&
                        classes.textRed
                    }`}
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <CustomField
                    label="Order Subtotal"
                    value={values.po_subtotal}
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <CustomField
                    label="Order Addition"
                    value={values.po_manual_addition}
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <CustomField
                    label="Order Deduction"
                    value={values.po_manual_deduction}
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <CustomField
                    label="Order Payable Total"
                    value={values.po_payable_total}
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <ReferenceInput
                    source="po_product_discount_id"
                    label="Coupon Code"
                    variant="outlined"
                    helperText={false}
                    reference="v1/productDiscount"
                    filter={{ _status: "active", _discount_type: "coupon" }}
                    allowEmpty
                    fullWidth
                >
                    <AutocompleteInput
                        matchSuggestion={() => true}
                        optionValue="pd_id"
                        optionText="pd_name"
                        resettable
                    />
                </ReferenceInput>
            </Grid>
            <Grid item sm={6} md={3}>
                <SelectInput
                    source="po_subscription_frequency"
                    label="Subscription Frequency"
                    variant="outlined"
                    helperText={false}
                    choices={[{ id: "Monthly", name: "Monthly" }]}
                    allowEmpty
                    fullWidth
                />
            </Grid>
            {isRoleAdministrator && (
                <Grid item sm={6} md={3}>
                    <NumberInput
                        source="po_manual_addition"
                        label="Manual Addition"
                        variant="outlined"
                        helperText={false}
                        validate={minValue(
                            0,
                            "Manual addition can't be negative"
                        )}
                        min={0}
                        fullWidth
                    />
                </Grid>
            )}
            {isRoleAdministrator && (
                <Grid item sm={6} md={3}>
                    <NumberInput
                        source="po_manual_deduction"
                        label="Manual Deduction"
                        variant="outlined"
                        helperText={false}
                        validate={minValue(
                            0,
                            "Manual deduction can't be negative"
                        )}
                        min={0}
                        fullWidth
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default Order;
