import { Button, CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
    Datagrid,
    FunctionField,
    Pagination,
    Record,
    ReferenceField,
    TextField,
    useRedirect,
} from "react-admin";

import { useRequest } from "../../../hooks";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import "./Style.css";

const ExpandableRow = (props) => {
    const parentId = props.record ? props.record.ah_id : null;

    const classes = useAroggaStyles();

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const { isLoading, data, total, refetch } = useRequest(
        `/v1/accountingHead?_childOf=${parentId}&_page=${page}&_perPage=${perPage}`,
        {}
    );

    useEffect(() => {
        if (parentId) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentId, page, perPage]);

    const redirect = useRedirect();

    const handleViewLedger = (headId) => {
        redirect("/ledger", undefined, undefined, undefined, {
            headId,
        });
    };

    if (isLoading) {
        return <CircularProgress size={20} />;
    }

    if (!data) {
        return <div>No data available</div>;
    }

    const transformedData = data.reduce((acc, item) => {
        acc[item.ah_id] = item;
        return acc;
    }, {});

    return (
        <div>
            <Datagrid
                expand={
                    data.some((item) => item.ah_has_child === 1) ? (
                        <ExpandableRow />
                    ) : null
                }
                isRowExpandable={(row) => row?.ah_has_child === 1}
                data={transformedData}
                ids={data.map((item) => item.ah_id)}
                className="hideTableHeader"
                rowClick="show"
                classes={{ expandedPanel: classes.expandedPanel }}
            >
                <TextField source="ah_id" label="ID" />
                <TextField source="ah_acc_code" label={"Accounting Code"} />
                {/*<TextField source="ah_abbr" label={"Abbr"} />*/}
                <FunctionField
                    label="Ledger Type"
                    render={({ ah_ledger_type }: Record) =>
                        capitalizeFirstLetterOfEachWord(ah_ledger_type)
                    }
                />{" "}
                <TextField source="ah_name" label="Account Name" />
                <ReferenceField
                    source="ah_parent_id"
                    label="Parent Name"
                    reference="v1/accountingHead"
                    sortBy="ah_parent_id"
                >
                    <TextField source="ah_name" />
                </ReferenceField>
                <TextField source="ah_has_child" label="Has Child" />
                <TextField source="ah_type" label="Type" />
                <ReferenceField
                    source="ah_group_id"
                    label="Group"
                    reference="v1/accountingHeadGroup"
                    sortBy="ah_group_id"
                    link={false}
                >
                    <TextField source="ahg_name" />
                </ReferenceField>
                <TextField
                    source="ah_opening_balance_type"
                    label="Balance Type"
                />
                <TextField
                    source="ah_opening_balance"
                    label="Opening Balance"
                />
                <TextField
                    source="ah_current_balance"
                    label={"Curr Head Balance"}
                />
                {/* <TextField
                    source="ah_accumulated_balance"
                    label="Acc. Balance"
                /> */}
                <FunctionField
                    label="Ledger"
                    render={({ ah_id }) => (
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation(); // Stop the row from expanding
                                handleViewLedger(ah_id);
                            }}
                        >
                            View
                        </Button>
                    )}
                />
            </Datagrid>
            <Pagination
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                total={total}
                onChange={(item) => console.log(item)}
            />
        </div>
    );
};

export default ExpandableRow;
