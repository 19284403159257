import { FC, useCallback, useState } from "react";
import {
    AutocompleteInput,
    Create,
    CreateProps,
    DateInput,
    FileField,
    FileInput,
    FormTab,
    NumberInput,
    ReferenceInput,
    TabbedForm,
    TextInput,
    useMutation,
    useNotify,
    useRedirect,
} from "react-admin";
import { FormSpy } from "react-final-form";

import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import UserNameRankOptionTextRenderer from "../../../components/UserNameRankOptionTextRenderer";
import LedgerMethodInput from "../../../components/manageFinance/ledgers/LedgerMethodInput";
import LedgerMultipleEntry from "../../../components/manageFinance/ledgers/LedgerMultipleEntry";
import { useDocumentTitle } from "../../../hooks";
import { IMAGE_FILE_MAX_SIZE } from "../../../utils/constants";
import { autoGeneratedLedgerReason } from "../../../utils/helpers";

const LedgerCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga | Ledger Create");

    const notify = useNotify();
    const redirect = useRedirect();
    const [mutate] = useMutation();

    const [rank, setRank] = useState("");
    const [username, setUsername] = useState("");
    const [ledgerReason, setLedgerReason] = useState("");
    const [formValues, setFormValues] = useState<any>({});

    const { l_a_date, l_a_uid } = formValues;

    if (formValues.l_type === "salary_and_allowances") {
        formValues.l_reason = autoGeneratedLedgerReason({
            l_a_date,
            username,
            rank,
            l_a_uid,
        });
    } else {
        formValues.l_reason = ledgerReason;
    }

    const save = useCallback(
        async ({ pathname, multipleEntry, attachedFiles, ...rest }: any) => {
            if (pathname.endsWith("1") && !multipleEntry?.length)
                return notify("Data is not provided!", { type: "warning" });

            try {
                const { message } = await mutate(
                    {
                        type: "create",
                        resource: "v1/ledger",
                        payload: {
                            data: pathname.endsWith("1")
                                ? { entryType: "multiple", data: multipleEntry }
                                : {
                                      entryType: "single ",
                                      data: rest,
                                      attachedFiles,
                                  },
                        },
                    },
                    { returnPromise: true }
                );
                notify(message, { type: "success" });
                redirect("/v1/ledger");
            } catch (err: any) {
                notify(
                    err.message || "Something went wrong, Please try again!",
                    { type: "error" }
                );
            }
        },
        [mutate, notify, redirect]
    );

    return (
        // @ts-ignore
        <Create {...props}>
            <TabbedForm redirect="list" submitOnEnter={false} save={save}>
                <FormTab label="Single Entry">
                    <TaxonomiesByVocabularyInput
                        fetchKey="ledger_types"
                        source="l_type"
                        label="Type"
                        helperText={false}
                        title
                    />
                    <LedgerMethodInput
                        source="l_method"
                        variant="outlined"
                        defaultValue="Cash"
                    />
                    {formValues?.l_type === "salary_and_allowances" ? (
                        <div style={{ width: 256 }}>
                            <ReferenceInput
                                source="l_a_uid"
                                label="User"
                                variant="outlined"
                                helperText={false}
                                reference="v1/users"
                                filter={{
                                    _isEmployee: true,
                                }}
                                onSelect={(item) => {
                                    setRank(item.u_rank);
                                    setUsername(item.u_name);
                                }}
                                fullWidth
                            >
                                <AutocompleteInput
                                    matchSuggestion={() => true}
                                    optionValue="u_id"
                                    inputText={(value) => value?.u_name}
                                    optionText={
                                        <UserNameRankOptionTextRenderer />
                                    }
                                    resettable
                                />
                            </ReferenceInput>
                            <DateInput
                                source="l_a_date"
                                label="Date"
                                variant="outlined"
                                helperText={false}
                                fullWidth
                            />
                        </div>
                    ) : (
                        <TextInput
                            source="l_reason"
                            label="Reason"
                            variant="outlined"
                            helperText={false}
                            onChange={(e) => setLedgerReason(e.target.value)}
                        />
                    )}
                    <NumberInput
                        source="l_amount"
                        label="Amount"
                        variant="outlined"
                        helperText={false}
                    />
                    <FileInput
                        source="attachedFiles"
                        label="Related Files"
                        accept="image/*, application/pdf"
                        maxSize={IMAGE_FILE_MAX_SIZE}
                        options={{ maxSize: 5 }}
                        multiple
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                    <FormSpy
                        subscription={{ values: true }}
                        onChange={({ values }) =>
                            // Fix bad setState() call inside `FormSpy` error using setTimeout
                            setTimeout(() => {
                                setFormValues(values);
                            }, 0)
                        }
                    />
                </FormTab>
                <FormTab label="Multiple Entry">
                    <LedgerMultipleEntry pathname={props.location.pathname} />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default LedgerCreate;
