import { toFixedNumber } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

const Summary = ({
    isShowProfitPercentage,
    isSoftDeleted,
    mrp,
    sellingPrice,
    purchasePrice,
}) => {
    const classes = useAroggaStyles();

    const { discountPercentage, profitPercentage } = getCalculateSummary({
        mrp,
        sellingPrice,
        purchasePrice,
    });

    const renderProfit = () => {
        if (!isShowProfitPercentage) return null;

        const profitColor = isSoftDeleted ? { color: "#ccc" } : {};
        const profitClass =
            profitPercentage <= 0 || profitPercentage > 50
                ? classes.textRed
                : classes.textGreen;

        return (
            <div
                style={profitColor}
                className={`${profitClass} ${classes.whitespaceNowrap}`}
            >
                Profit:{" "}
                {profitPercentage ? profitPercentage : "Not yet purchased"}
            </div>
        );
    };

    return (
        <>
            Discount: {discountPercentage}
            {renderProfit()}
        </>
    );
};

export default Summary;

const getCalculateSummary = ({ mrp, sellingPrice, purchasePrice }) => {
    const discountPercentage = toFixedNumber(
        ((mrp - sellingPrice) / mrp) * 100
    );
    const profitPercentage = !!purchasePrice
        ? toFixedNumber(((sellingPrice - purchasePrice) / mrp) * 100)
        : 0;

    return {
        discountPercentage,
        profitPercentage,
    };
};
