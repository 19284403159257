import { Box, Button } from "@material-ui/core";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import { FC, useState } from "react";
import {
    FileField,
    FunctionField,
    List,
    ListProps,
    Record,
    ReferenceField,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import PaymentActionModal from "../../../components/manageRequisition/requisitionPayment/PaymentActionModal";
import {
    useDocumentTitle,
    useExport,
    useRequest,
    useXLSXDownloader,
} from "../../../hooks";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import RequisitionPaymentFilter from "./RequisitionPaymentFilter";
import RequisitionPaymentListExpand from "./RequisitionPaymentListExpand";
import {
    capitalizeFirstLetterOfEachWord,
    getColorByStatus,
    toFormattedDateTime,
} from "../../../utils/helpers";
import { CustomizableDatagrid } from "../../../lib";

const RequisitionPaymentList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Requisition Payment List");

    const classes = useAroggaStyles();
    const exporter = useExport(rest);
    const { onExportToXLSX } = useXLSXDownloader();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [paymentId, setPaymentId] = useState(null);
    const [amount, setAmount] = useState(null);
    const [paymentType, setPaymentType] = useState("");

    const { refetch } = useRequest(
        `/v1/requisitionPaymentAction/${paymentId}/downloadRequisitionPaymentAction`,
        {
            method: "POST",
        },
        {
            isRefresh: true,
            onSuccess: ({ data }) => {
                data?.data?.length &&
                    onExportToXLSX(data?.data, getDownloadedFileName());
                setPaymentType("");
                setIsDialogOpen(false);
            },
        }
    );

    const onConfirm = () => {
        refetch();
    };

    const getDownloadedFileName = () => {
        let fileName;

        if (paymentType === "city_to_city") {
            fileName = `requisition-payment-${paymentId}-city-to-city-${toFormattedDateTime(
                {
                    isDate: true,
                    dateString: new Date().toString(),
                }
            )}`;
        } else if (paymentType === "city_to_others") {
            fileName = `requisition-payment-${paymentId}-city-to-others-${toFormattedDateTime(
                {
                    isDate: true,
                    dateString: new Date().toString(),
                }
            )}`;
        } else {
            fileName = `requisition-payment-${paymentId}-cash-${toFormattedDateTime(
                {
                    isDate: true,
                    dateString: new Date().toString(),
                }
            )}`;
        }

        return fileName;
    };

    return (
        <>
            <List
                {...rest}
                title="List of Requisition Payment"
                filters={<RequisitionPaymentFilter children={""} />}
                filterDefaultValues={{ _status: "pending" }}
                perPage={25}
                sort={{ field: "rp_id", order: "DESC" }}
                exporter={exporter}
                bulkActionButtons={false}
            >
                <CustomizableDatagrid
                    expand={
                        permissions?.includes(
                            "requisitionPaymentDetailView"
                        ) ? (
                            <RequisitionPaymentListExpand />
                        ) : null
                    }
                    classes={{ expandedPanel: classes.expandedPanel }}
                    hideableColumns={["rp_created_at", "rp_created_by"]}
                >
                    <TextField source="rp_id" label="ID" />
                    <FunctionField
                        source="rp_payment_type"
                        label="Payment Type"
                        render={({ rp_payment_type }: Record) =>
                            capitalizeFirstLetterOfEachWord(rp_payment_type)
                        }
                    />
                    <TextField
                        source="rp_payment_amount"
                        label="Payment Amount"
                    />
                    <TextField
                        source="rp_upload_amount"
                        label="Upload Amount"
                    />
                    <TextField source="rp_count" label="Payment Count" />
                    <FunctionField
                        label="Payment Status"
                        sortBy="rp_payment_status"
                        render={(record) => {
                            const color = getColorByStatus(
                                record.rp_payment_status
                            );
                            return (
                                <span
                                    className={classes.statusBtn}
                                    style={{
                                        color: color,
                                        backgroundColor: color + "10",
                                    }}
                                >
                                    {record.rp_payment_status}
                                </span>
                            );
                        }}
                    />
                    <FunctionField
                        label="Time Difference"
                        render={({
                            rp_created_at,
                            rp_upload_at,
                            rp_payment_status,
                        }: Record) => {
                            const time =
                                rp_payment_status === "paid"
                                    ? DateTime.fromSQL(rp_upload_at)
                                    : DateTime.local();
                            const diff = time
                                .diff(DateTime.fromSQL(rp_created_at), [
                                    "days",
                                    "hours",
                                    "minutes",
                                ])
                                .toObject();

                            return (
                                <span
                                    className={`${rp_payment_status === "paid"
                                        ? classes.textOrange
                                        : classes.textRed
                                        } ${classes.whitespaceNowrap}`}
                                >
                                    {!!diff.days &&
                                        `${diff.days} ${pluralize(
                                            "day",
                                            diff.days
                                        )} `}
                                    {!!diff.hours &&
                                        `${diff.hours} ${pluralize(
                                            "hour",
                                            diff.hours
                                        )} `}
                                    {!!Math.floor(diff.minutes) &&
                                        `${Math.floor(diff.minutes)}min`}
                                </span>
                            );
                        }}
                    />
                    <AroggaDateField
                        source="rp_payment_date"
                        label="Payment Date"
                    />
                    <AroggaDateField source="rp_upload_at" label="Upload At" />
                    <ReferenceField
                        source="rp_upload_by"
                        label="Upload By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <FileField
                        source="attachedFiles_rp_upload_images"
                        src="src"
                        title="Attached Files"
                        target="_blank"
                        label="Attached Files"
                        // @ts-ignore
                        onClick={(e) => e.stopPropagation()}
                    />
                    <ReferenceField
                        source="rp_created_by"
                        label="Created By"
                        reference="v1/users"
                        sortBy="rp_created_by"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="rp_created_at"
                        label="Created At"
                    />
                    <FunctionField
                        label="Action"
                        render={({
                            id,
                            rp_payment_status,
                            rp_payment_amount,
                            rp_payment_type,
                        }: Record) => {
                            if (rp_payment_status === "paid") return;

                            return (
                                <Box display="flex" gridGap={5}>
                                    {rp_payment_type === "city_to_city" ? (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                setPaymentId(id);
                                                setPaymentType("city_to_city");
                                                onConfirm();
                                            }}
                                        >
                                            Download
                                        </Button>
                                    ) : rp_payment_type === "city_to_others" ? (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                setPaymentId(id);
                                                setPaymentType(
                                                    "city_to_others"
                                                );
                                                onConfirm();
                                            }}
                                        >
                                            Download
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                setPaymentId(id);
                                                setPaymentType("cash");
                                                onConfirm();
                                            }}
                                        >
                                            Download
                                        </Button>
                                    )}
                                    {rp_payment_status === "processing" && (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                setPaymentId(id);
                                                setIsDialogOpen(true);
                                                setAmount(rp_payment_amount);
                                                setPaymentType(rp_payment_type);
                                            }}
                                        >
                                            Upload
                                        </Button>
                                    )}
                                </Box>
                            );
                        }}
                    />
                </CustomizableDatagrid>
            </List>
            {isDialogOpen && (
                <PaymentActionModal
                    paymentId={paymentId}
                    paymentType={paymentType}
                    amount={amount}
                    isDialogOpen={isDialogOpen}
                    setIsDialogOpen={setIsDialogOpen}
                />
            )}
        </>
    );
};

export default RequisitionPaymentList;
