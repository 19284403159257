import { Grid } from "@material-ui/core";
import {
    AutocompleteInput,
    FileField,
    FileInput,
    NumberInput,
    ReferenceInput,
    TextInput,
    required,
} from "react-admin";
import { useFormState } from "react-final-form";

import { IMAGE_FILE_MAX_SIZE } from "../../../utils/constants";

const ExpenseEntryForm = () => {
    const { values } = useFormState();

    return (
        <>
            {!!values.ee_id && (
                <Grid item sm={3}>
                    <TextInput
                        source="ee_id"
                        label="ID"
                        variant="outlined"
                        helperText={false}
                        fullWidth
                        disabled
                    />
                </Grid>
            )}
            <Grid item sm={3}>
                {" "}
                <ReferenceInput
                    source="ee_expense_head_mapping_id"
                    label="Expense Type"
                    reference="v1/expenseHeadMapping"
                    variant="outlined"
                    helperText={false}
                    validate={[required()]}
                    fullWidth
                >
                    <AutocompleteInput
                        optionValue="ehm_id"
                        optionText="ehm_expense_title"
                        resettable
                    />
                </ReferenceInput>
            </Grid>
            <Grid item sm={3}>
                {" "}
                <NumberInput
                    source="ee_expense_amount"
                    label="Amount"
                    variant="outlined"
                    helperText={false}
                    validate={[required()]}
                    fullWidth
                />
            </Grid>
            <Grid item sm={3}>
                {" "}
                <TextInput
                    source="ee_expense_description"
                    variant="outlined"
                    label=" Description"
                    minRows={5}
                    validate={[required()]}
                    fullWidth
                />
            </Grid>
            <FileInput
                source="attachedFiles_ee_attachment"
                label="Files"
                accept="image/*, application/pdf,"
                maxSize={IMAGE_FILE_MAX_SIZE}
                helperText={false}
                multiple
            >
                <FileField source="src" title="title" />
            </FileInput>
        </>
    );
};

export default ExpenseEntryForm;
