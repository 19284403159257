import { FC } from "react";
import { List, ListProps, ReferenceField, TextField } from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import { useDocumentTitle, useExport } from "../../../hooks";
import { CustomizableDatagrid } from "../../../lib";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import NotificationScheduleFilter from "./NotificationScheduleFilter";

const NotificationScheduleList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Notification Schedule List");

    const exporter = useExport(rest);
    const classes = useAroggaStyles();

    return (
        <List
            {...rest}
            title="List of Notification Schedule"
            filters={<NotificationScheduleFilter children={""} />}
            perPage={25}
            sort={{ field: "ns_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={permissions?.includes(
                "notificationScheduleDelete"
            )}
        >
            <CustomizableDatagrid
                rowClick="show"
                hideableColumns={["ns_created_by"]}
            >
                <TextField source="ns_id" label="ID" />
                <TextField source="ns_name" label="Name" />
                <TextField source="ns_channels" label="Channels" />
                <TextField
                    source="ns_status"
                    label="Status"
                    className={classes.capitalize}
                />

                <AroggaDateField source="ns_date_time" label="Date" />
                <AroggaDateField source="ns_created_at" label="Created At" />
                <ReferenceField
                    source="ns_created_by"
                    label="Created By"
                    reference="v1/users"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>
    );
};

export default NotificationScheduleList;
