import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";
import {
    AutocompleteInput,
    FileField,
    FileInput,
    NumberInput,
    ReferenceInput,
    SaveButton,
    SimpleForm,
    Toolbar,
    maxValue,
    minValue,
    required,
    useNotify,
    useRefresh,
} from "react-admin";
import { uploadDataProvider } from "../../../dataProvider";
import { useGetCurrentUser } from "../../../hooks";
import { IMAGE_FILE_MAX_SIZE } from "../../../utils/constants";

const PaymentActionModal = ({
    paymentType,
    paymentId,
    amount,
    isDialogOpen,
    setIsDialogOpen,
}) => {
    const classes = useStyles();

    const currentUser = useGetCurrentUser();
    const refresh = useRefresh();
    const notify = useNotify();
    const onSave = async (data) => {
        const payload = {
            ...data,
        };
        try {
            await uploadDataProvider.create(
                `v1/requisitionPaymentAction/${paymentId}/uploadRequisitionPaymentAction`,
                {
                    data: payload,
                }
            );
            notify("Successfully Created!", { type: "success" });
            handleDialogClose();
            refresh();
        } catch (err: any) {
            notify(err.message || "Failed!", { type: "warning" });
        }
    };
    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const CustomToolbar = (props) => (
        <Toolbar {...props}>
            <SaveButton label="Confirm" />
            <Box>
                <Button
                    onClick={handleDialogClose}
                    className={classes.closeButton}
                >
                    Cancel
                </Button>
            </Box>
        </Toolbar>
    );

    const getAccountFromLabel = () => {
        switch (paymentType) {
            case "city_to_city":
                return "City Bank Accounting Head ID";
            case "cash":
                return "Cash Head ID";
            case "city_to_others":
                return "City to Other Bank Accounting Head ID";
            default:
                return "Account From";
        }
    };
    return (
        <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="md">
            <DialogTitle>Upload Payment</DialogTitle>
            <DialogContent>
                <SimpleForm save={onSave} toolbar={<CustomToolbar />}>
                    <ReferenceInput
                        source="rp_payment_head_id"
                        label={getAccountFromLabel()}
                        reference="v1/accountingHead"
                        variant="outlined"
                        helperText={false}
                        filter={{
                            _head_user_id: currentUser.u_id,
                        }}
                        validate={required()}
                        fullWidth
                    >
                        <AutocompleteInput
                            optionValue="ah_id"
                            optionText={(record) =>
                                `${record?.ah_name} (${record?.ah_current_balance}tk)`
                            }
                            resettable
                        />
                    </ReferenceInput>
                    <NumberInput
                        source="rp_upload_amount"
                        label="Upload Amount"
                        variant="outlined"
                        helperText={false}
                        validate={[
                            required(),
                            minValue(
                                amount,
                                `Upload amount must be = ${amount}`
                            ),
                            maxValue(
                                amount,
                                `Upload amount must be = ${amount}`
                            ),
                        ]}
                        min={amount}
                        max={amount}
                        fullWidth
                    />
                    <FileInput
                        source="attachedFiles_pr_attachment"
                        label="Files"
                        accept="image/*, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        maxSize={IMAGE_FILE_MAX_SIZE}
                        helperText={false}
                        multiple
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                </SimpleForm>
            </DialogContent>
        </Dialog>
    );
};

export default PaymentActionModal;
const useStyles = makeStyles(() => ({
    closeButton: {
        marginLeft: 10,
        backgroundColor: "red",
        color: "white",
        "&:hover": {
            backgroundColor: "#f58989",
        },
    },
}));
