import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { TextInput } from "react-admin";
import { useFormState } from "react-final-form";

import AroggaDialogActions from "../../../components/AroggaDialogActions";
import { useRequest } from "../../../hooks";

type CacheTabDialogProps = {
    keyName: string;
    open: boolean;
    handleClose: () => void;
};

const CacheTabDialog: FC<CacheTabDialogProps> = ({
    keyName,
    open,
    handleClose,
}) => {
    const { values } = useFormState();

    const { key, group, value } = values;

    const { isLoading, refetch } = useRequest(
        keyName === "flush" || keyName === "stats"
            ? `/v1/cache/${keyName}`
            : `/v1/cache/${key ? key : ""}${group ? `/${group}` : ""}`,
        {
            method:
                keyName === "set"
                    ? "POST"
                    : keyName === "delete"
                    ? "DELETE"
                    : "GET",
            body: keyName === "set" ? { value } : undefined,
        },
        {
            onSuccess: () => {
                handleClose();
                if (keyName !== "flush" && keyName !== "stats") {
                    values.key = "";
                }
                if (keyName === "set") {
                    values.value = "";
                }
            },
        }
    );

    const disabled = () => {
        if (keyName === "flush" || keyName === "stats") return false;

        return !key;
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                handleClose();
                values.key = "";
                values.value = "";
            }}
        >
            <DialogTitle>{`Are you sure you want to ${keyName} cache?`}</DialogTitle>
            {(keyName === "get" ||
                keyName === "set" ||
                keyName === "delete") && (
                <DialogContent>
                    <TextInput
                        source="key"
                        label="Key"
                        variant="outlined"
                        helperText={false}
                        fullWidth
                    />
                    <TextInput
                        source="group"
                        label="Group"
                        variant="outlined"
                        helperText={false}
                        initialValue="default"
                        fullWidth
                    />
                    {keyName === "set" && (
                        <TextInput
                            source="value"
                            label="Value"
                            variant="outlined"
                            helperText={false}
                            fullWidth
                        />
                    )}
                </DialogContent>
            )}
            <AroggaDialogActions
                isLoading={isLoading}
                disabled={disabled()}
                onDialogClose={() => {
                    handleClose();
                    values.key = "";
                    values.value = "";
                }}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default CacheTabDialog;
