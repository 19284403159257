import { FC } from "react";
import { Edit, EditProps, SimpleForm } from "react-admin";

import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import DiscountForm from "../../../components/manageDatabase/discounts/DiscountForm";
import { useDocumentTitle } from "../../../hooks";

const DiscountEdit: FC<EditProps> = ({ hasEdit, ...rest }) => {
    useDocumentTitle("Arogga | Discount Edit");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm
                redirect="list"
                submitOnEnter={false}
                toolbar={
                    <SaveDeleteToolbar
                        isSave
                        isDelete={rest.permissions?.includes(
                            "productDiscountDelete"
                        )}
                    />
                }
            >
                <DiscountForm />
            </SimpleForm>
        </Edit>
    );
};

export default DiscountEdit;
