import { FC } from "react";
import { Create, CreateProps, SimpleForm } from "react-admin";

import BlogForm from "../../../components/manageSite/blogs/BlogForm";
import { useDocumentTitle } from "../../../hooks";
import { blogTransform } from "./utils/blogTransform";

const BlogCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga | Blog Create");

    return (
        <Create {...props} transform={blogTransform}>
            <SimpleForm redirect="list">
                <BlogForm />
            </SimpleForm>
        </Create>
    );
};

export default BlogCreate;
