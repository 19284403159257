import UpdateAssign from "../../../components/manageLabTest/CollectorAssign/UpdateAssign";

const LabLocationShow = () => {
    return (
        <div>
            <UpdateAssign />
        </div>
    );
};

export default LabLocationShow;
