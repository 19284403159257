import { FC } from "react";
import {
    Create,
    CreateProps,
    ImageField,
    ImageInput,
    NumberInput,
    SimpleForm,
} from "react-admin";

import { useDocumentTitle } from "../../../hooks";

const LabBannerCreate: FC<CreateProps> = (props) => {
    useDocumentTitle("Arogga |Lab Banner Create");

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <NumberInput
                    source="sortOrder"
                    variant="outlined"
                    label="Sort Order"
                />
                <ImageInput
                    source="attachedFiles-imageUrl"
                    label="Pictures (3000*3000 px)"
                    accept="image/*"
                    maxSize={10000000}
                >
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};

export default LabBannerCreate;
