import { Grid } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import {
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    useNotify,
    useRecordContext,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { useEffectOnDependencyChange, useInputRef } from "../../../hooks";
import {
    getProductTextRenderer,
    getQuantityLabel,
    getReadableSKU,
    isEmpty,
} from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import AroggaButton from "../../AroggaButton";
import ProductVariantUnitInput from "../../ProductVariantUnitInput";

type ProductEntryFormProps = {
    productOrderItems: any[];
    setProductOrderItems: (productOrderItems) => void;
};

const ProductEntryForm: FC<ProductEntryFormProps> = ({
    productOrderItems,
    setProductOrderItems,
}) => {
    const record = useRecordContext();
    const notify = useNotify();
    const form = useForm();
    const classes = useAroggaStyles();
    const { values } = useFormState();

    const inputRefs = {
        product: useInputRef(),
        variant: useInputRef(),
        unit: useInputRef(),
        qty: useInputRef(),
    };

    const [selectedProduct, setSelectedProduct] = useState<any>({});

    useEffect(() => {
        if (values.id && record?.poi) {
            setProductOrderItems(record.poi);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record?.poi]);

    useEffectOnDependencyChange(() => {
        if (!values.poi_product_id) {
            setSelectedProduct({});
        }
    }, [values.poi_product_id]);

    const selectedProductVariant = selectedProduct?.pv?.find(
        (item) => item.pv_id === values.poi_product_variant_id
    );
    const selectedProductUnit = selectedProduct?.pu?.find(
        (item) => item.pu_id === values.poi_product_unit_id
    );
    const baseUnit = selectedProductVariant?.pu_base_unit_label;
    const salesUnitMultiplier =
        selectedProductVariant?.[
            values.isB2BUser || !!values.po_is_b2b_order
                ? "pu_b2b_base_unit_multiplier"
                : "pu_b2c_base_unit_multiplier"
        ];

    const handleAddProduct = () => {
        const validationMsg = validateInputs();
        if (validationMsg) return notifyWarning(validationMsg);

        const productAlreadyAddedMsg = checkProductAlreadyAdded();
        if (productAlreadyAddedMsg)
            return notifyWarning(productAlreadyAddedMsg);

        addProductItem();
        resetFormFields();
        setSelectedProduct({});

        setTimeout(() => {
            inputRefs.product.current?.focus();
        }, 1);
    };

    const validateInputs = () => {
        const {
            poi_product_id,
            poi_product_variant_id,
            poi_product_unit_id,
            poi_product_qty,
        } = values;

        if (!poi_product_id) return "Product is required!";
        if (!poi_product_variant_id) return "Variant is required!";
        if (!poi_product_unit_id) return "Unit is required!";
        if (!poi_product_qty || poi_product_qty < 0)
            return "Qty must be greater than 0!";

        return null;
    };

    const checkProductAlreadyAdded = () => {
        const productOrderItem = productOrderItems?.find(
            (item) =>
                item.poi_product_variant_id === values.poi_product_variant_id
        );
        const readableSKU = getReadableSKU(
            selectedProductVariant?.pv_attribute
        );
        if (!isEmpty(productOrderItem))
            return `Variant (${readableSKU}) already added!`;

        return null;
    };

    const notifyWarning = (msg) => {
        notify(msg, { type: "warning" });
    };

    const addProductItem = () => {
        const { p_name, p_brand, p_brand_id, p_form, p_strength, p_cold } =
            selectedProduct || {};

        const {
            poi_product_id,
            poi_product_variant_id,
            poi_product_unit_id,
            poi_product_qty,
        } = values;

        const readableSKU = getReadableSKU(
            selectedProductVariant?.pv_attribute
        );

        const newProduct = {
            p_name,
            poi_product_id,
            p_brand,
            p_brand_id,
            productVariant: readableSKU,
            poi_product_variant_id,
            productUnit: selectedProductUnit?.pu_label,
            poi_product_unit_id,
            baseUnit,
            salesUnitMultiplier,
            p_form,
            p_strength,
            p_cold,
            poi_product_qty,
            poi_mrp_price:
                selectedProductVariant?.[
                    values.isB2BUser || !!values.po_is_b2b_order
                        ? "pv_b2b_mrp"
                        : "pv_b2c_mrp"
                ],
            poi_discount_price:
                selectedProductVariant?.[
                    values.isB2BUser || !!values.po_is_b2b_order
                        ? "pv_b2b_price"
                        : "pv_b2c_price"
                ],
            is_antibiotics: selectedProductVariant?.is_antibiotics,
            is_controlled: selectedProductVariant?.is_controlled,
        };

        setProductOrderItems((prevProducts) => [...prevProducts, newProduct]);
    };

    const resetFormFields = () => {
        [
            "poi_product_id",
            "poi_product_variant_id",
            "poi_product_unit_id",
            "poi_product_qty",
        ].forEach((field) => form.change(field, undefined));
    };

    values.poi = productOrderItems || [];

    if (!values.userId && !values.po_user_id) return;

    return (
        <Grid container spacing={1}>
            <Grid item md={4}>
                <ReferenceInput
                    source="poi_product_id"
                    label="Product"
                    variant="outlined"
                    helperText={false}
                    // reference="general/v3/search"
                    reference="v1/product"
                    enableGetChoices={({ q }) => !!q && q.trim().length > 0}
                    filter={{
                        _details: 1,
                        _allow_sales: 1,
                    }}
                    sort={{ field: "p_name", order: "ASC" }}
                    fullWidth
                >
                    <AutocompleteInput
                        optionValue="p_id"
                        optionText={getProductTextRenderer}
                        options={{
                            InputProps: {
                                inputRef: inputRefs.product,
                            },
                        }}
                        matchSuggestion={() => true}
                        onSelect={(product) => {
                            setSelectedProduct(product);
                            setTimeout(() => {
                                product?.pv?.length === 1
                                    ? inputRefs.qty.current?.focus()
                                    : inputRefs.variant.current?.focus();
                            }, 1);
                        }}
                        resettable
                    />
                </ReferenceInput>
            </Grid>
            <Grid item md={3}>
                <ProductVariantUnitInput
                    source="poi_product_variant_id"
                    label="Variant"
                    data={selectedProduct?.pv}
                    inputRef={inputRefs.variant}
                    onSelect={() => inputRefs.qty.current?.focus()}
                />
            </Grid>
            <Grid item md={3}>
                <ProductVariantUnitInput
                    source="poi_product_unit_id"
                    label="Unit"
                    data={selectedProduct?.pu}
                    isB2BUser={values.isB2BUser || !!values.po_is_b2b_order}
                    isB2CUser={!values.isB2BUser || !values.po_is_b2b_order}
                    inputRef={inputRefs.unit}
                    onSelect={() =>
                        !values.poi_product_qty &&
                        inputRefs.qty.current?.focus()
                    }
                    disabled
                />
            </Grid>
            <Grid item md={1}>
                <NumberInput
                    source="poi_product_qty"
                    label="Qty"
                    variant="outlined"
                    helperText={false}
                    inputRef={inputRefs.qty}
                    onKeyDown={(e) => e.key === "Enter" && handleAddProduct()}
                    min={1}
                    fullWidth
                />
                <span className={classes.whitespaceNowrap}>
                    {getQuantityLabel({
                        qty: values.poi_product_qty,
                        salesUnit: selectedProductUnit?.pu_label,
                        baseUnit,
                        salesUnitMultiplier,
                    })}
                </span>
            </Grid>
            {!!values.poi_product_id &&
                !!values.poi_product_variant_id &&
                !!values.poi_product_unit_id &&
                !!values.poi_product_qty && (
                    <Grid item md={1}>
                        <AroggaButton
                            label="Add New"
                            onClick={handleAddProduct}
                            style={{
                                width: 93,
                                height: 38,
                                marginTop: 9,
                            }}
                        />
                    </Grid>
                )}
        </Grid>
    );
};

export default ProductEntryForm;
