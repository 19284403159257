import React, { useEffect, useState } from "react";
import {
    AppBar,
    Box,
    Button,
    makeStyles,
    Tab,
    Tabs,
    Typography,
    Theme,
} from "@material-ui/core";
import {
    ArrayField,
    AutocompleteInput,
    Confirm,
    ReferenceInput,
    required,
    TextInput,
} from "react-admin";
import { useFormState } from "react-final-form";

import TaxonomiesByVocabularyInput from "../../../../components/TaxonomiesByVocabularyInput";
import QuotationCreate from "../../../../components/manageRequisition/purchaseRequisition/QuotationCreate";
import QuotationList from "../../../../components/manageRequisition/purchaseRequisition/QuotationList";
import {
    useDocumentTitle,
    useGetCurrentUser,
    useRequest,
} from "../../../../hooks";
import { useAroggaStyles } from "../../../../utils/useAroggaStyles";
import QuotationListTable from "./QuotationListTable";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const QuotationListTab = ({ record }) => {
    useDocumentTitle("Arogga |  Quotation Tab");
    const { values } = useFormState();
    const classes = useAroggaStyles();
    const classes2 = useStyles();

    const { permissions } = useGetCurrentUser();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isQuotationDialogOpen, setIsQuotationDialogOpen] = useState(false);
    const [actionType, setActionType] = useState("");
    const [actionContext, setActionContext] = useState("");
    const [quotationAction, setQuotationAction] = useState("");
    const [procurementTitle, setProcurementTitle] = useState("");
    const [quotationId, setQuotationId] = useState(null);
    const [selectAccount, setSelectAccount] = useState(null);
    const [selectProduct, setSelectProduct] = useState(null);
    const [issueReason, setIssueReason] = useState(null);

    const hasApprovedStatus = record?.quotations?.some(
        (item) => item?.q_status === "approved" || item?.q_status === "rejected"
    );
    const procurementActionButtonShow = record?.quotations?.some(
        (item) => item?.is_show_procurement_action_button === true
    );
    const financeActionButtonShow = record?.quotations?.some(
        (item) => item?.is_show_finance_action_button === true
    );
    const capApprovalButtonShow = record?.quotations?.some(
        (item) => item?.is_show_management_action_button === true
    );
    const financeIssueShow = record?.quotations?.some(
        (item) => item?.q_finance_issue_reason !== ""
    );

    const { isLoading, refetch } = useRequest(
        `/v1/quotationAction/${quotationId}/${actionContext}`,
        {
            method: "POST",
            body: (() => {
                if (actionContext === "statusAction") {
                    return actionType === "approved"
                        ? {
                              q_procurement_title: procurementTitle,
                              q_status: "approved",
                          }
                        : { q_status: "rejected" };
                }
                if (actionContext === "accountStatusAction") {
                    return actionType === "finance_cleared"
                        ? {
                              q_status: "finance_cleared",
                              q_payable_head_id: selectAccount,
                              q_advance_to_vendor_head_id: selectProduct,
                          }
                        : {
                              q_status: "finance_issue",
                              q_finance_issue_reason: issueReason,
                          };
                }
                if (actionContext === "capStatusAction") {
                    return actionType === "approved"
                        ? { q_cap_status: "approved" }
                        : {
                              q_cap_status: "rejected",
                          };
                }
                return {};
            })(),
        },

        {
            onSuccess: () => {
                actionContext === "capStatusAction" &&
                    actionType === "approved" &&
                    window.location.reload();
            },
            isRefresh: true,
        }
    );

    const getConfirmTitleAction = (actionType, actionContext) => {
        switch (actionContext) {
            case "accountStatusAction":
                return actionType === "finance_cleared"
                    ? "finance_cleared"
                    : "finance_issue";
            default:
                return actionType === "approved" ? "approve" : "reject";
        }
    };

    const confirmTitleActions = getConfirmTitleAction(
        actionType,
        actionContext
    );

    const [value, setValue] = useState(0);

    useEffect(() => {
        const showData = record?.quotations?.some(
            (item) => item?.q_status !== "pending"
        );
        setValue(showData ? 1 : 0);
    }, [record]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={classes2.mainDiv}>
            <QuotationList />
            <div className={classes2.div}>
                {record?.quotations?.length > 0 &&
                    !hasApprovedStatus &&
                    permissions?.includes("quotationMenu") &&
                    permissions?.includes("quotationView") && (
                        <div>
                            <Typography
                                variant="body1"
                                color="textPrimary"
                                className={classes2.title}
                            >
                                Quotation Items
                            </Typography>
                        </div>
                    )}
                {record?.pr_status === "department_approved" &&
                    !hasApprovedStatus &&
                    permissions?.includes("quotationCreate") && (
                        <div>
                            <Button
                                variant="contained"
                                size="small"
                                className={classes.bgGreen}
                                onClick={() => {
                                    setQuotationId(record?.id);
                                    setQuotationAction("create");
                                    setIsQuotationDialogOpen(true);
                                }}
                            >
                                Add Quotation
                            </Button>
                        </div>
                    )}
            </div>
            {record?.quotations?.length > 0 &&
                permissions?.includes("quotationMenu") &&
                permissions?.includes("quotationView") && (
                    <div className={classes2.root}>
                        <AppBar position="static" className={classes2.root2}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="All Quotation" {...a11yProps(0)} />
                                <Tab
                                    label="Approved Quotation"
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </AppBar>
                        <div className={classes2.expandTable}>
                            <TabPanel value={value} index={0}>
                                {permissions?.includes(
                                    "purchaseRequisitionView"
                                ) && (
                                    <ArrayField source="quotations">
                                        <QuotationListTable
                                            record={record}
                                            procurementActionButtonShow={
                                                procurementActionButtonShow
                                            }
                                            setQuotationId={setQuotationId}
                                            setActionType={setActionType}
                                            setActionContext={setActionContext}
                                            setIsDialogOpen={setIsDialogOpen}
                                            financeActionButtonShow={
                                                financeActionButtonShow
                                            }
                                            financeIssueShow={financeIssueShow}
                                            capApprovalButtonShow={
                                                capApprovalButtonShow
                                            }
                                        />
                                    </ArrayField>
                                )}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {permissions?.includes(
                                    "purchaseRequisitionView"
                                ) && (
                                    <ArrayField
                                        source="quotations"
                                        // @ts-ignore
                                        record={{
                                            quotations:
                                                values?.quotations?.filter(
                                                    (item) =>
                                                        item.q_status !==
                                                        "pending"
                                                ),
                                        }}
                                    >
                                        <QuotationListTable
                                            record={record}
                                            procurementActionButtonShow={
                                                procurementActionButtonShow
                                            }
                                            setQuotationId={setQuotationId}
                                            setActionType={setActionType}
                                            setActionContext={setActionContext}
                                            setIsDialogOpen={setIsDialogOpen}
                                            financeActionButtonShow={
                                                financeActionButtonShow
                                            }
                                            financeIssueShow={financeIssueShow}
                                            capApprovalButtonShow={
                                                capApprovalButtonShow
                                            }
                                        />
                                    </ArrayField>
                                )}
                            </TabPanel>
                        </div>
                    </div>
                )}

            {isQuotationDialogOpen === true && (
                <QuotationCreate
                    id={quotationId}
                    quotationAction={quotationAction}
                    isQuotationDialogOpen={isQuotationDialogOpen}
                    setIsQuotationDialogOpen={setIsQuotationDialogOpen}
                />
            )}
            <Confirm
                isOpen={isDialogOpen}
                loading={isLoading}
                title={`Are you sure you want to move status to ${confirmTitleActions} for this quotations #${quotationId}?`}
                content={
                    actionContext === "statusAction" &&
                    actionType === "approved" ? (
                        <TaxonomiesByVocabularyInput
                            fetchKey="procurement_title"
                            source="q_procurement_title"
                            label="Procurement Title"
                            helperText={false}
                            validate={[required()]}
                            defaultValue={procurementTitle}
                            onChange={(e) =>
                                setProcurementTitle(e?.target?.value)
                            }
                            fullWidth
                        />
                    ) : actionContext === "accountStatusAction" ? (
                        actionType === "finance_issue" ? (
                            <TextInput
                                source="issueReason"
                                variant="outlined"
                                label="Issue Reason"
                                defaultValue={issueReason}
                                onChange={(e) =>
                                    setIssueReason(e?.target?.value)
                                }
                                fullWidth
                            />
                        ) : (
                            <>
                                <ReferenceInput
                                    source="q_payable_head_id"
                                    label="Vendor Payable Head Id"
                                    reference="v1/accountingHead"
                                    variant="outlined"
                                    helperText={false}
                                    validate={[required()]}
                                    fullWidth
                                >
                                    <AutocompleteInput
                                        optionValue="ah_id"
                                        optionText="ah_name"
                                        resettable
                                        onSelect={(item) => {
                                            setSelectAccount(item.ah_id);
                                        }}
                                    />
                                </ReferenceInput>
                                <ReferenceInput
                                    source="q_advance_to_vendor_head_id"
                                    label="Advance To Vendor"
                                    reference="v1/accountingHead"
                                    variant="outlined"
                                    helperText={false}
                                    validate={[required()]}
                                    fullWidth
                                >
                                    <AutocompleteInput
                                        optionValue="ah_id"
                                        optionText="ah_name"
                                        resettable
                                        onSelect={(item) => {
                                            setSelectProduct(item.ah_id);
                                        }}
                                    />
                                </ReferenceInput>
                            </>
                        )
                    ) : null
                }
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
        </div>
    );
};

export default QuotationListTab;

const useStyles = makeStyles((theme: Theme) => ({
    mainDiv: {
        marginTop: 20,
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        padding: "25px 0",
    },
    root2: {
        backgroundColor: theme.palette.background.paper,
        margin: "-40px 10px",
        padding: "8px 20px",
    },
    expandTable: {
        margin: "7px -35px 0px -15px",
    },
    div: {
        display: "flex",
        justifyContent: "space-between",
        margin: "40px 0 20px 0",
    },
    title: {
        fontWeight: "bolder",
    },
}));
