const ManageLabTestIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <mask
            id="mask0_111_42611"
            // style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_111_42611)">
            <path
                d="M4.99994 21C4.14994 21 3.54577 20.6208 3.18744 19.8625C2.82911 19.1042 2.91661 18.4 3.44994 17.75L8.99994 11V5H7.99994C7.71661 5 7.47911 4.90417 7.28744 4.7125C7.09577 4.52083 6.99994 4.28333 6.99994 4C6.99994 3.71667 7.09577 3.47917 7.28744 3.2875C7.47911 3.09583 7.71661 3 7.99994 3H15.9999C16.2833 3 16.5208 3.09583 16.7124 3.2875C16.9041 3.47917 16.9999 3.71667 16.9999 4C16.9999 4.28333 16.9041 4.52083 16.7124 4.7125C16.5208 4.90417 16.2833 5 15.9999 5H14.9999V11L20.5499 17.75C21.0833 18.4 21.1708 19.1042 20.8124 19.8625C20.4541 20.6208 19.8499 21 18.9999 21H4.99994Z"
                fill="#969BAD"
            />
        </g>
    </svg>
);

export default ManageLabTestIcon;
