import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import { FC } from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { useRequest } from "../../../../hooks";
import { userEmployeeInputTextRenderer } from "../../../../utils/helpers";
import AroggaDialogActions from "../../../AroggaDialogActions";
import UserEmployeeOptionTextRenderer from "../../../UserEmployeeOptionTextRenderer";

type AddKamDialogProps = {
    isDialogOpen: boolean;
    setIsDialogOpen: (isDialogOpen: boolean) => void;
    setVendors: (vendors: any) => void;
    selectedVendorIdForKam: number;
    selectedVendorName: string;
};

const AddKamDialog: FC<AddKamDialogProps> = ({
    isDialogOpen,
    setIsDialogOpen,
    setVendors,
    selectedVendorIdForKam,
    selectedVendorName,
}) => {
    const form = useForm();
    const { values } = useFormState();

    const { isLoading, refetch } = useRequest(
        `/v1/ProductAction/${selectedVendorIdForKam}/setVendorKimAction`,
        {
            method: "POST",
            body: { v_kam_user_id: values.v_kam_user_id },
        },
        {
            onSuccess: () => {
                setVendors((prev) => ({
                    ...prev,
                    [selectedVendorIdForKam]: {
                        v_kam_user_id: values.v_kam_user_id,
                    },
                }));
                onDialogClose();
            },
        }
    );

    const onDialogClose = () => {
        setIsDialogOpen(false);
        form.change("v_kam_user_id", undefined);
    };

    return (
        <Dialog open={isDialogOpen} onClose={onDialogClose} maxWidth="xl">
            <DialogTitle>
                <Typography>
                    Add KAM {selectedVendorName && `(${selectedVendorName})`}
                </Typography>
            </DialogTitle>
            <DialogContent style={{ width: 600 }}>
                <ReferenceInput
                    source="v_kam_user_id"
                    label="User"
                    variant="outlined"
                    helperText={false}
                    reference="v1/users"
                    fullWidth
                >
                    <AutocompleteInput
                        matchSuggestion={() => true}
                        helperText={false}
                        optionText={<UserEmployeeOptionTextRenderer />}
                        inputText={userEmployeeInputTextRenderer}
                        options={{
                            InputProps: {
                                multiline: true,
                            },
                        }}
                        resettable
                    />
                </ReferenceInput>
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={onDialogClose}
                onConfirm={refetch}
                disabled={!values.v_kam_user_id}
            />
        </Dialog>
    );
};

export default AddKamDialog;
