import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CSSProperties, FC } from "react";
import { Labeled } from "react-admin";

import { capitalizeFirstLetterOfEachWord } from "../utils/helpers";

type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type AroggaAccordionProps = {
    title: string;
    isGrid?: boolean;
    children: any;
    alignItems?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
    accordionDetailsStyle?: CSSProperties;
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
};

const AroggaAccordion: FC<AroggaAccordionProps> = ({
    title,
    isGrid = true,
    children,
    alignItems,
    accordionDetailsStyle,
    xs = 12,
    sm = 6,
    md = 3,
}) => {
    const classes = useStyles();

    return (
        <Accordion className={classes.accordion} defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionSummary}
            >
                <span className={classes.title}>{title}</span>
            </AccordionSummary>
            <AccordionDetails style={accordionDetailsStyle}>
                {isGrid ? (
                    <Grid container spacing={1} alignItems={alignItems}>
                        {children?.filter(Boolean)?.map((item, index) => (
                            // @ts-ignore
                            <Grid item xs={xs} sm={sm} md={md} key={index}>
                                {item?.props?.addLabel ? (
                                    <Labeled
                                        label={
                                            item.props.label ||
                                            capitalizeFirstLetterOfEachWord(
                                                item.props.source
                                            )
                                        }
                                    >
                                        {item}
                                    </Labeled>
                                ) : (
                                    item
                                )}
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    children
                )}
            </AccordionDetails>
        </Accordion>
    );
};

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            color: "#0E7673",
            fontWeight: 600,
        },
        accordion: {
            marginBottom: 10,
        },
        accordionSummary: {
            background: "#DFEDED",
            fontWeight: "bold",
            fontSize: 20,
        },
    })
);

export default AroggaAccordion;
