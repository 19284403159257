import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import { FC } from "react";

import { getReadableSKU, isEmpty } from "../../../utils/helpers";
import AroggaDialogActions from "../../AroggaDialogActions";
import AroggaMovableImageInput from "../../AroggaMovableImageInput";

type ImageDialogProps = {
    activeImageRowIndex: number;
    attribute: any;
    attachedFiles: any[];
    open: boolean;
    handleClose: () => void;
};

const ImageDialog: FC<ImageDialogProps> = ({
    activeImageRowIndex,
    attribute,
    attachedFiles,
    open,
    handleClose,
}) => (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>
            <Typography>
                Images (Minimum dimensions: 700x700px, Ratio: 1:1)
            </Typography>
            {!isEmpty(attribute) && (
                <Typography>{getReadableSKU(attribute)}</Typography>
            )}
        </DialogTitle>
        <DialogContent>
            <AroggaMovableImageInput
                source={`attachedFiles_pv_images-${activeImageRowIndex}`}
                label=""
                initialValue={attachedFiles}
                dimentionValidation
            />
        </DialogContent>
        <AroggaDialogActions
            isLoading={false}
            hasCancelBtn={false}
            onDialogClose={handleClose}
            onConfirm={handleClose}
        />
    </Dialog>
);

export default ImageDialog;
