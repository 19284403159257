import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    makeStyles,
} from "@material-ui/core";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Title } from "react-admin";
import { useReactToPrint } from "react-to-print";
import AroggaButton from "../../../components/AroggaButton";
import AroggaProgress from "../../../components/AroggaProgress";
import NoDataFound from "../../../components/NoDataFound";
import CustomTableCell from "../../../components/manageAccounting/CustomTableCell";
import { useRequest, useXLSXDownloader } from "../../../hooks";
import { numberFormat, toFormattedDateTime } from "../../../utils/helpers";

const IncomeStatementPage = () => {
    const classes = useStyles();

    const printableRef = useRef(null);
    const { onExportToXLSX } = useXLSXDownloader();
    const currentDate = new Date();

    const [startDate, setStartDate] = useState(
        toFormattedDateTime({
            isDate: true,
            dateString: new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                1
            ).toString(),
        })
    );
    const [endDate, setEndDate] = useState(
        toFormattedDateTime({
            isPreviousDate: true,
            dateString: new Date().toString(),
        })
    );

    const { data, isLoading, isSuccess, refetch } = useRequest(
        `/v1/accounts/incomeStatement?_start_date=${startDate}&_end_date=${endDate}`
    );

    useEffect(() => {
        if (!startDate || !endDate) return;

        const debouncedHandleDateChange = debounce(() => refetch(), 1000);
        debouncedHandleDateChange();
        return () => debouncedHandleDateChange.cancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    const isSpecialCategory = (category) => {
        return [
            "Total Income",
            "Total Expense",
            "Net Profit",
            "Net Loss",
        ].includes(category);
    };

    const profitLossColor = (category) => {
        if (category === "Net Profit") {
            return "green";
        } else if (category === "Net Loss") {
            return "#EF1962";
        } else {
            return "";
        }
    };

    const formattedBalance = (balance) => {
        if (!balance) return "";
        return numberFormat(balance);
    };

    const formattedData = data?.map(({ account_head_name, balance }) => ({
        account_head_name: account_head_name,
        balance: !isSpecialCategory(account_head_name) ? balance : 0,
        totalBalance: isSpecialCategory(account_head_name) ? balance : 0,
    }));

    const handleDateChange = (e) => {
        e.target.id === "start-date"
            ? setStartDate(e.target.value)
            : setEndDate(e.target.value);
    };

    let filename;

    if (startDate && endDate && data?.length) {
        filename = `Income Statement - ${startDate}_${endDate}`;
    }

    const handlePrint = useReactToPrint({
        content: () => printableRef.current,
        documentTitle: filename,
        pageStyle: `
                @page {
                margin: 3mm;
            }
        `,
    });

    return (
        <Paper style={{ marginTop: 25, padding: 20 }}>
            <Title title="Income Statement" />
            {!!formattedData?.length && (
                <Box display="flex" justifyContent="right" gridGap={5}>
                    <AroggaButton
                        type="success"
                        label="Print"
                        onClick={handlePrint}
                    />
                    <AroggaButton
                        type="success"
                        label="Export"
                        onClick={() => onExportToXLSX(formattedData, filename)}
                    />
                </Box>
            )}
            <div ref={printableRef}>
                <Typography variant="h5" align="center">
                    Income Statement
                </Typography>
                <Box textAlign="center" my={2}>
                    <label htmlFor="start-date">Start Date: </label>
                    <input
                        type="date"
                        id="start-date"
                        value={startDate}
                        onChange={handleDateChange}
                    />
                    <label htmlFor="end-date" style={{ marginLeft: 5 }}>
                        End Date:{" "}
                    </label>
                    <input
                        type="date"
                        id="end-date"
                        value={endDate}
                        onChange={handleDateChange}
                    />
                </Box>
                {isLoading && (
                    <Box position="relative" mt={10}>
                        <AroggaProgress />
                    </Box>
                )}
                {isSuccess && !formattedData?.length && <NoDataFound />}
                {!isLoading && !!formattedData?.length && (
                    <Table className={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.borderRight}>
                                    Account Name
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className={classes.borderRight}
                                >
                                    Balance
                                </TableCell>
                                <TableCell align="right">
                                    Total Balance
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {formattedData.map(
                                (
                                    {
                                        account_head_name,
                                        balance,
                                        totalBalance,
                                    },
                                    index
                                ) => (
                                    <TableRow key={index}>
                                        <CustomTableCell
                                            data={account_head_name}
                                            className={
                                                isSpecialCategory(
                                                    account_head_name
                                                )
                                                    ? classes.fontBold
                                                    : ""
                                            }
                                            style={{
                                                color: profitLossColor(
                                                    account_head_name
                                                ),
                                            }}
                                        />
                                        <CustomTableCell
                                            data={formattedBalance(balance)}
                                            align="right"
                                        />
                                        <CustomTableCell
                                            data={formattedBalance(
                                                totalBalance
                                            )}
                                            align="right"
                                            className={
                                                isSpecialCategory(
                                                    account_head_name
                                                )
                                                    ? classes.fontBold
                                                    : ""
                                            }
                                            style={{
                                                color: profitLossColor(
                                                    account_head_name
                                                ),
                                            }}
                                        />
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                )}
            </div>
        </Paper>
    );
};

export default IncomeStatementPage;

const useStyles = makeStyles({
    table: {
        width: "100%",
        maxWidth: 960,
        margin: "0 auto",
    },
    borderRight: {
        borderRight: "1px solid #ddd",
    },
    fontBold: {
        fontWeight: "bold",
    },
});
