import { FC, useState } from "react";
import {
    List,
    ListProps,
    Record,
    ReferenceField,
    SimpleForm,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import { useDocumentTitle } from "../../../hooks";
import { CustomizableDatagrid } from "../../../lib";
import RevisionDialog from "./RevisionDialog";
import RevisionFilter from "./RevisionFilter";

const RevisionList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Revision List");

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowClick = (_, __, record: Record) => {
        setSelectedRow(record);
        setIsDialogOpen(true);
        return "";
    };

    return (
        <>
            <List
                {...rest}
                title="List of Revision"
                perPage={25}
                sort={{ field: "r_id", order: "DESC" }}
                filters={<RevisionFilter children={""} />}
                bulkActionButtons={false}
                exporter={false}
            >
                <CustomizableDatagrid rowClick={handleRowClick}>
                    <TextField source="r_id" label="ID" />
                    <TextField source="r_entity_id" label="Entity ID" />
                    <TextField source="r_entity" label="Entity" />
                    <TextField source="r_action" label="Action" />
                    <TextField source="r_status" label="Status" />
                    <TextField source="r_comment" label="Comment" />
                    <AroggaDateField source="r_created_at" label="Created At" />
                    <ReferenceField
                        source="r_created_by"
                        label="Created By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="r_approved_at"
                        label="Approved At"
                    />
                    <ReferenceField
                        source="r_approved_by"
                        label="Approved By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                </CustomizableDatagrid>
            </List>
            <SimpleForm toolbar={null}>
                <RevisionDialog
                    isDialogOpen={isDialogOpen}
                    setIsDialogOpen={setIsDialogOpen}
                    selectedRow={selectedRow}
                />
            </SimpleForm>
        </>
    );
};

export default RevisionList;
