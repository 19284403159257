import { DateTime } from "luxon";
import pluralize from "pluralize";
import {
    Datagrid,
    FunctionField,
    ImageField,
    Link,
    NumberField,
    Record,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import {
    capitalizeFirstLetterOfEachWord,
    getQuantityLabel,
    toFixedNumber,
} from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

const LocalItemDatagrid = (props) => {
    const classes = useAroggaStyles();

    return (
        <Datagrid {...props}>
            <TextField source="ps_id" label="ID" />
            <FunctionField
                label="Status"
                sortBy="ps_status"
                render={({ ps_status }: Record) => (
                    <span className={classes.whitespaceNowrap}>
                        {capitalizeFirstLetterOfEachWord(ps_status)}
                    </span>
                )}
            />
            <TextField source="p_name" label="Item Name" />
            <ImageField
                source="p_image"
                label="Image"
                src="src"
                title="title"
                className="small__img"
            />
            <TextField source="p_id" label="SKU ID" />
            <TextField source="pv_id" label="Variant ID" />
            <TextField
                source="ps_category"
                label="Category (L1)"
                className={classes.capitalize}
            />
            <TextField source="p_form" label="Form" />
            <TextField source="p_strength" label="Strength" />
            <FunctionField
                label="Brand"
                sortBy="p_brand"
                render={({ p_brand_id, p_brand }: Record) => (
                    <Link to={`/v1/productBrand/${p_brand_id}/show`}>
                        {p_brand}
                    </Link>
                )}
            />
            <FunctionField
                label="Local Vendor"
                sortBy="ps_local_vendor"
                render={({ ps_local_vendor_id, ps_local_vendor }: Record) => (
                    <Link to={`/v1/vendor/${ps_local_vendor_id}/show`}>
                        {ps_local_vendor}
                    </Link>
                )}
            />
            <FunctionField
                source="pv_stock_qty"
                label="In Stock Qty"
                sortBy="pv_stock_qty"
                render={({
                    pv_stock_qty: qty,
                    pv_base_unit_label: baseUnit,
                    pv_unit_label: salesUnit,
                    pv_unit_multiplier: salesUnitMultiplier,
                }: Record) => (
                    <span className={classes.whitespaceNowrap}>
                        {getQuantityLabel({
                            qty,
                            salesUnit,
                            baseUnit,
                            salesUnitMultiplier,
                        })}
                    </span>
                )}
            />
            <FunctionField
                source="pv_req_qty"
                label="Required Qty"
                sortBy="pv_req_qty"
                render={({
                    pv_req_qty: qty,
                    pv_base_unit_label: baseUnit,
                    pv_unit_label: salesUnit,
                    pv_unit_multiplier: salesUnitMultiplier,
                }: Record) => (
                    <span className={classes.whitespaceNowrap}>
                        {getQuantityLabel({
                            qty,
                            salesUnit,
                            baseUnit,
                            salesUnitMultiplier,
                        })}
                    </span>
                )}
            />
            <NumberField source="pv_mrp" label="MRP" />
            <NumberField source="pv_last_tp" label="Last Official TP" />
            <FunctionField
                label="Aprox. Amount"
                render={({ pv_req_qty, pv_last_tp }: Record) =>
                    toFixedNumber(pv_req_qty * pv_last_tp)
                }
            />
            <AroggaDateField source="ps_created_at" label="Created At" />
            <FunctionField
                label="Timer"
                render={({ ps_created_at }: Record) => {
                    const diff = DateTime.local()
                        .diff(DateTime.fromSQL(ps_created_at), [
                            "days",
                            "hours",
                            "minutes",
                        ])
                        .toObject();

                    return (
                        <span
                            className={`${classes.textRed} ${classes.whitespaceNowrap}`}
                        >
                            {!!diff.days &&
                                `${diff.days} ${pluralize("day", diff.days)} `}
                            {!!diff.hours &&
                                `${diff.hours} ${pluralize(
                                    "hour",
                                    diff.hours
                                )} `}
                            {!!Math.floor(diff.minutes) &&
                                `${Math.floor(diff.minutes)}min`}
                        </span>
                    );
                }}
            />
        </Datagrid>
    );
};

export default LocalItemDatagrid;
