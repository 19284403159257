import {
    Datagrid,
    FunctionField,
    Pagination,
    Record,
    ReferenceField,
    ReferenceManyField,
    TextField,
    Link,
} from "react-admin";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

const RequisitionPaymentListExpand = (props) => {
    const classes = useAroggaStyles();
    return (
        <>
            <ReferenceManyField
                reference="v1/requisitionPaymentItem"
                target="_requisition_payment_id"
                filter={{ _requisition_payment_id: props?.record?.rp_id }}
                pagination={<Pagination />}
                sort={{ field: "rpi_id", order: "DESC" }}
            >
                <Datagrid>
                    <TextField source="rpi_id" label="ID" />

                    <TextField source="rpi_entity" label="Entity Type" />
                    <FunctionField
                        label="Requisition ID"
                        render={({ rpi_entity_id, rpi_entity }: Record) => (
                            <Link
                                to={`/v1/${rpi_entity}/${rpi_entity_id}/show`}
                            >
                                ({rpi_entity_id})View
                            </Link>
                        )}
                    />

                    <TextField source="rpi_amount" label="Amount" />
                    <TextField
                        source="rpi_payment_method"
                        label="Payment Method"
                        className={classes.capitalize}
                    />
                    <ReferenceField
                        label="Bank"
                        source="rpi_bank_id"
                        reference="v1/bank"
                    >
                        <TextField source="b_name" />
                    </ReferenceField>
                    <TextField
                        source="rpi_account_number"
                        label="Account Number"
                    />
                </Datagrid>
            </ReferenceManyField>{" "}
        </>
    );
};

export default RequisitionPaymentListExpand;
