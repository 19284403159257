import { Box } from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useRef, useState } from "react";
import {
    AutocompleteInput,
    Labeled,
    NumberInput,
    ReferenceInput,
    TextInput,
    minValue,
    required,
} from "react-admin";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import { TINY_MCE_EDITOR_INIT } from "../../../utils/constants";
import {
    getProductTextRenderer,
    getReadableSKU,
    groupBy,
} from "../../../utils/helpers";
import AroggaMovableImageInput from "../../AroggaMovableImageInput";
import FormatedBooleanInput from "../../FormatedBooleanInput";
import InlineArrayInput from "../../InlineArrayInput";
import TaxonomiesByVocabularyInput from "../../TaxonomiesByVocabularyInput";

const BlogForm = (props) => {
    const { values } = useFormState();

    const inputRef = useRef<HTMLInputElement>(null!);
    const [selectedProductVariants, setSelectedProductVariants] =
        useState(null);
    const [description, setDescription] = useState(values.bp_description);

    const { data, refetch } = useRequest("");

    useEffect(() => {
        if (props?.record?.bp_products?.length && values.bp_id) {
            fetchProductData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.record]);

    useEffect(() => {
        if (!data?.length) return;

        const mergeIndividualVariants = data?.reduce((acc, currentValue) => {
            acc.push(...currentValue.pv);
            return acc;
        }, []);

        // TODO: Add support specific variant selection in groupBy function
        // pv_id, pv_sku, pv_attribute
        const groupByProductVariantProductId = groupBy(
            mergeIndividualVariants,
            (data) => data.pv_p_id
        );

        setSelectedProductVariants(groupByProductVariantProductId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const fetchProductData = () => {
        const productIds = values.bp_products
            ?.filter((item) => !!item?.p_id)
            ?.map((item) => item?.p_id);

        productIds?.length &&
            refetch({
                endpoint: `/v1/product?_details=1&ids=${productIds?.join(",")}`,
            });
    };

    values.bp_description = description;

    return (
        <>
            {values.bp_id && (
                <TextInput
                    source="bp_id"
                    label="ID"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
            )}
            <Box display="flex" gridGap={8}>
                <TextInput
                    source="bp_title"
                    label="Title"
                    variant="outlined"
                    helperText={false}
                    validate={[required()]}
                    multiline
                />
                <TaxonomiesByVocabularyInput
                    fetchKey="blog_type"
                    source="bp_type"
                    label="Blog Type"
                    helperText={false}
                    validate={[required()]}
                />
                <NumberInput
                    source="bp_reading_time"
                    label="Reading Time"
                    variant="outlined"
                    helperText={false}
                    validate={[minValue(1)]}
                    min={1}
                />
                <FormatedBooleanInput
                    source="bp_is_feature"
                    label="Feature?"
                    style={{
                        marginTop: 8,
                    }}
                    fullWidth
                />
                <FormatedBooleanInput
                    source="bp_is_active"
                    label="Active?"
                    style={{
                        marginTop: 8,
                    }}
                    fullWidth
                />
            </Box>
            {/* TODO: Refactor this part in future */}
            <InlineArrayInput
                source="bp_products"
                label="Products"
                enableRenderProps
            >
                {({ getSource, scopedFormData }) => {
                    const individualProductVariantsLength =
                        selectedProductVariants?.[scopedFormData?.p_id]?.length;

                    return (
                        <Box display="flex" gridGap={8}>
                            <ReferenceInput
                                source={getSource("p_id")}
                                record={scopedFormData}
                                label="Product"
                                variant="outlined"
                                helperText={false}
                                reference="general/v3/search"
                                enableGetChoices={({ q }) =>
                                    !!q && q.trim().length > 0
                                }
                                onSelect={({ p_id, pv }) => {
                                    setSelectedProductVariants((prevData) => ({
                                        ...prevData,
                                        [p_id]: pv,
                                    }));
                                    individualProductVariantsLength !== 1 &&
                                        setTimeout(() => {
                                            inputRef.current?.focus();
                                        }, 1);
                                }}
                                sort={{ field: "p_name", order: "ASC" }}
                            >
                                <AutocompleteInput
                                    optionValue="p_id"
                                    optionText={getProductTextRenderer}
                                    options={{
                                        InputProps: { multiline: true },
                                    }}
                                    matchSuggestion={() => true}
                                    resettable
                                />
                            </ReferenceInput>
                            {scopedFormData?.p_id &&
                                !!individualProductVariantsLength && (
                                    <AutocompleteInput
                                        source={getSource("pv_id")}
                                        record={scopedFormData}
                                        label="Variant"
                                        variant="outlined"
                                        helperText={false}
                                        options={{
                                            InputProps: {
                                                inputRef: inputRef,
                                                multiline: true,
                                            },
                                        }}
                                        style={{ width: 256 }}
                                        choices={
                                            selectedProductVariants?.[
                                                scopedFormData?.p_id
                                            ]
                                        }
                                        optionText={(item) =>
                                            getReadableSKU(item?.pv_attribute)
                                        }
                                        optionValue="pv_id"
                                        initialValue={
                                            individualProductVariantsLength ===
                                                1 &&
                                            selectedProductVariants?.[
                                                scopedFormData?.p_id
                                            ][0]?.pv_id
                                        }
                                        validate={[required()]}
                                        disabled={
                                            individualProductVariantsLength ===
                                            1
                                        }
                                    />
                                )}
                        </Box>
                    );
                }}
            </InlineArrayInput>
            <InlineArrayInput source="bp_youtube" label="Videos">
                <TextInput
                    source="title"
                    label="Title"
                    variant="outlined"
                    helperText={false}
                    multiline
                />
                <TextInput
                    source="key"
                    label="Key"
                    variant="outlined"
                    helperText={false}
                    multiline
                />
            </InlineArrayInput>
            <AroggaMovableImageInput
                source="attachedFiles_bp_images"
                label="Attached Images (1800*945 px)"
            />
            <Labeled label="Description *" fullWidth>
                <Editor
                    tinymceScriptSrc={
                        process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
                    }
                    init={TINY_MCE_EDITOR_INIT}
                    value={description}
                    onEditorChange={(newValue) => setDescription(newValue)}
                />
            </Labeled>
        </>
    );
};

export default BlogForm;
