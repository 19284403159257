import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { TextInput, useNotify } from "react-admin";
import { useFormState } from "react-final-form";

import AroggaDialogActions from "../../../components/AroggaDialogActions";
import { useRequest } from "../../../hooks";

type ElasticSearchTabDialogProps = {
    keyName: string;
    open: boolean;
    handleClose: () => void;
};

const ElasticSearchTabDialog: FC<ElasticSearchTabDialogProps> = ({
    keyName,
    open,
    handleClose,
}) => {
    const notify = useNotify();
    const { values } = useFormState();
    const [currentBulkIndexPage, setCurrentBulkIndexPage] = useState(1);

    const { elastic_text } = values;

    let endpoint;

    if (keyName === "bulkIndex") {
        endpoint = `/v1/adminActions/search/medicine/bulkIndex/${elastic_text}/${currentBulkIndexPage}`;
    } else if (keyName === "setIndex") {
        endpoint = `/v1/adminActions/search/medicine/setIndex/${elastic_text}`;
    } else {
        endpoint = `/v1/adminActions/search/medicine/indices/${keyName}/${elastic_text}`;
    }

    const { data, isLoading, refetch } = useRequest(
        endpoint,
        {
            method: keyName === "delete" ? "DELETE" : "POST",
        },
        {
            isKeepPreviousData: true,
            onSuccess: () => keyName !== "bulkIndex" && handleClose(),
        }
    );

    useEffect(() => {
        if (keyName !== "bulkIndex") return;

        if (data?.hasMore) {
            setCurrentBulkIndexPage((prevState) => prevState + 1);
            refetch();
        } else {
            handleClose();
            notify(`Total ${currentBulkIndexPage - 1} page indexed!`, {
                type: "success",
            });
            setCurrentBulkIndexPage(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <Dialog
            open={open}
            onClose={() => {
                handleClose();
                values.elastic_text = "arogga";
            }}
        >
            <DialogTitle>
                {keyName === "create" && "Create Index"}
                {keyName === "delete" && "Delete Index"}
                {keyName === "bulkIndex" && "Bulk Index"}
                {keyName === "setIndex" && "Set Index"}
            </DialogTitle>
            <DialogContent>
                <TextInput
                    source="elastic_text"
                    label=""
                    variant="outlined"
                    helperText={false}
                    defaultValue="arogga"
                    fullWidth
                />
                {keyName === "bulkIndex" &&
                    `Current Page: ${currentBulkIndexPage}`}
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading || currentBulkIndexPage !== 1}
                disabled={!values.elastic_text}
                onDialogClose={() => {
                    handleClose();
                    values.elastic_text = "arogga";
                }}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default ElasticSearchTabDialog;
