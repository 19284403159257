import AcUnitIcon from "@material-ui/icons/AcUnit";
import { useState } from "react";
import {
    BooleanField,
    Datagrid,
    FunctionField,
    ImageField,
    Link,
    NumberField,
    Pagination,
    Record,
    ReferenceField,
    ReferenceManyField,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../../components/AroggaDateField";
import LoaderOrButton from "../../../../components/LoaderOrButton";
import { capitalizeFirstLetterOfEachWord } from "../../../../utils/helpers";
import { useAroggaStyles } from "../../../../utils/useAroggaStyles";
import IssueExpand from "../../issues/IssueExpand";

const Issue = () => {
    const classes = useAroggaStyles();

    const [isLoadIssue, setIsLoadIssue] = useState(false);

    return (
        <>
            {!isLoadIssue && (
                <LoaderOrButton
                    label="Load Issue"
                    isLoading={false}
                    display="flex"
                    justifyContent="center"
                    mt={3}
                    mb={4}
                    onClick={() => setIsLoadIssue(true)}
                />
            )}
            {isLoadIssue && (
                <ReferenceManyField
                    reference="v1/issue"
                    target="_order_id"
                    pagination={<Pagination />}
                    filter={{ _details: 1 }}
                >
                    <Datagrid
                        expand={<IssueExpand />}
                        isRowExpandable={(row) => !!row?.ic?.length}
                        classes={{ expandedPanel: classes.expandedPanel }}
                    >
                        <TextField source="i_id" label="ID" />
                        <FunctionField
                            label="Shipment ID"
                            onClick={(e) => e.stopPropagation()}
                            sortBy="i_shipment_id"
                            render={({
                                i_shipment_id,
                                i_order_id,
                                s_sequence,
                            }: Record) => (
                                <Link to={`/v1/shipment/${i_shipment_id}/show`}>
                                    {i_order_id}
                                    {s_sequence}
                                </Link>
                            )}
                        />
                        <TextField
                            source="i_type"
                            label="Type"
                            className={classes.capitalize}
                        />
                        <FunctionField
                            label="Status"
                            onClick={(e) => e.stopPropagation()}
                            sortBy="i_status"
                            className={classes.whitespaceNowrap}
                            render={({ i_status }: Record) =>
                                capitalizeFirstLetterOfEachWord(i_status)
                            }
                        />
                        <ReferenceField
                            source="i_shipment_id"
                            label="Cold"
                            reference="v1/shipment"
                            link={false}
                        >
                            <FunctionField
                                render={(record: Record) => {
                                    if (!record?.s_m_cold) return;
                                    return <AcUnitIcon />;
                                }}
                            />
                        </ReferenceField>
                        <NumberField
                            source="i_total_in_amount"
                            label="Total In Amount"
                        />
                        <NumberField
                            source="i_total_out_amount"
                            label="Total Out Amount"
                        />
                        <TextField
                            source="i_money_or_product"
                            label="Return Money / Product"
                            className={classes.capitalize}
                        />
                        <BooleanField
                            source="i_is_calculate_additions"
                            label="Calculate Additions?"
                            FalseIcon={() => null}
                            looseValue
                        />
                        <TextField source="i_details" label="Details" />
                        <ImageField
                            source="attachedFiles_i_images"
                            label="Images"
                            src="src"
                            title="title"
                            className="small__img"
                        />
                        <AroggaDateField
                            source="i_created_at"
                            label="Created At"
                        />
                        <ReferenceField
                            source="i_created_by"
                            label="Created By"
                            reference="v1/users"
                            link="show"
                        >
                            <TextField source="u_name" />
                        </ReferenceField>
                    </Datagrid>
                </ReferenceManyField>
            )}
        </>
    );
};

export default Issue;
