import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    ReferenceInput,
    SelectInput,
    TextInput,
} from "react-admin";

const IssueFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            resettable
            alwaysOn
        />
        <SelectInput
            source="_type"
            label="Type"
            variant="outlined"
            choices={[
                { id: "missing", name: "Missing" },
                { id: "replacement", name: "Replacement" },
                { id: "return", name: "Return" },
                { id: "damage_refund", name: "Damage Refund" },
            ]}
            alwaysOn
        />
        <SelectInput
            source="_status"
            label="Status"
            variant="outlined"
            choices={[
                { id: "processing", name: "Processing" },
                { id: "qc", name: "QC" },
                { id: "approved", name: "Approved" },
                { id: "rejected", name: "Rejected" },
                { id: "shipment_generated", name: "Shipment Generated" },
                { id: "settled", name: "Settled" },
            ]}
            alwaysOn
        />
        <ReferenceInput
            source="_order_id"
            label="Order"
            variant="outlined"
            reference="v1/productOrder"
        >
            <AutocompleteInput
                optionText="po_id"
                matchSuggestion={() => true}
                resettable
            />
        </ReferenceInput>
        <ReferenceInput
            source="_shipment_id"
            label="Shipment"
            variant="outlined"
            reference="v1/shipment"
        >
            <AutocompleteInput
                optionText={(item) =>
                    `${item?.s_order_id || ""}${item?.s_sequence || ""}`
                }
                matchSuggestion={() => true}
                resettable
            />
        </ReferenceInput>
    </Filter>
);

export default IssueFilter;
