import { Chip, Typography } from "@material-ui/core";
import { FC } from "react";
import {
    FunctionField,
    Link,
    Record,
    ReferenceField,
    RichTextField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
    useShowController,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import AroggaImageField from "../../../components/AroggaImageField";
import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";
import { isEmpty } from "../../../utils/helpers";

const CampaignShow: FC<ShowProps> = ({ hasShow, ...rest }) => {
    useDocumentTitle("Arogga | Campaign Show");

    const { record } = useShowController(rest);

    return (
        <Show {...rest}>
            <SimpleShowLayout>
                {record?.isRevision && (
                    <Typography variant="h5" style={{ marginBottom: 10 }}>
                        Revision Content
                    </Typography>
                )}
                <ColumnShowLayout md={2} simpleShowLayout={false}>
                    <TextField source="p_id" label="ID" />
                    <AroggaDateField source="p_created_at" label="Created At" />
                    <ReferenceField
                        source="p_created_by"
                        label="Created By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="p_modified_at"
                        label="Modified At"
                    />
                    <ReferenceField
                        source="p_modified_by"
                        label="Modified By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="p_supervised_at"
                        label="Supervised At"
                    />
                    <ReferenceField
                        source="p_supervised_by"
                        label="Supervised By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField source="p_deleted_at" label="Deleted At" />
                    <ReferenceField
                        source="p_deleted_by"
                        label="Deleted By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                </ColumnShowLayout>
                <FunctionField
                    label="Tags"
                    render={({ p_product_tags }: Record) => {
                        if (isEmpty(p_product_tags)) return;

                        return p_product_tags.map((tag, i) => (
                            <Chip
                                key={i}
                                label={tag}
                                variant="outlined"
                                color="primary"
                                size="small"
                                style={{
                                    marginRight: 6,
                                }}
                            />
                        ));
                    }}
                />
                <FunctionField
                    label="Internal Tags"
                    render={({ p_product_internal_tags }: Record) => {
                        if (isEmpty(p_product_internal_tags)) return;

                        return p_product_internal_tags.map((tag, i) => (
                            <Chip
                                key={i}
                                label={tag}
                                variant="outlined"
                                color="primary"
                                size="small"
                                style={{
                                    marginRight: 6,
                                }}
                            />
                        ));
                    }}
                />
                <FunctionField
                    label="Keywords"
                    render={({ p_product_keywords }: Record) => {
                        if (isEmpty(p_product_keywords)) return;

                        return p_product_keywords.map((keyword, i) => (
                            <Chip
                                key={i}
                                label={keyword}
                                variant="outlined"
                                color="primary"
                                size="small"
                                style={{
                                    marginRight: 6,
                                }}
                            />
                        ));
                    }}
                />
                <TextField
                    source="p_meta_description"
                    label="Meta Description"
                />
                <TextField source="p_yt_title" label="Youtube Title" />
                <FunctionField
                    label="Youtube Video"
                    render={({ p_yt_key }: Record) => {
                        if (!p_yt_key) return null;

                        return (
                            <iframe
                                title={p_yt_key}
                                src={`https://www.youtube.com/embed/${p_yt_key}`}
                                width={640}
                                height={360}
                                frameBorder="0"
                                allowFullScreen
                            />
                        );
                    }}
                />
                <AroggaImageField
                    source="attachedFiles_p_images"
                    label="Attached Images"
                />
                <FunctionField
                    label="Description"
                    render={({
                        p_type,
                        p_generic_id,
                        p_description,
                    }: Record) => {
                        if (p_description)
                            return (
                                <RichTextField
                                    source="p_description"
                                    label="Description"
                                />
                            );

                        if (p_type === "medicine" && p_generic_id)
                            return (
                                <Link
                                    to={`/v1/generics/${p_generic_id}/show`}
                                    target="_blank"
                                >
                                    To view description click here
                                </Link>
                            );

                        return null;
                    }}
                />
            </SimpleShowLayout>
        </Show>
    );
};

export default CampaignShow;
