import { Box, Button } from "@material-ui/core";
import { FC, useState } from "react";
import {
    Confirm,
    Datagrid,
    FunctionField,
    List,
    ListProps,
    Record,
    ReferenceField,
    TextField,
    SimpleForm,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import AssignUser from "../../../components/manageAccounting/AssignUser";
import { useDocumentTitle, useExport, useRequest } from "../../../hooks";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import { getColorByStatus } from "../../../utils/helpers";

const OpExList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | OpEx List");
    const classes = useAroggaStyles();
    const exporter = useExport(rest);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
    const [opExId, setOpExId] = useState(null);

    const { isLoading, refetch } = useRequest(
        `/v1/OpExAction/${opExId}/hrOpExReceivedAction`,
        {
            method: "POST",
        },
        {
            isRefresh: true,
            onSuccess: () => setIsDialogOpen(false),
        }
    );

    return (
        <>
            <List
                {...rest}
                title="List of OpEx"
                perPage={25}
                exporter={exporter}
                bulkActionButtons={false}
            >
                <Datagrid style={{ height: 50 }}>
                    <TextField source="id" label="Id" />
                    <ReferenceField
                        source="oe_item_id"
                        label="Name"
                        reference="v1/quotationItemMapping"
                        sortBy="oe_item_id"
                        link="show"
                    >
                        <TextField source="qim_name" />
                    </ReferenceField>
                    <TextField source="oe_total_quantity" label="Total Count" />
                    <ReferenceField
                        source="oe_user_id"
                        label="User"
                        reference="v1/users"
                        sortBy="oe_user_id"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <FunctionField
                        label="Status"
                        sortBy="oe_status"
                        render={(record) => {
                            const color = getColorByStatus(record.oe_status);
                            return (
                                <span
                                    className={classes.statusBtn}
                                    style={{
                                        color: color,
                                        backgroundColor: color + "10",
                                    }}
                                >
                                    {record.oe_status}
                                </span>
                            );
                        }}
                    />
                    <AroggaDateField
                        source="oe_created_at"
                        label="Created At"
                    />
                    <ReferenceField
                        source="oe_created_by"
                        label="Created By"
                        reference="v1/users"
                        sortBy="oe_created_by"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <FunctionField
                        label="Action"
                        onClick={(e) => e.stopPropagation()}
                        render={({ id, oe_status, oe_user_id }: Record) => {
                            return (
                                <Box display="flex" gridGap={5}>
                                    {permissions?.includes("opExHrReceived") &&
                                        oe_status === "pending" && (
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => {
                                                    setOpExId(id);
                                                    setIsDialogOpen(true);
                                                }}
                                            >
                                                Received
                                            </Button>
                                        )}
                                    {oe_user_id === 0 && (
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            onClick={() => {
                                                setOpExId(id);
                                                setIsAssignDialogOpen(true);
                                            }}
                                        >
                                            Assign
                                        </Button>
                                    )}
                                </Box>
                            );
                        }}
                    />
                </Datagrid>
            </List>
            <Confirm
                isOpen={isDialogOpen}
                loading={isLoading}
                title={`Are you sure you want to Received #${opExId}?`}
                content={null}
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
            {isAssignDialogOpen === true && (
                <SimpleForm toolbar={null}>
                    <AssignUser
                        url={`/v1/OpExAction/${opExId}/employeeOpExAssignAction`}
                        isDialogOpen={isAssignDialogOpen}
                        setIsDialogOpen={setIsAssignDialogOpen}
                    />
                </SimpleForm>
            )}
        </>
    );
};

export default OpExList;
