import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import {
    Confirm,
    FileField,
    FunctionField,
    Link,
    Record,
    ReferenceField,
    TextField,
    useRecordContext,
} from "react-admin";

import { useGetCurrentUser, useRequest } from "../../../hooks";
import {
    capitalizeFirstLetterOfEachWord,
    getColorByStatus,
} from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import AroggaDateField from "../../AroggaDateField";
import ColumnShowLayout from "../../ColumnShowLayout";
import PurchaseRequisitionEdit from "./PurchaseRequisitionEdit";
import DescriptionWithModal from "../../DescriptionWithModal";

const Label = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography variant="body1" className={classes.label}>
            {children}
        </Typography>
    );
};
const QuotationList = () => {
    const record = useRecordContext();
    const classes = useAroggaStyles();
    const classes2 = useStyles();
    const currentUser = useGetCurrentUser();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isRequisitionDialogOpen, setIsRequisitionDialogOpen] =
        useState(false);
    const [purchaseRequisitionId, setPurchaseRequisitionId] = useState(null);
    const [action, setAction] = useState("");
    const { isLoading, refetch } = useRequest(
        `/v1/purchaseRequisitionAction/${purchaseRequisitionId}/statusAction`,
        {
            method: "POST",
            body: {
                pr_status: action,
            },
        },
        {
            isRefresh: true,
            onSuccess: () => setIsDialogOpen(false),
        }
    );
    const { data } = useRequest(
        `/v1/employee?_u_id=${record?.pr_created_by}`,
        {},
        {
            isPreFetching: true,
            isWarningNotify: false,
        }
    );
    const employeeId = data?.map((employee) => employee?.e_id);

    const confirmTitleAction =
        action === "department_approved" ? "approve" : "reject";
    return (
        <>
            {record.pr_status === "pending" && (
                <Box className={classes2.div}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            setIsRequisitionDialogOpen(true);
                        }}
                    >
                        Edit
                    </Button>
                </Box>
            )}

            <ColumnShowLayout md={3} simpleShowLayout={false}>
                <TextField source="id" label={<Label>ID</Label>} />
                <TextField source="pr_name" label={<Label>Item Name</Label>} />
                <TextField
                    source="pr_quantity"
                    label={<Label>Quantity</Label>}
                />
                <TextField source="pr_unit" label={<Label>Unit</Label>} />
                <FunctionField
                    label={<Label>Department</Label>}
                    render={({ pr_department }: Record) =>
                        capitalizeFirstLetterOfEachWord(pr_department)
                    }
                />
                <AroggaDateField
                    source="pr_created_at"
                    //@ts-ignore
                    label={<Label>Created At</Label>}
                />
                <FunctionField
                    label={<Label>Created By</Label>}
                    onClick={(e) => e.stopPropagation()}
                    render={({}: Record) => (
                        <>
                            <ReferenceField
                                source="pr_created_by"
                                label={<Label>Created By</Label>}
                                reference="v1/users"
                                sortBy="pr_created_by"
                                link="show"
                            >
                                <TextField
                                    source="u_name"
                                    className={classes2.namePadding}
                                />
                            </ReferenceField>
                            (
                            <Link to={`/v1/employee/${employeeId}`}>
                                {employeeId}
                            </Link>
                            )
                        </>
                    )}
                />

                <AroggaDateField
                    source="pr_modified_at"
                    //@ts-ignore
                    label={<Label>Modifed At</Label>}
                />
                <ReferenceField
                    source="pr_modified_by"
                    label={<Label>Modifed By</Label>}
                    reference="v1/users"
                    sortBy="pr_modified_by"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
                <FunctionField
                    label={<Label>Description</Label>}
                    onClick={(e) => e.stopPropagation()}
                    render={({ pr_description }: Record) => (
                        <DescriptionWithModal description={pr_description} />
                    )}
                />
                <FileField
                    source="attachedFiles_pr_attachment"
                    src="src"
                    title="title"
                    target="_blank"
                    label={<Label>Attachment Files</Label>}
                    // @ts-ignore
                    onClick={(e) => e.stopPropagation()}
                    sortable={false}
                />

                {record?.pr_status === "pending" && (
                    <FunctionField
                        label={<Label>Action</Label>}
                        onClick={(e) => e.stopPropagation()}
                        render={({ id, pr_status, pr_created_by }: Record) => {
                            if (
                                pr_status === "pending" &&
                                pr_created_by !== currentUser?.u_id
                            ) {
                                return (
                                    <Box display="flex" gridGap={5}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                setPurchaseRequisitionId(id);
                                                setAction(
                                                    "department_approved"
                                                );
                                                setIsDialogOpen(true);
                                            }}
                                        >
                                            Approve
                                        </Button>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                setPurchaseRequisitionId(id);
                                                setAction("rejected");
                                                setIsDialogOpen(true);
                                            }}
                                            className={classes.bgRed}
                                        >
                                            Reject
                                        </Button>
                                    </Box>
                                );
                            } else {
                                return;
                            }
                        }}
                    />
                )}
                <FunctionField
                    label={<Label>Status</Label>}
                    onClick={(e) => e.stopPropagation()}
                    render={({ pr_status }: Record) => {
                        const text = capitalizeFirstLetterOfEachWord(pr_status);
                        const color = getColorByStatus(record.pr_status);
                        return (
                            <Typography
                                variant="body2"
                                className={classes2.statusButton}
                                style={{ color: color }}
                            >
                                {text}
                            </Typography>
                        );
                    }}
                />
            </ColumnShowLayout>
            {isRequisitionDialogOpen === true && (
                <PurchaseRequisitionEdit
                    record={record}
                    isRequisitionDialogOpen={isRequisitionDialogOpen}
                    setIsRequisitionDialogOpen={setIsRequisitionDialogOpen}
                />
            )}
            <Confirm
                isOpen={isDialogOpen}
                loading={isLoading}
                title={`Are you sure you want to ${confirmTitleAction} this purchase Requisition #${purchaseRequisitionId}?`}
                content={false}
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
        </>
    );
};

export default QuotationList;

const useStyles = makeStyles({
    div: {
        display: "flex",
        justifyContent: "flex-end",
    },
    statusButton: {
        width: "100%",
        borderRadius: 42,
        textTransform: "uppercase",
        fontWeight: "bolder",
    },
    label: {
        fontWeight: "bolder",
        color: "black",
        fontSize: 16,
    },
    namePadding: {
        marginRight: 5,
    },
});
