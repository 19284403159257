import { Button } from "@material-ui/core";
import { FC } from "react";
import {
    Datagrid,
    FunctionField,
    List,
    ListProps,
    NumberField,
    Record,
    ReferenceField,
    TextField,
    useRedirect,
} from "react-admin";

import {
    useDocumentTitle,
    useExport,
    useNavigateFromList,
} from "../../../hooks";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import ExpandableRow from "./ExpandableRow";
import AccountingHeadFilter from "./HeadFilter";

const HeadList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Head List");

    const redirect = useRedirect();
    const exporter = useExport(rest);
    const classes = useAroggaStyles();
    const navigateFromList = useNavigateFromList(
        "accountingHeadView",
        "accountingHeadEdit"
    );

    const handleViewLedger = (headId) => {
        redirect("/ledger", undefined, undefined, undefined, {
            headId,
        });
    };

    return (
        <List
            {...rest}
            title="List of Head"
            perPage={25}
            filters={<AccountingHeadFilter children={""} />}
            filterDefaultValues={{ _childOf: 0 }}
            sort={{ field: "ah_id", order: "DESC" }}
            bulkActionButtons={permissions?.includes("accountingHeadDelete")}
            exporter={exporter}
        >
            <Datagrid
                expand={<ExpandableRow />}
                isRowExpandable={(row) => row?.ah_has_child === 1}
                hasBulkActions={false}
                rowClick={navigateFromList}
                classes={{ expandedPanel: classes.expandedPanel }}
            >
                <TextField source="ah_acc_code" label="Accounting Code" />
                <TextField source="ah_name" label="Account Name" />
                <FunctionField
                    label="Ledger Type"
                    render={({ ah_ledger_type }: Record) =>
                        capitalizeFirstLetterOfEachWord(ah_ledger_type)
                    }
                />
                <ReferenceField
                    source="ah_parent_id"
                    label="Parent Name"
                    reference="v1/accountingHead"
                    sortBy="ah_parent_id"
                >
                    <TextField source="ah_name" />
                </ReferenceField>
                <TextField source="ah_has_child" label="Has Child" />
                <TextField
                    source="ah_type"
                    label="Type"
                    className={classes.capitalize}
                />
                <TextField source="ah_id" label="ID" />
                <ReferenceField
                    source="ah_group_id"
                    label="Group"
                    reference="v1/accountingHeadGroup"
                    sortBy="ah_group_id"
                    link={false}
                >
                    <TextField source="ahg_name" />
                </ReferenceField>
                <NumberField
                    source="ah_opening_balance_type"
                    label="Balance Type"
                    className={classes.capitalize}
                />
                <NumberField
                    source="ah_opening_balance"
                    label="Opening Balance"
                />
                <NumberField
                    source="ah_current_balance"
                    label="Curr. Head Balance"
                />
                {/* <NumberField
                    source="ah_accumulated_balance"
                    label="Acc. Balance"
                /> */}
                <FunctionField
                    label="Ledger"
                    onClick={(e) => e.stopPropagation()}
                    render={({ ah_id }: Record) => (
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={() => handleViewLedger(ah_id)}
                        >
                            View
                        </Button>
                    )}
                />
            </Datagrid>
        </List>
    );
};

export default HeadList;
