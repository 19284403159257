import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MovableImageInput } from "@maugou/react-admin-movable-image-input";
import { FC, useState } from "react";
import { ImageField, useNotify } from "react-admin";
import { useForm } from "react-final-form";

import { IMAGE_FILE_MAX_SIZE } from "../utils/constants";
import { getImageDetails } from "../utils/helpers";

type AroggaMovableImageInputProps = {
    source: string;
    minimumDimension?: number;
    dimentionValidation?: boolean;
    [key: string]: any;
};

const AroggaMovableImageInput: FC<AroggaMovableImageInputProps> = ({
    source,
    minimumDimension = 700,
    dimentionValidation = false,
    ...rest
}) => {
    const form = useForm();
    const classes = useStyles();
    const notify = useNotify();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const onDropHandler = async (newFiles) => {
        const imageDetails = await getImageDetails(newFiles);

        let isWarningToasterShow = false;
        const haveToRemoveImageNames = [];

        imageDetails?.forEach((image) => {
            if (
                image.width < minimumDimension ||
                image.height < minimumDimension ||
                image.width !== image.height
            ) {
                haveToRemoveImageNames.push(image.title);
                if (!isWarningToasterShow) {
                    isWarningToasterShow = true;
                }
            }
        });

        const filteredAttachedFiles = form
            .getState()
            ?.values?.[source]?.filter(
                (item) =>
                    item.s3key || !haveToRemoveImageNames.includes(item.title)
            );

        form.change(source, filteredAttachedFiles);

        if (isWarningToasterShow) {
            notify(
                `Please upload an image with a minimum dimensions of ${minimumDimension}x${minimumDimension} pixels! Only square-shaped images allowed such as an aspect ratio of 1:1`,
                { type: "warning" }
            );
        }
    };

    return (
        <>
            <MovableImageInput
                source={source}
                label={`Attached Images (Minimum dimensions: ${minimumDimension}x${minimumDimension}px, Ratio: 1:1)`}
                placeholder="Drag and drop some files here, or click to select files"
                accept=".jpeg,.jpg,.png,.webp"
                maxSize={IMAGE_FILE_MAX_SIZE}
                classes={classes}
                helperText={false}
                options={{
                    ...(dimentionValidation && { onDrop: onDropHandler }),
                }}
                multiple
                {...rest}
            >
                <ImageField
                    source="src"
                    title="title"
                    // @ts-ignore
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedImage({
                            src: e.target?.currentSrc,
                            alt: e.target?.alt,
                        });
                        e.target?.currentSrc && setIsDialogOpen(true);
                    }}
                />
            </MovableImageInput>
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <img src={selectedImage?.src} alt={selectedImage?.alt} />
            </Dialog>
        </>
    );
};

export default AroggaMovableImageInput;

const useStyles = makeStyles({
    root: {
        backgroundColor: "#f0f0f0",
        borderRadius: 10,
        padding: 10,
    },
    dropZone: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 150,
        border: "2px dashed #008069",
        borderRadius: 10,
    },
    preview: {
        marginTop: 12,
    },
});
