import { FC } from "react";
import { AutocompleteArrayInput, AutocompleteInput } from "react-admin";
import { useForm } from "react-final-form";

type ZoneInputProps = {
    inputType?: "autocompleteInput" | "autocompleteArrayInput";
    [key: string]: any;
};

const ZoneInput: FC<ZoneInputProps> = ({ inputType, ...rest }) => {
    const form = useForm();

    if (inputType === "autocompleteArrayInput") {
        return (
            <AutocompleteArrayInput
                source="sb_zone_id"
                label="Zone"
                helperText={false}
                onChange={() => form.change("shipmentId", undefined)}
                resettable
                {...rest}
            />
        );
    }

    return (
        <AutocompleteInput
            source="sb_zone_id"
            label="Zone"
            helperText={false}
            onChange={() => form.change("shipmentId", undefined)}
            resettable
            {...rest}
        />
    );
};

export default ZoneInput;
