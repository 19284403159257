import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
    AutocompleteArrayInput,
    minValue,
    NumberInput,
    ReferenceArrayInput,
    required,
    SelectInput,
    TextInput,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/helpers";
import TaxonomiesByVocabularyInput from "../../TaxonomiesByVocabularyInput";
import UserEmployeeOptionTextRenderer from "../../UserEmployeeOptionTextRenderer";

const ApprovalCapForm = () => {
    const { values } = useFormState();
    const form = useForm();
    const [acApproverEntity, setAcApproverEntity] = useState(
        values?.ac_approver_entity
    );
    const ids = values?.ac_approver_entity_ids?.join(",");

    const { data: users } = useRequest(
        `/admin/v1/users?ids=${ids}`,
        {},
        {
            isBaseUrl: true,
            isSuccessNotify: false,
            isWarningNotify: false,
            refreshDeps: [ids],
        }
    );

    const [vendorOptions, setVendorOptions] = useState(users || []);

    useEffect(() => {
        setAcApproverEntity(values?.ac_approver_entity);
        if (values?.ac_approver_entity !== acApproverEntity) {
            form.change("ac_approver_entity_ids", []);
            setVendorOptions([]);
        }
        if (values?.ac_id) {
            setVendorOptions(users);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.ac_approver_entity, acApproverEntity, form, users]);

    useEffect(() => {
        const currentIds = values?.ac_approver_entity_ids || [];
        setVendorOptions((prevVendorOptions) =>
            prevVendorOptions?.filter((option) =>
                currentIds.includes(option.u_id)
            )
        );
    }, [values?.ac_approver_entity_ids]);

    const handleSelect = (item) => {
        setVendorOptions((prevVendorOptions) => {
            const exists = prevVendorOptions.some(
                (option) => option.u_id === item.u_id
            );
            return exists ? prevVendorOptions : [...prevVendorOptions, item];
        });
    };

    return (
        <Grid container spacing={1}>
            {!!values.ac_id && (
                <Grid item sm={6} md={6}>
                    <TextInput
                        source="ac_id"
                        label="ID"
                        variant="outlined"
                        helperText={false}
                        fullWidth
                        disabled
                    />
                </Grid>
            )}
            <Grid item sm={6} md={6}>
                <TaxonomiesByVocabularyInput
                    fetchKey="procurement_title"
                    source="ac_procurement_title"
                    label="Procurement Title"
                    helperText={false}
                    validate={[required()]}
                    fullWidth
                />
            </Grid>
            <Grid item sm={6} md={4}>
                <NumberInput
                    source="ac_max_threshold_amount"
                    label="Ac Max Threshold Amount"
                    variant="outlined"
                    helperText={false}
                    validate={[minValue(0, "Maximum amount can't be negative")]}
                    min={0}
                    fullWidth
                />
            </Grid>
            <Grid item sm={6} md={4}>
                <SelectInput
                    variant="outlined"
                    source="ac_approver_entity"
                    choices={[
                        { id: "User", name: "User" },
                        { id: "Rank", name: "Rank" },
                    ]}
                    fullWidth
                />
            </Grid>

            <Grid item sm={6}>
                {acApproverEntity === "User" ? (
                    <ReferenceArrayInput
                        source="ac_approver_entity_ids"
                        label="User"
                        variant="outlined"
                        reference="v1/users"
                        helperText={false}
                        allowEmpty
                        onSelect={handleSelect}
                    >
                        <AutocompleteArrayInput
                            optionText={<UserEmployeeOptionTextRenderer />}
                            matchSuggestion={() => true}
                        />
                    </ReferenceArrayInput>
                ) : acApproverEntity === "Rank" ? (
                    <ReferenceArrayInput
                        source="ac_approver_entity_ids"
                        label="Rank"
                        variant="outlined"
                        reference="v1/rank"
                        helperText={false}
                        allowEmpty
                    >
                        <AutocompleteArrayInput
                            optionValue="r_id"
                            optionText="r_title"
                            matchSuggestion={() => true}
                            resettable
                            disabled={
                                values.ac_approver_entity_ids.length === 1
                            }
                        />
                    </ReferenceArrayInput>
                ) : null}
            </Grid>
            {acApproverEntity === "User" && (
                <Grid item sm={6} md={4}>
                    <NumberInput
                        source="ac_minimum_count"
                        label="Minimum User Require"
                        variant="outlined"
                        fullWidth
                        validate={[minValue(0, "Count can't be negative")]}
                        min={0}
                    />
                </Grid>
            )}
            {values?.ac_minimum_count > 0 && acApproverEntity === "User" && (
                <Grid item sm={4}>
                    <AutocompleteArrayInput
                        source="ac_required_user_ids"
                        label="Require Users"
                        variant="outlined"
                        helperText={false}
                        choices={vendorOptions?.map(
                            ({ u_id, u_name, u_mobile }) => ({
                                id: u_id,
                                name: `${capitalizeFirstLetterOfEachWord(
                                    u_name
                                )} (${u_mobile})`,
                            })
                        )}
                        allowEmpty
                        disabled={
                            values?.ac_minimum_count ===
                            values?.ac_required_user_ids?.length
                        }
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default ApprovalCapForm;
