import { FC } from "react";
import { useForm, useFormState } from "react-final-form";

type FormProps = {
    children: (props: { form: any; formData: any }) => JSX.Element;
};

const Form: FC<FormProps> = ({ children }) => {
    const form = useForm();
    const { values: formData } = useFormState();

    return children({ form, formData });
};

export default Form;
