import { useState } from "react";
import {
    Datagrid,
    FunctionField,
    Link,
    Pagination,
    Record,
    ReferenceField,
    ReferenceManyField,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../../components/AroggaDateField";
import LoaderOrButton from "../../../../components/LoaderOrButton";

const History = () => {
    const [isLoadHistory, setIsLoadHistory] = useState(false);

    return (
        <>
            {!isLoadHistory && (
                <LoaderOrButton
                    label="Load History"
                    isLoading={false}
                    display="flex"
                    justifyContent="center"
                    mt={3}
                    mb={4}
                    onClick={() => setIsLoadHistory(true)}
                />
            )}
            {isLoadHistory && (
                <ReferenceManyField
                    reference="v1/orderActivityLog"
                    target="_order_id"
                    pagination={<Pagination />}
                    sort={{ field: "oal_created_at", order: "DESC" }}
                >
                    <Datagrid>
                        <ReferenceField
                            source="oal_created_by"
                            label="Name"
                            reference="v1/users"
                            link="show"
                        >
                            <TextField source="u_name" />
                        </ReferenceField>
                        <AroggaDateField source="oal_created_at" label="Date" />
                        <FunctionField
                            label="Shipment ID"
                            sortBy="oal_shipment_id"
                            render={({ oal_shipment_id }: Record) => {
                                if (!oal_shipment_id) return;

                                return (
                                    <Link
                                        to={`/v1/shipment/${oal_shipment_id}/show`}
                                    >
                                        {oal_shipment_id}
                                    </Link>
                                );
                            }}
                        />
                        <FunctionField
                            label="Issue ID"
                            sortBy="oal_issue_id"
                            render={({ oal_issue_id }: Record) => {
                                if (!oal_issue_id) return;

                                return (
                                    <Link to={`/v1/issue/${oal_issue_id}/show`}>
                                        {oal_issue_id}
                                    </Link>
                                );
                            }}
                        />
                        <TextField source="oal_title" label="Title" />
                        <TextField
                            source="oal_description"
                            label="Description"
                        />
                    </Datagrid>
                </ReferenceManyField>
            )}
        </>
    );
};

export default History;
