import { NotificationType } from "react-admin";

import { logger } from "../../../../utils/helpers";

type OrderOnSaveProps = {
    notify: (
        message: string,
        type?:
            | NotificationType
            | (NotificationOptions & { type: NotificationType })
    ) => void;
    redirect: (action: string, url: string) => void;
    mutate: (data: any, options?: any) => void | Promise<any>;
    values: any;
};

export const orderOnSave = async ({
    notify,
    redirect,
    mutate,
    values,
}: OrderOnSaveProps) => {
    if (
        values?.poi?.length &&
        values?.poi?.some?.((item) => !item?.poi_product_qty)
    ) {
        return notify("All quantity must be required!", {
            type: "warning",
        });
    }

    const formattedProductOrderItems = values?.poi?.map(
        ({
            poi_id,
            poi_product_id,
            poi_product_variant_id,
            poi_product_unit_id,
            poi_product_qty,
        }) => ({
            ...(poi_id && { poi_id }),
            poi_product_id,
            poi_product_variant_id,
            poi_product_unit_id,
            poi_product_qty,
        })
    );

    try {
        const { message } = await mutate(
            {
                type: values.id ? "update" : "create",
                resource: "v1/productOrder",
                payload: {
                    ...(values.id ? { id: values.id } : {}),
                    data: {
                        ...values,
                        poi: !!formattedProductOrderItems?.length
                            ? JSON.stringify(formattedProductOrderItems)
                            : "",
                    },
                },
            },
            { returnPromise: true }
        );

        notify(message, {
            type: "success",
        });
        redirect("list", "/v1/productOrder");
    } catch (err: any) {
        logger(err);
        notify(err?.message || "Something went wrong, Please try again!", {
            type: "error",
        });
    }
};
