import { FC, useMemo, useState } from "react";
import {
    AutocompleteInput,
    Create,
    CreateProps,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    minLength,
    minValue,
    required,
} from "react-admin";
import { useDocumentTitle } from "../../../hooks";

const validateName = [
    required(),
    minLength(2, "Name must be at least 2 characters long"),
];
const validateRequired = [required()];

const HeadCreate: FC<CreateProps> = ({ hasCreate, ...rest }) => {
    useDocumentTitle("Arogga | Head Create");

    const [formData, setFormData] = useState({
        ah_type: "",
        ah_group_id: "",
        ah_opening_balance_type: "",
    });

    const [visibility, setVisibility] = useState({
        ah_type: true,
        ah_group_id: true,
        ah_opening_balance_type: true,
    });

    //TODO:: Handle parent select reset

    const handleParentSelect = (record) => {
        if (record) {
            const newFormData = {
                ah_type: record.ah_type,
                ah_group_id: record.ah_group_id,
                ah_opening_balance_type: record.ah_opening_balance_type,
            };
            setFormData(newFormData);
            setVisibility({
                ah_type: false,
                ah_group_id: false,
                ah_opening_balance_type: false,
            });
        }
    };

    const typeChoices = useMemo(
        () => [
            { id: "asset", name: "Asset" },
            { id: "liability", name: "Liability" },
            { id: "equity", name: "Owner Equity" },
            { id: "income", name: "Income" },
            { id: "expense", name: "Expense" },
        ],
        []
    );

    const openingBalanceChoices = useMemo(
        () => [
            { id: "credit", name: "Credit" },
            { id: "debit", name: "Debit" },
        ],
        []
    );

    const ledgerType = useMemo(
        () => [
            { id: "master_ledger", name: "Master Ledger" },
            { id: "general_ledger", name: "General Ledger" },
        ],
        []
    );

    return (
        <Create {...rest}>
            <SimpleForm redirect="list" initialValues={formData}>
                <TextInput
                    source="ah_acc_code"
                    label="Accounting Code"
                    variant="outlined"
                    helperText={false}
                    validate={validateName}
                />
                <TextInput
                    source="ah_name"
                    label="Name"
                    variant="outlined"
                    helperText={false}
                    validate={validateName}
                />
                <TextInput
                    source="ah_description"
                    label="Description"
                    variant="outlined"
                    helperText={false}
                    minRows={2}
                    multiline
                />
                <ReferenceInput
                    source="ah_parent_id"
                    label="Parent"
                    reference="v1/accountingHead"
                    variant="outlined"
                    helperText={false}
                >
                    <AutocompleteInput
                        optionValue="ah_id"
                        optionText="ah_name"
                        resettable
                        onSelect={handleParentSelect}
                        onReset={() => {
                            setFormData({
                                ah_type: "",
                                ah_group_id: "",
                                ah_opening_balance_type: "",
                            });
                            setVisibility({
                                ah_type: true,
                                ah_group_id: true,
                                ah_opening_balance_type: true,
                            });
                        }}
                    />
                </ReferenceInput>
                <SelectInput
                    source="ah_ledger_type"
                    label="Ledger Type"
                    variant="outlined"
                    helperText={false}
                    choices={ledgerType}
                    validate={validateRequired}
                />
                {visibility.ah_type && (
                    <SelectInput
                        source="ah_type"
                        label="Type"
                        variant="outlined"
                        helperText={false}
                        choices={typeChoices}
                        validate={validateRequired}
                    />
                )}
                {visibility.ah_group_id && (
                    <ReferenceInput
                        source="ah_group_id"
                        label="Group"
                        reference="v1/accountingHeadGroup"
                        variant="outlined"
                        validate={validateName}
                        helperText={false}
                    >
                        <AutocompleteInput
                            optionValue="ahg_id"
                            optionText="ahg_name"
                            resettable
                        />
                    </ReferenceInput>
                )}
                {visibility.ah_opening_balance_type && (
                    <SelectInput
                        source="ah_opening_balance_type"
                        label="Opening Balance Type"
                        variant="outlined"
                        helperText={false}
                        choices={openingBalanceChoices}
                        validate={validateRequired}
                    />
                )}
                <NumberInput
                    source="ah_opening_balance"
                    label="Opening Balance"
                    variant="outlined"
                    helperText={false}
                    validate={minValue(0, "Opening balance can't be negative")}
                />
                <NumberInput
                    source="ah_weight"
                    label="Weight"
                    variant="outlined"
                    helperText={false}
                />
            </SimpleForm>
        </Create>
    );
};

export default HeadCreate;
