import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import { useFormState, useForm } from "react-final-form";

import { useRequest } from "../../../hooks";
import SupplierForm from "../supplier/SupplierForm";
import AroggaDialogActions from "../../AroggaDialogActions";

const ReqSupplierForm = ({
    isQuotationDialogOpen,
    setIsQuotationDialogOpen,
    supplierItemTrack,
}) => {
    const { values } = useFormState();
    const form = useForm();
    const { isLoading, refetch } = useRequest(
        `/v1/supplier`,
        {
            method: "POST",
            body: {
                ...values,
            },
        },
        {
            onSuccess: ({ data }) => {
                setIsQuotationDialogOpen(false);
                form.change(supplierItemTrack, data?.s_id);
                form.change("s_name", undefined);
                form.change("s_email", undefined);
                form.change("s_mobile", undefined);
                form.change("s_address", undefined);
                form.change("s_account_number", undefined);
                form.change("s_tin", undefined);
                form.change("s_bin", undefined);
                form.change("s_payment_terms", undefined);
                form.change("s_payment_term_condition", undefined);
                form.change("s_payment_method", undefined);
                form.change("s_bank_id", undefined);
                form.change("s_weight", undefined);
                form.change("s_status", undefined);
            },
        }
    );
    return (
        <Dialog
            open={isQuotationDialogOpen}
            onClose={() => setIsQuotationDialogOpen(false)}
            maxWidth="lg"
        >
            <DialogTitle>
                <Typography>Create New Supplier</Typography>
            </DialogTitle>
            <DialogContent>
                <SupplierForm />
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={() => setIsQuotationDialogOpen(false)}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default ReqSupplierForm;
