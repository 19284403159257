import FileCopyIcon from "@material-ui/icons/FileCopy";
import { FC, useState } from "react";
import {
    Button,
    Confirm,
    EditActionsProps,
    ListButton,
    ShowButton,
    TopToolbar,
} from "react-admin";
import { useHistory } from "react-router-dom";

import { useRequest } from "../../../hooks";

const OrderEditActions: FC<EditActionsProps> = ({ basePath, data }) => {
    const history = useHistory();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { isLoading, refetch } = useRequest(
        `/v1/productOrderAction/${data?.po_id}/reOrderAction`,
        {
            method: "POST",
        },
        {
            onSuccess: () => {
                history.push(basePath);
                handleDialogClose();
            },
        }
    );

    const handleDialogOpen = () => setIsDialogOpen(true);
    const handleDialogClose = () => setIsDialogOpen(false);

    return (
        <TopToolbar>
            <ListButton basePath={basePath} />
            <ShowButton basePath={basePath} record={data} />
            <Button label="Re Order" onClick={handleDialogOpen}>
                <FileCopyIcon />
            </Button>
            <Confirm
                title={`Reorder #${data?.po_id}`}
                content="Are you sure you want to reorder this order?"
                isOpen={isDialogOpen}
                loading={isLoading}
                onConfirm={refetch}
                onClose={handleDialogClose}
            />
        </TopToolbar>
    );
};

export default OrderEditActions;
