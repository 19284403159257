import { Box } from "@material-ui/core";
import { FC, MouseEvent, useState } from "react";
import {
    Button,
    Confirm,
    Datagrid,
    FunctionField,
    Link,
    List,
    ListProps,
    Record,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import { useDocumentTitle, useExport, useRequest } from "../../../hooks";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import PendingReorderFilter from "./PendingReorderFilter";

const PendingReorderList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Pending Reorder List");

    const exporter = useExport(rest);
    const classes = useAroggaStyles();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [pendingReorderId, setPendingReorderId] = useState("");
    const [status, setStatus] = useState("");

    const { isLoading, refetch } = useRequest(
        `/v1/productOrderAction/${pendingReorderId}/pendingReOrderApprovalAction`,
        {
            method: "POST",
            body: {
                _approval_status:
                    status === "approve" ? "approved" : "cancelled",
            },
        },
        {
            isRefresh: true,
            onSuccess: () => setIsDialogOpen(false),
        }
    );

    return (
        <>
            <List
                {...rest}
                title="List of Pending Reorder"
                perPage={25}
                sort={{ field: "pr_id", order: "DESC" }}
                filters={<PendingReorderFilter children={""} />}
                bulkActionButtons={false}
                exporter={exporter}
                {...rest}
            >
                <Datagrid>
                    <FunctionField
                        label="ID"
                        sortBy="pr_id"
                        onClick={(e: MouseEvent) => e.stopPropagation()}
                        render={({ pr_id, po_id }: Record) => (
                            <Link to={`/v1/productOrder/${po_id}`}>
                                {pr_id}
                            </Link>
                        )}
                    />
                    <TextField source="u_name" label="Name" />
                    <TextField source="u_mobile" label="Mobile" />
                    <TextField source="po_payable_total" label="Total" />
                    <TextField source="po_address" label="Address" />
                    <AroggaDateField
                        source="pr_created_at"
                        label="Created At"
                    />
                    <AroggaDateField
                        source="po_delivered_at"
                        label="Delivered At"
                    />
                    <FunctionField
                        label="Action"
                        render={({ pr_id, pr_status }: Record) => {
                            if (pr_status !== "pending") {
                                return (
                                    <span
                                        className={classes.capitalize}
                                        style={{
                                            color:
                                                pr_status === "approved"
                                                    ? "green"
                                                    : pr_status === "cancelled"
                                                    ? "#EF1962"
                                                    : "",
                                        }}
                                    >
                                        {pr_status}
                                    </span>
                                );
                            }

                            return (
                                <Box display="flex" gridGap={5}>
                                    <Button
                                        label="Approve"
                                        variant="contained"
                                        onClick={() => {
                                            setIsDialogOpen(true);
                                            setPendingReorderId(pr_id);
                                            setStatus("approve");
                                        }}
                                    />
                                    <Button
                                        label="Cancel"
                                        variant="contained"
                                        style={{ backgroundColor: "#6c757d" }}
                                        onClick={() => {
                                            setIsDialogOpen(true);
                                            setPendingReorderId(pr_id);
                                            setStatus("cancel");
                                        }}
                                    />
                                </Box>
                            );
                        }}
                    />
                </Datagrid>
            </List>
            <Confirm
                isOpen={isDialogOpen}
                loading={isLoading}
                title={`Re-order #${pendingReorderId}`}
                content={`Are you sure you want to ${status} this re-order?`}
                onConfirm={refetch}
                onClose={() => setIsDialogOpen(false)}
            />
        </>
    );
};

export default PendingReorderList;
