import { FC } from "react";
import {
    BooleanField,
    Datagrid,
    FunctionField,
    Link,
    List,
    ListProps,
    NumberField,
    Record,
    ReferenceField,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import { useDocumentTitle, useExport } from "../../../hooks";
import { getQuantityLabel, getReadableSKU } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import OrderProductExpand from "./OrderProductExpand";
import OrderProductFilter from "./OrderProductFilter";

const OrderProductList: FC<ListProps> = (props) => {
    useDocumentTitle("Arogga | Order Product List");

    const exporter = useExport(props);
    const classes = useAroggaStyles();

    return (
        <List
            title="List of Order Product"
            filters={<OrderProductFilter children={""} />}
            perPage={25}
            sort={{ field: "poi_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={false}
            {...props}
        >
            <Datagrid
                expand={<OrderProductExpand />}
                isRowExpandable={(row) => !!row?.poi_stock_mapping?.length}
                classes={{ expandedPanel: classes.expandedPanel }}
            >
                <TextField source="poi_id" label="ID" />
                <FunctionField
                    label="Order ID"
                    onClick={(e) => e.stopPropagation()}
                    render={({ poi_order_id }: Record) => (
                        <Link to={`/v1/productOrder/${poi_order_id}/show`}>
                            {poi_order_id}
                        </Link>
                    )}
                />
                <ReferenceField
                    source="poi_order_id"
                    label="Order Date"
                    reference="v1/productOrder"
                    link={false}
                >
                    <AroggaDateField source="po_created_at" />
                </ReferenceField>
                <ReferenceField
                    source="poi_product_id"
                    label="Product"
                    reference="v1/product"
                    link="edit"
                >
                    <TextField source="p_name" />
                </ReferenceField>
                <ReferenceField
                    source="poi_product_variant_id"
                    label="Variant"
                    reference="v1/productVariant"
                    link={false}
                >
                    <FunctionField
                        render={(record: Record) =>
                            getReadableSKU(record.pv_attribute)
                        }
                    />
                </ReferenceField>
                <ReferenceField
                    source="poi_product_id"
                    label="Form"
                    reference="v1/product"
                    link={false}
                >
                    <TextField source="p_form" className={classes.capitalize} />
                </ReferenceField>
                <ReferenceField
                    source="poi_product_id"
                    label="Strength"
                    reference="v1/product"
                    link={false}
                >
                    <TextField source="p_strength" />
                </ReferenceField>
                <FunctionField
                    source="poi_product_qty"
                    label="Qty"
                    sortBy="poi_product_qty"
                    className={classes.whitespaceNowrap}
                    render={({
                        poi_product_qty: qty,
                        product_base_unit: baseUnit,
                    }: Record) => (
                        <ReferenceField
                            source="poi_product_unit_id"
                            reference="v1/productUnit"
                            link={false}
                        >
                            <FunctionField
                                render={({
                                    pu_label: salesUnit,
                                    pu_multiplier: salesUnitMultiplier,
                                }: Record) =>
                                    getQuantityLabel({
                                        qty,
                                        salesUnit,
                                        baseUnit,
                                        salesUnitMultiplier,
                                    })
                                }
                            />
                        </ReferenceField>
                    )}
                />
                <NumberField
                    source="poi_supplier_price"
                    label="Supplier Price"
                />
                <NumberField
                    source="poi_discount_price"
                    label="Discount Price"
                />
                <NumberField source="poi_mrp_price" label="MRP" />
                <FunctionField
                    label="Profit Percent"
                    sortBy="supplier_percent"
                    render={({ supplier_percent }: Record) => {
                        if (!supplier_percent) return;

                        return (
                            <span
                                style={
                                    supplier_percent < 0
                                        ? { color: classes.textRed }
                                        : { color: classes.textGreen }
                                }
                            >
                                {supplier_percent}%
                            </span>
                        );
                    }}
                />
                <BooleanField
                    source="poi_item_unavailable"
                    label="Available?"
                    FalseIcon={() => null}
                    looseValue
                />
            </Datagrid>
        </List>
    );
};

export default OrderProductList;
