import { FC, useState } from "react";
import { Create, CreateProps, SimpleForm, TransformData } from "react-admin";

import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import TransactionForm from "../../../components/manageAccounting/transactions/TransactionForm";
import { useDocumentTitle } from "../../../hooks";
import { convertAttachmentsToBase64 } from "../../../utils/helpers";

const transform: TransformData = async (data) => ({
    ...data,
    atd: await convertAttachmentsToBase64("atd_attachments", data.atd),
});

const TransactionCreate: FC<CreateProps> = ({ hasCreate, ...rest }) => {
    useDocumentTitle("Arogga | Transaction Create");

    const [isDifference, setIsDifference] = useState(false);

    return (
        <Create transform={transform} {...rest}>
            <SimpleForm
                redirect="list"
                toolbar={
                    <SaveDeleteToolbar isSave isSaveDisabled={isDifference} />
                }
            >
                <TransactionForm setIsDifference={setIsDifference} />
            </SimpleForm>
        </Create>
    );
};

export default TransactionCreate;
