import { Box, Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { FC, useState } from "react";
import { usePermissions } from "react-admin";
import { useFormState } from "react-final-form";

import {
    getReadableSKU,
    isEmpty,
    isInteger,
    toFixedNumber,
} from "../../../utils/helpers";
import ImageDialog from "./ImageDialog";
import Summary from "./Summary";

type TableWithEditableCellsProps = {
    readOnlyColumns: string[];
    initialHidableKeys?: object;
    hideableColumns?: {
        source: string;
        label: string;
        defaultChecked?: boolean;
    }[];
    row: any[];
    setRow: (row) => void;
    disableAdd?: boolean;
    disableRemove?: boolean;
};

const TableWithEditableCells: FC<TableWithEditableCellsProps> = ({
    readOnlyColumns,
    initialHidableKeys,
    hideableColumns,
    row = [],
    setRow,
    disableRemove = false,
}) => {
    const classes = useStyles();
    const { values } = useFormState();
    const { permissions } = usePermissions();

    const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
    const [activeImageRowIndex, setActiveImageRowIndex] = useState(null);
    const [activeCell, setActiveCell] = useState(null);
    const [hidableKeys, setHidableKeys] = useState<any>(
        !!hideableColumns?.length
            ? {
                  ...Object.fromEntries(
                      hideableColumns.map((item) => [
                          item.source,
                          !item.defaultChecked || false,
                      ])
                  ),
              }
            : { ...initialHidableKeys }
    );

    const typeObj = {
        pv_attribute: "string",
        pv_sku: "string",
        pvBaseUnitLabel: "string",
        pv_mrp: "number",
        pv_b2c_discounted_price: "number",
        pv_b2b_discounted_price: "number",
        pv_b2c_min_qty: "number",
        pv_b2c_max_qty: "number",
        pv_b2b_min_qty: "number",
        pv_b2b_max_qty: "number",
        pvB2CSummary: "string",
        pvB2BSummary: "string",
        pv_is_base: "boolean",
    };

    const findSalesUnitLabel = (isB2C) => {
        const propertyName = isB2C
            ? "pu_is_b2c_sales_unit"
            : "pu_is_b2b_sales_unit";
        const salesUnit = values?.pu?.find((item) => item?.[propertyName]);

        return isInteger(salesUnit?.pu_label)
            ? salesUnit?.pu_labelTitle
            : salesUnit?.pu_label;
    };

    const getSalesUnitLabel = (isB2C = true) => {
        const label = findSalesUnitLabel(isB2C);
        return label ? `(${label})` : "";
    };

    const handleCellClick = ({ rowIndex, colIndex, key, inputType }) => {
        if (readOnlyColumns.includes(key)) return;
        setActiveCell({ rowIndex, colIndex, key, inputType });
    };

    const handleOnKeyDown = (e) => {
        if (["e", "-"].includes(e.key.toLowerCase())) {
            e.preventDefault();
        }
        if (e.key === "Enter") {
            setActiveCell(null);
        }
    };

    const handleOnBlur = () => {
        setActiveCell(null);
    };

    const handleOnFocus = (e) => {
        e.target.select();
    };

    const handleCheckboxChange = (newRow, activeCell, checkedValue) => {
        newRow.forEach(
            (item, index) =>
                (item[activeCell.key] =
                    activeCell.rowIndex === index ? checkedValue : 0)
        );
    };

    const handleNumericInputChange = (newRow, activeCell, targetValue) => {
        if (
            targetValue === 0 ||
            ([
                "pv_b2c_min_qty",
                "pv_b2c_max_qty",
                "pv_b2b_min_qty",
                "pv_b2b_max_qty",
            ].includes(activeCell.key) &&
                !isInteger(targetValue))
        ) {
            targetValue = null;
        }
        newRow[activeCell.rowIndex][activeCell.key] = targetValue;
    };

    const handleOnChange = (e) => {
        const targetValue =
            e.target.type === "checkbox"
                ? e.target.checked
                    ? 1
                    : 0
                : +e.target.value;
        const newRow = [...row];

        if (e.target.type === "checkbox" && activeCell.key === "pv_is_base") {
            handleCheckboxChange(newRow, activeCell, targetValue);
        } else {
            handleNumericInputChange(newRow, activeCell, targetValue);
        }

        setRow(newRow);
    };

    const calculateDiscountedPrice = (originalPrice, percent) => {
        return toFixedNumber(originalPrice - (originalPrice * percent) / 100);
    };

    const updateDiscountedPrice = (rowIndex, key, discountedPrice) => {
        const newRow = [...row];
        newRow[rowIndex][
            key === "b2cPercent"
                ? "pv_b2c_discounted_price"
                : "pv_b2b_discounted_price"
        ] = discountedPrice;
        return newRow;
    };

    const handlePercentageChange = (percent, rowIndex, key) => {
        const originalMRP = row[rowIndex].pv_mrp;
        const calculatedMRP = calculateDiscountedPrice(originalMRP, percent);
        const updatedRow = updateDiscountedPrice(rowIndex, key, calculatedMRP);
        setRow(updatedRow);
    };

    const handleRemoveRow = (rowIndex) => {
        setRow(row.toSpliced(rowIndex, 1));
    };

    return (
        <>
            <Box display="flex" justifyContent="flex-end">
                {!!hideableColumns?.length &&
                    hideableColumns.map(({ source, label }, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    checked={!hidableKeys?.[source]}
                                    onChange={() =>
                                        setHidableKeys((prev) => ({
                                            ...prev,
                                            [source]: !prev?.[source],
                                        }))
                                    }
                                    inputProps={{
                                        "aria-label": "primary checkbox",
                                    }}
                                />
                            }
                            label={label}
                        />
                    ))}
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e) => {
                                const isChecked = e.target.checked;
                                setHidableKeys((prev) => ({
                                    ...prev,
                                    isChangeMinMaxQty: isChecked,
                                    pv_b2c_min_qty: !isChecked,
                                    pv_b2c_max_qty: !isChecked,
                                    pv_b2b_min_qty: !isChecked,
                                    pv_b2b_max_qty: !isChecked,
                                }));
                            }}
                            inputProps={{
                                "aria-label": "primary checkbox",
                            }}
                        />
                    }
                    label="Change Min / Max Qty"
                />
            </Box>
            <table className={classes.table}>
                <thead>
                    <tr>
                        {!isEmpty(row?.[0]?.pv_attribute) && (
                            <th className={classes.th} rowSpan={2}>
                                Attribute
                            </th>
                        )}
                        {process.env.REACT_APP_NODE_ENV === "development" && (
                            <th className={classes.th} rowSpan={2}>
                                SKU
                            </th>
                        )}
                        <th className={classes.th} rowSpan={2}>
                            Unit
                        </th>
                        <th className={classes.th} rowSpan={2}>
                            MRP
                        </th>
                        <th className={classes.th} rowSpan={2}>
                            B2C Price
                        </th>
                        <th className={classes.th} rowSpan={2}>
                            %
                        </th>
                        <th className={classes.th} rowSpan={2}>
                            B2B Price
                        </th>
                        <th className={classes.th} rowSpan={2}>
                            %
                        </th>
                        {hidableKeys?.isChangeMinMaxQty && (
                            <th className={classes.th} colSpan={2}>
                                B2C {getSalesUnitLabel()}
                            </th>
                        )}
                        {hidableKeys?.isChangeMinMaxQty && (
                            <th className={classes.th} colSpan={2}>
                                B2B {getSalesUnitLabel(false)}
                            </th>
                        )}
                        <th className={classes.th} colSpan={2}>
                            Summary
                        </th>
                        {permissions?.includes("role:administrator") && (
                            <th className={classes.th} rowSpan={2}>
                                Base
                            </th>
                        )}
                        <th className={classes.th} rowSpan={2}>
                            Image
                        </th>
                        {!disableRemove && (
                            <th className={classes.th} rowSpan={2}>
                                Remove
                            </th>
                        )}
                    </tr>
                    <tr>
                        {hidableKeys?.isChangeMinMaxQty && (
                            <th className={classes.th}>Min</th>
                        )}
                        {hidableKeys?.isChangeMinMaxQty && (
                            <th className={classes.th}>Max</th>
                        )}
                        {hidableKeys?.isChangeMinMaxQty && (
                            <th className={classes.th}>Min</th>
                        )}
                        {hidableKeys?.isChangeMinMaxQty && (
                            <th className={classes.th}>Max</th>
                        )}
                        <th className={classes.th}>B2C (%)</th>
                        <th className={classes.th}>B2B (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {!!row?.length &&
                        row.map((item, rowIndex) => {
                            const {
                                id,
                                pv_id,
                                pv_p_id,
                                pv_purchase_price,
                                pv_allow_purchase,
                                pv_allow_sales,
                                pv_is_base,
                                pv_deleted_at,
                                attachedFiles_pv_images,
                                ...restItem
                            } = item;

                            const formattedAttribute = getReadableSKU(
                                item?.pv_attribute
                            );

                            const isItemSoftDeleted =
                                pv_deleted_at &&
                                pv_deleted_at !== "0000-00-00 00:00:00";

                            return (
                                <tr
                                    key={rowIndex}
                                    style={
                                        isItemSoftDeleted
                                            ? {
                                                  color: "#ccc",
                                              }
                                            : {}
                                    }
                                >
                                    {Object.entries(
                                        permissions?.includes(
                                            "role:administrator"
                                        )
                                            ? { ...restItem, pv_is_base }
                                            : restItem
                                    ).map(([key, value]: any, colIndex) => {
                                        if (
                                            hidableKeys?.[key] ||
                                            (row?.length === 1 &&
                                                key === "pv_attribute" &&
                                                isEmpty(value)) ||
                                            (process.env.REACT_APP_NODE_ENV !==
                                                "development" &&
                                                key === "pv_sku")
                                        )
                                            return null;

                                        let inputType;
                                        let modifiedValue;

                                        if (
                                            activeCell?.inputType === "number"
                                        ) {
                                            inputType = "number";
                                        } else if (
                                            activeCell?.inputType === "boolean"
                                        ) {
                                            inputType = "checkbox";
                                        } else {
                                            inputType = "text";
                                        }

                                        if (typeObj[key] === "boolean") {
                                            modifiedValue = value ? (
                                                <CheckIcon />
                                            ) : (
                                                ""
                                            );
                                        } else if (key === "pv_attribute") {
                                            modifiedValue = formattedAttribute;
                                        } else if (
                                            [
                                                "pvB2CSummary",
                                                "pvB2BSummary",
                                            ].includes(key)
                                        ) {
                                            modifiedValue = (
                                                <Summary
                                                    isShowProfitPercentage={
                                                        !values.cloneActionFrom &&
                                                        values.p_id
                                                    }
                                                    isSoftDeleted={
                                                        isItemSoftDeleted
                                                    }
                                                    mrp={
                                                        row?.[rowIndex]?.pv_mrp
                                                    }
                                                    sellingPrice={
                                                        row?.[rowIndex]?.[
                                                            key ===
                                                            "pvB2CSummary"
                                                                ? "pv_b2c_discounted_price"
                                                                : "pv_b2b_discounted_price"
                                                        ]
                                                    }
                                                    purchasePrice={
                                                        pv_purchase_price
                                                    }
                                                />
                                            );
                                        } else {
                                            modifiedValue = value;
                                        }

                                        return (
                                            <td
                                                key={colIndex}
                                                className={classes.td}
                                                style={
                                                    key === "pv_attribute" ||
                                                    key === "pv_sku"
                                                        ? {
                                                              textAlign: "left",
                                                              padding:
                                                                  "3px 8px",
                                                          }
                                                        : {
                                                              textAlign:
                                                                  "center",
                                                          }
                                                }
                                                onClick={() => {
                                                    !isItemSoftDeleted &&
                                                        handleCellClick({
                                                            rowIndex,
                                                            colIndex,
                                                            key,
                                                            inputType:
                                                                typeObj[key],
                                                        });
                                                }}
                                            >
                                                {activeCell &&
                                                activeCell.rowIndex ===
                                                    rowIndex &&
                                                activeCell.colIndex ===
                                                    colIndex ? (
                                                    <input
                                                        type={inputType}
                                                        checked={
                                                            !!row?.[rowIndex]?.[
                                                                key
                                                            ]
                                                        }
                                                        min={1}
                                                        value={
                                                            row?.[rowIndex]?.[
                                                                key
                                                            ]
                                                        }
                                                        onKeyDown={
                                                            handleOnKeyDown
                                                        }
                                                        onBlur={handleOnBlur}
                                                        onFocus={handleOnFocus}
                                                        onChange={
                                                            handleOnChange
                                                        }
                                                        className={
                                                            classes.input
                                                        }
                                                        autoFocus
                                                    />
                                                ) : (
                                                    <>{modifiedValue}</>
                                                )}
                                                {[
                                                    "b2cPercent",
                                                    "b2bPercent",
                                                ].includes(key) && (
                                                    <select
                                                        onChange={(e) =>
                                                            handlePercentageChange(
                                                                +e.target.value,
                                                                rowIndex,
                                                                key
                                                            )
                                                        }
                                                        disabled={
                                                            isItemSoftDeleted
                                                        }
                                                    >
                                                        <option value="">
                                                            Select
                                                        </option>
                                                        {[
                                                            5, 10, 12, 15, 20,
                                                            30,
                                                        ].map((item, index) => (
                                                            <option
                                                                key={index}
                                                                value={item}
                                                            >
                                                                {item}
                                                            </option>
                                                        ))}
                                                    </select>
                                                )}
                                            </td>
                                        );
                                    })}
                                    <td
                                        className={classes.td}
                                        onClick={() => {
                                            if (!isItemSoftDeleted) {
                                                setActiveImageRowIndex(
                                                    rowIndex
                                                );
                                                setIsImageDialogOpen(true);
                                            }
                                        }}
                                        style={
                                            !isItemSoftDeleted
                                                ? { cursor: "pointer" }
                                                : {}
                                        }
                                    >
                                        <PhotoLibraryIcon />
                                    </td>
                                    {!disableRemove && (
                                        <td
                                            className={classes.td}
                                            onClick={() =>
                                                !isItemSoftDeleted &&
                                                handleRemoveRow(rowIndex)
                                            }
                                            style={
                                                !isItemSoftDeleted
                                                    ? { cursor: "pointer" }
                                                    : {}
                                            }
                                        >
                                            <CloseIcon />
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                    <ImageDialog
                        activeImageRowIndex={activeImageRowIndex}
                        attribute={row?.[activeImageRowIndex]?.pv_attribute}
                        attachedFiles={
                            row?.[activeImageRowIndex]?.[
                                "attachedFiles_pv_images"
                            ]
                        }
                        open={isImageDialogOpen}
                        handleClose={() => setIsImageDialogOpen(false)}
                    />
                </tbody>
            </table>
        </>
    );
};

const useStyles = makeStyles({
    table: {
        width: "100%",
        border: "1px solid #ced4da",
        borderCollapse: "collapse",
    },
    th: {
        padding: "3px 3px",
        borderRight: "1px solid #ced4da",
        borderBottom: "1px solid #ced4da",
    },
    td: {
        position: "relative",
        padding: "3px 3px",
        textAlign: "center",
        borderRight: "1px solid #ced4da",
        borderBottom: "1px solid #ced4da",
    },
    input: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        textAlign: "center",
        fontSize: 16,
    },
});

export default TableWithEditableCells;
