import queryString from "query-string";
import { CSSProperties, FC, useMemo, useState } from "react";
import { Labeled } from "react-admin";
import { useForm, useFormState } from "react-final-form";
import Cascader from "rsuite/Cascader";
import "rsuite/dist/rsuite-no-reset.min.css";

import { useRequest } from "../hooks";
import { httpClient } from "../utils/http";

const API_ENDPOINT = "/v1/taxonomy";
const QUERY_PARAMS = {
    _v_machine_name: "product_category",
    _fields: "t_id,t_parent_id,t_title,t_has_child,t_weight",
    _order: "ASC",
    _page: 1,
    _perPage: 50000,
};

type CascaderCategoryInputProps = {
    source: string;
    label?: string;
    placeholder?: string;
    addLabel?: boolean;
    isLastLevelSelectable?: boolean;
    setSelectedLastCategoryId?: (value: number) => void;
    style?: CSSProperties;
};

const CascaderCategoryInput: FC<CascaderCategoryInputProps> = ({
    source,
    label,
    placeholder,
    addLabel = true,
    isLastLevelSelectable = true,
    setSelectedLastCategoryId,
    style,
}) => {
    const form = useForm();
    const { values } = useFormState();

    const stringified = queryString.stringify(QUERY_PARAMS);

    const [defaultValueString, setDefaultValueString] =
        useState("Select Category");

    const { isLoading, data } = useRequest(
        `${API_ENDPOINT}?${stringified}&_orderBy=t_weight&_parent_id=0`,
        {},
        {
            isPreFetching: true,
            isWarningNotify: false,
        }
    );

    useRequest(
        `${API_ENDPOINT}?${stringified}&_reverse_parent=${values?.[source]}&_orderBy=t_parent_id`,
        {},
        {
            isPreFetching: !!values?.[source],
            isWarningNotify: false,
            ...(isLastLevelSelectable && { refreshDeps: [values?.[source]] }),
            onSuccess: ({ data }) => {
                setDefaultValueString(
                    data?.map((item) => item.t_title)?.join(" > ")
                );
            },
        }
    );

    const tree = useMemo(() => buildTreeFromList(data), [data]);

    const setProductCategoryId = (value) => form.change(source, value);

    const renderCascader = () => (
        <Cascader
            valueKey="t_id"
            labelKey="t_title"
            data={tree}
            placeholder={placeholder || defaultValueString}
            getChildren={(node) =>
                httpClient(
                    `${API_ENDPOINT}?${stringified}&_parent_id=${node.t_id}`
                ).then((res: any) => buildTreeFromList(res?.json?.data))
            }
            renderValue={(_, activePaths) =>
                activePaths.map((item) => item.t_title).join(" > ")
            }
            onSelect={({ t_id, t_has_child }) => {
                if (isLastLevelSelectable && !t_has_child) {
                    setProductCategoryId(t_id);
                    setSelectedLastCategoryId &&
                        setSelectedLastCategoryId(t_id);
                }
                !isLastLevelSelectable && setProductCategoryId(t_id);
            }}
            onClean={() => {
                setDefaultValueString("Select Category");
                setProductCategoryId(undefined);
                setSelectedLastCategoryId && setSelectedLastCategoryId(null);
            }}
            // @ts-ignore
            loading={isLoading}
            parentSelectable={!isLastLevelSelectable}
            size="lg"
            style={style}
            menuWidth={180}
            searchable={false}
            block
        />
    );

    if (label && addLabel)
        return (
            <Labeled label={label} fullWidth>
                {renderCascader()}
            </Labeled>
        );

    return renderCascader();
};

export default CascaderCategoryInput;

const buildTreeFromList = (list) => {
    if (!list?.length) return [];

    const map = new Map();
    const tree = [];

    const initializeItem = (item) => ({
        ...item,
        children: item.t_has_child ? [] : null,
    });

    list.forEach((item) => {
        const initializedItem = initializeItem(item);
        map.set(item.t_id, initializedItem);
    });

    list.forEach((item) => {
        const parent = map.get(item.t_parent_id);

        if (!parent) {
            tree.push(map.get(item.t_id));
        } else {
            parent.children.push(map.get(item.t_id));
        }
    });

    return tree;
};
