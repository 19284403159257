import AcUnitIcon from "@material-ui/icons/AcUnit";
import { FC } from "react";
import {
    Datagrid,
    FunctionField,
    List,
    ListProps,
    NumberField,
    Record,
    ReferenceField,
    TextField,
    useRefresh,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import Tag from "../../../components/Tag";
import DurationField from "../../../components/manageOrder/orders/DurationField";
import UserField from "../../../components/manageOrder/orders/UserField";
import {
    useDocumentTitle,
    useExport,
    useNavigateFromList,
    useRecursiveTimeout,
} from "../../../hooks";
import { toFilterObj } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import OrderFilter from "./OrderFilter";

const OrderList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Order List");

    const exporter = useExport(rest);
    const refresh = useRefresh();
    const classes = useAroggaStyles();
    const navigateFromList = useNavigateFromList(
        "productOrderView",
        "productOrderEdit"
    );

    useRecursiveTimeout(refresh, 1000 * 60 * 2);

    const filterObj = toFilterObj(rest.location.search);

    const rowStyle = (record: Record) => ({
        backgroundColor: !!record?.i_status
            ? record?.i_status === "settled"
                ? "rgb(212 239 223 / 29%)"
                : "rgb(255 0 0 / 7%)"
            : "white",
    });

    return (
        <List
            title="List of Order"
            filters={<OrderFilter children={""} />}
            perPage={25}
            sort={{ field: "po_id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={permissions?.includes("productOrderDelete")}
            {...rest}
        >
            <Datagrid rowClick={navigateFromList} rowStyle={rowStyle}>
                <FunctionField
                    label="ID"
                    sortBy="po_id"
                    render={({
                        po_id,
                        po_stock_unavailable_count,
                        po_is_urgent,
                        po_item_unavailable,
                        po_delivery_option,
                    }: Record) => (
                        <span className={classes.whitespaceNowrap}>
                            {po_id}{" "}
                            {!!po_stock_unavailable_count && (
                                <span className={classes.textRed}>
                                    ({po_stock_unavailable_count})
                                </span>
                            )}{" "}
                            {!!po_is_urgent && (
                                <span className={classes.textRed}>
                                    (Urgent)
                                </span>
                            )}
                            {!!po_item_unavailable && (
                                <span className={classes.textRed}>
                                    (Unavailable)
                                </span>
                            )}
                            <br />
                            {po_delivery_option === "express" && (
                                <Tag name="Express" />
                            )}
                        </span>
                    )}
                />
                <UserField sortBy="po_user_id" />
                <NumberField source="po_payable_total" label="Payable Total" />
                <AroggaDateField source="po_created_at" label="Date" />
                <DurationField />
                <FunctionField
                    label="Status"
                    sortBy="po_status"
                    render={(record: Record) => (
                        <span
                            className={`${classes.capitalize} ${
                                ["processing", "cancelled"].includes(
                                    record.po_status
                                ) && classes.textRed
                            }`}
                        >
                            {record?.po_status}
                        </span>
                    )}
                />
                <FunctionField
                    label="Cold"
                    sortBy="po_is_cold"
                    render={(record: Record) => {
                        if (!record.po_is_cold) return;
                        return <AcUnitIcon />;
                    }}
                />
                {filterObj._is_issued && (
                    <AroggaDateField
                        source="i_created_at"
                        label="Issue Created At"
                    />
                )}
                {filterObj._is_issued && (
                    <TextField
                        source="i_status"
                        label="Issue Status"
                        className={classes.capitalize}
                    />
                )}
                <FunctionField
                    label="Payment Status"
                    sortBy="po_payment_status"
                    render={(record: Record) => (
                        <span
                            className={`${classes.capitalize} ${
                                record.po_payment_status === "pending" &&
                                classes.textRed
                            }`}
                        >
                            {record?.po_payment_status}
                        </span>
                    )}
                />
                <TextField source="po_internal_notes" label="Internal Note" />
                {filterObj._is_cancel_requested && (
                    <ReferenceField
                        source="s_cancel_requested_by"
                        label="Cancel Requested By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                )}
            </Datagrid>
        </List>
    );
};

export default OrderList;
