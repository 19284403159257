import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { SelectInput, TextInput, usePermissions } from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import { Status } from "../../../utils/enums";
import { isJSONParsable, logger } from "../../../utils/helpers";
import { httpClient } from "../../../utils/http";
import AddressTypeInput from "../../AddressTypeInput";
import AroggaDialogActions from "../../AroggaDialogActions";
import AddnewSubArea from "./AddnewSubArea";
import AreaInput from "./AreaInput";
import DistrictInput from "./DistrictInput";
import SubAreaInput from "./SubAreaInput";

type LocationCreateModalProps = {
    open: boolean;
    onDialogClose: () => void;
    userId: number;
    setNewlyAddedLocation: (newlyAddedLocation) => void;
};

const LocationCreateModal: FC<LocationCreateModalProps> = ({
    open,
    onDialogClose,
    userId,
    setNewlyAddedLocation,
}) => {
    const { values } = useFormState();
    const { permissions } = usePermissions();

    const [locations, setLocations] = useState(null);
    const [isAddNewSubAreaDialogOpen, setIsAddNewSubAreaDialogOpen] =
        useState(false);
    const [isSubAreaRefresh, setIsSubAreaRefresh] = useState(false);
    const [hasSubArea, setHasSubArea] = useState(false);
    const form = useForm();
    const toChoices = (items: string[]) =>
        items.map((item: string) => ({ id: item, name: item }));

    const { isLoading, refetch } = useRequest(
        "/v1/userLocations",
        {
            method: "POST",
            body: {
                u_id: userId,
                l_division: values.l_division,
                l_district: values.l_district,
                l_area: values.l_area,
                ul_sa_id: values.ul_sa_id,
                ul_name: values.ul_name,
                ul_mobile: values.ul_mobile,
                ul_type: values.ul_type,
                ul_address: values.ul_address,
                ul_default: 1,
            },
        },
        {
            onSuccess: ({ data }) => {
                onDialogClose();
                reset();
                setNewlyAddedLocation(data);
            },
        }
    );

    useEffect(() => {
        const locationsFromStroage = sessionStorage.getItem("locations");

        if (locationsFromStroage) {
            setLocations(
                isJSONParsable(locationsFromStroage)
                    ? JSON.parse(locationsFromStroage)
                    : {}
            );
        } else {
            httpClient("/v1/allLocations/", { isBaseUrl: true })
                .then(({ json }: any) => {
                    if (json.status === Status.SUCCESS) {
                        setLocations(json.data);
                        sessionStorage.setItem(
                            "locations",
                            JSON.stringify(json.data)
                        );
                    }
                })
                .catch((err) => logger(err));
        }
    }, []);

    useEffect(() => {
        if (
            !!locations &&
            !!values?.l_division &&
            !!values?.l_district &&
            !!values?.l_area &&
            !!locations[values?.l_division] &&
            !!locations[values?.l_division][values?.l_district] &&
            !!locations[values?.l_division][values?.l_district][
                values?.l_area
            ] &&
            !!locations[values?.l_division][values?.l_district][values?.l_area][
                "l_has_subarea"
            ]
        ) {
            setHasSubArea(true);
        } else {
            setHasSubArea(false);
            form.change("ul_sa_id", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.l_division, values?.l_district, values?.l_area, locations]);

    const reset = () => {
        ["l_id", "ul_type", "ul_address"].forEach(
            (key) => (values[key] = undefined)
        );
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                onDialogClose();
                reset();
            }}
            style={{ height: 800 }}
        >
            <DialogTitle>
                <Typography>Create new user location</Typography>
            </DialogTitle>
            <DialogContent>
                <TextInput
                    source="ul_name"
                    label="Name *"
                    variant="outlined"
                    helperText={false}
                    initialValue={values.po_user_name}
                    fullWidth
                />
                <TextInput
                    source="ul_mobile"
                    label="Mobile *"
                    variant="outlined"
                    helperText={false}
                    initialValue={values.po_user_mobile}
                    fullWidth
                />
                <SelectInput
                    source="l_division"
                    label="Shipping Division"
                    variant="outlined"
                    helperText={false}
                    choices={
                        !!locations ? toChoices(Object.keys(locations)) : []
                    }
                    allowEmpty
                    fullWidth
                />
                <DistrictInput
                    source="l_district"
                    label="Shipping City"
                    variant="outlined"
                    helperText={false}
                    locations={locations}
                    actionType="create"
                    setLocations={setLocations}
                    allowEmpty
                    fullWidth
                />
                <AreaInput
                    source="l_area"
                    label="Shipping Area"
                    variant="outlined"
                    helperText={false}
                    locations={locations}
                    actionType="create"
                    setLocations={setLocations}
                    allowEmpty
                    fullWidth
                />
                {hasSubArea && (
                    <Grid container>
                        <Grid item sm={8} md={8}>
                            <SubAreaInput
                                source="ul_sa_id"
                                label="Shipping Sub Area"
                                variant="outlined"
                                locations={locations}
                                actionType="create"
                                isSubAreaRefresh={isSubAreaRefresh}
                                setLocations={setLocations}
                                translateChoice={false}
                                allowEmpty
                                fullWidth
                            />
                        </Grid>
                        {permissions?.includes("subAreaCreate") && (
                            <Grid item sm={4} md={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: 10, marginLeft: 3 }}
                                    onClick={() => {
                                        setIsAddNewSubAreaDialogOpen(true);
                                        setIsSubAreaRefresh(false);
                                    }}
                                >
                                    Add New
                                </Button>
                            </Grid>
                        )}
                        <AddnewSubArea
                            locations={locations}
                            actionType="create"
                            open={isAddNewSubAreaDialogOpen}
                            setIsSubAreaRefresh={setIsSubAreaRefresh}
                            handleClose={() => {
                                setIsAddNewSubAreaDialogOpen(false);
                            }}
                        />
                    </Grid>
                )}
                <AddressTypeInput
                    source="ul_type"
                    label="Address Type *"
                    variant="outlined"
                    helperText={false}
                    fullWidth
                />
                <TextInput
                    source="ul_address"
                    label="Address *"
                    variant="outlined"
                    helperText={false}
                    multiline
                    fullWidth
                />
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={() => {
                    onDialogClose();
                    reset();
                }}
                onConfirm={refetch}
                disabled={
                    !userId ||
                    !values.ul_name ||
                    !values.ul_mobile ||
                    !values.l_division ||
                    !values.l_district ||
                    !values.l_area ||
                    !values.ul_type ||
                    !values.ul_address
                }
            />
        </Dialog>
    );
};

export default LocationCreateModal;
