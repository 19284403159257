import { Box } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import {
    BulkDeleteButton,
    Button,
    List,
    ListProps,
    Pagination,
    SimpleForm,
    useListContext,
} from "react-admin";

import "../../../assets/style.css";
import ProductDatagrid from "../../../components/manageDatabase/products/ProductDatagrid";
import {
    useDocumentTitle,
    useExport,
    useNavigateFromList,
} from "../../../hooks";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import BulkTagAssignDialog from "./BulkTagAssignDialog";
import CategoryMovementDialog from "./CategoryMovementDialog";
import ChangeVendorDialog from "./ChangeVendorDialog";
import ProductFilter from "./ProductFilter";

const CustomPagination = ({ ...props }) => {
    const { filterValues, perPage, setPerPage, page, setPage } =
        useListContext();
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([
        25, 100, 200,
    ]);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);

    useEffect(() => {
        // Load saved page number from localStorage before component mounts
        const savedPage = localStorage.getItem("productListPage");
        if (savedPage && page === 1) {
            // Only restore if page is 1 (initial load)
            setPage(parseInt(savedPage, 10));
        }

        const isIdsFilterPresent = !!filterValues?.ids;

        if (isIdsFilterPresent) {
            setRowsPerPageOptions([500, 1000, 1500]);
            setRowsPerPage(500);
            setPerPage(500);
            setPage(parseInt(savedPage, 10));
        } else {
            setRowsPerPageOptions([25, 100, 200]);
            setRowsPerPage(25);
            setPerPage(25);
            setPage(parseInt(savedPage, 10));
        }
    }, [filterValues.ids]);

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPerPage(newRowsPerPage);
    };

    useEffect(() => {
        localStorage.setItem("productListPage", page.toString());
    }, [page]);

    return (
        <Pagination
            //@ts-ignore
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            {...props}
        />
    );
};
const ProductList: FC<ListProps> = (props) => {
    useDocumentTitle("Arogga | Product List");

    const exporter = useExport(props);
    const classes = useAroggaStyles();
    const navigateFromList = useNavigateFromList("productView", "productEdit");

    const [selectedLastCategoryId, setSelectedLastCategoryId] = useState(null);
    const [cascaderCategoryPlaceholder, setCascaderCategoryPlaceholder] =
        useState("");
    const [isBulkTagAssignDialogOpen, setIsBulkTagAssignDialogOpen] =
        useState(false);

    const hasSelectedLastCategoryId = window.location?.hash?.includes(
        "selectedLastCategoryId"
    );

    useEffect(() => {
        if (!hasSelectedLastCategoryId && selectedLastCategoryId) {
            setSelectedLastCategoryId(null);
            setCascaderCategoryPlaceholder("Select Category");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSelectedLastCategoryId]);

    const ProductBulkActionButtons = (props) => {
        const [isVendorDialogOpen, setIsVendorDialogOpen] = useState(false);
        const [isCategoryMovementDialogOpen, setIsCategoryMovementDialogOpen] =
            useState(false);

        return (
            <Box display="flex" gridGap={5}>
                <Button
                    label="Change Vendor"
                    variant="contained"
                    onClick={() => setIsVendorDialogOpen(true)}
                />
                <Button
                    label="Items Move To"
                    variant="contained"
                    onClick={() => setIsCategoryMovementDialogOpen(true)}
                />
                <Button
                    label="Bulk Tag Assign"
                    variant="contained"
                    onClick={() => setIsBulkTagAssignDialogOpen(true)}
                />
                <BulkDeleteButton {...props} />
                <SimpleForm toolbar={null}>
                    <ChangeVendorDialog
                        isDialogOpen={isVendorDialogOpen}
                        setIsDialogOpen={setIsVendorDialogOpen}
                        selectedProductIds={props?.selectedIds}
                    />
                    <CategoryMovementDialog
                        isDialogOpen={isCategoryMovementDialogOpen}
                        setIsDialogOpen={setIsCategoryMovementDialogOpen}
                        selectedProductIds={props?.selectedIds}
                    />
                    <BulkTagAssignDialog
                        isDialogOpen={isBulkTagAssignDialogOpen}
                        setIsDialogOpen={setIsBulkTagAssignDialogOpen}
                        selectedProductIds={props?.selectedIds}
                    />
                </SimpleForm>
            </Box>
        );
    };

    return (
        <List
            {...props}
            title="List of Product"
            filter={{
                _details: 1,
                ...(hasSelectedLastCategoryId &&
                    selectedLastCategoryId && {
                        _product_category_id: selectedLastCategoryId,
                    }),
            }}
            filters={
                <ProductFilter
                    children={""}
                    cascaderCategoryPlaceholder={cascaderCategoryPlaceholder}
                    setSelectedLastCategoryId={setSelectedLastCategoryId}
                />
            }
            sort={{ field: "p_id", order: "DESC" }}
            pagination={<CustomPagination />}
            exporter={exporter}
            bulkActionButtons={
                <ProductBulkActionButtons
                    setIsBulkTagAssignDialogOpen={setIsBulkTagAssignDialogOpen}
                />
            }
        >
            <ProductDatagrid {...props} />
        </List>
    );
};

export default ProductList;
