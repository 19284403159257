import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    makeStyles,
} from "@material-ui/core";
import { FC, useState } from "react";
import { Confirm, NumberInput, SelectInput, TextInput } from "react-admin";
import { useForm, useFormState } from "react-final-form";

import LoaderOrButton from "../../../components/LoaderOrButton";
import { useRequest } from "../../../hooks";
import { isEmpty } from "../../../utils/helpers";

const BkashTab: FC = () => {
    const classes = useStyles();
    const form = useForm();
    const { values } = useFormState();

    const [isOpenTransferDialog, setIsOpenTransferDialog] = useState(false);
    const [isOpenPayoutDialog, setIsOpenPayoutDialog] = useState(false);

    const { transferAmount, transferType, payoutAmount, o_id, withdrawMobile } =
        values;

    const {
        data: checkBalanceData,
        isLoading: isLoadingCheckBalance,
        refetch: refetchCheckBalance,
    } = useRequest(`/v1/adminActions/bkash/b2c/checkBalance`);

    const {
        isLoading: isLoadingIntraAccountTransfer,
        refetch: refetchIntraAccountTransfer,
    } = useRequest(
        `/v1/adminActions/bkash/b2c/intraAccountTransfer`,
        {
            method: "POST",
            body: {
                amount: transferAmount,
                transferType,
            },
        },
        {
            onSuccess: () => {
                form.change("transferAmount", undefined);
                form.change("transferType", "Collection2Disbursement");
                setIsOpenTransferDialog(false);
            },
        }
    );

    const { isLoading: isLoadingPayout, refetch: refetchPayout } = useRequest(
        `/v1/adminActions/bkash/b2c/payout`,
        {
            method: "POST",
            body: {
                amount: payoutAmount,
                o_id,
                withdrawMobile,
            },
        },
        {
            onSuccess: () => {
                form.change("payoutAmount", undefined);
                form.change("o_id", undefined);
                form.change("withdrawMobile", undefined);
                setIsOpenPayoutDialog(false);
            },
        }
    );

    return (
        <>
            <LoaderOrButton
                label="B2C Check Balance"
                isLoading={isLoadingCheckBalance}
                onClick={refetchCheckBalance}
            />
            {!isEmpty(checkBalanceData) && (
                <TableContainer style={{ marginTop: 10 }}>
                    <Table size="small" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: "bold" }}>
                                    Account
                                </TableCell>
                                <TableCell
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                >
                                    Amount
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    Organization Disbursement eMoney Account
                                </TableCell>
                                <TableCell align="right">
                                    {
                                        checkBalanceData[
                                            "Organization Disbursement eMoney Account"
                                        ]
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Organization eMoney Account
                                </TableCell>
                                <TableCell align="right">
                                    {
                                        checkBalanceData[
                                            "Organization eMoney Account"
                                        ]
                                    }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Box display="flex" gridGap={8} alignItems="center" mt={2}>
                <NumberInput
                    source="transferAmount"
                    label="Amount"
                    variant="outlined"
                    helperText={false}
                />
                <SelectInput
                    source="transferType"
                    label="Transfer Type"
                    variant="outlined"
                    helperText={false}
                    defaultValue="Collection2Disbursement"
                    choices={[
                        {
                            id: "Collection2Disbursement",
                            name: "Collection2Disbursement",
                        },
                        {
                            id: "Disbursement2Collection",
                            name: "Disbursement2Collection",
                        },
                    ]}
                />
                <Button
                    variant="contained"
                    onClick={() => setIsOpenTransferDialog(true)}
                    disabled={!transferAmount}
                >
                    B2C Intra Account Transfer
                </Button>
                <Confirm
                    isOpen={isOpenTransferDialog}
                    loading={isLoadingIntraAccountTransfer}
                    title="B2C Intra Account Transfer"
                    content={`Are you sure you want to transfer ${transferType}, Amount: ${transferAmount}?`}
                    onConfirm={refetchIntraAccountTransfer}
                    onClose={() => setIsOpenTransferDialog(false)}
                />
            </Box>
            <Box display="flex" gridGap={8} alignItems="center">
                <NumberInput
                    source="payoutAmount"
                    label="Amount"
                    variant="outlined"
                    helperText={false}
                />
                <NumberInput
                    source="o_id"
                    label="Order ID"
                    variant="outlined"
                    helperText={false}
                />
                <TextInput
                    source="withdrawMobile"
                    label="Withdraw Mobile"
                    variant="outlined"
                    helperText={false}
                />
                <Button
                    variant="contained"
                    onClick={() => setIsOpenPayoutDialog(true)}
                    disabled={!payoutAmount || !o_id || !withdrawMobile}
                >
                    B2C Payout
                </Button>
                <Confirm
                    isOpen={isOpenPayoutDialog}
                    loading={isLoadingPayout}
                    title="B2C Payout"
                    content={`Are you sure you want to payout Order ID: ${o_id}, Amount: ${payoutAmount}, Withdraw Mobile: ${withdrawMobile}?`}
                    onConfirm={refetchPayout}
                    onClose={() => setIsOpenPayoutDialog(false)}
                />
            </Box>
        </>
    );
};

const useStyles = makeStyles({
    table: {
        width: 430,
    },
});

export default BkashTab;
