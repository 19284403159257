const OrderStatusIcon = (props) => (
    <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect
            width="59"
            height="59"
            rx="29.5"
            fill="#1DA099"
            fillOpacity="0.1"
        />
        <path
            d="M36.0628 29.5H22.9378C22.1357 29.5 21.4795 30.1562 21.4795 30.9583V32.4167H37.5212V30.9583C37.5212 30.1562 36.8649 29.5 36.0628 29.5Z"
            fill="#1DA099"
        />
        <path
            d="M28.4066 24.3958H22.5732V27.3125H28.4066V24.3958Z"
            fill="#1DA099"
        />
        <path
            d="M36.4274 24.3958H30.5941V27.3125H36.4274V24.3958Z"
            fill="#1DA099"
        />
        <path
            d="M41.167 14.9167H17.8337C16.2295 14.9167 14.917 16.2292 14.917 17.8333V41.1667C14.917 42.7708 16.2295 44.0833 17.8337 44.0833H41.167C42.7712 44.0833 44.0837 42.7708 44.0837 41.1667V17.8333C44.0837 16.2292 42.7712 14.9167 41.167 14.9167ZM39.7087 36.7917H37.5212V34.6042H21.4795V36.7917H19.292V31.2062C19.292 30.2437 19.6566 29.3687 20.2399 28.6979V25.125C20.2399 23.5208 21.5524 22.2083 23.1566 22.2083H28.042C28.5816 22.2083 29.092 22.3833 29.5003 22.675C29.9087 22.3833 30.4191 22.2083 30.9587 22.2083H35.8441C37.4482 22.2083 38.7607 23.5208 38.7607 25.125V28.6979C39.3441 29.3687 39.7087 30.2437 39.7087 31.2062V36.7917Z"
            fill="#1DA099"
        />
    </svg>
);
export default OrderStatusIcon;
