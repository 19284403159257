import { Box, Typography } from "@material-ui/core";
import { FC, useState } from "react";
import { Create, CreateProps, SimpleForm } from "react-admin";
import { useHistory } from "react-router-dom";

import campaign from "../../../assets/images/campaign.png";
import TaxonomiesByVocabularyInput from "../../../components/TaxonomiesByVocabularyInput";
import { useDocumentTitle } from "../../../hooks";

const CampaignCreate: FC<CreateProps> = ({ hasCreate, ...rest }) => {
    useDocumentTitle("Arogga | Campaign Create");

    const history = useHistory();

    const [campaignType, setCampaignType] = useState("");

    const getBtnStyle = ({
        borderColor = "#667085",
        backgroundColor = "#fff",
        color = "#475467",
        ...rest
    }) => ({
        width: "100%",
        padding: 20,
        fontWeight: 600,
        border: `1px solid ${borderColor}`,
        borderRadius: 8,
        backgroundColor,
        color,
        ...rest,
    });

    const handleNext = (e) => {
        e.preventDefault();

        campaignType && history.push(`/product-list-campaign/${campaignType}`);
    };

    return (
        <Create {...rest}>
            <SimpleForm
                submitOnEnter={false}
                toolbar={null}
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Typography
                    style={{ marginBottom: 15, fontSize: 20, fontWeight: 600 }}
                >
                    Create Campaign
                </Typography>
                <Box
                    width={580}
                    height={449}
                    padding={2}
                    alignContent="center"
                    border="1px solid #D0D5DD"
                    borderRadius={15}
                >
                    <img
                        src={campaign}
                        alt="Campaign"
                        style={{ display: "block", margin: "auto" }}
                    />
                    <TaxonomiesByVocabularyInput
                        fetchKey="campaign_type"
                        source="c_type"
                        label="Campaign Type"
                        helperText={false}
                        style={{ margin: "25px 0px" }}
                        onChange={(e) => setCampaignType(e.target.value)}
                        fullWidth
                    />
                    <Box display="flex" gridGap={10}>
                        <button style={getBtnStyle({})}>Close</button>
                        <button
                            style={getBtnStyle({
                                borderColor: campaignType
                                    ? "#0E7673"
                                    : "#BBBBBB",
                                backgroundColor: campaignType
                                    ? "#0E7673"
                                    : "#DDDDDD",
                                color: campaignType ? "#fff" : "#888888",
                                cursor: campaignType
                                    ? "pointer"
                                    : "not-allowed",
                            })}
                            onClick={handleNext}
                        >
                            Next
                        </button>
                    </Box>
                </Box>
            </SimpleForm>
        </Create>
    );
};

export default CampaignCreate;
