import { Box, Divider, Grid } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useEffect } from "react";
import {
    AutocompleteInput,
    DateTimeInput,
    FileField,
    FileInput,
    Labeled,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    minLength,
    required,
    usePermissions,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { IMAGE_FILE_MAX_SIZE } from "../../../utils/constants";
import {
    toFixedNumber,
    toFormattedDateTime,
    userEmployeeInputTextRenderer,
} from "../../../utils/helpers";
import TaxonomiesByVocabularyInput from "../../TaxonomiesByVocabularyInput";
import UserEmployeeOptionTextRenderer from "../../UserEmployeeOptionTextRenderer";

const TransactionForm = ({ setIsDifference }) => {
    const form = useForm();
    const { permissions } = usePermissions();
    const { values } = useFormState();

    useEffect(() => {
        if (values.at_id) return;

        form.change(
            "atd",
            Array.from({ length: 2 }).map(() => ({
                atd_type: undefined,
                atd_account_head_id: undefined,
                atd_amount: undefined,
            }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsDifference(!!difference);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.atd]);

    let totalDrAmount = 0;
    let totalCrAmount = 0;

    values.atd?.forEach((item) => {
        if (item?.atd_type === "debit" && item?.atd_amount) {
            totalDrAmount += item.atd_amount;
        } else if (item?.atd_type === "credit" && item?.atd_amount) {
            totalCrAmount += item.atd_amount;
        }
    });

    const getTextRenderer = (props) => {
        switch (props?.entityType) {
            case "ProductOrder":
                return props.po_id + "";
            case "CashCollection":
                return `ID: ${props.cc_id}, Date: ${props.cc_created}, Amount: ${props.cc_amount}`;
            case "ProductPurchase":
                return props.pp_id + "";
            case "ExpenseEntry":
                return `ID: ${props.ee_id}, Amount: ${props.ee_expense_amount}`;
            case "Employee":
                return props.e_name;
            case "EmployeeLoan":
                return props.el_id + "";
            case "Salary":
                return props.s_id + "";
            case "Vendor":
                return props.v_name;
            case "Supplier":
                return `${props.s_name} (${props.s_mobile})`;
            case "Shipment":
                return props.s_id + "";
            case "Issue":
                return props.i_id + "";
            default:
                return "";
        }
    };

    const getEntityReference = (entityType) => {
        const obj = {
            ProductOrder: "v1/productOrder",
            User: "v1/users",
            CashCollection: "v1/cashCollection",
            ProductPurchase: "v1/productPurchase",
            ExpenseEntry: "v1/expenseEntry",
            Employee: "v1/employee",
            EmployeeLoan: "v1/employeeLoan",
            Salary: "v1/salary",
            Vendor: "v1/vendor",
            Supplier: "v1/supplier",
            Shipment: "v1/shipment",
            Issue: "v1/issue",
        };

        return obj?.[entityType];
    };

    const refEntityInputTextRenderer = (choice) => getTextRenderer(choice);

    const RefEntityOptionTextRenderer = (props) => {
        const { record } = props || {};

        return getTextRenderer({ ...record, entityType: props?.entityType });
    };

    const difference = Math.abs(toFixedNumber(totalDrAmount - totalCrAmount));

    const haveAccountingTransactionEdit = permissions?.includes(
        "accountingTransactionEdit"
    );

    return (
        <>
            {!!values.at_id && (
                <TextInput
                    source="at_id"
                    label="ID"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
            )}
            <Box display="flex" gridColumnGap={8}>
                <SelectInput
                    source="at_type"
                    label="Type"
                    variant="outlined"
                    helperText={false}
                    style={{ width: 256 }}
                    choices={[
                        {
                            id: "purchase",
                            name: "Purchase",
                        },
                        {
                            id: "payment",
                            name: "Payment",
                        },
                        {
                            id: "receive",
                            name: "Receive",
                        },
                        {
                            id: "journal",
                            name: "Journal",
                        },
                        {
                            id: "contra",
                            name: "Contra",
                        },
                    ]}
                    validate={[required()]}
                    disabled={!haveAccountingTransactionEdit}
                />
                <DateTimeInput
                    source="at_date"
                    label="Date"
                    variant="outlined"
                    helperText={false}
                    style={{ width: 256 }}
                    defaultValue={
                        values.at_id
                            ? null
                            : toFormattedDateTime({
                                  dateString: new Date().toString(),
                              })
                    }
                    parse={(dateTime) =>
                        toFormattedDateTime({
                            dateString: dateTime,
                        })
                    }
                    validate={[required()]}
                    disabled={!haveAccountingTransactionEdit}
                />
            </Box>
            <TextInput
                source="at_narration"
                label="Narration"
                variant="outlined"
                helperText={false}
                style={{ width: 521 }}
                validate={[
                    required(),
                    minLength(
                        5,
                        "Narration must be at least 5 characters long"
                    ),
                ]}
                disabled={!haveAccountingTransactionEdit}
                minRows={2}
                multiline
            />
            <FileInput
                source="attachedFiles_at_attachments"
                label="Related Files"
                accept="image/*, application/pdf"
                maxSize={IMAGE_FILE_MAX_SIZE}
                helperText={false}
                multiple
            >
                <FileField source="src" title="title" />
            </FileInput>
            <Labeled label="Transaction Details" fullWidth>
                <FieldArray name="atd">
                    {({ fields }) => (
                        <>
                            {fields.map((name, index) => (
                                <Grid container spacing={1} key={index}>
                                    <Grid item xs={12} sm={1}>
                                        <AutocompleteInput
                                            source={`${name}.atd_type`}
                                            label="Dr / Cr"
                                            variant="outlined"
                                            helperText={false}
                                            defaultValue={
                                                index === 0 ? "debit" : "credit"
                                            }
                                            choices={[
                                                {
                                                    id: "credit",
                                                    name: "Credit",
                                                },
                                                {
                                                    id: "debit",
                                                    name: "Debit",
                                                },
                                            ]}
                                            onChange={() => {
                                                form.change(
                                                    `${name}.atd_amount`,
                                                    undefined
                                                );
                                                form.change(
                                                    `${name}.random_1`,
                                                    undefined
                                                );
                                                form.change(
                                                    `${name}.random_2`,
                                                    undefined
                                                );
                                            }}
                                            disabled={
                                                !haveAccountingTransactionEdit
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <ReferenceInput
                                            source={`${name}.atd_account_head_id`}
                                            label="Ledger"
                                            variant="outlined"
                                            helperText={false}
                                            reference="v1/accountingHead"
                                            validate={[required()]}
                                            disabled={
                                                !haveAccountingTransactionEdit
                                            }
                                        >
                                            <AutocompleteInput
                                                optionText="ah_name"
                                                options={{
                                                    InputProps: {
                                                        multiline: true,
                                                    },
                                                }}
                                                resettable
                                                fullWidth
                                            />
                                        </ReferenceInput>
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                        <NumberInput
                                            source={
                                                values.atd[index]?.atd_type ===
                                                "debit"
                                                    ? `${name}.atd_amount`
                                                    : `${name}.random_1`
                                            }
                                            label="Dr Amount"
                                            variant="outlined"
                                            helperText={false}
                                            disabled={
                                                !haveAccountingTransactionEdit ||
                                                !values.atd[index]?.atd_type ||
                                                values.atd[index]?.atd_type ===
                                                    "credit"
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                        <NumberInput
                                            source={
                                                values.atd[index]?.atd_type ===
                                                "credit"
                                                    ? `${name}.atd_amount`
                                                    : `${name}.random_2`
                                            }
                                            label="Cr Amount"
                                            variant="outlined"
                                            helperText={false}
                                            disabled={
                                                !haveAccountingTransactionEdit ||
                                                !values.atd[index]?.atd_type ||
                                                values.atd[index]?.atd_type ===
                                                    "debit"
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                        <TaxonomiesByVocabularyInput
                                            fetchKey="accounting_transaction_details_ref_entity_type"
                                            inputType="autoCompleteInput"
                                            source={`${name}.atd_ref_entity_type`}
                                            label="Entity Type"
                                            helperText={false}
                                            onChange={() =>
                                                form.change(
                                                    `${name}.atd_ref_entity_id`,
                                                    undefined
                                                )
                                            }
                                            rawTitle
                                            disabled={
                                                !haveAccountingTransactionEdit
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    {!!values?.atd?.[index]
                                        ?.atd_ref_entity_type && (
                                        <Grid item xs={12} sm={3}>
                                            <ReferenceInput
                                                source={`${name}.atd_ref_entity_id`}
                                                label="Entity"
                                                variant="outlined"
                                                helperText={false}
                                                reference={getEntityReference(
                                                    values?.atd?.[index]
                                                        ?.atd_ref_entity_type
                                                )}
                                                validate={
                                                    values?.atd?.[index]
                                                        ?.atd_ref_entity_type
                                                        ? [required()]
                                                        : null
                                                }
                                                disabled={
                                                    !haveAccountingTransactionEdit
                                                }
                                                fullWidth
                                            >
                                                <AutocompleteInput
                                                    matchSuggestion={() => true}
                                                    helperText={false}
                                                    optionText={
                                                        values?.atd?.[index]
                                                            ?.atd_ref_entity_type ===
                                                        "User" ? (
                                                            <UserEmployeeOptionTextRenderer />
                                                        ) : (
                                                            <RefEntityOptionTextRenderer
                                                                entityType={
                                                                    values
                                                                        ?.atd?.[
                                                                        index
                                                                    ]
                                                                        ?.atd_ref_entity_type
                                                                }
                                                            />
                                                        )
                                                    }
                                                    inputText={
                                                        values?.atd?.[index]
                                                            ?.atd_ref_entity_type ===
                                                        "User"
                                                            ? userEmployeeInputTextRenderer
                                                            : (choice) =>
                                                                  refEntityInputTextRenderer(
                                                                      {
                                                                          ...choice,
                                                                          entityType:
                                                                              values
                                                                                  ?.atd?.[
                                                                                  index
                                                                              ]
                                                                                  ?.atd_ref_entity_type,
                                                                      }
                                                                  )
                                                    }
                                                    resettable
                                                />
                                            </ReferenceInput>
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        xs={12}
                                        sm={1}
                                        style={{ marginTop: -9 }}
                                    >
                                        <FileInput
                                            source={`${name}.atd_attachments`}
                                            label=""
                                            accept="image/*, application/pdf"
                                            maxSize={IMAGE_FILE_MAX_SIZE}
                                            placeholder={<AttachFileIcon />}
                                            helperText={false}
                                            multiple
                                        >
                                            <FileField
                                                source="src"
                                                title="title"
                                            />
                                        </FileInput>
                                    </Grid>
                                    {haveAccountingTransactionEdit && (
                                        <Grid item xs={12} sm={1}>
                                            <Box display="flex" mt={2}>
                                                {index !== 0 && (
                                                    <span
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            fields.remove(index)
                                                        }
                                                    >
                                                        <HighlightOffIcon />
                                                    </span>
                                                )}
                                                {values.atd?.length - 1 ===
                                                    index && (
                                                    <span
                                                        style={{
                                                            marginLeft: 5,
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            fields.push({
                                                                atd_type:
                                                                    undefined,
                                                                atd_account_head_id:
                                                                    undefined,
                                                                atd_amount:
                                                                    undefined,
                                                            })
                                                        }
                                                    >
                                                        <AddCircleOutlineIcon />
                                                    </span>
                                                )}
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            ))}
                        </>
                    )}
                </FieldArray>
            </Labeled>
            <Box my={3}>
                <Divider />
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    Total
                </Grid>
                <Grid item xs={12} sm={2} />
                <Grid item xs={12} sm={1}>
                    {toFixedNumber(totalDrAmount)}
                </Grid>
                <Grid item xs={12} sm={1}>
                    {toFixedNumber(totalCrAmount)}
                </Grid>
            </Grid>
            {values.atd?.length && !!difference && (
                <Box mt={3}>
                    <span style={difference ? { color: "#EF1962" } : {}}>
                        Difference: {difference}
                    </span>
                </Box>
            )}
        </>
    );
};

export default TransactionForm;
