import { Box } from "@material-ui/core";
import { useState } from "react";
import {
    Button,
    Confirm,
    Datagrid,
    FunctionField,
    NumberField,
    Pagination,
    Record,
    ReferenceField,
    ReferenceManyField,
    SelectInput,
    TextField,
    TextInput,
    useNotify,
    usePermissions,
} from "react-admin";
import { useFormState } from "react-final-form";

import AroggaDateField from "../../../../components/AroggaDateField";
import LoaderOrButton from "../../../../components/LoaderOrButton";
import { useRequest } from "../../../../hooks";
import { useAroggaStyles } from "../../../../utils/useAroggaStyles";

const Payment = () => {
    const classes = useAroggaStyles();
    const notify = useNotify();
    const { permissions } = usePermissions();
    const { values } = useFormState();

    const [isLoadPayment, setIsLoadPayment] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [action, setAction] = useState("");
    const [servicePaymentId, setServicePaymentId] = useState("");

    const { transactionMethod, transactionId } = values;

    const { isLoading: isLoadingPaymentCheck, refetch: handlePaymentCheck } =
        useRequest(
            `/v1/servicePayments/check/${servicePaymentId}/${transactionMethod}/${
                transactionMethod === "sslCommerz" ? "" : transactionId
            }`,
            {},
            {
                isRefresh: true,
                onSuccess: () => handleDialogClose(),
            }
        );

    const {
        isLoading: isLoadingUndoCashReceived,
        refetch: handleUndoCashReceived,
    } = useRequest(
        `/v1/servicePayments/undoCashReceived/${servicePaymentId}/`,
        {
            method: "POST",
        },
        {
            isRefresh: true,
            onSuccess: () => handleDialogClose(),
        }
    );

    const onConfirm = () => {
        if (action === "undo cash received") return handleUndoCashReceived();

        if (!transactionMethod)
            return notify("Transaction method is required!", {
                type: "warning",
            });

        if (transactionMethod !== "sslCommerz" && !transactionId)
            return notify("Transaction ID is required!", {
                type: "warning",
            });

        if (
            transactionMethod === "bKash" &&
            (transactionId?.length < 8 ||
                transactionId?.length > 100 ||
                transactionId?.length === undefined)
        ) {
            notify("Payment ID must be 8-100 digits!", {
                type: "warning",
            });
        } else if (
            transactionMethod === "nagad" &&
            (transactionId?.length < 11 || transactionId?.length === undefined)
        ) {
            notify("Payment ID must be greater than 10 digits!", {
                type: "warning",
            });
        } else {
            handlePaymentCheck();
        }
    };

    const handleDialogClose = () => {
        values.transactionMethod = undefined;
        values.transactionId = undefined;
        setIsDialogOpen(false);
    };

    return (
        <>
            {!isLoadPayment && (
                <LoaderOrButton
                    label="Load Payment"
                    isLoading={false}
                    display="flex"
                    justifyContent="center"
                    mt={3}
                    mb={4}
                    onClick={() => setIsLoadPayment(true)}
                />
            )}
            {isLoadPayment && (
                <ReferenceManyField
                    reference="v1/servicePayment"
                    target="_service_id"
                    filter={{
                        _service: "ProductOrder",
                    }}
                    pagination={<Pagination />}
                >
                    <Datagrid>
                        <TextField source="id" label="ID" />
                        <TextField source="sp_service" label="Service" />
                        <TextField
                            source="sp_payment_gateway"
                            label="Payment Gateway"
                            className={classes.capitalize}
                        />
                        <TextField
                            source="sp_our_transaction_id"
                            label="Our Transaction ID"
                        />
                        <TextField
                            source="sp_their_transaction_id"
                            label="Their Transaction ID"
                        />
                        <TextField
                            source="sp_their_payment_id"
                            label="Their Payment ID"
                        />
                        <TextField source="sp_mobile_no" label="Mobile" />
                        <TextField source="sp_card_no" label="Card No" />
                        <NumberField source="sp_amount" label="Amount" />
                        <NumberField
                            source="sp_gateway_fee"
                            label="Gateway Fee"
                        />
                        <AroggaDateField
                            source="sp_success_date"
                            label="Success Date"
                            addLabel={false}
                        />
                        <FunctionField
                            label="Payment Status"
                            sortBy="sp_payment_status"
                            render={(record: Record) => (
                                <span
                                    className={`${classes.capitalize} ${
                                        record.sp_payment_status ===
                                            "pending" && classes.textRed
                                    }`}
                                >
                                    {record?.sp_payment_status}
                                </span>
                            )}
                        />
                        <TextField
                            source="sp_payment_type"
                            label="Payment Type"
                        />
                        <TextField
                            source="sp_payment_out_type"
                            label="Payment Out Type"
                        />
                        <AroggaDateField
                            source="sp_reconciled_at"
                            label="Reconciled At"
                            addLabel={false}
                        />
                        <ReferenceField
                            source="sp_payment_received_by"
                            label="Received By"
                            reference="v1/users"
                            link="show"
                        >
                            <TextField source="u_name" />
                        </ReferenceField>
                        <AroggaDateField
                            source="sp_created_at"
                            label="Created At"
                            addLabel={false}
                        />
                        <FunctionField
                            label="Action"
                            render={({
                                sp_id,
                                sp_payment_gateway,
                                sp_payment_status,
                                sp_payment_type,
                            }: Record) => (
                                <Box
                                    display="flex"
                                    gridGap={5}
                                    className={classes.whitespaceNowrap}
                                >
                                    {sp_payment_status === "pending" && (
                                        <Button
                                            label="Check Payment"
                                            variant="contained"
                                            onClick={() => {
                                                setAction("check payment");
                                                setServicePaymentId(sp_id);
                                                setIsDialogOpen(true);
                                            }}
                                        />
                                    )}
                                    {/* TODO: Add delevering stage condition */}
                                    {permissions?.includes(
                                        "undoCashReceived"
                                    ) &&
                                        sp_payment_gateway === "cash" &&
                                        sp_payment_status === "paid" &&
                                        sp_payment_type === "IN" && (
                                            <Button
                                                label="Undo Cash Received"
                                                variant="contained"
                                                onClick={() => {
                                                    setAction(
                                                        "undo cash received"
                                                    );
                                                    setServicePaymentId(sp_id);
                                                    setIsDialogOpen(true);
                                                }}
                                            />
                                        )}
                                </Box>
                            )}
                        />
                    </Datagrid>
                </ReferenceManyField>
            )}
            <Confirm
                title={`Are you sure you want to ${action}? #${servicePaymentId}`}
                content={
                    action === "undo cash received" ? null : (
                        <>
                            <SelectInput
                                source="transactionMethod"
                                label="Transaction Method"
                                variant="outlined"
                                helperText={false}
                                choices={[
                                    {
                                        id: "nagad",
                                        name: "Nagad",
                                    },
                                    {
                                        id: "bKash",
                                        name: "bKash",
                                    },
                                    {
                                        id: "sslCommerz",
                                        name: "SSL Commerz",
                                    },
                                ]}
                                fullWidth
                            />
                            {values.transactionMethod !== "sslCommerz" && (
                                <TextInput
                                    source="transactionId"
                                    label="Payment ID"
                                    variant="outlined"
                                    helperText={false}
                                    fullWidth
                                />
                            )}
                        </>
                    )
                }
                isOpen={isDialogOpen}
                loading={
                    action === "check payment"
                        ? isLoadingPaymentCheck
                        : isLoadingUndoCashReceived
                }
                onConfirm={onConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

export default Payment;
