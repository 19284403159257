import { FC } from "react";
import { Edit, EditProps, SimpleForm, TransformData } from "react-admin";

import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import RegionForm from "../../../components/manageSite/regions/RegionForm";
import { useDocumentTitle } from "../../../hooks";
import { groupBy } from "../../../utils/helpers";

const RegionEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Region Edit");

    const transform: TransformData = ({ regionType, rt, ...rest }) => {
        const groupByRegionType = groupBy(rt, (data) => data.rt_name);

        return {
            ...rest,
            rt: regionType?.map((rt_name) => ({
                rt_id: groupByRegionType?.[rt_name]?.[0].rt_id,
                rt_name,
            })),
        };
    };

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            transform={transform}
            {...rest}
        >
            <SimpleForm
                initialValues={({ rt }) => ({
                    regionType: rt?.map(({ rt_name }) => rt_name),
                })}
                toolbar={
                    <SaveDeleteToolbar
                        isSave
                        isDelete={permissions?.includes("regionDelete")}
                    />
                }
            >
                <RegionForm />
            </SimpleForm>
        </Edit>
    );
};

export default RegionEdit;
