import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    ReferenceInput,
    SelectInput,
    TextInput,
} from "react-admin";

import TreeDropdownInput from "../../../components/TreeDropdownInput";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";
import { userEmployeeInputTextRenderer } from "../../../utils/helpers";

const PurchaseRequisitionFilter: FC<FilterProps> = (props) => (
    <Filter {...props}>
        <TextInput
            source="_search"
            label="Search"
            variant="outlined"
            resettable
            alwaysOn
        />
        <SelectInput
            source="_status"
            label="Status"
            variant="outlined"
            choices={[
                { id: "pending", name: "Pending" },
                { id: "department_approved", name: "Department Approved" },
                { id: "procurement_approved", name: "Procurement Approved" },
                { id: "finance_issue", name: "Finance Issue" },
                { id: "finance_cleared", name: "Finance Cleared" },
                { id: "management_approved", name: "Management Approved" },
                { id: "product_received", name: "Product Received" },
                { id: "purchase_completed", name: "Purchase Complete" },
                {
                    id: "rejected",
                    name: "Rejected",
                },
            ]}
            alwaysOn
        />
        <TreeDropdownInput
            reference="/v1/taxonomiesByVocabulary/department"
            source="_department"
            label="Department"
            variant="outlined"
            keyId="t_id"
            keyParent="t_parent_id"
            optionValue="t_machine_name"
            optionTextValue="t_title"
            alwaysOn
        />
        <ReferenceInput
            source="_created_by"
            label="Created By"
            variant="outlined"
            reference="v1/users"
            alwaysOn
        >
            <AutocompleteInput
                matchSuggestion={() => true}
                helperText={false}
                optionText={<UserEmployeeOptionTextRenderer />}
                inputText={userEmployeeInputTextRenderer}
                resettable
            />
        </ReferenceInput>
    </Filter>
);

export default PurchaseRequisitionFilter;
