import { Link } from "react-admin";

import { getFormattedDateString } from "../../../utils/helpers";
import styles from "./LedgerTable.module.css";

const Body = ({ index, data, headIdsData, formattedBalance, ...rest }) => (
    <div className={styles.tbody} {...rest}>
        <div className={styles.td}>
            {getFormattedDateString(data?.debit_entries[index]?.date)}
        </div>
        <div className={`${styles.td} ${styles["w-220"]}`}>
            {data?.debit_entries[index]?.account_head_id ===
            "opening_balance" ? (
                "Opening Balance"
            ) : (
                <Link
                    to={`/v1/accountingTransaction/${data?.debit_entries[index]?.transaction_id}`}
                >
                    {headIdsData[data?.debit_entries[index]?.account_head_id]}
                </Link>
            )}
        </div>
        <div className={`${styles.td} ${styles["align-right"]}`}>
            {formattedBalance(data?.debit_entries[index]?.amount)}
        </div>
        <div className={styles.td}>
            {getFormattedDateString(data?.credit_entries[index]?.date)}
        </div>
        <div className={`${styles.td} ${styles["w-220"]}`}>
            {data?.credit_entries[index]?.account_head_id ===
            "opening_balance" ? (
                "Opening Balance"
            ) : (
                <Link
                    to={`/v1/accountingTransaction/${data?.credit_entries[index]?.transaction_id}`}
                >
                    {headIdsData[data?.credit_entries[index]?.account_head_id]}
                </Link>
            )}
        </div>
        <div className={`${styles.td} ${styles["align-right"]}`}>
            {formattedBalance(data?.credit_entries[index]?.amount)}
        </div>
    </div>
);

export default Body;
