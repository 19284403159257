import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { useFormState } from "react-final-form";

import AroggaDialogActions from "../../../components/AroggaDialogActions";
import CascaderCategoryInput from "../../../components/CascaderCategoryInput";
import { useRequest } from "../../../hooks";

type CategoryMovementDialogProps = {
    isDialogOpen: boolean;
    setIsDialogOpen: (value: boolean) => void;
    selectedProductIds: number[];
};

const CategoryMovementDialog: FC<CategoryMovementDialogProps> = ({
    isDialogOpen,
    setIsDialogOpen,
    selectedProductIds,
}) => {
    const { values } = useFormState();

    const { isLoading, refetch } = useRequest(
        "/v3/productCategoryBulkUpdate",
        {
            method: "POST",
            body: {
                _category_id: values.selectedLastCategoryId,
                _product_ids: selectedProductIds,
            },
        },
        {
            onSuccess: () => handleDialogClose(),
        }
    );

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        values.selectedLastCategoryId = undefined;
    };

    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleDialogClose}
            style={{ zIndex: 1 }}
            maxWidth="xl"
        >
            <DialogTitle>Category Movement</DialogTitle>
            <DialogContent style={{ width: 1000 }}>
                <CascaderCategoryInput
                    source="selectedLastCategoryId"
                    isLastLevelSelectable={false}
                />
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={handleDialogClose}
                onConfirm={refetch}
                disabled={!values.selectedLastCategoryId}
            />
        </Dialog>
    );
};

export default CategoryMovementDialog;
