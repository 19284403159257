import { Box, Button, Paper, TextField } from "@material-ui/core";
import { FC, useState } from "react";
import { FormTab, TabbedForm, Title, Toolbar, useNotify } from "react-admin";

import { uploadDataProvider } from "../../../dataProvider";
import { useDocumentTitle, useRequest } from "../../../hooks";
import { logger } from "../../../utils/helpers";

const UnBlockIpPage: FC = () => {
    useDocumentTitle("Arogga | UnBlock IP");
    const notify = useNotify();
    const [ip, setIp] = useState("");
    const { data, refetch: refetchOptionsMultiple } = useRequest(
        "/v1/optionsMultiple",
        {},
        { isPreFetching: true }
    );
    const { refetch: handleUnblockIp } = useRequest(
        "/v1/adminActions/ip/unblock/",
        {
            method: "POST",
            body: {
                ip,
            },
        }
    );
    const onSave = async (data: object) => {
        try {
            await uploadDataProvider.create("v1/optionsMultiple", {
                data,
            });
            notify("Successfully save!", { type: "success" });
            refetchOptionsMultiple();
        } catch (err) {
            logger(err);
            notify("Something went wrong! Please try again!", {
                type: "error",
            });
        }
    };

    return (
        <Paper style={{ marginTop: 25 }}>
            <Title title="UnBlock IP" />
            <TabbedForm
                initialValues={data}
                syncWithLocation={false}
                toolbar={<Toolbar alwaysEnableSaveButton />}
                save={onSave}
            >
                <FormTab label="Unblock IP">
                    <Box
                        display="flex"
                        style={{ width: "100%" }}
                        gridGap={8}
                        mb={1}
                    >
                        <TextField
                            name="ipAddress"
                            label="IP Address"
                            placeholder="Enter ip address"
                            size="small"
                            variant="outlined"
                            value={ip}
                            onChange={(e) => setIp(e.target.value)}
                        />

                        <Button
                            variant="contained"
                            onClick={handleUnblockIp}
                            disableElevation
                            disabled={!ip}
                        >
                            Unblock IP
                        </Button>
                    </Box>
                </FormTab>
            </TabbedForm>
        </Paper>
    );
};

export default UnBlockIpPage;
