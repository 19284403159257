import { DateTime } from "luxon";

import { useAroggaStyles } from "../../../utils/useAroggaStyles";

// TODO: Make this a reusable component for all duration fields
const DurationField = (props) => {
    const classes = useAroggaStyles();

    const { po_status, delivery_time, po_created_at } = props?.record || {};

    if (!["processing", "confirmed", "delivered"].includes(po_status))
        return null;

    const date =
        po_status === "delivered"
            ? DateTime.fromSQL(delivery_time)
            : DateTime.local();

    const diff = date
        .diff(DateTime.fromSQL(po_created_at), ["days", "hours", "minutes"])
        .toObject();

    if (!diff.days && !diff.hours) return null;

    return (
        <span className={diff.days >= 1 ? classes.textRed : ""}>
            {diff.days}d-{diff.hours}h
        </span>
    );
};

export default DurationField;

DurationField.defaultProps = { label: "Duration" };
