import { AutocompleteInput, ReferenceInput } from "react-admin";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { useFormState } from "react-final-form";

import AroggaDialogActions from "../AroggaDialogActions";
import { useRequest } from "../../hooks";
import UserEmployeeOptionTextRenderer from "../UserEmployeeOptionTextRenderer";
import { userEmployeeInputTextRenderer } from "../../utils/helpers";

const AssignUser = ({ url, isDialogOpen, setIsDialogOpen }) => {
    const { values } = useFormState();
    const { isLoading, refetch } = useRequest(
        url,
        {
            method: "POST",
            body: {
                user_id: values.user_id,
            },
        },
        {
            onSuccess: () => handleDialogClose(),
        }
    );
    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };
    return (
        <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="md">
            <DialogTitle>Assign</DialogTitle>
            <DialogContent>
                <ReferenceInput
                    source="user_id"
                    label="Employee"
                    reference="v1/users"
                    variant="outlined"
                    filter={{
                        _isEmployee: 1,
                    }}
                >
                    <AutocompleteInput
                        matchSuggestion={() => true}
                        helperText={false}
                        optionText={<UserEmployeeOptionTextRenderer />}
                        inputText={userEmployeeInputTextRenderer}
                        resettable
                    />
                </ReferenceInput>
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={handleDialogClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

export default AssignUser;
