import { Box } from "@material-ui/core";
import { KeyboardEvent, useEffect, useState } from "react";
import { TextInput, required, useNotify } from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import { isEmpty, isValidMobileNo } from "../../../utils/helpers";
import LoaderOrButton from "../../LoaderOrButton";
import Tag from "../../Tag";
import UserCreateModal from "./UserCreateModal";

const PopulateUserInfo = () => {
    const notify = useNotify();
    const form = useForm();
    const { values } = useFormState();

    const [isUserCreateModalOpen, setIsUserCreateModalOpen] = useState(false);

    const { data, isLoading, refetch } = useRequest(
        `/v1/users?_purpose=order&_mobile=${values.po_user_mobile?.replace(
            /^\+?88/,
            ""
        )}`,
        {},
        {
            onFinally: () => {
                form.change("isUserChecked", true);
                form.change("isCreateNewUser", false);
            },
        }
    );

    useEffect(() => {
        setFormFields(data?.[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        resetFormFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.po_user_mobile]);

    const setFormFields = (userData) => {
        form.change("userId", userData?.u_id);
        form.change("po_user_name", userData?.u_name);
        form.change("isB2BUser", userData?.is_b2b);
        form.change("totalOrder", userData?.u_o_count || 0);
        form.change("totalDeliveredOrder", userData?.u_d_count || 0);
    };

    const resetFormFields = () => {
        const fieldsToReset = [
            "userId",
            "po_user_name",
            "po_user_location_id",
            "isB2BUser",
            "isUserChecked",
            "totalOrder",
            "totalDeliveredOrder",
        ];

        fieldsToReset.forEach((key) => form.change(key, undefined));

        if (values.totalOrder) {
            form.change("totalOrder", data?.[0]?.u_o_count || 0);
        }
        if (values.totalDeliveredOrder) {
            form.change("totalDeliveredOrder", data?.[0]?.u_d_count || 0);
        }
    };

    const handleCheckUser = () => {
        if (!isValidMobileNo(values.po_user_mobile?.replace(/^\+?88/, "")))
            return notify("Invalid mobile number!", { type: "warning" });
        refetch();
    };

    const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== "Enter" || isLoading) return;

        if (isShowCheckUserBtn) handleCheckUser();
        if (isShowCreateNewUserBtn) {
            setIsUserCreateModalOpen(true);
        }
    };

    const isShowCheckUserBtn = !values.isUserChecked;
    const isShowCreateNewUserBtn =
        values.isUserChecked && isEmpty(data) && !values.isCreateNewUser;

    return (
        <Box display="flex" alignItems="center" gridGap={8} width="100%">
            <TextInput
                source="po_user_mobile"
                label="Mobile No"
                variant="outlined"
                style={{ width: 256 }}
                initialValue="+88"
                validate={[required()]}
                helperText={false}
                onKeyDown={handleOnKeyDown}
                autoFocus
            />
            {values?.userId && (
                <Tag
                    name={values?.isB2BUser ? "B2B User" : "B2C User"}
                    style={{
                        height: 19,
                    }}
                />
            )}
            {isShowCheckUserBtn && (
                <LoaderOrButton
                    label="Check User"
                    isLoadingLabel={isLoading}
                    btnVariant="contained"
                    btnColor="primary"
                    btnStyle={{ whiteSpace: "nowrap" }}
                    onClick={handleCheckUser}
                />
            )}
            {isShowCreateNewUserBtn && (
                <LoaderOrButton
                    label="Create New User"
                    btnVariant="contained"
                    btnColor="primary"
                    btnStyle={{ whiteSpace: "nowrap" }}
                    onClick={() => setIsUserCreateModalOpen(true)}
                />
            )}
            <UserCreateModal
                open={isUserCreateModalOpen}
                setOpen={setIsUserCreateModalOpen}
            />
        </Box>
    );
};

export default PopulateUserInfo;
