import { Grid } from "@material-ui/core";
import { AutocompleteInput, ReferenceInput, TextInput } from "react-admin";
import { useFormState } from "react-final-form";

const ExpenseHeadMappingForm = () => {
    const { values } = useFormState();
    return (
        <>
            {!!values.ehm_id && (
                <Grid item sm={3}>
                    <TextInput
                        source="ehm_id"
                        label="ID"
                        variant="outlined"
                        helperText={false}
                        fullWidth
                        disabled
                    />
                </Grid>
            )}
            <Grid item sm={3}>
                {" "}
                <TextInput
                    source="ehm_expense_title"
                    variant="outlined"
                    label="Title"
                    fullWidth
                />
            </Grid>
            <Grid item sm={3}>
                {" "}
                <ReferenceInput
                    source="ehm_debit_head_id"
                    label="Debit Head"
                    reference="v1/accountingHead"
                    variant="outlined"
                    helperText={false}
                    fullWidth
                >
                    <AutocompleteInput
                        optionValue="ah_id"
                        optionText="ah_name"
                        resettable
                    />
                </ReferenceInput>
            </Grid>
            <Grid item sm={3}>
                {" "}
                <ReferenceInput
                    source="ehm_credit_head_id"
                    label="Credit Head"
                    reference="v1/accountingHead"
                    variant="outlined"
                    helperText={false}
                    fullWidth
                >
                    <AutocompleteInput
                        optionValue="ah_id"
                        optionText="ah_name"
                        resettable
                    />
                </ReferenceInput>
            </Grid>
        </>
    );
};

export default ExpenseHeadMappingForm;
