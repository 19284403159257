import { FC } from "react";
import { Create, CreateProps, SimpleForm } from "react-admin";

import DiscountForm from "../../../components/manageDatabase/discounts/DiscountForm";
import { useDocumentTitle } from "../../../hooks";

const DiscountCreate: FC<CreateProps> = ({ hasCreate, ...rest }) => {
    useDocumentTitle("Arogga | Discount Create");

    return (
        <Create {...rest}>
            <SimpleForm redirect="list" submitOnEnter={false}>
                <DiscountForm />
            </SimpleForm>
        </Create>
    );
};

export default DiscountCreate;
