import { FC, useState } from "react";
import {
    AutocompleteInput,
    CreateProps,
    Edit,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    minLength,
    minValue,
    required,
} from "react-admin";
import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import { useDocumentTitle } from "../../../hooks";
import UserEmployeeOptionTextRenderer from "../../../components/UserEmployeeOptionTextRenderer";

const HeadEdit: FC<CreateProps> = ({ permissions, hasCreate, ...rest }) => {
    useDocumentTitle("Arogga | Head Edit");

    const [groupType, setGroupType] = useState("");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...rest}
        >
            <SimpleForm
                redirect="list"
                toolbar={
                    <SaveDeleteToolbar
                        isSave
                        isDelete={permissions?.includes("accountingHeadDelete")}
                    />
                }
            >
                <TextInput
                    source="ah_id"
                    label="ID"
                    variant="outlined"
                    helperText={false}
                    disabled
                />
                <TextInput
                    source="ah_name"
                    label="Name"
                    variant="outlined"
                    helperText={false}
                    validate={[
                        required(),
                        minLength(2, "Name must be at least 2 characters long"),
                    ]}
                />
                <ReferenceInput
                    source="ah_parent_id"
                    label="Parent"
                    variant="outlined"
                    helperText={false}
                    reference="v1/accountingHead"
                    filter={groupType ? { _group_type: groupType } : null}
                >
                    <AutocompleteInput
                        optionValue="ah_id"
                        optionText="ah_name"
                        resettable
                    />
                </ReferenceInput>
                <ReferenceInput
                    source="ah_group_id"
                    label="Group"
                    variant="outlined"
                    helperText={false}
                    reference="v1/accountingHeadGroup"
                    onSelect={(data) => setGroupType(data.ahg_type)}
                    validate={[required()]}
                >
                    <AutocompleteInput
                        optionValue="ahg_id"
                        optionText="ahg_name"
                        resettable
                    />
                </ReferenceInput>
                <SelectInput
                    source="ah_type"
                    label="Type"
                    variant="outlined"
                    helperText={false}
                    choices={[
                        {
                            id: "asset",
                            name: "Asset",
                        },
                        {
                            id: "liability",
                            name: "Liability",
                        },
                        {
                            id: "equity",
                            name: "Owner Equity",
                        },
                        {
                            id: "income",
                            name: "Income",
                        },
                        {
                            id: "expense",
                            name: "Expense",
                        },
                    ]}
                    validate={[required()]}
                />
                <NumberInput
                    source="ah_opening_balance"
                    label="Opening Balance"
                    variant="outlined"
                    helperText={false}
                    validate={minValue(0, "Opening balance can't be negative")}
                />
                <SelectInput
                    source="ah_opening_balance_type"
                    label="Opening Balance Type"
                    variant="outlined"
                    helperText={false}
                    choices={[
                        {
                            id: "credit",
                            name: "Credit",
                        },
                        {
                            id: "debit",
                            name: "Debit",
                        },
                    ]}
                    validate={[required()]}
                />
                <NumberInput
                    source="ah_weight"
                    label="Weight"
                    variant="outlined"
                    helperText={false}
                />
                <TextInput
                    source="ah_description"
                    label="Description"
                    variant="outlined"
                    helperText={false}
                    minRows={2}
                    multiline
                />
                <ReferenceInput
                    source="ah_head_user_id"
                    label="Head Owner"
                    variant="outlined"
                    helperText={false}
                    reference="v1/users"
                    validate={[required()]}
                >
                    <AutocompleteInput
                        matchSuggestion={() => true}
                        optionValue="u_id"
                        helperText={false}
                        optionText={<UserEmployeeOptionTextRenderer />}
                        inputText={(record: {
                            u_name: string;
                            u_mobile: string;
                        }) =>
                            !!record
                                ? `${record.u_name} (${record.u_mobile})`
                                : ""
                        }
                        resettable
                    />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export default HeadEdit;
