import { SelectInput, required } from "react-admin";
import { useForm } from "react-final-form";

const ShiftTypeInput = (props) => {
    const form = useForm();

    return (
        <SelectInput
            source="sb_shift_type"
            label="Shift Type"
            helperText={false}
            choices={[
                { id: "regular", name: "Regular" },
                { id: "express", name: "Express" },
            ]}
            onChange={() => {
                form.change("sb_shift_schedule_id", undefined);
                form.change("sb_deliveryman_id", undefined);
                form.change("shipmentId", undefined);
            }}
            validate={[required()]}
            {...props}
        />
    );
};

export default ShiftTypeInput;
