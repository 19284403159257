import { FC } from "react";
import {
    Datagrid,
    NumberField,
    Pagination,
    ReferenceField,
    ReferenceManyField,
    Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField,
    useShowController,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import ColumnShowLayout from "../../../components/ColumnShowLayout";
import { useDocumentTitle } from "../../../hooks";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

const AssetShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Asset Show");
    const classes = useAroggaStyles();
    const { record } = useShowController(props);
    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="Assets">
                    {" "}
                    <ColumnShowLayout md={6}>
                        <TextField source="a_id" label="ID" />
                        <TextField source="a_asset_name" label="Name" />
                        <ReferenceField
                            source="a_category_id"
                            label="Category"
                            reference="v1/taxonomiesByVocabulary/asset_category"
                            sortBy="a_category_id"
                            link={false}
                        >
                            <TextField source="t_title" />
                        </ReferenceField>
                        <NumberField
                            source="a_purchase_cost"
                            label="Purchase Cost"
                        />
                        <NumberField source="a_asset_life" label="Asset Life" />
                        <NumberField
                            source="a_residual_value"
                            label="Residual Value"
                        />
                        <TextField
                            source="a_depreciation_method"
                            label="Depreciation Method"
                        />
                        <ReferenceField
                            source="a_department_id"
                            label="Department"
                            reference="v1/taxonomiesByVocabulary/department"
                            sortBy="a_department_id"
                            link={false}
                        >
                            <TextField source="t_title" />
                        </ReferenceField>
                        <TextField source="a_location" label="Location" />
                        <TextField
                            source="a_status"
                            label="Status"
                            className={classes.capitalize}
                        />
                        <AroggaDateField
                            source="a_purchase_date"
                            label="Purchase Date"
                        />
                        <AroggaDateField
                            source="a_created_at"
                            label="Created At"
                        />
                        <ReferenceField
                            source="a_created_by"
                            label="Created By"
                            reference="v1/users"
                            sortBy="a_created_by"
                            link="show"
                        >
                            <TextField source="u_name" />
                        </ReferenceField>
                        <AroggaDateField
                            source="a_modified_at"
                            label="Modified At"
                        />
                        <ReferenceField
                            source="a_modified_by"
                            label="Modified By"
                            reference="v1/users"
                            sortBy="a_modified_by"
                            link="show"
                        >
                            <TextField source="u_name" />
                        </ReferenceField>
                    </ColumnShowLayout>
                </Tab>
                <Tab label="History">
                    <ReferenceManyField
                        addLabel={false}
                        reference="v1/contentHistory"
                        target="ch_id"
                        pagination={<Pagination />}
                        filter={{
                            _entity_name: "Asset",
                            _field_name: "a_user_id",
                            _entity_id: record?.a_id,
                        }}
                    >
                        <Datagrid>
                            <ReferenceField
                                source="ch_created_by"
                                label="Created By"
                                reference="v1/users"
                                sortBy="ch_created_by"
                                link="show"
                            >
                                <TextField source="u_name" />
                            </ReferenceField>
                            <AroggaDateField
                                source="ch_created_at"
                                label="Created At"
                            />
                            <TextField
                                source="ch_id"
                                label="Asset History Id"
                            />
                            <TextField source="ch_entity_name" label="Name" />
                            <ReferenceField
                                source="ch_old_value"
                                label="Previous Assign"
                                reference="v1/users"
                                sortBy="ch_old_value"
                                link="show"
                            >
                                <TextField source="u_name" />
                            </ReferenceField>
                            <ReferenceField
                                source="ch_new_value"
                                label="New Assign"
                                reference="v1/users"
                                sortBy="ch_new_value"
                                link="show"
                            >
                                <TextField source="u_name" />
                            </ReferenceField>
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default AssetShow;
