const Check2Icon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={120}
        height={120}
        viewBox="0 0 120 120"
        fill="none"
        {...props}
    >
        <circle cx={60} cy={60} r={60} fill="#E8F5F5" />
        <mask
            id="mask0_985_7353"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x={32}
            y={32}
            width={56}
            height={56}
        >
            <rect x={32} y={32} width={56} height={56} fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_985_7353)">
            <path
                d="M56.7327 64.2003L51.716 59.1837C51.2882 58.7559 50.7438 58.542 50.0827 58.542C49.4216 58.542 48.8771 58.7559 48.4494 59.1837C48.0216 59.6114 47.8077 60.1559 47.8077 60.817C47.8077 61.4781 48.0216 62.0226 48.4494 62.4503L55.0993 69.1003C55.566 69.567 56.1105 69.8003 56.7327 69.8003C57.3549 69.8003 57.8994 69.567 58.366 69.1003L71.5494 55.917C71.9771 55.4892 72.191 54.9448 72.191 54.2837C72.191 53.6225 71.9771 53.0781 71.5494 52.6503C71.1216 52.2225 70.5771 52.0087 69.916 52.0087C69.2549 52.0087 68.7105 52.2225 68.2827 52.6503L56.7327 64.2003ZM59.9994 83.3337C56.7716 83.3337 53.7382 82.7212 50.8994 81.4962C48.0605 80.2712 45.591 78.6087 43.491 76.5087C41.391 74.4087 39.7285 71.9392 38.5035 69.1003C37.2785 66.2614 36.666 63.2281 36.666 60.0003C36.666 56.7725 37.2785 53.7392 38.5035 50.9003C39.7285 48.0614 41.391 45.592 43.491 43.492C45.591 41.392 48.0605 39.7295 50.8994 38.5045C53.7382 37.2795 56.7716 36.667 59.9994 36.667C63.2271 36.667 66.2605 37.2795 69.0994 38.5045C71.9382 39.7295 74.4077 41.392 76.5077 43.492C78.6077 45.592 80.2702 48.0614 81.4952 50.9003C82.7202 53.7392 83.3327 56.7725 83.3327 60.0003C83.3327 63.2281 82.7202 66.2614 81.4952 69.1003C80.2702 71.9392 78.6077 74.4087 76.5077 76.5087C74.4077 78.6087 71.9382 80.2712 69.0994 81.4962C66.2605 82.7212 63.2271 83.3337 59.9994 83.3337Z"
                fill="#1DA099"
            />
        </g>
    </svg>
);
export default Check2Icon;
