import { FC } from "react";
import { Edit, EditProps, SimpleForm } from "react-admin";

import TaxonomyForm from "../../../components/manageTaxonomy/TaxonomyForm";
import SaveDeleteToolbar from "../../../components/SaveDeleteToolbar";
import { useDocumentTitle } from "../../../hooks";

const TaxonomyEdit: FC<EditProps> = (props) => {
    useDocumentTitle("Arogga | Taxonomy Term Edit");

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            {...props}
        >
            <SimpleForm toolbar={<SaveDeleteToolbar isSave />}>
                <TaxonomyForm />
            </SimpleForm>
        </Edit>
    );
};

export default TaxonomyEdit;
