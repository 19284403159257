import { Divider, Tab, Tabs } from "@material-ui/core";
import { FC, useCallback, useState } from "react";
import {
    BulkDeleteButton,
    Button,
    List,
    ListContextProvider,
    ListProps,
    SimpleForm,
    useListContext,
} from "react-admin";

import { useDocumentTitle, useExport } from "../../../hooks";
import PurchaseOrderDatagrid from "../purchaseOrders/PurchaseOrderDatagrid";
import LocalItemDatagrid from "./LocalItemDatagrid";
import LocalVendorFilter from "./LocalVendorFilter";
import MoveToVendorDialog from "./MoveToVendorDialog";

const LocalVendorList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Local Vendors");

    const exporter = useExport(rest);
    const [tabValue, setTabValue] = useState(0);

    const BulkActionButtons = (props) => {
        const [isDialogOpen, setIsDialogOpen] = useState(false);

        return (
            <>
                <Button
                    label="Move to Local Vendor"
                    variant="contained"
                    onClick={() => setIsDialogOpen(true)}
                />
                {permissions?.includes("procurementStatusDelete") && (
                    <BulkDeleteButton {...props} />
                )}
                <SimpleForm toolbar={null}>
                    <MoveToVendorDialog
                        selectedIds={props?.selectedIds}
                        isDialogOpen={isDialogOpen}
                        setIsDialogOpen={setIsDialogOpen}
                    />
                </SimpleForm>
            </>
        );
    };

    return (
        <List
            {...rest}
            title="Local Vendors"
            filters={<LocalVendorFilter children={""} tabValue={tabValue} />}
            filterDefaultValues={{ tabValue }}
            resource={tabValue ? "v1/purchaseOrder" : undefined}
            sort={{ field: "id", order: "DESC" }}
            exporter={exporter}
            bulkActionButtons={!tabValue ? <BulkActionButtons /> : false}
        >
            <TabbedDatagrid
                tabValue={tabValue}
                setTabValue={setTabValue}
                permissions={permissions}
            />
        </List>
    );
};

export default LocalVendorList;

const TabbedDatagrid = ({ tabValue, setTabValue, permissions, ...rest }) => {
    const listContext = useListContext();
    const { filterValues, setFilters, displayedFilters } = listContext;

    const tabs = [
        { id: 0, name: "Local Items" },
        permissions?.includes("purchaseOrderView") && {
            id: 1,
            name: "P.O",
        },
        permissions?.includes("purchaseOrderView") && {
            id: 2,
            name: "History",
        },
    ].filter(Boolean);

    const handleOnChange = useCallback(
        (_, value) => {
            setTabValue(value);
            setFilters &&
                setFilters(
                    {
                        tabValue: value,
                        ...(value && {
                            _vendor_type: "local",
                        }),
                        ...(value === 2 && { _status: "received" }),
                    },
                    displayedFilters
                );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <>
            <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleOnChange}
                centered
            >
                {tabs.map((tab) => (
                    <Tab key={tab.id} label={tab.name} value={tab.id} />
                ))}
            </Tabs>
            <Divider />
            <ListContextProvider value={listContext}>
                {!tabValue && <LocalItemDatagrid {...rest} />}
                {tabValue === 1 && <PurchaseOrderDatagrid {...rest} />}
                {tabValue === 2 && (
                    <PurchaseOrderDatagrid from="vendorHistoryTab" {...rest} />
                )}
            </ListContextProvider>
        </>
    );
};
