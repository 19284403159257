import { Box, Grid, makeStyles } from "@material-ui/core";
import { FC, useState } from "react";
import { BooleanInput, ImageField } from "react-admin";
import { useFormState } from "react-final-form";

import OrderCalculate from "../../../../components/manageOrder/orders/OrderCalculate";
import ProductEntryForm from "../../../../components/manageOrder/orders/ProductEntryForm";
import ProductTable from "../../../../components/manageOrder/orders/productTable";

type ProductProps = {
    userCash: number;
};

const Product: FC<ProductProps> = ({ userCash }) => {
    const { values } = useFormState();
    const classesImg = useStylesImg();

    const [productOrderItems, setProductOrderItems] = useState([]);

    return (
        <>
            {!!values?.attachedFiles_po_prescriptions?.length && (
                <BooleanInput
                    label="Hide Prescription"
                    source="isPrescriptionHide"
                    helperText={false}
                />
            )}
            <Grid container>
                {!values.isPrescriptionHide && (
                    <Grid item lg={6} xl={6}>
                        <ImageField
                            source="attachedFiles_po_prescriptions"
                            src="src"
                            title="title"
                            classes={classesImg}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    lg={
                        !!values?.attachedFiles_po_prescriptions?.length &&
                        !values.isPrescriptionHide
                            ? 6
                            : 12
                    }
                    xl={
                        !!values?.attachedFiles_po_prescriptions?.length &&
                        !values.isPrescriptionHide
                            ? 6
                            : 12
                    }
                >
                    <ProductTable
                        productOrderItems={productOrderItems}
                        setProductOrderItems={setProductOrderItems}
                    />
                    <Box my={2}>
                        <OrderCalculate
                            userCash={userCash}
                            productOrderItems={productOrderItems}
                            setProductOrderItems={setProductOrderItems}
                        />
                    </Box>
                    <ProductEntryForm
                        productOrderItems={productOrderItems}
                        setProductOrderItems={setProductOrderItems}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Product;

const useStylesImg = makeStyles({
    list: {
        display: "block",
        margin: 0,
        padding: 0,
        marginRight: 20,
    },
    image: {
        width: "100%",
        maxHeight: "100%",
    },
});
