import { FC, useCallback, useState } from "react";
import {
    Edit,
    EditProps,
    FormTab,
    TabbedForm,
    useMutation,
    useNotify,
    useRedirect,
} from "react-admin";

import Shipment from "../../../components/manageOrder/orders/Shipment";
import { useDocumentTitle } from "../../../hooks";
import OrderEditActions from "./OrderEditActions";
import OrderEditToolbar from "./OrderEditToolbar";
import {
    Admin,
    History,
    Issue,
    Order,
    Payment,
    PreviousHistory,
    Product,
    Setup,
    User,
} from "./tabs";
import { orderOnSave } from "./utils/orderOnSave";
import GoogleMapComponent from "./tabs/GoogleMapComponent";

const OrderEdit: FC<EditProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Order Edit");

    const notify = useNotify();
    const redirect = useRedirect();
    const [mutate, { loading }] = useMutation();

    const [userCash, setUserCash] = useState(0);

    const onSave = useCallback(
        async (values) => {
            orderOnSave({
                notify,
                redirect,
                mutate,
                values,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mutate]
    );

    return (
        <Edit
            mutationMode={
                process.env.REACT_APP_NODE_ENV === "development"
                    ? "pessimistic"
                    : "optimistic"
            }
            actions={<OrderEditActions />}
            {...rest}
        >
            <TabbedForm
                toolbar={<OrderEditToolbar isLoading={loading} />}
                save={onSave}
                submitOnEnter={false}
            >
                <FormTab label="User">
                    <User permissions={permissions} setUserCash={setUserCash} />
                </FormTab>
                <FormTab label="Product">
                    <Product userCash={userCash} />
                </FormTab>
                <FormTab label="Order">
                    <Order permissions={permissions} />
                </FormTab>
                <FormTab label="Shipment">
                    <Shipment />
                </FormTab>
                <FormTab label="Issue">
                    <Issue />
                </FormTab>
                <FormTab label="Payment">
                    <Payment />
                </FormTab>
                <FormTab label="Setup">
                    <Setup permissions={permissions} />
                </FormTab>
                <FormTab label="Admin">
                    <Admin />
                </FormTab>
                {permissions?.includes("orderActivityLogView") && (
                    <FormTab label="History">
                        <History />
                    </FormTab>
                )}
                {+rest?.id <= 803158 &&
                    permissions?.includes("orderShipmentHistoryView") && (
                        <FormTab label="Previous History">
                            <PreviousHistory />
                        </FormTab>
                    )}
                {permissions?.includes("actionGpsDataView") && (
                    <FormTab label="Action">
                        <GoogleMapComponent />
                    </FormTab>
                )}
            </TabbedForm>
        </Edit>
    );
};

export default OrderEdit;
