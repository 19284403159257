import { FC, useState } from "react";
import {
    Button,
    Confirm,
    Datagrid,
    FunctionField,
    List,
    ListProps,
    NumberField,
    Record,
    ReferenceField,
    TextField,
    useRefresh,
} from "react-admin";
import DetailsViewDialog from "../../../components/manageFinance/threePlCollection/DetailsViewDialog";
import { useDocumentTitle, useExport, useRequest } from "../../../hooks";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import ThreePlCollectionFilter from "./ThreePlCollectionFilter";

const ThreePlCollectionList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | 3PL Collection Confirm");

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [showViewDetailsDialog, setShowViewDetailsDialog] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const exporter = useExport(rest);
    const classes = useAroggaStyles();
    const refresh = useRefresh();

    const onDialogClose = () => {
        setShowConfirmDialog(false);
        setShowCancelDialog(false);
    };

    const { isLoading, refetch } = useRequest(
        showConfirmDialog
            ? `/v1/approveThirdPartyCollection/${selectedCollection}`
            : `/v1/cancelThirdPartyCollection/${selectedCollection}`,
        {
            method: "POST",
            body: {},
        },
        {
            onSuccess: () => {
                onDialogClose();
                refresh();
            },
        }
    );

    const viewTableColumns = [
        "REFID",
        "Recipient Name",
        "Order time",
        "Delivery Date",
        "Invoice No.",
        "Destination Hub",
        "Payment Method",
        "Product Price",
        "Shipping Price",
        "Collected Amount(COD)",
        "Collected Amount(BOD)",
        "COD",
        "BOD",
        "Fragile Price",
        "Booking Price",
        "Parcel Status",
        "Assignee Name",
        "Paid Status",
        "Payment Date",
    ];

    return (
        <>
            <List
                {...rest}
                title="List of 3PL Collection"
                filters={<ThreePlCollectionFilter children={""} />}
                perPage={25}
                sort={{ field: "tc_id", order: "DESC" }}
                bulkActionButtons={false}
                exporter={exporter}
                {...rest}
            >
                <Datagrid>
                    <TextField source="id" />
                    <TextField label="Reference ID" source="tc_reference" />
                    <TextField source="tc_source" label="Courier" />

                    {/* <FunctionField
                        label="Payment Date"
                        sortBy="tc_data.Payment_Date_"
                        render={({ tc_data }: Record) => (
                            <span>{tc_data?.Payment_Date_}</span>
                        )}
                    />
                    <FunctionField
                        label="Order ID"
                        sortBy="tc_data.Invoices"
                        render={({ tc_data }: Record) => (
                            <span>{tc_data?.Invoices}</span>
                        )}
                    /> */}

                    <NumberField source="tc_amount" label="Amount" />

                    <FunctionField
                        label="Status"
                        sortBy="tc_status"
                        render={({ tc_status }: Record) => (
                            <span
                                className={`${classes.capitalize} ${
                                    (tc_status === "pending" &&
                                        classes.textBlack) ||
                                    (tc_status === "confirmed" &&
                                        classes.textGreen) ||
                                    (tc_status === "cancelled" &&
                                        classes.textRed)
                                }`}
                            >
                                {tc_status}
                            </span>
                        )}
                    />

                    <ReferenceField
                        source="tc_created_by"
                        label="Created By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <ReferenceField
                        source="tc_confirmed_by"
                        label="Approved By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>

                    <FunctionField
                        label="View"
                        render={(record: Record) =>
                            record?.tc_data?.length > 0 && (
                                <Button
                                    label="view"
                                    color="primary"
                                    variant="outlined"
                                    className={classes.whitespaceNowrap}
                                    onClick={() => {
                                        setSelectedCollection(record);
                                        setShowViewDetailsDialog(true);
                                    }}
                                />
                            )
                        }
                    />

                    {permissions?.includes("approve3PLCollection") && (
                        <FunctionField
                            label="Action"
                            render={(record: Record) => {
                                return (
                                    record?.tc_status === "pending" && (
                                        <>
                                            <Button
                                                label="Confirm"
                                                variant="contained"
                                                className={
                                                    classes.whitespaceNowrap
                                                }
                                                onClick={() => {
                                                    setSelectedCollection(
                                                        record?.id
                                                    );
                                                    setShowConfirmDialog(true);
                                                }}
                                            />
                                            <Button
                                                label="Cancel"
                                                variant="contained"
                                                color="secondary"
                                                className={
                                                    classes.whitespaceNowrap
                                                }
                                                onClick={() => {
                                                    setSelectedCollection(
                                                        record?.id
                                                    );
                                                    setShowCancelDialog(true);
                                                }}
                                                style={{
                                                    marginLeft: "4px",
                                                    backgroundColor: "#E57373",
                                                }}
                                            />
                                        </>
                                    )
                                );
                            }}
                        />
                    )}
                </Datagrid>
            </List>

            {(showConfirmDialog || showCancelDialog) && (
                <Confirm
                    isOpen={showConfirmDialog || showCancelDialog}
                    loading={isLoading}
                    title={`Are you sure you want to ${
                        showConfirmDialog ? "confirm" : "cancel"
                    } the collection?`}
                    content={false}
                    onConfirm={refetch}
                    onClose={() => {
                        setSelectedCollection(null);
                        setShowConfirmDialog(false);
                        setShowCancelDialog(false);
                    }}
                />
            )}

            {showViewDetailsDialog && (
                <DetailsViewDialog
                    data={selectedCollection}
                    open={showViewDetailsDialog}
                    onClose={() => {
                        setShowViewDetailsDialog(false);
                        setSelectedCollection(null);
                    }}
                />
            )}
        </>
    );
};
export default ThreePlCollectionList;
