import { Paper } from "@material-ui/core";
import { FC } from "react";
import { FormTab, TabbedForm, Title, Toolbar, useNotify } from "react-admin";
import { JsonInput } from "react-admin-json-view";

import { uploadDataProvider } from "../../../dataProvider";
import { useDocumentTitle, useRequest } from "../../../hooks";
import { logger } from "../../../utils/helpers";

const SettingsPage: FC = () => {
    useDocumentTitle("Arogga | Settings");
    const notify = useNotify();
    const { data, refetch: refetchOptionsMultiple } = useRequest(
        "/v1/optionsMultiple",
        {},
        { isPreFetching: true }
    );

    const onSave = async (data: object) => {
        try {
            await uploadDataProvider.create("v1/optionsMultiple", {
                data,
            });
            notify("Successfully save!", { type: "success" });
            refetchOptionsMultiple();
        } catch (err) {
            logger(err);
            notify("Something went wrong! Please try again!", {
                type: "error",
            });
        }
    };

    return (
        <Paper style={{ marginTop: 25 }}>
            <Title title="Settings" />
            <TabbedForm
                initialValues={data}
                syncWithLocation={false}
                toolbar={<Toolbar alwaysEnableSaveButton />}
                save={onSave}
            >
                <FormTab label="FAQs">
                    <JsonInput
                        source="faqs"
                        reactJsonOptions={{
                            name: null,
                            collapsed: false,
                            enableClipboard: false,
                            displayDataTypes: false,
                        }}
                    />
                </FormTab>
            </TabbedForm>
        </Paper>
    );
};

export default SettingsPage;
