import { FC } from "react";
import {
    Datagrid,
    FunctionField,
    List,
    ListProps,
    ReferenceField,
    TextField,
} from "react-admin";

import {
    useDocumentTitle,
    useExport,
    useNavigateFromList,
} from "../../../hooks";
import LocatiopnFilter from "./LocationFilter";

const LocationList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Location List");

    const exporter = useExport(rest);
    const navigateFromList = useNavigateFromList(
        "locationView",
        "locationEdit"
    );

    return (
        <List
            {...rest}
            title="List of Location"
            perPage={25}
            sort={{ field: "l_id", order: "DESC" }}
            filters={<LocatiopnFilter children={""} />}
            bulkActionButtons={permissions?.includes("locationDelete")}
            exporter={exporter}
        >
            <Datagrid rowClick={navigateFromList}>
                <TextField source="l_id" label="ID" />
                <TextField source="l_division" label="Division" />
                <TextField source="l_district" label="District" />
                <TextField source="l_area" label="Area" />
                <TextField source="l_postcode" label="Postcode" />
                {/* <TextField source="l_zone" label="Zone" /> */}
                <ReferenceField
                    source="l_zone_id"
                    label="Zone"
                    reference="v1/zone"
                    link={true}
                >
                    <TextField source="z_name" />
                </ReferenceField>
                <TextField source="l_courier" label="Courier" />
                <FunctionField
                    source="l_status"
                    label="Status"
                    render={(record) =>
                        `${record.l_status ? "Active" : "Inactive"}`
                    }
                />
            </Datagrid>
        </List>
    );
};

export default LocationList;
