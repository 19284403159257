import { FC } from "react";
import { Create, CreateProps, SimpleForm, TextInput } from "react-admin";

import { useDocumentTitle } from "../../../hooks";

const GenericCreate: FC<CreateProps> = ({ hasCreate, ...rest }) => {
    useDocumentTitle("Arogga | Generic Create");

    return (
        <Create {...rest}>
            <SimpleForm redirect="list">
                <TextInput
                    source="g_name"
                    label="Generic"
                    variant="outlined"
                    helperText={false}
                />
            </SimpleForm>
        </Create>
    );
};

export default GenericCreate;
