import { Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { FC, useEffect } from "react";
import {
    NumberInput,
    TextInput,
    minValue,
    useShowController,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import AroggaDialogActions from "../../../components/AroggaDialogActions";
import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import InlineArrayInput from "../../../components/InlineArrayInput";
import { useRequest } from "../../../hooks";
import { getQuantityLabel, isArray } from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";

type IssueApprovalDialogProps = {
    isDialogOpen: boolean;
    setIsDialogOpen: (isDialogOpen: boolean) => void;
    status: "approved" | "rejected";
    [key: string]: any;
};

const IssueApprovalDialog: FC<IssueApprovalDialogProps> = ({
    isDialogOpen,
    setIsDialogOpen,
    status,
    ...rest
}) => {
    const classes = useAroggaStyles();
    const { record } = useShowController(rest);
    const form = useForm();
    const { values, hasValidationErrors } = useFormState();

    useEffect(() => {
        values.i_is_calculate_additions = 0;
        form.change("ic", getInitialIssueChild());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record, isDialogOpen]);

    const getIssueChild = () => {
        return (
            values?.ic?.map(({ ic_id, ic_stock_mapping, unit_multiplier }) => ({
                ic_id,
                ...([
                    "missing",
                    "replacement",
                    "damage_refund",
                    "return",
                ].includes(issueType) && {
                    ic_stock_mapping:
                        ic_stock_mapping?.map(({ used_in_order, ...rest }) => ({
                            ...rest,
                            used_in_order: used_in_order * unit_multiplier || 0,
                        })) || [],
                }),
                ...(issueType === "damage_refund" &&
                    moneyCollect === "money" && {
                        ic_stock_mapping:
                            ic_stock_mapping?.map(
                                ({ used_in_order, ...rest }) => ({
                                    ...rest,
                                    used_in_order: used_in_order,
                                })
                            ) || [],
                    }),
            })) || []
        );
    };

    const issueType = record?.i_type;
    const moneyCollect = record.i_money_or_product;

    const { isLoading, refetch } = useRequest(
        `/v1/issueAction/${record?.i_id}/statusUpdate`,
        {
            method: "POST",
            body: {
                status,
                ...(values.i_is_calculate_additions && {
                    i_is_calculate_additions: 1,
                }),
                ...(["missing", "damage_refund", "replacement"].includes(
                    issueType
                ) &&
                    status === "approved" && {
                        ic: getIssueChild(),
                    }),
            },
        },
        {
            isRefresh: true,
            onSuccess: () => onDialogClose(),
        }
    );

    const getInitialIssueChild = () => {
        return (
            record?.ic?.map(
                ({
                    ic_id,
                    unit_label,
                    unit_multiplier,
                    product_base_unit,
                    ic_out_count,
                    ic_stock_mapping,
                }) => ({
                    ic_id,
                    unit_label,
                    unit_multiplier,
                    product_base_unit,
                    ic_out_count,
                    ic_stock_mapping:
                        (isArray(ic_stock_mapping) &&
                            ic_stock_mapping?.map(({ sd_id, sd_batch_no }) => ({
                                sd_id,
                                sd_batch_no,
                                used_in_order:
                                    ic_stock_mapping?.length === 1
                                        ? ic_out_count
                                        : "",
                            }))) ||
                        [],
                })
            ) || []
        );
    };

    const onDialogClose = () => setIsDialogOpen(false);

    const getQtyValidation = (scopedFormData, issueItems) => {
        const foundScopedFormData = issueItems?.find(
            (item) => item?.ic_id === scopedFormData?.ic_id
        );

        const totalQty = foundScopedFormData?.ic_stock_mapping?.reduce(
            (prevValue, { used_in_order }) => prevValue + +used_in_order,
            0
        );

        if (scopedFormData?.ic_out_count === totalQty) return undefined;

        return `Summation of all qty must be equal to ${scopedFormData?.ic_out_count}`;
    };

    const confirmationText = `Are you sure you want to ${
        status === "approved" ? "approve" : "reject"
    } this issue #${record?.i_id}?`;

    const isDetailsShow =
        status === "approved" &&
        ["missing", "damage_refund", "replacement"].includes(issueType) &&
        record?.ic?.some((item) => item?.ic_stock_mapping?.length > 1);

    const isCalculateAdditionShow =
        status === "approved" &&
        ((issueType === "missing" && record?.i_money_or_product === "money") ||
            issueType === "return");

    return (
        <Dialog open={isDialogOpen} onClose={onDialogClose} maxWidth="xl">
            <DialogTitle>
                {status === "rejected" ||
                (issueType !== "missing" && issueType !== "return")
                    ? `Issue #${record?.i_id}`
                    : confirmationText}
            </DialogTitle>
            <DialogContent>
                {isCalculateAdditionShow && (
                    <FormatedBooleanInput
                        source="i_is_calculate_additions"
                        label="ডেলিভারি ফি এবং ক্যাশব্যাক বাদ দিয়ে রিফান্ড হবে"
                    />
                )}
                {(status === "rejected" || !isCalculateAdditionShow) &&
                    confirmationText}
                {isDetailsShow && (
                    <InlineArrayInput
                        source="ic"
                        label="Details"
                        disableAdd
                        disableRemove
                        disableReordering
                        enableRenderProps
                    >
                        {({ getSource, scopedFormData }) => (
                            <div className={classes.inlineArrayInputBorder}>
                                <TextInput
                                    source={getSource("ic_id")}
                                    record={scopedFormData}
                                    label="ID"
                                    variant="outlined"
                                    helperText={false}
                                    disabled
                                />
                                <InlineArrayInput
                                    source={getSource("ic_stock_mapping")}
                                    record={scopedFormData}
                                    label=""
                                    disableAdd
                                    disableRemove
                                    disableItemLabel
                                    disableReordering
                                    enableRenderProps
                                >
                                    {({
                                        getSource,
                                        scopedFormData:
                                            stockMappingScopedFormData,
                                    }) => (
                                        <Grid container spacing={1}>
                                            <Grid item sm={3}>
                                                <TextInput
                                                    source={getSource("sd_id")}
                                                    record={
                                                        stockMappingScopedFormData
                                                    }
                                                    label="SDID"
                                                    variant="outlined"
                                                    helperText={false}
                                                    fullWidth
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item sm={4}>
                                                <TextInput
                                                    source={getSource(
                                                        "sd_batch_no"
                                                    )}
                                                    record={
                                                        stockMappingScopedFormData
                                                    }
                                                    label="Batch No"
                                                    variant="outlined"
                                                    helperText={false}
                                                    fullWidth
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <NumberInput
                                                    source={getSource(
                                                        "used_in_order"
                                                    )}
                                                    record={
                                                        stockMappingScopedFormData
                                                    }
                                                    label="Qty"
                                                    variant="outlined"
                                                    helperText={false}
                                                    className={
                                                        classes.whitespaceNowrap
                                                    }
                                                    validate={[
                                                        minValue(
                                                            0,
                                                            "Qty can't be negative"
                                                        ),
                                                        (_, allValues) =>
                                                            getQtyValidation(
                                                                scopedFormData,
                                                                allValues?.ic
                                                            ),
                                                    ]}
                                                    min={0}
                                                    max={
                                                        scopedFormData?.ic_out_count
                                                    }
                                                    disabled={
                                                        scopedFormData
                                                            ?.ic_stock_mapping
                                                            ?.length === 1
                                                    }
                                                    fullWidth
                                                />
                                                {issueType === "missing" ||
                                                moneyCollect === "product" ? (
                                                    <div
                                                        className={
                                                            classes.whitespaceNowrap
                                                        }
                                                    >
                                                        {getQuantityLabel({
                                                            qty: stockMappingScopedFormData?.used_in_order,
                                                            salesUnit:
                                                                scopedFormData?.unit_label,
                                                            baseUnit:
                                                                scopedFormData?.product_base_unit,
                                                            salesUnitMultiplier:
                                                                scopedFormData?.unit_multiplier,
                                                        })}
                                                    </div>
                                                ) : (
                                                    // TODO:
                                                    <>
                                                        <div
                                                            className={
                                                                classes.whitespaceNowrap
                                                            }
                                                        >
                                                            {
                                                                stockMappingScopedFormData.used_in_order
                                                            }{" "}
                                                            <span>
                                                                {
                                                                    scopedFormData.product_base_unit
                                                                }
                                                            </span>
                                                        </div>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                </InlineArrayInput>
                            </div>
                        )}
                    </InlineArrayInput>
                )}
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={onDialogClose}
                onConfirm={refetch}
                disabled={hasValidationErrors}
            />
        </Dialog>
    );
};

export default IssueApprovalDialog;
