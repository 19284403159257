import { FC } from "react";
import {
    AutocompleteInput,
    Filter,
    FilterProps,
    ReferenceInput,
    TextInput,
} from "react-admin";

import FormatedBooleanInput from "../../../components/FormatedBooleanInput";
import { default as EmpBankBranchRenderer } from "../../../components/manageHR/bank/EmpBankRenderer";
import EmpBranchRenderer from "../../../components/manageHR/bank/EmpBranchRenderer";

const BankFilter: FC<FilterProps> = (props) => {
    return (
        <Filter {...props}>
            <TextInput
                source="_search"
                label="Search"
                variant="outlined"
                resettable
                alwaysOn
            />

            <ReferenceInput
                source="b_id"
                label="Bank"
                variant="outlined"
                reference="v1/bank"
                alwaysOn
            >
                <AutocompleteInput
                    matchSuggestion={() => true}
                    optionValue="b_id"
                    helperText={false}
                    optionText={<EmpBankBranchRenderer />}
                    inputText={(record: { b_name: string }) =>
                        !!record ? `${record.b_name}` : ""
                    }
                    resettable
                />
            </ReferenceInput>
            <ReferenceInput
                source="b_id"
                label="Branch"
                variant="outlined"
                reference="v1/bank"
                alwaysOn
            >
                <AutocompleteInput
                    matchSuggestion={() => true}
                    optionValue="b_id"
                    helperText={false}
                    optionText={<EmpBranchRenderer />}
                    inputText={(record: { b_branch: string }) =>
                        !!record ? `${record.b_branch}` : ""
                    }
                    resettable
                />
            </ReferenceInput>

            <FormatedBooleanInput source="_active" label="Active" />
        </Filter>
    );
};

export default BankFilter;
