import AcUnitIcon from "@material-ui/icons/AcUnit";
import { FC } from "react";
import {
    ArrayField,
    BooleanField,
    Datagrid,
    FunctionField,
    Labeled,
    Link,
    NumberField,
    Record,
    ReferenceField,
    Show,
    ShowProps,
    SimpleForm,
    SimpleShowLayout,
    TextField,
} from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import AroggaImageField from "../../../components/AroggaImageField";
import ColumnShowLayout from "../../../components/ColumnShowLayout";
import Tag from "../../../components/Tag";
import Shipment from "../../../components/manageOrder/orders/Shipment";
import { useDocumentTitle } from "../../../hooks";
import {
    getQuantityLabel,
    getReadableSKU,
    isEmpty,
    numberFormat,
    toFixedNumber,
} from "../../../utils/helpers";
import { useAroggaStyles } from "../../../utils/useAroggaStyles";
import { History, Issue, PreviousHistory } from "./tabs";

const OrderShow: FC<ShowProps> = (props) => {
    useDocumentTitle("Arogga | Order Show");

    const classes = useAroggaStyles();

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ColumnShowLayout md={2} simpleShowLayout={false}>
                    <FunctionField
                        source="po_id"
                        label="ID"
                        render={({
                            po_id,
                            po_stock_unavailable_count,
                            po_is_urgent,
                            po_item_unavailable,
                            po_delivery_option,
                        }: Record) => (
                            <span className={classes.whitespaceNowrap}>
                                {po_id}{" "}
                                {!!po_stock_unavailable_count && (
                                    <span className={classes.textRed}>
                                        ({po_stock_unavailable_count})
                                    </span>
                                )}{" "}
                                {!!po_is_urgent && (
                                    <span className={classes.textRed}>
                                        (Urgent)
                                    </span>
                                )}
                                {!!po_item_unavailable && (
                                    <span className={classes.textRed}>
                                        (Unavailable)
                                    </span>
                                )}
                                <br />
                                {po_delivery_option === "express" && (
                                    <Tag name="Express" />
                                )}
                            </span>
                        )}
                    />
                    <ReferenceField
                        source="po_user_id"
                        label="User"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <TextField source="po_user_mobile" label="Mobile" />
                    <ReferenceField
                        source="po_warehouse_id"
                        label="Warehouse"
                        reference="v1/warehouse"
                        link="show"
                    >
                        <TextField source="w_title" />
                    </ReferenceField>
                    {/* <TextField source="po_zone" label="Zone" /> */}
                    <ReferenceField
                        source="po_zone_id"
                        label="Zone"
                        reference="v1/zone"
                        link={true}
                    >
                        <TextField source="z_name" />
                    </ReferenceField>
                    <FunctionField
                        source="po_status"
                        label="Status"
                        render={(record: Record) => (
                            <span
                                className={`${classes.capitalize} ${
                                    record?.po_status === "processing" &&
                                    classes.textRed
                                }`}
                            >
                                {record?.po_status}
                            </span>
                        )}
                    />
                    <NumberField source="po_item_count" label="Item Count" />
                    <BooleanField
                        source="po_address_checked"
                        label="Address Checked?"
                        looseValue
                    />
                    <TextField
                        source="po_subscription_frequency"
                        label="Subscription Frequency"
                    />
                    <TextField
                        source="po_delivery_option"
                        label="Delivery Option"
                        className={classes.capitalize}
                    />
                    <ReferenceField
                        source="po_delivery_shift_schedule_id"
                        label="Delivery Shift Schedule"
                        reference="v1/shiftSchedule"
                        link="show"
                    >
                        <FunctionField
                            render={({ s_title, ss_date }: Record) =>
                                `${s_title} (${ss_date})`
                            }
                        />
                    </ReferenceField>
                    <ReferenceField
                        source="po_product_discount_id"
                        label="Coupon"
                        reference="v1/productDiscount"
                        link="show"
                    >
                        <TextField source="pd_name" />
                    </ReferenceField>
                    <NumberField
                        source="po_coupon_discount_amount"
                        label="Coupon Discount Amount"
                    />
                    <NumberField source="po_subtotal" label="Subtotal" />
                    <NumberField
                        source="po_general_discount_amount"
                        label="General Discount Amount"
                    />
                    <NumberField
                        source="po_rounding_amount"
                        label="Rounding Amount"
                    />
                    <NumberField
                        source="po_manual_addition"
                        label="Manual Addition"
                    />
                    <NumberField
                        source="po_manual_deduction"
                        label="Manual Deduction"
                    />
                    <NumberField
                        source="po_user_cash_used"
                        label="User Cash Used"
                    />
                    <NumberField
                        source="po_user_bonus_used"
                        label="User Bonus Used"
                    />
                    <NumberField
                        source="po_delivery_fee"
                        label="Delivery Fee"
                    />
                    <NumberField
                        source="po_payable_total"
                        label="Total Payable"
                    />
                    <NumberField source="po_cash_back" label="Cash Back" />
                    <NumberField source="po_paid_amount" label="Paid Amount" />
                    <NumberField
                        source="po_refund_amount"
                        label="Refund Amount"
                    />
                    <NumberField source="po_due_amount" label="Due Amount" />
                    <FunctionField
                        label="Cold"
                        render={(record: Record) => {
                            if (!record.po_is_cold) return;
                            return <AcUnitIcon />;
                        }}
                    />
                    <TextField source="po_address" label="Address" />
                    <TextField source="po_user_notes" label="User Note" />
                    <TextField
                        source="po_internal_notes"
                        label="Internal Note"
                    />
                    <AroggaDateField
                        source="po_cancel_requested_at"
                        label="Cancel Requested At"
                    />
                    <ReferenceField
                        source="po_cancel_requested_by"
                        label="Cancel Requested By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <TextField
                        source="po_cancel_request_reason"
                        label="Cancel Request Reason"
                    />
                    <AroggaDateField
                        source="po_payment_eligible_time"
                        label="Payment Eligible Time"
                    />
                    <FunctionField
                        label="Payment Status"
                        render={(record: Record) => (
                            <span
                                className={`${classes.capitalize} ${
                                    record.po_payment_status === "pending" &&
                                    classes.textRed
                                }`}
                            >
                                {record?.po_payment_status}
                            </span>
                        )}
                    />
                    <AroggaDateField
                        source="po_payment_at"
                        label="Payment At"
                    />
                    <AroggaDateField
                        source="po_processing_at"
                        label="Processing At"
                    />
                    <ReferenceField
                        source="po_processing_by"
                        label="Processing By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="po_confirmed_at"
                        label="Confirmed At"
                    />
                    <ReferenceField
                        source="po_confirmed_by"
                        label="Confirmed By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="po_cancelled_at"
                        label="Cancelled At"
                    />
                    <ReferenceField
                        source="po_cancelled_by"
                        label="Cancelled By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="po_delivered_at"
                        label="Delivered At"
                    />
                    <ReferenceField
                        source="po_delivered_by"
                        label="Delivered By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="po_created_at"
                        label="Created At"
                    />
                    <ReferenceField
                        source="po_created_by"
                        label="Created By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                    <AroggaDateField
                        source="po_modified_at"
                        label="Modified At"
                    />
                    <ReferenceField
                        source="po_modified_by"
                        label="Modified By"
                        reference="v1/users"
                        link="show"
                    >
                        <TextField source="u_name" />
                    </ReferenceField>
                </ColumnShowLayout>
                <ArrayField source="poi" label="Items">
                    <Datagrid
                        expand={<ItemsExpand />}
                        isRowExpandable={(row) =>
                            !!row?.poi_stock_mapping?.length
                        }
                        classes={{ expandedPanel: classes.expandedPanel }}
                    >
                        <FunctionField
                            label="ID"
                            render={({ poi_id, poi_stock_mapping }: Record) => (
                                <span
                                    className={
                                        isEmpty(poi_stock_mapping)
                                            ? classes.whitespaceNowrap
                                            : ""
                                    }
                                >
                                    {poi_id}{" "}
                                    {isEmpty(poi_stock_mapping) && (
                                        <span
                                            className={
                                                isEmpty(poi_stock_mapping)
                                                    ? classes.textRed
                                                    : ""
                                            }
                                        >
                                            (Out of Stock)
                                        </span>
                                    )}
                                </span>
                            )}
                        />
                        <TextField source="poi_product_id" label="Product ID" />
                        <TextField
                            source="poi_product_variant_id"
                            label="Variant ID"
                        />
                        <FunctionField
                            label="Name"
                            render={({
                                poi_product_id,
                                p_name,
                                is_antibiotics,
                                is_controlled,
                            }: Record) => (
                                <span className={classes.whitespaceNowrap}>
                                    <Link
                                        to={`/v1/product/${poi_product_id}/show`}
                                    >
                                        {p_name}
                                    </Link>
                                    <br />
                                    <div className={classes.textRed}>
                                        {is_antibiotics && is_controlled
                                            ? "(Antibiotics, Controlled Drug)"
                                            : is_antibiotics
                                            ? "(Antibiotics)"
                                            : is_controlled
                                            ? "(Controlled Drug)"
                                            : ""}
                                    </div>
                                </span>
                            )}
                        />
                        <ReferenceField
                            source="poi_product_variant_id"
                            label="Variant"
                            reference="v1/productVariant"
                            link={false}
                        >
                            <FunctionField
                                render={(record: Record) =>
                                    getReadableSKU(record.pv_attribute)
                                }
                            />
                        </ReferenceField>
                        <TextField
                            source="p_form"
                            label="Form"
                            className={classes.capitalize}
                        />
                        <TextField source="p_strength" label="Strength" />
                        <ReferenceField
                            source="p_generic_id"
                            label="Generic"
                            reference="v1/generics"
                            link="show"
                        >
                            <TextField source="g_name" />
                        </ReferenceField>
                        <FunctionField
                            label="Brand"
                            onClick={(e) => e.stopPropagation()}
                            render={({ p_brand_id, p_brand }: Record) => (
                                <Link
                                    to={`/v1/productBrand/${p_brand_id}/show`}
                                >
                                    {p_brand}
                                </Link>
                            )}
                        />
                        <FunctionField
                            source="poi_product_qty"
                            label="Qty"
                            className={classes.whitespaceNowrap}
                            render={({
                                poi_product_qty: qty,
                                p_unit_label: salesUnit,
                                product_base_unit: baseUnit,
                                pu_multiplier: salesUnitMultiplier,
                            }: Record) =>
                                getQuantityLabel({
                                    qty,
                                    salesUnit,
                                    baseUnit,
                                    salesUnitMultiplier,
                                })
                            }
                        />
                        <FunctionField
                            source="poi_mrp_price"
                            label="Total MRP Price"
                            render={({
                                poi_product_qty,
                                poi_mrp_price,
                            }: Record) =>
                                numberFormat(
                                    toFixedNumber(
                                        poi_product_qty * poi_mrp_price
                                    )
                                )
                            }
                        />
                        <FunctionField
                            source="poi_supplier_price"
                            label="Total Supplier Price"
                            render={({
                                poi_product_qty,
                                poi_supplier_price,
                            }: Record) =>
                                numberFormat(
                                    toFixedNumber(
                                        poi_product_qty * poi_supplier_price
                                    )
                                )
                            }
                        />
                        <FunctionField
                            source="poi_discount_price"
                            label="Total Discount Price"
                            render={({
                                poi_product_qty,
                                poi_discount_price,
                            }: Record) =>
                                numberFormat(
                                    toFixedNumber(
                                        poi_product_qty * poi_discount_price
                                    )
                                )
                            }
                        />
                        <BooleanField
                            source="p_cold"
                            label="Cold?"
                            looseValue
                        />
                    </Datagrid>
                </ArrayField>
                <Labeled label="Shipments">
                    <SimpleForm toolbar={null}>
                        <Shipment />
                    </SimpleForm>
                </Labeled>
                <Labeled label="Issues">
                    <SimpleForm toolbar={null}>
                        <Issue />
                    </SimpleForm>
                </Labeled>
                {props?.permissions?.includes("orderActivityLogView") && (
                    <Labeled label="History">
                        <SimpleForm toolbar={null}>
                            <History />
                        </SimpleForm>
                    </Labeled>
                )}
                {+props?.id <= 803158 &&
                    props?.permissions?.includes(
                        "orderShipmentHistoryView"
                    ) && (
                        <Labeled label="Previous History">
                            <SimpleForm toolbar={null}>
                                <PreviousHistory />
                            </SimpleForm>
                        </Labeled>
                    )}
                <AroggaImageField
                    source="attachedFiles_po_prescriptions"
                    label="Prescriptions"
                />
            </SimpleShowLayout>
        </Show>
    );
};

export default OrderShow;

const ItemsExpand = () => (
    <Labeled label="Stock Mapping">
        <ArrayField source="poi_stock_mapping">
            <Datagrid>
                <TextField source="sd_id" label="SDID" />
                <TextField source="sd_batch_no" label="Batch No" />
                <TextField source="used_in_order" label="Used" />
            </Datagrid>
        </ArrayField>
    </Labeled>
);
