import { Paper } from "@material-ui/core";
import { FC, useState } from "react";
import {
    FormTab,
    TabbedForm,
    TextInput,
    Title,
    Toolbar,
    useNotify,
} from "react-admin";

import { uploadDataProvider } from "../../../dataProvider";
import { useDocumentTitle, useRequest } from "../../../hooks";
import { logger } from "../../../utils/helpers";
import AdminTab from "./AdminTab";
import BkashTab from "./BkashTab";
import CacheTab from "./CacheTab";
import CacheTabDialog from "./CacheTabDialog";
import CallTab from "./CallTab";
import ElasticSearchTab from "./ElasticSearchTab";
import ElasticSearchTabDialog from "./ElasticSearchTabDialog";
import SecureForm from "./SecureForm";

const SuperAdminSettingsPage: FC = () => {
    useDocumentTitle("Arogga | Super Admin Settings");

    const notify = useNotify();

    const [key, setKey] = useState("");
    const [tabs, setTabs] = useState(null);
    const [activeTab, setActiveTab] = useState("");
    const [formattedData, setFormattedData] = useState<any>({});
    const [openCacheDialog, setOpenCacheDialog] = useState(false);
    const [openElasticSearchDialog, setOpenElasticSearchDialog] =
        useState(false);

    const ADMIN_TAB_QUERY_PARAMS = `yt_video_key&yt_video_title&prescription_percent&call_time&call_percent&healthcare_percent&b2b_discount_percent&req_order_prescription`;
    const VERSIONS_TAB_QUERY_PARAMS = `android_app_current_version&android_app_min_version&ios_app_current_version&ios_app_min_version`;

    let QUERY_PARAMS;

    if (activeTab === "admin") {
        QUERY_PARAMS = ADMIN_TAB_QUERY_PARAMS;
    } else if (activeTab === "versions") {
        QUERY_PARAMS = VERSIONS_TAB_QUERY_PARAMS;
    } else {
        QUERY_PARAMS = activeTab;
    }

    const { data, refetch: fetchSuperOptionsMultiple } = useRequest(
        `/v1/superOptionsMultiple?${QUERY_PARAMS}`,
        {},
        {
            onSuccess: ({ data }) => {
                let formattedData = {};

                if (
                    [
                        "b2b_settings",
                        "secureSettings",
                        "settings",
                        "accountingSettings",
                        "roleSettings",
                        "purchaseRequisitionSetting",
                    ].includes(activeTab)
                ) {
                    formattedData[activeTab] = Object.keys(
                        data?.[activeTab] || {}
                    )?.map((item) => ({
                        key: item,
                        value: data?.[activeTab]?.[item],
                    }));
                } else {
                    formattedData = data;
                }

                setFormattedData((prev) => ({
                    ...prev,
                    ...formattedData,
                }));
            },
        }
    );

    const onSave = async (data) => {
        let formattedPayload;

        if (activeTab === "admin") {
            formattedPayload = {
                yt_video_key: data?.yt_video_key,
                yt_video_title: data?.yt_video_title,
                prescription_percent: data?.prescription_percent,
                call_time: data?.call_time,
                call_percent: data?.call_percent,
                healthcare_percent: data?.healthcare_percent,
                b2b_discount_percent: data?.b2b_discount_percent,
                req_order_prescription: data?.req_order_prescription,
            };
        } else if (activeTab === "versions") {
            formattedPayload = {
                android_app_current_version: data?.android_app_current_version,
                android_app_min_version: data?.android_app_min_version,
                ios_app_current_version: data?.ios_app_current_version,
                ios_app_min_version: data?.ios_app_min_version,
            };
        } else {
            const obj = {};
            data?.[activeTab]?.forEach((item) => (obj[item.key] = item.value));
            formattedPayload = {
                [activeTab]: obj,
            };
        }

        try {
            await uploadDataProvider.create("v1/superOptionsMultiple", {
                data: formattedPayload,
            });
            notify("Successfully save!", { type: "success" });
        } catch (err) {
            logger(err);
            notify("Something went wrong! Please try again!", {
                type: "error",
            });
        }
    };

    const handleTabChange = (key) => {
        !tabs?.[key] &&
            setTabs((prev) => ({
                ...prev,
                [key]: true,
            }));
        setActiveTab(key);
        !tabs?.[key] && fetchSuperOptionsMultiple();
    };

    return (
        <Paper style={{ marginTop: 25 }}>
            <Title title="Super Admin Settings" />
            <TabbedForm
                initialValues={formattedData}
                syncWithLocation={false}
                submitOnEnter={false}
                toolbar={<Toolbar alwaysEnableSaveButton />}
                save={onSave}
            >
                <FormTab label="Cache">
                    <CacheTab setOpen={setOpenCacheDialog} setKey={setKey} />
                    <CacheTabDialog
                        keyName={key}
                        open={openCacheDialog}
                        handleClose={() => setOpenCacheDialog(false)}
                    />
                </FormTab>
                <FormTab label="Search">
                    <ElasticSearchTab
                        setKey={setKey}
                        setOpen={setOpenElasticSearchDialog}
                    />
                    <ElasticSearchTabDialog
                        keyName={key}
                        open={openElasticSearchDialog}
                        handleClose={() => setOpenElasticSearchDialog(false)}
                    />
                </FormTab>
                <FormTab label="Bkash">
                    <BkashTab />
                </FormTab>
                <FormTab label="Call">
                    <CallTab />
                </FormTab>
                <FormTab
                    label="Admin"
                    // @ts-ignore
                    onClick={() => handleTabChange("admin")}
                >
                    <AdminTab />
                </FormTab>
                <FormTab
                    label="Versions"
                    // @ts-ignore
                    onClick={() => handleTabChange("versions")}
                >
                    <TextInput
                        source="android_app_current_version"
                        label="Android App Current Version"
                        variant="outlined"
                    />
                    <TextInput
                        source="android_app_min_version"
                        label="Android App Min Version"
                        variant="outlined"
                    />
                    <TextInput
                        source="ios_app_current_version"
                        label="IOS App Current Version"
                        variant="outlined"
                    />
                    <TextInput
                        source="ios_app_min_version"
                        label="IOS App Min Version"
                        variant="outlined"
                    />
                </FormTab>
                <FormTab
                    label="B2B"
                    // @ts-ignore
                    onClick={() => handleTabChange("b2b_settings")}
                >
                    <SecureForm source="b2b_settings" />
                </FormTab>
                <FormTab
                    label="Secure"
                    // @ts-ignore
                    onClick={() => handleTabChange("secureSettings")}
                >
                    <SecureForm source="secureSettings" />
                </FormTab>
                <FormTab
                    label="Config"
                    // @ts-ignore
                    onClick={() => handleTabChange("settings")}
                >
                    <SecureForm source="settings" />
                </FormTab>
                <FormTab
                    label="Accounting"
                    // @ts-ignore
                    onClick={() => handleTabChange("accountingSettings")}
                >
                    {tabs?.["accountingSettings"] && (
                        <SecureForm
                            source="accountingSettings"
                            referenceSource="v1/accountingHead"
                            inputType="referenceInput"
                            inputLabel="Accounting Head"
                            referenceField="ah_name"
                            record={formattedData?.accountingSettings}
                        />
                    )}
                </FormTab>
                <FormTab
                    label="Role Settings"
                    // @ts-ignore
                    onClick={() => handleTabChange("roleSettings")}
                >
                    {tabs?.["roleSettings"] && (
                        <SecureForm
                            source="roleSettings"
                            prePopulatedFields={[
                                "delivery",
                                "cash_collector",
                                "picker",
                                "packer",
                                "sorter",
                                "shelver",
                            ]}
                            referenceSource="v1/rank"
                            inputType="referenceInput"
                            inputLabel="Select Role"
                            referenceField="r_title"
                            record={formattedData?.roleSettings}
                        />
                    )}
                </FormTab>
                <FormTab
                    label="Purchase Req"
                    // @ts-ignore
                    onClick={() =>
                        handleTabChange("purchaseRequisitionSetting")
                    }
                >
                    {tabs?.["purchaseRequisitionSetting"] && (
                        <SecureForm
                            source="purchaseRequisitionSetting"
                            referenceSource="v1/rank"
                            inputType="referenceArrayInput"
                            inputLabel="Select Rank"
                            referenceField="r_title"
                            record={data}
                        />
                    )}
                </FormTab>
            </TabbedForm>
        </Paper>
    );
};

export default SuperAdminSettingsPage;
