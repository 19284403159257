import {
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
    makeStyles,
} from "@material-ui/core";
import { FC } from "react";
import { useFormState } from "react-final-form";

import { useRequest } from "../../../hooks";
import AroggaDialogActions from "../../AroggaDialogActions";

type ShippingDialogProps = {
    open: boolean;
    hasSubArea: any;
    formValues: any;
    subAreaId: any;
    handleShippingDialogClose: () => void;
    setIsLocationEditBtnClick: (isLocationEditBtnClick: boolean) => void;
    [key: string]: any;
};

const ShippingDialog: FC<ShippingDialogProps> = ({
    open,
    hasSubArea,
    formValues,
    subAreaId,
    currentSubArea,
    handleShippingDialogClose,
    setIsLocationEditBtnClick,
    ...rest
}) => {
    const classes = useStyles();
    const { values } = useFormState();
    const { isLoading, refetch } = useRequest(
        `/lab-order/api/v1/admin/orders/${rest.record?.id}/location`,

        {
            method: "PUT",
            body: {
                userLocationId: formValues?.userLocation?.id,
                subareaId: subAreaId?.id || "",
            },
        },
        {
            isRefresh: true,
            onSuccess: () => {
                handleShippingDialogClose();
                setIsLocationEditBtnClick(false);
            },
            onError: () => {
                handleShippingDialogClose();
            },
        }
    );

    return (
        <Dialog open={open} onClose={handleShippingDialogClose}>
            <DialogTitle>Are you sure this address is correct?</DialogTitle>
            <DialogContent>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.headingLabel}>
                                Division
                            </TableCell>
                            <TableCell>{values?.location?.division}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.headingLabel}>
                                City
                            </TableCell>
                            <TableCell>{values?.location?.district}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.headingLabel}>
                                Area
                            </TableCell>
                            <TableCell>{values?.location?.area}</TableCell>
                        </TableRow>
                        {hasSubArea ? (
                            <TableRow>
                                <TableCell>Sub Area</TableCell>
                                <TableCell>
                                    {currentSubArea?.sa_title}(
                                    {currentSubArea?.sa_zone})
                                </TableCell>
                            </TableRow>
                        ) : null}

                        <TableRow>
                            <TableCell className={classes.headingLabel}>
                                Home Address
                            </TableCell>
                            <TableCell>
                                {formValues?.userLocation?.address}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <AroggaDialogActions
                isLoading={isLoading}
                onDialogClose={handleShippingDialogClose}
                onConfirm={refetch}
            />
        </Dialog>
    );
};

const useStyles = makeStyles({
    headingLabel: {
        fontWeight: 600,
    },
});

export default ShippingDialog;
