import { FC } from "react";
import { List, ListProps, ReferenceField, TextField } from "react-admin";

import AroggaDateField from "../../../components/AroggaDateField";
import {
    useDocumentTitle,
    useExport,
    useNavigateFromList,
} from "../../../hooks";
import { CustomizableDatagrid } from "../../../lib";

const ExpenseHeadMappingList: FC<ListProps> = ({ permissions, ...rest }) => {
    useDocumentTitle("Arogga | Expense Head Mapping List");

    const exporter = useExport(rest);
    const navigateFromList = useNavigateFromList(
        "expenseHeadMappingView",
        "expenseHeadMappingEdit"
    );

    return (
        <List
            {...rest}
            title="List of Expense Head Mappings"
            // filters={<ExpenseEntryFilter children={""} />}
            perPage={25}
            exporter={exporter}
            bulkActionButtons={false}
        >
            <CustomizableDatagrid
                rowClick={navigateFromList}
                hideableColumns={["ehm_created_at", "ehm_created_by"]}
            >
                <TextField source="ehm_id" label="Id" />
                <TextField source="ehm_expense_title" label="Title" />
                <ReferenceField
                    source="ehm_debit_head_id"
                    label="Debit Head"
                    reference="v1/accountingHead"
                >
                    <TextField source="ah_name" />
                </ReferenceField>
                <ReferenceField
                    source="ehm_credit_head_id"
                    label="Credit Head"
                    reference="v1/accountingHead"
                >
                    <TextField source="ah_name" />
                </ReferenceField>
                <AroggaDateField source="ehm_created_at" label="Created At" />
                <ReferenceField
                    source="ehm_created_by"
                    label="Created By"
                    reference="v1/users"
                    sortBy="ehm_created_by"
                    link="show"
                >
                    <TextField source="u_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>
    );
};

export default ExpenseHeadMappingList;
